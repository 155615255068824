import React from "react";
import { Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import Tooltip from "@material-ui/core/Tooltip";

import CityHandler from "../helpers/CityHandler";
// import UnixTimeHandler from "../helpers/UnixTimeHandler";
import CategoryHandler from "../helpers/CategoryHandler";
import { useMediaQuery } from "react-responsive";

import noImage from "../images/noImage.png";

const mediaUrl = process.env.REACT_APP_MEDIA_DOMAIN + "/a/medium/";

const Ad = ({ ad, loadingAds, defaultGrid, idx }) => {
  return (
    <Link
      to={"/annons/" + ad.annons_id + "-" + ad.permalink}
      className="homepage-ad"
      style={{ color: "inherit", textDecoration: "none" }}
      key={idx}
    >
      <div className={defaultGrid ? "annons-wrapper-sing" : "annons-wrapper-three"}>
        <div className={ defaultGrid ? "annons-container" : "annons-container-three" }>
          <div className={defaultGrid ? "frame" : "frame-grid"}>
            {loadingAds ? (
              <Skeleton variant="rect" height={defaultGrid ? 200 : 150} />
            ) : (
              <img
                className="ad-img"
                id="ad-img"
                src={
                  ad.thumb === null
                    ? noImage
                    : mediaUrl + ad.thumb
                }
                alt="ad"
              />
            )}
          </div>
          <div className={ defaultGrid ? "ad-column-information" : "ad-column-information-grid" }>
            {loadingAds ? (
              <Skeleton variant="text" width={150} height={30} />
            ) : (
              <Tooltip title={ad.rubrik}>
                <span className={defaultGrid ? "annons-title" : "annons-title-grid"}>
                  {ad.rubrik}
                </span>
              </Tooltip>
            )}
            <div className="adSideContainer">
              <div className="adSide left">
                {loadingAds ? (
                  <Skeleton
                    variant="text"
                    width={100}
                    height={20}
                    style={{ marginTop: 20 }}
                  />
                ) : (
                  <span className={ defaultGrid ? "annons-price" : "annons-price-grid" }>
                    {formatMoney(ad.pris)}:-
                  </span>
                )}
              </div>
              <div className="adSide right">
                {loadingAds ? (
                  <Skeleton
                    variant="text"
                    width={372}
                    height={341}
                    style={{ marginTop: 24 }}
                  />
                ) : (
                  <span className={defaultGrid ? "annons-info" : "annons-info-grid"}>
                    {/*UnixTimeHandler(ad.uppdaterad)*/}
                    {CityHandler(ad.ort_id)}{" "}
                    {defaultGrid ? (
                      <span> | {CategoryHandler(ad.kategori_id)} </span>
                    ) : (
                      ""
                    )}{" "}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Ads = ({ ads, loadingAds, defaultGrid }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  let split = 0;
  
  if( ads.length > 12 ) {
    //plit = isMobile ? 8 : 9;
    split = 12;
  }

  let adunit = 17;

  return (
    <div className={ defaultGrid ? "annonserna rader" : "annonserna" }>
      {ads.map((ad, idx) => 
        parseInt((idx) % split) > 0 || idx === 0 || split === 0 ? (
          <Ad
            ad={ad}
            loadingAds={loadingAds}
            defaultGrid={defaultGrid}
            idx={idx}
            key={idx}
          />
        ) : (
          <React.Fragment key={idx}>
            <div className="home-midbanner" adunit={ isMobile ? "1" + adunit++ : "2" + adunit++ }></div>
            <Ad
              ad={ad}
              loadingAds={loadingAds}
              defaultGrid={defaultGrid}
              idx={idx}
              key={idx}
            />
          </React.Fragment>
        )
      )}
    </div>
  );
};

const formatMoney = (dough) => {
  return dough.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export default Ads;
