import React from "react";
import { Link } from "react-router-dom";

import Tooltip from "@material-ui/core/Tooltip";

import Facebook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";

const citiboardLogo = process.env.REACT_APP_MEDIA_DOMAIN + "/layout/logos/cb-logo-white.png";

const Footer = () => {
  const commonQuestionsLocation = {
    pathname: "/vanliga-fragor",
  };

  const cookiesLocation = {
    pathname: "/cookies",
  };

  const shopSafeLocation = {
    pathname: "/handla-tryggt",
  };

  const termsOfUseLocation = {
    pathname: "/anvandarvillkor",
  };

  const integrityPolicyLocation = {
    pathname: "/integritetspolicy",
  };

  const contactUsLocation = {
    pathname: "/kontakta-oss",
  };

  const aboutUsLocation = {
    pathname: "/om-oss",
  };

  return (
    <footer className="footer-container">
        <div className="footer-row">
          <div className="footer-column-logo">
            <figure className="footer-links-1">
              <img
                src={citiboardLogo}
                className="logo-with-text"
                alt="logo-with-text"
              />
            </figure>
            <span className="footer-links-1-text">
              Köp &amp; sälj på nätet - snabbt, enkelt och helt gratis!
            </span>
          </div>
          <div className="footer-column-information">
            <ul>
              <li className="footer-links">
                <Link
                  to={commonQuestionsLocation}
                  className="footer-link"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Vanliga frågor
                </Link>
              </li>
              <li className="footer-links">
                <Link
                  to={cookiesLocation}
                  className="footer-link"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Cookies
                </Link>
              </li>
              <li className="footer-links">
                <Link
                  to={shopSafeLocation}
                  className="footer-link"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Handla tryggt
                </Link>
              </li>
              <li className="footer-links">
                <Link
                  to={termsOfUseLocation}
                  className="footer-link"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Användarvillkor
                </Link>
              </li>
              <li className="footer-links">
                <Link
                  to={integrityPolicyLocation}
                  className="footer-link"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Integritetspolicy
                </Link>
              </li>
              <li className="footer-links">
                <Link
                  to={contactUsLocation}
                  className="footer-link"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Kontakta oss
                </Link>
              </li>
              <li className="footer-links">
                <Link
                  to={aboutUsLocation}
                  className="footer-link"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Om Citiboard
                </Link>
              </li>
              <li className="footer-links">
                <Link
                  to="/yrkesguider"
                  className="footer-link"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Yrkesguider
                </Link>
              </li>
              <li className="footer-links">
                <Link
                  to="/foretagssidor"
                  className="footer-link"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Företagssidor
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-column-icons">
            <Tooltip title="Besök vår Facebook">
              <a
                href="https://www.instagram.com/citiboard/"
                className="footer-icons-container"
              >
                <Instagram
                  className="footer-icons-facebook"
                  alt="instagram-logo"
                />
              </a>
            </Tooltip>
            <Tooltip title="Besök vår Instagram">
              <a
                href="https://www.facebook.com/Citiboard"
                className="footer-icons-container"
              >
                <Facebook
                  className="footer-icons-instagram"
                  alt="facebook-logo"
                />
              </a>
            </Tooltip>
          </div>
        </div>
    </footer>
  );
};

export default Footer;
