import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ChatIcon from "@material-ui/icons/Chat";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import PersonIcon from "@material-ui/icons/Person";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ChangeHistoryIcon from "@material-ui/icons/ChangeHistory";
import PlaceIcon from "@material-ui/icons/Place";
import ScheduleIcon from "@material-ui/icons/Schedule";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

import { addFavoriteAd, removeFavoriteAd } from "../redux/actions/favoriteAds";

import emptyAvatar from "./../images/empty-avatar.jpg";

import ActionDialogComponent from "../components/ActionDialogComponent";
import DialogComponent from "../components/DialogComponent";

import UnixTimeHandler from "../helpers/UnixTimeHandler";
import CityHandler from "../helpers/CityHandler";
import CategoryHandler from "../helpers/CategoryHandler";
import UrlHandler from "../helpers/UrlHandler";
import DecodeHtml from "../helpers/DecodeHtml";

import BannerHandler from "../helpers/BannerHandler";

import { useMediaQuery } from "react-responsive";

import { EmailShareButton, FacebookShareButton, WhatsappShareButton } from "react-share";
import { EmailIcon, FacebookIcon, WhatsappIcon } from "react-share";

//import Enklare from "../components/Enklare";

const ChosenAd = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [report, setReport] = useState(false);
  const [sentReport, setSentReport] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [messageSentFail, setMessageSentFail] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [madeOffer, setMadeOffer] = useState(false);
  const [notLoggedInMessage, setNotLoggedInMessage] = useState(false);
  const [cantSendToSelf, setCantSendToSelf] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [ad, setAd] = useState([]);
  const [favs, setFavs] = useState([]);
  const [fetchedFavs, setFetchedFavs] = useState(false);
  const pathname = window.location.pathname;

  const adId = pathname.includes( "," ) ? pathname.split(",")[1] : pathname.replace("/annons/", "").split("-")[0];
  
  var headers = {
    "x-auth": cookieId,
  };
  const [selectedImg, setSelectedImg] = useState();
  const [selectedImgNo, setSelectedImgNo] = useState(0);
  const isMobile = useMediaQuery({ query: "(max-width: 759px)" });

  const apiKey = "&key=AIzaSyC4xiuSZy9kxJgbfwZ-wH8zq_VkAYF9WX4";
  const region = "&region=SE";
  const mapOptions = "&zoom=6";

  const encodedAddress = encodeURIComponent(CityHandler(ad.ort_id));
  const sourceString =
    "https://www.google.com/maps/embed/v1/place?q=" +
    encodedAddress +
    apiKey +
    region +
    mapOptions;

  var isLoggedIn = props.user.user_id !== undefined;

  const mediaUrl = process.env.REACT_APP_MEDIA_DOMAIN + "/a/large/";

  const loginLocation = {
    pathname: "/loggain",
    state: { fromChosenAd: true },
  };

  const categoryLocation = {
    pathname: UrlHandler(ad.kategori_id),
    state: { fromAd: true },
  };

  const homeLocation = {
    pathname: "/",
    state: { fromChosenAd: true },
  };

  const notFoundLocation = {
    pathname: "/sidan-finns-ej",
    state: { fromChosenAd: true },
  };

  window.scrollTo(0, 0);

  const chooseNextImage = () => {
    let pics = document.querySelectorAll( ".small-pic-wrapper-svante ul li" );
    let newpic = 0;

    
    if( selectedImgNo === pics.length - 1 ) {
      newpic = 0;
    } else {
      newpic = selectedImgNo + 1;
    }
    
    chooseImage( pics[newpic].querySelector("img").alt, newpic );
    setSelectedImgNo( newpic );
  }

  const choosePrevImage = () => {
    let pics = document.querySelectorAll( ".small-pic-wrapper-svante ul li" );
    let newpic = 0;

    if( selectedImgNo === 0 ) {
      newpic = pics.length - 1;
    } else {
      newpic = selectedImgNo - 1;
    }
    
    chooseImage( pics[newpic].querySelector("img").alt, newpic );
    setSelectedImgNo( newpic );
  }

  const chooseImage = (image, no) => {
    setSelectedImgNo(no);
    setSelectedImg(mediaUrl + image);
  };

  useEffect(() => {
    if (favs.length === 0 && !fetchedFavs) {
      try {
        axios
          .get(process.env.REACT_APP_API_DOMAIN + "/cb/fav.list", { headers: headers })
          .then((res) => {
            var ids = [];
            for (let i = 0; i < res.data.length; i++) {
              ids.push(res.data[i].annons_id);
            }
            setFavs(ids);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
    setFetchedFavs(true);
  }, [favs, headers, fetchedFavs]);

  useEffect(() => {
    if (ad.length === 0) {
      try {
        axios
          .get(process.env.REACT_APP_API_DOMAIN + "/cb/annons.get?attributenames=true&annons_id=" + adId, {
            headers: headers,
          })
          .then((res) => {
            setAd(res.data);
            document.title =
              res.data.rubrik +
              " i " +
              res.data.ortnamn +
              " - Gratis annonsering på Citiboard";
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", res.data.shortdesc);
            axios
              .get(
                process.env.REACT_APP_API_DOMAIN + "/cb/user?user_id=" +
                  res.data.user_id,
                { headers: headers }
              )
              .then((res) => {
                setUser(res.data);
              });
            setLoading(false);
            })
          .catch(() => {
            window.location.replace(notFoundLocation.pathname);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
    BannerHandler();
  }, []);

  const doneReporting = () => {
    closeAllModals();
    window.location.replace("/");
  };

  const getFavsList = () => {
    try {
      axios
        .get(process.env.REACT_APP_API_DOMAIN + "/cb/fav.list", { headers: headers })
        .then((res) => {
          var ids = [];
          for (let i = 0; i < res.data.length; i++) {
            ids.push(res.data[i].annons_id);
          }
          setFavs(ids);
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  const saveAsFavourite = () => {
    var obj = {
      id: ad.annons_id,
    };
    try {
      axios
        .post(process.env.REACT_APP_API_DOMAIN + "/cb/fav.add", obj, {
          headers: headers,
        })
        .then(() => {
          getFavsList();
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  const removeAsFavourite = () => {
    var obj = {
      id: ad.annons_id,
    };
    try {
      axios
        .post(process.env.REACT_APP_API_DOMAIN + "/cb/fav.remove", obj, {
          headers: headers,
        })
        .then(() => {
          getFavsList();
        });
    } catch (err) {
      console.log(err.message);
    }
  };

  const submitReport = (props) => {
    setReport(false);
    const reason = props;
    const id = adId;

    const reportObj = {
      id,
      reason,
    };

    try {
      axios.post(process.env.REACT_APP_API_DOMAIN + "/cb/annons.report", reportObj, {
        headers: headers,
      }).then(
        setSentReport(true)
      );
    } catch (err) {
      console.log(err.message);
    }
  };

  const makeOffer = (props) => {
    const offerObj = {
      annonsid : adId,
      adurl : window.location.href
    };

    try {
      axios.post(process.env.REACT_APP_API_DOMAIN + "/treddy/makeOffer", offerObj, {
        headers: headers,
      }).then( (resp) => {
        if( resp.data.error !== undefined ) {
          alert( resp.data.error );
        } else {
          window.location.href = resp.data.url;
        }
        setMadeOffer( false )
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const sendMessageToUser = () => {
    if (props.user.user_id === user.user_id) {
      setCantSendToSelf(true);
    } else if (isLoggedIn) {
      setSendMessage(true);
    } else {
      setNotLoggedInMessage(true);
    }
  };

  const closeAllModals = () => {
    setReport(false);
    setSentReport(false);
    setNotLoggedInMessage(false);
    setSendMessage(false);
    setMessageSentFail(false);
    setCantSendToSelf(false);
    setMadeOffer(false);
  };

  const submitMessageToUser = (props) => {
    setSendMessage(false);
    const obj = {
      annons_id: adId,
      content: props,
    };

    axios
      .post(process.env.REACT_APP_API_DOMAIN + "/cb/msg_send", obj, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setMessageSent(true);
        } else {
          setMessageSentFail(true);
        }
      });
  };

  const toLogin = () => {
    closeAllModals();
    window.location.replace(loginLocation.pathname);
  };

  useEffect(()=> {
    if(ad.bilder !== undefined){
      chooseImage(ad.bilder[0], 0)
    }
  },[ad.bilder])

  const getUserContainer = () => {
    return (
            <div className="chosenad-container-user">
              <p className="chosenad-seller-text">Säljare</p>
              <div className="chosenad-profile-container">
                <div className="chosenad-profile-userdata">
                  <div className="chosenad-container-avatar">
                    {loading ? (
                      <Skeleton
                        variant="circle"
                        width={100}
                        height={100}
                        style={{ margin: "0 auto" }}
                      />
                    ) : (
                      <img
                        src={
                          user.image === "" || user.image === undefined
                            ? emptyAvatar
                            : "data:image/png;base64, " + user.image
                        }
                        alt="avatar"
                        className="createad-avatar"
                      />
                    )}
                  </div>
                  <div className="chosenad-container-user-name">
                    {loading ? (
                      <Skeleton variant="text" width={220} height={70} />
                    ) : (
                      <p>{user.name}</p>
                    )}
                  </div>
                </div>

                <div className="chosenad-profile-actions">
                  <div className="chosenad-container-sub-info">
                    {loading ? (
                      <Skeleton variant="text" width={220} height={50} />
                    ) : (
                      <Link
                        to={"/profil/" + user.user_id}
                        style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}
                      >
                            <PersonIcon />
                            <span className="chosenad-container-sub-info-text">
                              Gå till profil
                            </span>
                      </Link>
                    )}
                  </div>
                  {user.telefon !== "" ? (
                    <div className="chosenad-container-sub">
                      {loading ? (
                        <Skeleton variant="text" width={220} height={50} />
                      ) : (
                        <a
                          href={"callto:" + user.telefon}
                          className="chosenad-container-sub-link"
                        >
                          <PhoneEnabledIcon />
                          <span className="chosenad-container-sub-text">
                            Ring
                          </span>
                        </a>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="chosenad-container-sub"
                    onClick={() => sendMessageToUser()}
                  >
                    {loading ? (
                      <Skeleton variant="text" width={220} height={50} />
                    ) : (
                      <>
                        <ChatIcon />
                        <span className="chosenad-container-sub-text">
                          Skicka meddelande
                        </span>
                      </>
                    )}
                  </div>
                  {isLoggedIn ? (
                    favs.includes(adId) ? (
                      <div
                      className="chosenad-container-sub-info"
                          onClick={removeAsFavourite}
                        >
                          <FavoriteIcon />
                          <span className="chosenad-container-sub-info-text">
                            Glöm
                          </span>
                        </div>
                      ) : (
                        <div
                          className="chosenad-container-sub-info"
                          onClick={saveAsFavourite}
                        >
                          <FavoriteIcon />
                          <span className="chosenad-container-sub-info-text">
                            Spara
                          </span>
                        </div>
                      )
                      ) : (
                        ""
                        )}
                </div>
                {ad.pris < 15000 ? (
                  <>
                  <div className="chosenad-container-sub chosenad-treddybutton" onClick={() => makeOffer()}>
                    <img src="https://media.citiboard.se/layout/treddy-icon.png" /> 
                        Köp nu med säker betalning och frakt
                  </div>
                  <a className="chosenad-treddyinfolink" href="https://treddy.se/hur-funkar-det" target="_blank">Hur fungerar Treddy?</a>
                  </>
                ) : ''
                }
              </div>
            </div>
    )
  }

  const getUserMap = () => {
    return (
      <div className="chosenad-google-map">
        <iframe
          src={loading ? "about:blank" : sourceString}
          title="region-map"
          loading="lazy"
          width="100%"
          height="250"
          allowFullScreen={true}
        />
        <div className="chosenad-share-wrapper">
          <div className="chosenad-share-prefix">
            Dela annonsen
          </div>
          <EmailShareButton url={window.location.href} subject="Tips om annons" body="Jag vill tipsa om en annons på Citiboard: ">
            <EmailIcon size={32} round={false} />
          </EmailShareButton>
          <FacebookShareButton url={window.location.href} quote="Jag vill tipsa om en annons på Citiboard: ">
            <FacebookIcon size={32} round={false} />
          </FacebookShareButton>
          <WhatsappShareButton url={window.location.href} title="Jag vill tipsa om en annons på Citiboard: ">
            <WhatsappIcon size={32} round={false} />
          </WhatsappShareButton>
        </div>
      </div>
    )
  }

  return (
    <div className="chosenad mainwrapper">
      <div className="chosenad-banner-container">
        <div adunit="201">
          <Skeleton variant="rect" width={1200} height={250} style={{maxWidth:"95vw"}} />
        </div>
        <div adunit="212">
          <Skeleton variant="rect" width={350} height={120} style={{maxWidth:"95vw"}} />
        </div>
      </div>
      {loading ? (
        <Skeleton variant="rect" height={30} />
      ) : (
        <div className="chosenad-info-container">
          <Link
            to={homeLocation}
            className="chosenad-info-link"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <div className="chosenad-go-back">
              <ArrowBackIcon />
            </div>
          </Link>
          <Link
            to={homeLocation}
            className="chosenad-info-link"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <div className="chosenad-homepage">STARTSIDAN</div>
          </Link>
          <Link
            to={categoryLocation}
            className="chosenad-info-link"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="chosenad-category-container">
              | {CategoryHandler(ad.kategori_id)}
            </div>
          </Link>
          <div className="chosenad-info-title">
            <span>| {ad.bildtext}</span>
          </div>
          <div className="chosenad-info-where-and-when">
            <PlaceIcon />&nbsp;{ ad.ortnamn }&nbsp;<ScheduleIcon />&nbsp;{ UnixTimeHandler(ad.uppdaterad) }
          </div>
        </div>
      )}
      <div className="chosenad-container">
        <div className="chosenad-wrapper">
          <div className="chosenad-content-container">
            <div className="chosenad-container-block">
              <div className="chosenad-container-ad">
                  <div className="container-svante">
                    <div className="headImage-svante-wrapper">
                      <span className="chosenad-prev-image" onClick={choosePrevImage}><ChevronLeftIcon/></span>
                      <span className="chosenad-next-image" onClick={chooseNextImage}><ChevronRightIcon/></span>
                      { loading ? (
                          <Skeleton variant="rect" width={800} height={300} style={{maxWidth:"95vw"}} /> ) : (
                          <img src={selectedImg} className="headImage-svante" alt={ad.rubrik + " i " + ad.ortnamn} />
                      ) }
                    </div>
                    <div className="small-pic-wrapper-svante">
                      <ul>
                        {ad.annons_id !== undefined
                          ?
                          ad.bilder.map((url, idx) => (
                              <li key={idx}>
                                <img
                                  src={mediaUrl + url}
                                  alt={url}
                                  onClick={() => chooseImage(url, idx)}
                                  className="secondary-image-svante"
                                />
                              </li>
                              ))
                              : ""}
                      </ul>
                    </div>
                  </div>
                  <div className="title-price-container-svante">
                    <div className="title-svante">
                      { loading ? ( <Skeleton variant="rect" width={250} height={16} style={{maxWidth:"95vw"}} /> ) : ad.rubrik }
                    </div>
                    <div className="svanterutan">
                      <div className="price-svante">
                      { loading ? ( <Skeleton variant="rect" width={250} height={16} style={{maxWidth:"95vw"}} /> ) : formatMoney(ad.pris) + " kr" }
                      </div>
                      {/* <div className='ort-svante'>
                      { loading ? ( 
                        <Skeleton variant="rect" width={250} height={16} style={{maxWidth:"95vw"}} /> 
                        ) : 
                        <>
                          <PlaceIcon />&nbsp;{ ad.ortnamn }&nbsp;<ScheduleIcon />&nbsp;{ UnixTimeHandler(ad.uppdaterad) }
                        </>
                      }
                      </div> */}
                    </div>
                    <div className="beskrivning-svante" dangerouslySetInnerHTML={{__html: DecodeHtml(ad.info)}}>
                    </div>
                    { ad.attribut !== undefined && Object.keys(ad.attribut).length > 0
                    ?
                    <div className="annons-attribut">
                      <ul className="attribut">
                        { Object.keys(ad.attribut).map( (key, idx) => (
                          <li key={idx}>
                            <strong>{key}</strong>: {ad.attribut[key]}
                          </li>
                        ) ) }
                      </ul>
                    </div>
                    : ""
                  }
                  </div>
                <div>
                </div>
                {/* <hr /> */}
              </div>
              {/* <Enklare img={selectedImg} price={ad.pris}/> */}
              { !isMobile ?
                (<div className="chosenad-contact-container">
                  {getUserContainer()}
                  {getUserMap()}
                </div>)
               : 
                (<>
                  {getUserContainer()}
                  {getUserMap()}
                </>)
              }
            </div>
          </div>
           <div className="chosenad-info-bottom-container">
              <div className="chosenad-report-container" onClick={() => setReport(true)}>
                <ChangeHistoryIcon className="chosenad-report-icon" />
                <span>Anmäl annons</span>
              </div>
            </div>
        </div>
      </div>
      {report ? (
        <ActionDialogComponent
          title="Motivera din anmälan"
          onSubmit={submitReport}
          onClose={closeAllModals}
        />
      ) : sentReport ? (
        <DialogComponent
          title="Anmälan mottagen"
          text="Din anmälan har mottagits, tack för du hjälper till att behålla Citiboard säkert och tryggt för alla!"
          onClose={doneReporting}
        />
      ) : sendMessage ? (
        <ActionDialogComponent
          title="Skicka meddelande"
          onSubmit={submitMessageToUser}
          onClose={closeAllModals}
        />
      ) : notLoggedInMessage ? (
        <DialogComponent
          title="Inte inloggad"
          text="Du behöver vara inloggad för att skicka ett meddelande. Du kommer att omdirigeras till logga in sidan"
          accept={toLogin}
          onClose={closeAllModals}
        />
      ) : cantSendToSelf ? (
        <DialogComponent
          title="Du försöker svara på din egna annons"
          text="Du kan inte svara på din egna annons"
          onClose={closeAllModals}
        />
      ) : messageSent ? (
        <DialogComponent
          title="Meddelandet har skickats"
          text="Meddelandet har skickats"
          onClose={closeAllModals}
        />
      ) : messageSentFail ? (
        <DialogComponent
          title="Något gick fel"
          text="Något gick fel och meddelandet har inte skickats. Försök igen"
          onClose={closeAllModals}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    arrayOfIds: state.favoriteAds.arr,
    allAdsArr: state.fetchAllAds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFavoriteAd: (adId) => dispatch(addFavoriteAd(adId)),
    removeFavoriteAd: (adId) => dispatch(removeFavoriteAd(adId)),
  };
};

const formatMoney = (dough) => {
  if (dough === undefined) {
    return 0;
  }
  return dough.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(ChosenAd)
);
