import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-scroll";

import commonquestions from "../dumpdata/commonquestions.json";

const CommonQuestions = () => {
  const [loading, setLoading] = useState(true);
  const [clickedTitle, setClickedTitle] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  const handleClick = (props) => {
    if (props === clickedTitle) {
      setClickedTitle("");
    } else {
      setClickedTitle(props);
    }
  };

  return (
    <div className="commonquestions-container mainwrapper">
      <div className="commonquestions-wrapper">
        <div className="commonquestions-title-container">
          <h1 className="commonquestions-title">
            {loading ? (
              <Skeleton variant="text" width={250} height={50} />
            ) : (
              <span>Citiboard - Vanliga frågor</span>
            )}
          </h1>
        </div>
        <div className="commonquestions-content-container">
          {loading ? (
            <Skeleton variant="text" width={250} height={20} />
          ) : (
            <div className="commonquestions-block-title">
              <strong>Innehållsförteckning</strong>
            </div>
          )}
          <ul>
            {loading ? (
              <Skeleton variant="text" width={100} height={20} />
            ) : (
              <Link to="generellt" spy={true} smooth={true} offset={-100}>
                <li className="commonquestions-link">Generellt</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={120} height={20} />
            ) : (
              <Link to="skapaannons" spy={true} smooth={true} offset={-100}>
                <li className="commonquestions-link">Lägga in annonser</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={100} height={20} />
            ) : (
              <Link to="annonser" spy={true} smooth={true} offset={-100}>
                <li className="commonquestions-link">Dina annonser</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={130} height={20} />
            ) : (
              <Link to="soker" spy={true} smooth={true} offset={-100}>
                <li className="commonquestions-link">Hur man söker</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={130} height={20} />
            ) : (
              <Link to="koper" spy={true} smooth={true} offset={-100}>
                <li className="commonquestions-link">Hur man köper</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={120} height={20} />
            ) : (
              <Link to="rapportera" spy={true} smooth={true} offset={-100}>
                <li className="commonquestions-link">Rapportera</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={200} height={20} />
            ) : (
              <Link to="fragor" spy={true} smooth={true} offset={-100}>
                <li className="commonquestions-link">Synpunkter och frågor</li>
              </Link>
            )}
          </ul>
          <div className="commonquestions-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={130} height={20} />
              ) : (
                <div className="commonquestions-block-title">
                  <span id="generellt">Generellt</span>
                </div>
              )}
            </strong>
            <div className="commonquestions-block-question">
              <ul>
                {commonquestions.general.map((question, idx) =>
                  loading ? (
                    <Skeleton
                      variant="rect"
                      width={500}
                      height={40}
                      style={{ margin: 10 }}
                      key={idx}
                    />
                  ) : (
                    <li
                      className="commonquestions-block-link"
                      key={idx}
                      onClick={() => handleClick(question.question)}
                    >
                      <div className="commonquestions-question">
                        <div className="commonquestions-question-title">
                          <span>{question.question}</span>
                        </div>
                        <div
                          className={
                            clickedTitle === question.question
                              ? "commonquestions-answer-active"
                              : "commonquestions-answer"
                          }
                        >
                          <span>{question.answer}</span>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="commonquestions-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={200} height={20} />
              ) : (
                <div className="commonquestions-block-title">
                  <span id="skapaannons">Lägga in annonser:</span>
                </div>
              )}
            </strong>
            <div className="commonquestions-block-question">
              <ul>
                {commonquestions.addAds.map((question, idx) =>
                  loading ? (
                    <Skeleton
                      variant="rect"
                      width={500}
                      height={40}
                      key={idx}
                      style={{ margin: 10 }}
                    />
                  ) : (
                    <li
                      className="commonquestions-block-link"
                      key={idx}
                      onClick={() => handleClick(question.question)}
                    >
                      <div className="commonquestions-question">
                        <div className="commonquestions-question-title">
                          <span>{question.question}</span>
                        </div>
                        <div
                          className={
                            clickedTitle === question.question
                              ? "commonquestions-answer-active"
                              : "commonquestions-answer"
                          }
                        >
                          <span>{question.answer}</span>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="commonquestions-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={130} height={20} />
              ) : (
                <div className="commonquestions-block-title">
                  <span id="annonser">Dina annonser:</span>
                </div>
              )}
            </strong>
            <div className="commonquestions-block-question">
              <ul>
                {commonquestions.yourAds.map((question, idx) =>
                  loading ? (
                    <Skeleton
                      variant="rect"
                      width={500}
                      height={40}
                      key={idx}
                      style={{ margin: 10 }}
                    />
                  ) : (
                    <li
                      className="commonquestions-block-link"
                      key={idx}
                      onClick={() => handleClick(question.question)}
                    >
                      <div className="commonquestions-question">
                        <div className="commonquestions-question-title">
                          <span>{question.question}</span>
                        </div>
                        <div
                          className={
                            clickedTitle === question.question
                              ? "commonquestions-answer-active"
                              : "commonquestions-answer"
                          }
                        >
                          <span>{question.answer}</span>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="commonquestions-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={120} height={20} />
              ) : (
                <div className="commonquestions-block-title">
                  <span id="soker">Hur man söker</span>
                </div>
              )}
            </strong>
            <div className="commonquestions-block-question">
              <ul>
                {commonquestions.howToSearch.map((question, idx) =>
                  loading ? (
                    <Skeleton
                      variant="rect"
                      width={500}
                      height={40}
                      key={idx}
                      style={{ margin: 10 }}
                    />
                  ) : (
                    <li
                      className="commonquestions-block-link"
                      key={idx}
                      onClick={() => handleClick(question.question)}
                    >
                      <div className="commonquestions-question">
                        <div className="commonquestions-question-title">
                          <span>{question.question}</span>
                        </div>
                        <div
                          className={
                            clickedTitle === question.question
                              ? "commonquestions-answer-active"
                              : "commonquestions-answer"
                          }
                        >
                          <span>{question.answer}</span>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="commonquestions-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={120} height={20} />
              ) : (
                <div className="commonquestions-block-title">
                  <span id="koper">Hur man köper</span>
                </div>
              )}
            </strong>
            <div className="commonquestions-block-question">
              <ul>
                {commonquestions.buy.map((question, idx) =>
                  loading ? (
                    <Skeleton
                      variant="rect"
                      width={500}
                      height={40}
                      key={idx}
                      style={{ margin: 10 }}
                    />
                  ) : (
                    <li
                      className="commonquestions-block-link"
                      key={idx}
                      onClick={() => handleClick(question.question)}
                    >
                      <div className="commonquestions-question">
                        <div className="commonquestions-question-title">
                          <span>{question.question}</span>
                        </div>
                        <div
                          className={
                            clickedTitle === question.question
                              ? "commonquestions-answer-active"
                              : "commonquestions-answer"
                          }
                        >
                          <span>{question.answer}</span>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="commonquestions-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="commonquestions-block-title">
                  <span id="rapportera">Rapportera</span>
                </div>
              )}
            </strong>
            <div className="commonquestions-block-question">
              <ul>
                {commonquestions.report.map((question, idx) =>
                  loading ? (
                    <Skeleton
                      variant="rect"
                      width={500}
                      height={40}
                      key={idx}
                      style={{ margin: 10 }}
                    />
                  ) : (
                    <li
                      className="commonquestions-block-link"
                      key={idx}
                      onClick={() => handleClick(question.question)}
                    >
                      <div className="commonquestions-question">
                        <div className="commonquestions-question-title">
                          <span>{question.question}</span>
                        </div>
                        <div
                          className={
                            clickedTitle === question.question
                              ? "commonquestions-answer-active"
                              : "commonquestions-answer"
                          }
                        >
                          <span>{question.answer}</span>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
          <div className="commonquestions-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={120} height={20} />
              ) : (
                <div className="commonquestions-block-title">
                  <span id="fragor">Synpunkter och frågor</span>
                </div>
              )}
            </strong>
            <div className="commonquestions-block-question">
              <ul>
                {commonquestions.questions.map((question, idx) =>
                  loading ? (
                    <Skeleton
                      variant="rect"
                      width={500}
                      height={40}
                      key={idx}
                      style={{ margin: 10 }}
                    />
                  ) : (
                    <li
                      className="commonquestions-block-link"
                      key={idx}
                      onClick={() => handleClick(question.question)}
                    >
                      <div className="commonquestions-question">
                        <div className="commonquestions-question-title">
                          <span>{question.question}</span>
                        </div>
                        <div
                          className={
                            clickedTitle === question.question
                              ? "commonquestions-answer-active"
                              : "commonquestions-answer"
                          }
                        >
                          <span>{question.answer}</span>
                        </div>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonQuestions;
