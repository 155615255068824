import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import InboxIcon from "@material-ui/icons/Inbox";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HistoryIcon from "@material-ui/icons/History";
import SettingsIcon from "@material-ui/icons/Settings";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Skeleton from "@material-ui/lab/Skeleton";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

import { logoutUser } from "../redux/actions/user";

import emptyAvatar from "../images/empty-avatar.jpg";

const ProfileInfo = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [loading, setLoading] = useState(true);
  var user = props.user;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  const homeLocation = {
    pathname: "/",
    state: { fromHome: true },
  };

  const adLocation = {
    pathname: "/annons",
    state: { fromHome: true },
  };

  const mypageLocation = {
    pathname: "/min-sida",
    state: { fromHome: true },
  };

  const settingsLocation = {
    pathname: "/installningar",
    state: { fromHome: true },
  };

  const messageLocation = {
    pathname: "/meddelanden",
    state: { fromHome: true },
  };

  const savedLocation = {
    pathname: "/sparade",
    state: { fromHome: true },
  };

  const searchHistory = {
    pathname: "/sokhistorik",
    state: { fromHome: true },
  };

  const handleLogout = () => {
    if (cookieId !== "") {
      cookies.set("id", "", { path: "/" });
    }
    props.logoutUser();
    window.location.replace(homeLocation.pathname);
    return <Navigate to={homeLocation.pathname} />;
  };

  return (
    <div className="my-page-wrapper-profile">
      <figure className="my-page-figure">
        {loading ? (
          <Skeleton variant="rect" width={200} height={250} />
        ) : (
          <img
            src={user.avatar !== "" ? user.avatar : emptyAvatar}
            alt="profile"
            className="my-page-profile"
          />
        )}
      </figure>
      <div className="my-page-wrapper-profile-name">
        {loading ? (
          <Skeleton variant="text" width={170} height={30} />
        ) : (
          <p>{user.name}</p>
        )}
      </div>
      {user.phone !== "" ? (
        <div className={"my-page-wrapper-profile-phone"}>
          {loading ? (
            <Skeleton variant="text" width={150} height={20} />
          ) : (
            <p>Tel: {user.phone}</p>
          )}
        </div>
      ) : (
        ""
      )}
      <br />
      <div className="my-page-wrapper-blocks">
        {loading ? (
          <div className="my-page-wrapper-block">
            <Skeleton variant="text" width={200} height={25} />
          </div>
        ) : (
          <Link
            to={adLocation}
            className="my-page-links"
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="my-page-wrapper-block">
              <AddIcon className="my-page-wrapper-block-icon" />
              <span className="my-page-wrapper-block-text">Lägg in annons</span>
            </div>
          </Link>
        )}
        {loading ? (
          <div className="my-page-wrapper-block">
            <Skeleton variant="text" width={200} height={25} />
          </div>
        ) : (
          <Link
            to={messageLocation}
            className="my-page-links"
            style={{ textDecoration: "none", color: "white" }}
          >
            <div className="my-page-wrapper-block">
              <InboxIcon className="my-page-wrapper-block-icon" />
              <span className="my-page-wrapper-block-text">Inbox</span>
            </div>
          </Link>
        )}
        {loading ? (
          <div className="my-page-wrapper-block">
            <Skeleton variant="text" width={200} height={25} />
          </div>
        ) : (
          <Link
            to={mypageLocation}
            className="my-page-links"
            style={{ textDecoration: "none" }}
          >
            <div className="my-page-wrapper-block">
              <ChromeReaderModeIcon className="my-page-wrapper-block-icon" />
              <span className="my-page-wrapper-block-text">Min sida</span>
            </div>
          </Link>
        )}
        {loading ? (
          <div className="my-page-wrapper-block">
            <Skeleton variant="text" width={200} height={25} />
          </div>
        ) : (
          <Link
            to={savedLocation}
            className="my-page-links"
            style={{ textDecoration: "none" }}
          >
            <div className="my-page-wrapper-block">
              <FavoriteIcon className="my-page-wrapper-block-icon" />
              <span className="my-page-wrapper-block-text">Sparade </span>
            </div>
          </Link>
        )}
        {loading ? (
          <div className="my-page-wrapper-block">
            <Skeleton variant="text" width={200} height={25} />
          </div>
        ) : (
          <Link
            to={searchHistory}
            className="my-page-links"
            style={{ textDecoration: "none" }}
          >
            <div className="my-page-wrapper-block">
              <HistoryIcon className="my-page-wrapper-block-icon" />
              <span className="my-page-wrapper-block-text">
                Min sökhistorik
              </span>
            </div>
          </Link>
        )}
        {loading ? (
          <div className="my-page-wrapper-block">
            <Skeleton variant="text" width={200} height={25} />
          </div>
        ) : (
          <Link
            to={settingsLocation}
            className="my-page-links"
            style={{ textDecoration: "none" }}
          >
            <div className="my-page-wrapper-block">
              <SettingsIcon className="my-page-wrapper-block-icon" />
              <span className="my-page-wrapper-block-text">Inställningar</span>
            </div>
          </Link>
        )}
        {loading ? (
          <div className="my-page-wrapper-block">
            <Skeleton variant="text" width={200} height={25} />
          </div>
        ) : (
          <Link
            to={homeLocation}
            className="my-page-links"
            onClick={handleLogout}
            style={{ textDecoration: "none" }}
          >
            <div className="my-page-wrapper-block">
              <ExitToAppIcon className="my-page-wrapper-block-icon" />
              <span className="my-page-wrapper-block-text">Logga ut</span>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(ProfileInfo)
);
