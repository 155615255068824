import axios from "axios";

function BannerHandler() {
  const banners = document.querySelectorAll("div[adunit]");
  const adURL = process.env.REACT_APP_AD_DOMAIN + "/fetch?uri=";

  for (let i = 0; i < banners.length; i++) {
    let thisunit = banners[i];

    if (!(window.getComputedStyle(thisunit).display === "none")) {
      let adunit = thisunit.getAttribute("adunit");

      try {
        axios
          .get(
            adURL +
              encodeURIComponent(window.location.pathname) +
              "&slot=" +
              adunit
          )
          .then((res) => {
            thisunit.innerHTML = res.data;
            
            if( res.data.includes( "adsbygoogle" ) ) {
              try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
              } catch ( err ) {
                console.log( err.message, "Adslot: " + adunit );
              }
            } else {
              let bannerlink = thisunit.querySelector( "a" );
              bannerlink.addEventListener( "click", () => {
                registerBannerClick( bannerlink.getAttribute( "banner_id") );
              })
            }

            if( res.data.includes( "No banner for slot" ) ) {
              thisunit.style.display = "none";
            }
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  return null;
}

function registerBannerClick( banner_id ) {
  axios.get( process.env.REACT_APP_AD_DOMAIN + "/click?id=" + banner_id );
}

export default BannerHandler;