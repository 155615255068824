import React from "react";

const Advertising = () => {
  return (
    <div className="advertising-container">
      <div className="advertising-title">
        <h1>Annonsering på Citiboard</h1>
      </div>
      <div className="advertising-content-container">
        <p>Kontakta oss</p>
      </div>
    </div>
  );
};

export default Advertising;
