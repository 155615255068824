import React from "react";

const IntegrityPolicy = () => {
  return (
    <div className="termsofuse-container mainwrapper">
      <div className="termsofuse-wrapper">
        <div className="termsofuse-title-container">
          <h1 className="termsofuse-title">Citiboard – Integritetspolicy</h1>
        </div>
        <div className="termsofuse-content-container">
          <p>Citiboard AB arbetar för att säkerställa att din integritet skyddas vid användning av våra tjänster. Den här policyn fastställer hur dina personuppgifter behandlas och skyddas.</p>
          <p>Vi uppdaterar vår Integritetspolicy enligt GDPR löpande och den här texten kommer att uppdateras.</p>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">1. Allmänt</span>
                </div>
            </strong>
              <ul>
                <li>Denna policy avseende integritet och marknadsföring (”Integritetspolicy”) beskriver hur Citiboard AB, org. nr 559165-0568, Grusåsvägen 10, 352 45 Växjö, e-mail: kontakt@citiboard.se, (”Citiboard AB”, ”vi”) samlar in, använder, lämnar ut och lagrar dina personuppgifter.</li>
                <li>1.1 Integritetspolicyn gäller då Citiboard AB tillhandahåller tjänster och produkter i anslutning till köp, vid event anordnade av Citiboard AB och övrig i kontakt med Citiboard AB (besök på webbplats etc).</li>
                <li>1.2 Du ska alltid kunna känna dig trygg när du lämnar dina personuppgifter till oss. Vi vill med denna Integritetspolicy visa hur vi säkerställer att dina personuppgifter behandlas i enlighet med gällande personuppgiftslagstiftning.</li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">2. Personuppgiftsansvarig</span>
                </div>
            </strong>
              <ul>
                <li>Citiboard AB är personuppgiftsansvarig för Citiboard ABs behandling av dina personuppgifter och ansvarar för att sådan behandling sker i enlighet med tillämplig lagstiftning.</li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">3. När behandlar vi dina personuppgifter?</span>
                </div>
            </strong>
              <ul>
                <li>3.1 För att du ska kunna besöka vår webbplats, köpa våra tjänster, delta i våra events eller kontakta oss för information måste vi samla in och behandla personuppgifter om dig.</li>
                <li>3.2 Citiboard AB samlar in och behandlar personuppgifter om dig när du genomför en kontakt gentemot köpare eller säljare, besöker Citiboard ABs webbplats eller anmäler dig till event som Citiboard AB anordnar samt när du per telefon, post eller e-post kontaktar Citiboard AB.</li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">4. Vilka personuppgifter behandlar vi om dig?</span>
                </div>
            </strong>
              <ul>
                <li>4.1 För dig som kontaktar oss per post, e-post eller telefon</li>
                <li>De personuppgifter som Citiboard AB samlar in och behandlar om dig när du vänder dig till Citiboard AB och kontaktar oss per post, e-post eller telefon är:
                  <ul>
                    <li>Namn</li>
                    <li>Adress</li>
                    <li>E-postadress</li>
                    <li>Telefonnummer</li>
                  </ul>
                </li>
                <li>4.2 För dig som besöker vår webbplats</li>
                <li>De personuppgifter som Citiboard AB samlar in när du besöker vår webbsida är:
                  <ul>
                    <li>Namn</li>
                    <li>Telefonnummer</li>
                  </ul>
                </li>
                <li>4.3 För dig som anmäler dig till ett event som Citiboard AB anordnar</li>
                <li>De personuppgifter som Citiboard AB samlar in och behandlar om dig när du anmäler dig till ett event som Citiboard AB anordnar är:
                  <ul>
                    <li>Namn</li>
                    <li>Identifikationsnummer (organisationsnummer)</li>
                    <li>E-postadress</li>
                    <li>Telefonnummer</li>
                  </ul>
                </li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">5. Varför behandlar vi uppgifter om dig?</span>
                </div>
            </strong>
              <ul>
                <li>5.1 För dig som köper tjänster av Citiboard AB</li>
                <li>Citiboard AB behandlar dina personuppgifter för olika ändamål. Huvudsakligen behandlar Citiboard AB dina personuppgifter i syfte att:
                  <ul>
                    <li>Fullgöra våra förpliktelser gentemot dig som kund, såsom genomförande av köp och fakturering</li>
                    <li>Möjliggöra allmän kundvård och kundservice, som för att besvara frågor och rätta felaktiga uppgifter</li>
                    <li>Lämna information, sända eventinbjudningar och rikta marknadsföring, per post, e-post, SMS/MMS samt telefon avseende Citiboard ABs och utvalda samarbetspartners varor och tjänster</li>
                    <li>Följa tillämplig lagstiftning, till exempel bokföringslagar</li>
                  </ul>
                </li>
                <li>5.2 För dig som kontaktar Citiboard AB per post, mail eller telefon</li>
                <li>Citiboard AB behandlar dina personuppgifter för att:
                  <ul>
                    <li>Besvara frågor</li>
                    <li>Erbjuda våra tjänster</li>
                    <li>Kontakta dig</li>
                  </ul>
                </li>
                <li>5.3 För dig som besöker Citiboard ABs webbplats</li>
                <li>Citiboard AB behandlar dina personuppgifter för att:
                  <ul>
                    <li>Marknadsföra och erbjuda våra tjänster</li>
                  </ul>
                </li>
                <li>5.4 För dig som anmäler dig till ett event som Citiboard AB anordnar</li>
                <li>Citiboard AB behandlar dina personuppgifter för att:
                  <ul>
                    <li>Planera, administrera och genomföra event</li>
                    <li>Tillhandahålla information om event</li>
                    <li>Köpa in tillbörligt tilltugg och livsmedel för eventet</li>
                  </ul>
                </li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">6. De lagliga grunderna för vår behandling av dina personuppgifter</span>
                </div>
            </strong>
              <ul>
                <li>6.1 Citiboard AB baserar behandlingen av dina personuppgifter på ett antal lagliga grunder. Dessa
beskrivs i det här avsnittet.</li>
                <li>6.2 Vi behandlar bland annat dina personuppgifter för att kunna fullgöra avtalet med dig som kund. Däri inbegripet att utföra tjänster och fakturera till dig.</li>
                <li>6.3 En del av den behandling av personuppgifter som vi utför baserar sig på en så kallad intresseavvägning. Detta gäller den behandling vi utför för att kunna skicka erbjudanden till dig om våra tjänster. Citiboard AB har ett berättigat intresse av att marknadsföra sina tjänster.</li>
                <li>6.4 I vissa fall kan Citiboard AB ha en rättslig förpliktelse att behandla dina personuppgifter. Detta gäller till exempel för den behandling av personuppgifter som vi utför för att kunna uppfylla bokföringslagens krav.</li>
                <li>6.5 Citiboard AB behandlar inte dina personuppgifter genom automatiserat beslutsfattande, inbegripen profilering.</li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">7. Sammanställning av vår personuppgiftsbehandling</span>
                </div>
            </strong>
              <table className="tosTable">
                <thead>
                  <tr>
                    <th>Ändamål</th>
                    <th>Rättslig grund</th>
                    <th>Kategorisering av personuppgifter</th>
                    <th>Lagringstid</th>
                  </tr>
                </thead>
                <tbody>
                    <tr>
                      <td><strong>Genomförande av köp, fakturering och utförande av tjänst</strong></td>
                      <td>Fullgörande av avtal om köp och utförande av tjänst till kund</td>
                      <td>Namn, identifikationsnummer, adress, telefonnummer samt e-postadress</td>
                      <td>Under den ansvarstid som gäller för utförd tjänst</td>
                    </tr>
                    <tr>
                      <td><strong>För att upprätthålla och administrera bolagets bokföring</strong></td>
                      <td>Fullgöra en rättslig förpliktelse</td>
                      <td>Namn, identifikationsnummer samt adress</td>
                      <td>7 år efter det kalenderår då räkenskapsåret avslutades</td>
                    </tr>
                    <tr>
                      <td><strong>För att marknadsföra tjänster samt sända eventinbjudningar</strong></td>
                      <td>Intresseavvägning</td>
                      <td>Namn, adress, telefonnummer samt e- postadress</td>
                      <td>18 månader efter att du senast varit aktiv hos Citiboard AB, till exempel genom att besöka vår webbplats eller kontakta oss</td>
                    </tr>
                    <tr>
                      <td><strong>För att genomföra event som Citiboard AB anordnar</strong></td>
                      <td>Fullgörande av avtal om deltagande i event</td>
                      <td>Namn, identifikationsnummer, telefonnummer samt e- postadress</td>
                      <td>Till dess att aktuellt event genomförts och fakturerats</td>
                    </tr>
                    <tr>
                      <td><strong>För att besvara inkomna frågor och erbjuda våra tjänster då du kontaktar Citiboard AB</strong></td>
                      <td>Intresseavvägning</td>
                      <td>Namn, adress, telefonnummer samt e- postadress</td>
                      <td>Till dess att inkommen fråga eller kontaktförfrågan besvarats</td>
                    </tr>
                </tbody>
              </table>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">8. Hur länge sparar vi uppgifter om dig?</span>
                </div>
            </strong>
              <ul>
                <li>Dina personuppgifter sparas endast så länge som det finns ett behov av att spara dem för att fullgöra de ändamål som uppgifterna samlades in för i enlighet med denna Integritetspolicy. Citiboard AB kan komma att spara uppgifterna längre om det behövs för att följa lagkrav eller för att bevaka Citiboard ABs rättsliga intressen, t.ex. om det pågår en juridisk process.</li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">9. Vem lämnar vi ut personuppgifter till?</span>
                </div>
            </strong>
              <ul>
                <li>9.1 Citiboard AB lämnar inte ut dina uppgifter till tredje part i marknadsföringssyfte.</li>
                <li>9.2 Personuppgifter kan komma att lämnas ut av Citiboard AB om det är nödvändigt för att följa gällande lagkrav eller krav från myndigheter, för att tillvarata Citiboard ABs rättsliga intressen eller för att upptäcka, förebygga eller uppmärksamma bedrägerier och andra säkerhets- eller tekniska problem.</li>
                <li>9.3 Personuppgifter i form av namn och e-postadress kan lämnas till tredje part Treddy AB vid publicering av annons i syfte att tillhandahålla extratjänster som underlättar försäljning i form av betalningsförmedling och frakt.</li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">10. Ändring av integritetspolicy</span>
                </div>
            </strong>
              <ul>
                <li>Citiboard AB har rätt att när som helst ändra Integritetspolicyn.</li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">11. Skyddet av dina personuppgifter</span>
                </div>
            </strong>
              <ul>
                <li>Du ska alltid kunna känna dig trygg när du lämnar dina personuppgifter till oss. Citiboard AB har därför vidtagit de säkerhetsåtgärder som behövs för att skydda dina personuppgifter mot otillbörlig åtkomst, förändring och radering.</li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">12. Dina rättigheter</span>
                </div>
            </strong>
              <ul>
                <li>12.1 Citiboard AB ansvarar för att dina personuppgifter behandlas i enlighet med gällande lagstiftning.</li>
                <li>12.2 Citiboard AB kommer på din begäran eller på eget initiativ att rätta, avidentifiera, radera eller komplettera uppgifter som upptäcks vara felaktiga, ofullständiga eller missvisande.</li>
                <li>12.2 Citiboard AB kommer på din begäran eller på eget initiativ att rätta, avidentifiera, radera eller komplettera uppgifter som upptäcks vara felaktiga, ofullständiga eller missvisande.</li>
                <li>12.3 Du har rätt att begära
                  <ul>
                    <li>a. Tillgång till dina personuppgifter. Det innebär att du har rätt att begära ett registerutdrag över den behandling som vi genomför avseende dina personuppgifter. Du har även rätt att få en kopia av de personuppgifter som behandlas. Du har rätt att en gång per kalenderår, genom en skriftligt undertecknad ansökan, kostnadsfritt erhålla ett registerutdrag från om vilka personuppgifter som finns registrerade om dig, ändamålen med behandlingen och till vilka mottagare uppgifterna har lämnats eller ska lämnas ut. Du har även rätt att i registerutdraget få information om var uppgifterna har hämtats ifrån om personuppgifterna inte har samlats in från dig, förekomsten av automatiserat beslutsfattande (inklusive profilering) samt den förutsedda period under vilken uppgifterna kommer att lagras eller kriterierna som används för att fastställa denna period. Du har vidare rätt att i registerutdraget få information om dina övriga rättigheter som anges i denna punkt.</li>
                    <li>b. Rättelse av dina personuppgifter. Vi kommer på din begäran att så snabbt som möjligt rätta de felaktiga eller ofullständiga uppgifter vi behandlar om dig.</li>
                    <li>c. Radering av dina personuppgifter. Det innebär att du har rätt att begära att dina personuppgifter tas bort om de inte längre är nödvändiga för det ändamål de samlades in för. Det kan dock finnas lagkrav på att vi inte omedelbart får radera dina personuppgifter i exempelvis bokförings- och skattelagstiftning. Vi kommer då att avsluta den behandling som görs för andra ändamål än att följa lagstiftningen.</li>
                    <li>d. Begränsning av behandling. Det innebär att dina personuppgifter markeras så att de endast får behandlas för vissa avgränsade ändamål. Du kan bland annat begära begränsning när du anser att dina uppgifter är felaktiga och du har begärt rättelse enligt punkt 12.3 b). Under tiden uppgifternas korrekthet utreds kommer behandlingen av dem att begränsas.</li>
                    <li>e. Återkallande av samtycke. Om behandling grundar sig på avgivet samtycke har du rätt att när som helst återkalla ditt samtycke.</li>
                  </ul>
                </li>
                <li>12.4 Citiboard AB kommer att underrätta varje mottagare till vilken personuppgifterna har lämnats ut enligt punkten 9 ovan om eventuella rättelser eller radering av uppgifter samt begränsning av behandling av uppgifter.</li>
                <li>12.5 Du har rätt att begära dataportabilitet. Det innebär en rätt att under vissa förutsättningar få ut och överföra dina personuppgifter i ett strukturerat, allmänt använt och maskinläsbart format till en annan personuppgiftsansvarig.</li>
                <li>12.6 Du har rätt att invända mot personuppgiftsbehandling som utförs med stöd av en intresseavvägning. Om du invänder mot sådan behandling kommer vi enbart att fortsätta behandlingen om det finns berättigade skäl till behandlingen som väger tyngre än dina intressen.</li>
                <li>12.7 Om du inte vill att behandlar dina personuppgifter för direkt marknadsföring har du alltid rätt att invända mot sådan behandling genom att skicka ett mail till kontakt@citiboard.se. När vi har mottagit din invändning kommer vi att upphöra med att behandla personuppgifterna för sådant marknadsföringsändamål.</li>
                <li>12.8 Du har rätt att inge eventuella klagomål angående behandling av dina personuppgifter till Datainspektionen</li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">13. Cookies</span>
                </div>
            </strong>
              <ul>
                <li>Cookies är en liten textfil bestående av bokstäver och siffror som skickas från vår webbserver och sparas på din webbläsare eller enhet. På citiboardab.se samt på citiboard.se använder vi följande cookies:</li>
                <li>1) Sessionscookies (en tillfällig cookie som upphör när du stänger din webbläsare eller enhet).</li>
                <li>2) Varaktiga cookies (cookies som ligger kvar på din dator tills du tar bort dem eller de går ut).</li>
                <li>3) Förstapartscookies (cookies som sätts av webbplatsen du besöker).</li>
                <li>4) Tredjepartscookies (cookies som satts av en tredjeparts webbplats. Hos oss används dessa i första hand för analyser, t.ex. Google Analytics.).</li>
                <li>De cookies vi använder förbättrar normalt sett de tjänster vi erbjuder. Några av våra tjänster behöver cookies för att fungera korrekt, medan andra förbättrar tjänsterna för dig. Vi använder cookies för övergripande analytisk information avseende din användning av våra tjänster och för att spara funktionella inställningar så som språk och andra uppgifter. Du kan själv styra över användningen av cookies. Din webbläsare eller enhet ger dig möjlighet att ändra inställningarna för användningen och omfattningen av cookies. Gå till inställningarna för din webbläsare eller enhet för att lära dig mer om hur du justerar inställningarna för cookies. Exempel på sådant du kan justera är blockering av alla cookies, att endast acceptera förstapartscookies eller att radera cookies när du stänger ner din webbläsare. Ha i åtanke att vissa av våra tjänster kanske inte fungerar ifall du blockerar eller raderar cookies. Du kan läsa mer om cookies generellt på post- och telestyrelsens webbsida, pts.se.</li>
              </ul>
          </div>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">14. Kontaktinformation</span>
                </div>
            </strong>
              <ul>
                <li>Tveka inte att kontakta oss om du har några frågor om denna Integritetspolicy, behandlingen av dina personuppgifter eller om du vill begära ett registerutdrag. Vår kontaktinformation hittar du nedan.</li>
                <li>Citiboard AB</li>
                <li>Grusåsvägen 10</li>
                <li>352 45, Växjö kontakt@citiboard.se</li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrityPolicy;
