import React, { useState, useEffect } from "react";
import axios from "axios";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

import AppRoutes from "./router/Routes";

import { user } from "./redux/actions/user";

import "./App.css";

function App(props) {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const isLoggedIn = props.user.user_id !== undefined;

  useEffect(() => {
    if (cookieId !== undefined && cookieId !== "" && !isLoggedIn) {
      var headers = {
        "x-auth": cookieId,
      };
      axios
        .get(process.env.REACT_APP_API_DOMAIN + "/cb/user.validate", {
          headers: headers,
        })
        .then((response) => props.setUser(response.data))
        .then((response) => { 
          if( response.payload.email === "" ) {
            //window.location.replace( "/installningar" );
          } else if( response.payload.consent === 0 ) {
            //window.location.replace( "/installningar" );
          }
        });
    }
  }, [cookieId, isLoggedIn, props]);

  return <AppRoutes />;
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (userData) => dispatch(user(userData)),
  };
};

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(App));
