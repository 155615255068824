import MonthHandler from "./MonthHandler";

const UnixTimeHandler = (props) => {
  const unix_timestamp = props;
  const date = new Date(unix_timestamp * 1000);
  const dateNumber = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  var hours = date.getHours();
  var minutes = date.getMinutes();

  if (hours < 10) {
    hours = "0" + hours;
  }

  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  const formattedDate =
    dateNumber + " " + MonthHandler(month) + " " + hours + ":" + minutes;
  const formattedDateWithYear =
    dateNumber +
    " " +
    MonthHandler(month) +
    " " +
    year +
    " " +
    hours +
    ":" +
    minutes;
  const today = new Date();
  const todayDate = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();

  if (year === todayYear) {
    if (dateNumber === todayDate && month === todayMonth) {
      return "Idag " + hours + ":" + minutes;
    }
    return formattedDate;
  }

  return formattedDateWithYear;
};

export default UnixTimeHandler;
