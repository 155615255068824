import * as t from "../actionTypes";

const initialState = {
  arr: [],
};

const favoriteAds = (state = initialState, action) => {
  switch (action.type) {
    case t.ADD_FAVORITE_USER:
      return {
        ...state,
        arr: [...state.arr, action.payload],
      };
    case t.REMOVE_FAVORITE_USER:
      return {
        ...state,
        arr: [...state.arr.filter((user) => user !== action.payload)],
      };
    default:
      return state;
  }
};

export default favoriteAds;
