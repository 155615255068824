import { combineReducers } from "redux";
import favoriteAds from "./favoriteAds";
import favoriteUsers from "./favoriteUsers";
import fetchAllAds from "./fetchAllAds";
import user from "./user";
import messages from "./usersInbox";
import myAds from "./myAds";

const allReducers = combineReducers({
  favoriteAds,
  favoriteUsers,
  fetchAllAds,
  user,
  messages,
  myAds,
});

export default allReducers;
