import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
import { withCookies } from "react-cookie";

import YrkesAds from "../components/YrkesAds";
import YrkesAdsLoading from "../components/YrkesAdsLoading";

const Yrkesguiden = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));

  const [loadingAds, setLoadingAds] = useState(true);

  const [ads, setAds] = useState([]);
  const [meta, setMeta] = useState([]);

  const location = useLocation();

  useEffect(() => {
    console.log( location.pathname, window.location.pathname );
    if( location.pathname === window.location.pathname ) {
      let pathname = location.pathname;

      setLoadingAds(true);

      try {
        axios
          .get(
            process.env.REACT_APP_API_DOMAIN + "/cb/yrkesguide?slug=" + pathname
          )
          .then((res) => {
            setAds(res.data.annonser);
            setMeta(res.data.metadata);

            document.title = res.data.metadata.title;
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", res.data.metadata.ingress);
            setLoadingAds(false);
          })
          .catch((res) => {
            console.log(res);
            //window.location.assign("/sidan-finns-ej");
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [cookieId, location]);

  return (
    <div className="yrkesguide-wrapper">
      { meta.fbpixel !== "" && meta.fbpixel !== undefined ?
        <img height="1" width="1" style={{ display: "none" }} src={ "https://www.facebook.com/tr?id=" + meta.fbpixel + "&ev=PageView&noscript=1" } />
      : "" }
      <div className="yrkesguide-container yrkesguide-hero">
        <img src={process.env.REACT_APP_MEDIA_DOMAIN + "/layout/yrkesguide/" + meta.cover} />
        <h1>{meta.heading}</h1>
        <span className="ingress" style={{whiteSpace: "pre-wrap"}}>
          {meta.ingress}
        </span>
      </div>
      
      <div className="yrkesguide-container">
        <div className="yrkesguide-content">
          <div>
              {ads.length > 0 && !loadingAds ? (
                <YrkesAds ads={ads}  />
              ) : loadingAds ? (
                <YrkesAdsLoading />
              ) : (
                <h4>Inga yrkesutövare hittades på denna ort</h4>
              )}
          </div>
        </div>
      </div>
      
      <div className="yrkesguide-container">
        <div className="yrkesguide-content">
          <h2>{meta.lowerHeading}</h2>
          <div className="yrkesguide-description" style={{whiteSpace: "pre-wrap"}}>
            { meta.longDescription }
          </div>
          <div className="yrkesguide-subheading">
            { meta.subHeading } <a href={ meta.subHeadingLink }>Klicka här</a>
          </div>
        </div>
      </div>
    </div>
  );
};

/*const mapStateToProps = (state) => {
  return {
    allAdsArr: state.fetchAllAds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllAds: (ads) => dispatch(fetchAllAds(ads)),
  };
};*/

//export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Home));
export default withCookies((Yrkesguiden));
