const orter = {
  1: {
    name: "Hela Sverige"
  },
  2: {
    name: "Blekinge"
  },
  3: {
    name: "Dalarna"
  },
  4: {
    name: "Gävleborg"
  },
  5: {
    name: "Gotland"
  },
  6: {
    name: "Halland"
  },
  7: {
    name: "Jämtland"
  },
  8: {
    name: "Jönköping"
  },
  9: {
    name: "Kalmar"
  },
  10: {
    name: "Kronoberg"
  },
  11: {
    name: "Norrbotten"
  },
  12: {
    name: "Örebro"
  },
  13: {
    name: "Östergötland"
  },
  14: {
    name: "Skåne"
  },
  15: {
    name: "Södermanland"
  },
  16: {
    name: "Stockholm"
  },
  17: {
    name: "Uppsala"
  },
  18: {
    name: "Värmland"
  },
  19: {
    name: "Västerbotten"
  },
  20: {
    name: "Västernorrland"
  },
  21: {
    name: "Västmanland"
  },
  22: {
    name: "Västra Götaland"
  },
  23: {
    name: "Karlshamn"
  },
  24: {
    name: "Karlskrona"
  },
  25: {
    name: "Olofström"
  },
  26: {
    name: "Ronneby"
  },
  27: {
    name: "Sölvesborg"
  },
  28: {
    name: "Älvdalen"
  },
  29: {
    name: "Avesta"
  },
  32: {
    name: "Gagnef"
  },
  33: {
    name: "Hedemora"
  },
  34: {
    name: "Leksand"
  },
  35: {
    name: "Ludvika"
  },
  36: {
    name: "Malung-Sälen"
  },
  37: {
    name: "Mora"
  },
  38: {
    name: "Orsa"
  },
  39: {
    name: "Rättvik"
  },
  40: {
    name: "Säter"
  },
  41: {
    name: "Smedjebacken"
  },
  42: {
    name: "Vansbro"
  },
  43: {
    name: "Bollnäs"
  },
  45: {
    name: "Hofors"
  },
  47: {
    name: "Ljusdal"
  },
  48: {
    name: "Nordanstig"
  },
  49: {
    name: "Ockelbo"
  },
  50: {
    name: "Ovanåker"
  },
  55: {
    name: "Falkenberg"
  },
  56: {
    name: "Halmstad"
  },
  57: {
    name: "Hylte"
  },
  58: {
    name: "Kungsbacka"
  },
  59: {
    name: "Laholm"
  },
  60: {
    name: "Onsala"
  },
  61: {
    name: "Varberg"
  },
  62: {
    name: "Åre"
  },
  63: {
    name: "Berg"
  },
  64: {
    name: "Bräcke"
  },
  65: {
    name: "Härjedalen"
  },
  66: {
    name: "Krokom"
  },
  67: {
    name: "Östersund"
  },
  68: {
    name: "Ragunda"
  },
  69: {
    name: "Strömsund"
  },
  70: {
    name: "Aneby"
  },
  71: {
    name: "Eksjö"
  },
  72: {
    name: "Gislaved"
  },
  73: {
    name: "Gnosjö"
  },
  74: {
    name: "Habo"
  },
  75: {
    name: "Jönköping"
  },
  76: {
    name: "Mullsjö"
  },
  77: {
    name: "Nässjö"
  },
  78: {
    name: "Sävsjö"
  },
  79: {
    name: "Tranås"
  },
  80: {
    name: "Vaggeryd"
  },
  81: {
    name: "Värnamo"
  },
  82: {
    name: "Vetlanda"
  },
  83: {
    name: "Borgholm"
  },
  84: {
    name: "Emmaboda"
  },
  85: {
    name: "Högsby"
  },
  86: {
    name: "Hultsfred"
  },
  87: {
    name: "Kalmar"
  },
  88: {
    name: "Mönsterås"
  },
  89: {
    name: "Mörbylånga"
  },
  90: {
    name: "Nybro"
  },
  91: {
    name: "Oskarshamn"
  },
  92: {
    name: "Torsås"
  },
  93: {
    name: "Västervik"
  },
  94: {
    name: "Vimmerby"
  },
  95: {
    name: "Älmhult"
  },
  96: {
    name: "Alvesta"
  },
  97: {
    name: "Lessebo"
  },
  98: {
    name: "Ljungby"
  },
  99: {
    name: "Markaryd"
  },
  100: {
    name: "Tingsryd"
  },
  101: {
    name: "Uppvidinge"
  },
  102: {
    name: "Växjö"
  },
  103: {
    name: "Älvsbyn"
  },
  104: {
    name: "Arjeplog"
  },
  105: {
    name: "Arvidsjaur"
  },
  106: {
    name: "Boden"
  },
  107: {
    name: "Gällivare"
  },
  108: {
    name: "Haparanda"
  },
  109: {
    name: "Jokkmokk"
  },
  110: {
    name: "Kalix"
  },
  111: {
    name: "Kiruna"
  },
  112: {
    name: "Luleå"
  },
  113: {
    name: "Överkalix"
  },
  114: {
    name: "Övertorneå"
  },
  115: {
    name: "Pajala"
  },
  116: {
    name: "Piteå"
  },
  117: {
    name: "Askersund"
  },
  118: {
    name: "Degerfors"
  },
  119: {
    name: "Hällefors"
  },
  120: {
    name: "Hallsberg"
  },
  121: {
    name: "Karlskoga"
  },
  122: {
    name: "Kumla"
  },
  123: {
    name: "Laxå"
  },
  124: {
    name: "Lekeberg"
  },
  125: {
    name: "Lindesberg"
  },
  126: {
    name: "Ljusnarsberg"
  },
  127: {
    name: "Nora"
  },
  128: {
    name: "Örebro"
  },
  129: {
    name: "Åtvidaberg"
  },
  130: {
    name: "Boxholm"
  },
  131: {
    name: "Finspång"
  },
  132: {
    name: "Kinda"
  },
  133: {
    name: "Linköping"
  },
  134: {
    name: "Mjölby"
  },
  135: {
    name: "Motala"
  },
  136: {
    name: "Norrköping"
  },
  137: {
    name: "Ödeshög"
  },
  138: {
    name: "Söderköping"
  },
  139: {
    name: "Vadstena"
  },
  140: {
    name: "Valdemarsvik"
  },
  141: {
    name: "Ydre"
  },
  142: {
    name: "Ängelholm"
  },
  143: {
    name: "Åstorp"
  },
  144: {
    name: "Båstad"
  },
  145: {
    name: "Bjuv"
  },
  146: {
    name: "Bromölla"
  },
  147: {
    name: "Burlöv"
  },
  148: {
    name: "Eslöv"
  },
  149: {
    name: "Hässleholm"
  },
  150: {
    name: "Helsingborg"
  },
  151: {
    name: "Höganäs"
  },
  152: {
    name: "Höör"
  },
  153: {
    name: "Hörby"
  },
  154: {
    name: "Kävlinge"
  },
  155: {
    name: "Klippan"
  },
  156: {
    name: "Kristianstad"
  },
  157: {
    name: "Landskrona"
  },
  158: {
    name: "Lomma"
  },
  159: {
    name: "Lund"
  },
  160: {
    name: "Malmö"
  },
  161: {
    name: "Örkelljunga"
  },
  162: {
    name: "Osby"
  },
  163: {
    name: "Östra Göinge"
  },
  164: {
    name: "Perstorp"
  },
  165: {
    name: "Simrishamn"
  },
  166: {
    name: "Sjöbo"
  },
  167: {
    name: "Skurup"
  },
  168: {
    name: "Staffanstorp"
  },
  169: {
    name: "Svalöv"
  },
  170: {
    name: "Svedala"
  },
  171: {
    name: "Tomelilla"
  },
  172: {
    name: "Trelleborg"
  },
  173: {
    name: "Vellinge"
  },
  174: {
    name: "Ystad"
  },
  175: {
    name: "Eskilstuna"
  },
  176: {
    name: "Flen"
  },
  177: {
    name: "Gnesta"
  },
  178: {
    name: "Katrineholm"
  },
  179: {
    name: "Nyköping"
  },
  180: {
    name: "Oxelösund"
  },
  181: {
    name: "Strängnäs"
  },
  182: {
    name: "Trosa"
  },
  183: {
    name: "Vingåker"
  },
  184: {
    name: "Botkyrka"
  },
  185: {
    name: "Danderyd"
  },
  186: {
    name: "Ekerö"
  },
  187: {
    name: "Haninge"
  },
  188: {
    name: "Huddinge"
  },
  189: {
    name: "Järfälla"
  },
  190: {
    name: "Lidingö"
  },
  191: {
    name: "Nacka"
  },
  192: {
    name: "Norrtälje"
  },
  193: {
    name: "Nykvarn"
  },
  194: {
    name: "Nynäshamn"
  },
  195: {
    name: "Österåker"
  },
  196: {
    name: "Salem"
  },
  197: {
    name: "Sigtuna"
  },
  198: {
    name: "Södertälje"
  },
  199: {
    name: "Sollentuna"
  },
  200: {
    name: "Solna"
  },
  201: {
    name: "Stockholm"
  },
  202: {
    name: "Sundbyberg"
  },
  203: {
    name: "Täby"
  },
  204: {
    name: "Tyresö"
  },
  205: {
    name: "Upplands Väsby"
  },
  206: {
    name: "Upplands-Bro"
  },
  207: {
    name: "Vallentuna"
  },
  208: {
    name: "Värmdö"
  },
  209: {
    name: "Vaxholm"
  },
  210: {
    name: "Älvkarleby"
  },
  211: {
    name: "Enköping"
  },
  212: {
    name: "Håbo"
  },
  213: {
    name: "Heby"
  },
  214: {
    name: "Knivsta"
  },
  215: {
    name: "Östhammar"
  },
  216: {
    name: "Tierp"
  },
  217: {
    name: "Uppsala"
  },
  218: {
    name: "Årjäng"
  },
  219: {
    name: "Arvika"
  },
  220: {
    name: "Eda"
  },
  221: {
    name: "Filipstad"
  },
  222: {
    name: "Forshaga"
  },
  223: {
    name: "Grums"
  },
  224: {
    name: "Hagfors"
  },
  225: {
    name: "Hammarö"
  },
  226: {
    name: "Karlstad"
  },
  227: {
    name: "Kil"
  },
  228: {
    name: "Kristinehamn"
  },
  229: {
    name: "Munkfors"
  },
  230: {
    name: "Säffle"
  },
  231: {
    name: "Storfors"
  },
  232: {
    name: "Sunne"
  },
  233: {
    name: "Torsby"
  },
  234: {
    name: "Åsele"
  },
  235: {
    name: "Bjurholm"
  },
  236: {
    name: "Dorotea"
  },
  237: {
    name: "Lycksele"
  },
  238: {
    name: "Malå"
  },
  239: {
    name: "Nordmaling"
  },
  240: {
    name: "Norsjö"
  },
  241: {
    name: "Robertsfors"
  },
  242: {
    name: "Skellefteå"
  },
  243: {
    name: "Sorsele"
  },
  244: {
    name: "Storuman"
  },
  245: {
    name: "Umeå"
  },
  246: {
    name: "Vännäs"
  },
  247: {
    name: "Vilhelmina"
  },
  248: {
    name: "Vindeln"
  },
  249: {
    name: "Ånge"
  },
  250: {
    name: "Härnösand"
  },
  251: {
    name: "Kramfors"
  },
  252: {
    name: "Örnsköldsvik"
  },
  253: {
    name: "Sollefteå"
  },
  254: {
    name: "Sundsvall"
  },
  255: {
    name: "Timrå"
  },
  256: {
    name: "Arboga"
  },
  257: {
    name: "Fagersta"
  },
  258: {
    name: "Hallstahammar"
  },
  259: {
    name: "Köping"
  },
  260: {
    name: "Kungsör"
  },
  261: {
    name: "Norberg"
  },
  262: {
    name: "Sala"
  },
  263: {
    name: "Skinnskatteberg"
  },
  264: {
    name: "Surahammar"
  },
  265: {
    name: "Västerås"
  },
  266: {
    name: "Ale"
  },
  267: {
    name: "Alingsås"
  },
  268: {
    name: "Åmål"
  },
  269: {
    name: "Bengtsfors"
  },
  272: {
    name: "Dals-Ed"
  },
  273: {
    name: "Essunga"
  },
  274: {
    name: "Falköping"
  },
  275: {
    name: "Färgelanda"
  },
  276: {
    name: "Göteborg"
  },
  277: {
    name: "Götene"
  },
  278: {
    name: "Grästorp"
  },
  279: {
    name: "Gullspång"
  },
  280: {
    name: "Härryda"
  },
  281: {
    name: "Herrljunga"
  },
  282: {
    name: "Hjo"
  },
  283: {
    name: "Karlsborg"
  },
  284: {
    name: "Kungälv"
  },
  285: {
    name: "Lerum"
  },
  286: {
    name: "Lidköping"
  },
  287: {
    name: "Lilla Edet"
  },
  288: {
    name: "Lysekil"
  },
  289: {
    name: "Mariestad"
  },
  291: {
    name: "Mellerud"
  },
  292: {
    name: "Mölndal"
  },
  293: {
    name: "Munkedal"
  },
  294: {
    name: "Öckerö"
  },
  295: {
    name: "Orust"
  },
  296: {
    name: "Partille"
  },
  297: {
    name: "Skara"
  },
  298: {
    name: "Skövde"
  },
  299: {
    name: "Sotenäs"
  },
  300: {
    name: "Stenungsund"
  },
  301: {
    name: "Strömstad"
  },
  302: {
    name: "Svenljunga"
  },
  303: {
    name: "Tanum"
  },
  304: {
    name: "Tibro"
  },
  305: {
    name: "Tidaholm"
  },
  306: {
    name: "Tjörn"
  },
  307: {
    name: "Töreboda"
  },
  308: {
    name: "Tranemo"
  },
  309: {
    name: "Trollhättan"
  },
  310: {
    name: "Vänersborg"
  },
  311: {
    name: "Ulricehamn"
  },
  312: {
    name: "Uddevalla"
  },
  313: {
    name: "Vara"
  },
  314: {
    name: "Vårgårda"
  },
  317: {
    name: "Falun"
  },
  318: {
    name: "Borlänge"
  },
  322: {
    name: "Gävle"
  },
  323: {
    name: "Söderhamn"
  },
  324: {
    name: "Hudiksvall"
  },
  325: {
    name: "Sandviken"
  },
  326: {
    name: "Bollebygd"
  },
  327: {
    name: "Borås"
  },
  328: {
    name: "Mark"
  },
  329: {
    name: "Gotland"
  }
}

export default orter