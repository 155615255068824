import * as t from "../actionTypes";

const initialState = {
  name: "",
  mail: "",
  avatar: "",
  phone: "",
  password: "",
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case t.SET_USER:
      var avatarUrl = "";
      if (action.payload.image !== "") {
        avatarUrl = "data:image/jpeg;base64," + action.payload.image;
      }
      return {
        ...initialState,
        address: action.payload.adress,
        consent: action.payload.consent,
        created: action.payload.created,
        website: action.payload.hemsida,
        last_login: action.payload.last_login,
        city: action.payload.ort_id,
        zip_code: action.payload.postnr,
        zip_city: action.payload.postort,
        name: action.payload.name,
        ort_id: action.payload.ort_id,
        mail: action.payload.email,
        avatar: avatarUrl,
        phone: action.payload.telefon,
        user_id: action.payload.user_id,
        type: action.payload.typ,
        fb_id: action.payload.fb_id,
        msgnotify: action.payload.msgnotify,
        newsLetter: action.payload.nyhetsbrev,
      };
    case t.REMOVE_USER:
      return initialState;
    default:
      return state;
  }
};

export default user;
