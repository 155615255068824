const CategoryHandler = (props) => {
  const category = parseInt(props);
  switch (category) {
    case 1:
      return "Alla kategorier ";
    case 6:
      return "Mode";
    case 7:
      return "Hem & Inredning";
    case 8:
      return "För barn";
    case 9:
      return "Fritid & Hobby";
    case 10:
      return "Elektronik";
    case 11:
      return "Djur";
    case 12:
      return "Fordon";
    case 13:
      return "Bostad";
    case 16:
      return "Hyresmarknad";
    case 17:
      return "Fritidsboende";
    case 18:
      return "Lägenheter";
    case 19:
      return "Villor & Radhus";
    case 20:
      return "Övrigt";
    case 21:
      return "Fågel";
    case 22:
      return "Fisk";
    case 23:
      return "Gnagare & Kaniner";
    case 24:
      return "Hund";
    case 25:
      return "Katt & Hundtillbehör";
    case 26:
      return "Katt";
    case 27:
      return "Lantbruksdjur";
    case 28:
      return "Reptil";
    case 29:
      return "Tillbehör";
    case 30:
      return "Övrigt";
    case 31:
      return "Datorer & Tillbehör";
    case 32:
      return "Ljud & Bild";
    case 33:
      return "Mobiler & Tillbehör";
    case 34:
      return "Övrigt";
    case 38:
      return "Konserter";
    case 40:
      return "Teater, Opera & Ballet";
    case 42:
      return "Barnkläder";
    case 43:
      return "Barnmöbler";
    case 44:
      return "Barnskor";
    case 45:
      return "Barnvagnar & Tillbehör";
    case 46:
      return "Bilbarnstolar";
    case 47:
      return "Leksaker";
    case 48:
      return "Övrigt";
    case 49:
      return "Båtar";
    case 50:
      return "Båtdelar & Tillbehör";
    case 51:
      return "Bilar";
    case 52:
      return "Bildelar & Tillbehör";
    case 53:
      return "Husvagnar & Husbilar";
    case 54:
      return "MC-delar & Tillbehör";
    case 55:
      return "Mopeder & Scooters";
    case 56:
      return "Motorcyklar";
    case 57:
      return "Övriga fordon";
    case 58:
      return "Snöskoterdelar & tillbehör";
    case 59:
      return "Snöskotrar";
    case 60:
      return "Biljetter";
    case 61:
      return "Böcker & Studentlitteratur";
    case 62:
      return "Cyklar";
    case 63:
      return "Häst & Ridsport";
    case 64:
      return "Hobby & Samlarprylar";
    case 65:
      return "Jakt & Fiske";
    case 66:
      return "Musik, Film & Spel";
    case 67:
      return "Musikinstrument";
    case 68:
      return "Resor";
    case 69:
      return "Sport & Fritid ";
    case 70:
      return "Övrigt";
    case 71:
      return "Antikt & Konst";
    case 72:
      return "Belysning";
    case 73:
      return "Bord & Stolar";
    case 74:
      return "Bygg & Trädgård ";
    case 75:
      return "Heminredning";
    case 76:
      return "Hyllor & Förvaring";
    case 77:
      return "Säng & Sovrum";
    case 78:
      return "Soffa/Fåtölj/Soffmöbler";
    case 79:
      return "TV & Stereomöbler";
    case 80:
      return "Vitvaror & Husgeråd";
    case 81:
      return "Övrigt";
    case 82:
      return "Fritidsboende";
    case 83:
      return "Lägenheter";
    case 84:
      return "Villor & Radhus";
    case 85:
      return "Övrigt";
    case 99:
      return "Accessoarer";
    case 103:
      return "Klockor";
    case 107:
      return "Smycken";
    case 110:
      return "Väskor";
    case 111:
      return "Övrigt";
    case 164:
      return "Bärbara datorer";
    case 165:
      return "Datortillbehör & Program";
    case 166:
      return "Stationära datorer";
    case 167:
      return "Övrigt";
    case 168:
      return "DVD-spelare & MP3-spelare";
    case 169:
      return "Kameror & Videokameror";
    case 170:
      return "Stereo & Surround";
    case 171:
      return "TV & Projektorer";
    case 172:
      return "Övrigt";
    case 173:
      return "Mobiler";
    case 174:
      return "Tillbehör";
    case 175:
      return "Övrigt";
    case 180:
      return "Motorbåt";
    case 181:
      return "Segelbåt";
    case 182:
      return "Övrigt";
    case 183:
      return "Motor & propeller ";
    case 184:
      return "Alfa Romeo";
    case 185:
      return "Audi";
    case 186:
      return "BMW";
    case 187:
      return "Cadillac";
    case 188:
      return "Chevrolet";
    case 189:
      return "Chrysler";
    case 190:
      return "Citroen";
    case 191:
      return "Dodge";
    case 192:
      return "Ferrari";
    case 193:
      return "Fiat";
    case 194:
      return "Ford";
    case 195:
      return "Honda";
    case 196:
      return "Hyundai";
    case 197:
      return "Jaguar";
    case 198:
      return "Jeep";
    case 199:
      return "Kia";
    case 200:
      return "Lamborghini";
    case 201:
      return "Land rover";
    case 202:
      return "Lexus";
    case 203:
      return "Lincoln";
    case 204:
      return "Maserati";
    case 205:
      return "Mazda";
    case 206:
      return "Mercedes Benz";
    case 207:
      return "Mini";
    case 208:
      return "Mitsubishi";
    case 209:
      return "Nissan";
    case 210:
      return "Opel";
    case 211:
      return "Övriga märken";
    case 212:
      return "Peugeot";
    case 213:
      return "Pontiac";
    case 214:
      return "Porsche";
    case 215:
      return "Renault";
    case 216:
      return "Rover/BMC";
    case 217:
      return "Saab";
    case 218:
      return "Seat";
    case 219:
      return "Skoda";
    case 220:
      return "Smart";
    case 221:
      return "Subaru";
    case 222:
      return "Suzuki";
    case 223:
      return "Toyota";
    case 224:
      return "Volkswagen";
    case 225:
      return "Volvo";
    case 226:
      return "Bilstereo";
    case 227:
      return "Däck & Fälgar";
    case 228:
      return "GPS";
    case 229:
      return "Reservdelar";
    case 230:
      return "Släp";
    case 231:
      return "Takboxar & Takräcken";
    case 232:
      return "Övrigt";
    case 233:
      return "Husbil";
    case 234:
      return "Husvagn";
    case 235:
      return "Tillbehör";
    case 236:
      return "Övrigt";
    case 237:
      return "Däck & Fälgar";
    case 238:
      return "Hjälm/Skydd/Kläder";
    case 239:
      return "Reservdelar";
    case 240:
      return "Övrigt";
    case 241:
      return "Cross/Enduro";
    case 242:
      return "Custom";
    case 243:
      return "Fyrhjuling/ATV";
    case 244:
      return "Sport";
    case 245:
      return "Touring";
    case 246:
      return "Övrigt";
    case 247:
      return "Foder & Stall";
    case 248:
      return "Hästar";
    case 249:
      return "Släp & Transport";
    case 250:
      return "Utrustning";
    case 251:
      return "Övrigt";
    case 252:
      return "Fiske";
    case 253:
      return "Jakt";
    case 254:
      return "Övrigt";
    case 255:
      return "CD & LP skivor";
    case 256:
      return "DVD & VHS filmer";
    case 257:
      return "PC-spel";
    case 258:
      return "TV-spel & TV -spelskonsoler";
    case 259:
      return "Övrigt";
    case 260:
      return "Gitarr/Bas/Förstärkare";
    case 261:
      return "Studio/Scenutrustning";
    case 262:
      return "Övrigt";
    case 263:
      return "Bollsport";
    case 264:
      return "Golf";
    case 265:
      return "Träning & Hälsa";
    case 266:
      return "Vintersport";
    case 267:
      return "Övrigt";
    case 268:
      return "Badrum/WC/Bastu";
    case 269:
      return "Byggvaror";
    case 270:
      return "Kamin & Värme ";
    case 271:
      return "Kök";
    case 272:
      return "Trädgård & Uteplats";
    case 273:
      return "Verktyg";
    case 274:
      return "Övrigt";
    case 275:
      return "Diskmaskin";
    case 276:
      return "Kökstillbehör & Porslin";
    case 277:
      return "Kyl & Frys";
    case 278:
      return "Spis & Micro";
    case 279:
      return "Tvättmaskiner & Torktumlare";
    case 280:
      return "Övrigt";
    case 301:
      return "Övrigt";
    case 306:
      return "Sport";
    case 311:
      return "Övrigt";
    case 312:
      return "Damkläder  & Damskor";
    case 313:
      return "Herrkläder & Herrskor";
    case 314:
      return "Skor";
    case 316:
      return "Jackor & Ytterplagg ";
    case 317:
      return "Byxor";
    case 318:
      return "Jeans";
    case 319:
      return "Kostymer & Kavajer";
    case 320:
      return "Skjortor";
    case 321:
      return "Toppar";
    case 327:
      return "Tröjor";
    case 331:
      return "Övrigt";
    case 332:
      return "Kjolar & Klänningar";
    case 333:
      return "Skor";
    case 335:
      return "Jackor & Ytterplagg";
    case 336:
      return "Byxor";
    case 337:
      return "Jeans";
    case 338:
      return "Kostymer & Kavajer";
    case 339:
      return "Skjortor";
    case 340:
      return "Tröjor & T - shirts";
    case 342:
      return "Övrigt";
    default:
      return;
  }
};

export default CategoryHandler;
