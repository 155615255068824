import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import Skeleton from "@material-ui/lab/Skeleton";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { useMediaQuery } from "react-responsive";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import axios from "axios";

import AddIcon from "@material-ui/icons/Add";
import PersonIcon from "@material-ui/icons/Person";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ChatIcon from "@material-ui/icons/Chat";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

// import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import InboxIcon from "@material-ui/icons/Inbox";
import HistoryIcon from "@material-ui/icons/History";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import Badge from "@material-ui/core/Badge";

import { logoutUser } from "../redux/actions/user";
import { fetchAllAds } from "../redux/actions/fetchAllAds";

import emptyAvatar from "../images/empty-avatar.jpg";

const ResponsiveNavigation = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const urlParams = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const citiboardLogo = process.env.REACT_APP_MEDIA_DOMAIN + "/layout/logos/cb-logo-dark.png";

  const [navOpen, setNavOpen] = useState(0);
  const [dropdown, setDropDown] = useState(false);
  const [searchWord, setSearchWord] = useState(urlParams.get("sok") || "");
  const [resultFromSearch, setResultFromSearch] = useState([]);
  const [checkingMsgs, setCheckingMsgs] = useState(false);

  const [examplesFromYourHistory, setExamplesFromYourHistory] = useState([]);

  const navigate = useNavigate();

  const isLoggedIn = props.user.user_id !== undefined;
  var user = props.user;

  var headers = {
    "x-auth": cookieId,
  };

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const homeLocation = {
    pathname: "/",
    state: { fromHome: true },
  };

  const adLocation = {
    pathname: "/annons",
    state: { fromHome: true },
  };

  const messageLocation = {
    pathname: "/meddelanden",
    state: { fromHome: true },
  };

  const loginLocation = {
    pathname: "/loggain",
    state: { fromHome: true },
  };

  const mypageLocation = {
    pathname: "/min-sida",
    state: { fromHome: true },
  };

  const settingsLocation = {
    pathname: "/installningar",
    state: { fromHome: true },
  };

  const searchHistory = {
    pathname: "/sokhistorik",
    state: { fromHome: true },
  };

  const savedLocation = {
    pathname: "/sparade",
    state: { fromHome: true },
  };

  useEffect(() => {
    const checkMessages = () => {
      axios
        .get(process.env.REACT_APP_API_DOMAIN + "/cb/msgs_unread", { headers: headers })
        .then((res) => {
          setUnreadMessages(res.data);
        });
    };

    if (isLoggedIn && !checkingMsgs) {
      setCheckingMsgs(true);
      checkMessages();

      setInterval(() => {
        checkMessages();
      }, 30000);
    }
  }, [isLoggedIn, headers, checkingMsgs]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [loading]);

  useEffect(() => {
    handleAutoCompleteExamples();
  }, [isLoggedIn]);

  const handleSearch = (word) => {
    if (word === undefined) {
      word = document.getElementById("searchfield").value;
    }

    if (word !== "" || word !== undefined) {
      setSearchWord(word);
      // Leta fram prefix, om man inte är inne på annons. Är man inne på annons, sök från botten

      navigate(window.location.pathname + "?sok=" + searchWord);
    } else {
      setSearchWord("");
      navigate("/");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleAutoComplete = (e) => {
    setSearchWord(e.target.value);
    var headers = {
      "x-auth": cookieId,
    };
    var newList = [];
    if (cookieId !== "" && isLoggedIn) {
      axios
        .get(
          process.env.REACT_APP_API_DOMAIN + "/cb/autocomplete?sok=" + e.target.value,
          { headers: headers }
        )
        .then((res) => {
          for (let i = 0; i < res.data.personal.length; i++) {
            if (
              res.data.personal[i] === undefined ||
              res.data.general[i] === "undefined"
            ) {
              break;
            } else {
              newList.push({ autocomplete: res.data.personal[i] });
            }
          }
          for (let i = 0; i < res.data.general.length; i++) {
            if (
              res.data.general[i] === undefined ||
              res.data.general[i] === "undefined"
            ) {
              break;
            } else {
              newList.push({ autocomplete: res.data.general[i] });
            }
          }
          setResultFromSearch(newList);
        });
    } else {
      axios
        .get(
          process.env.REACT_APP_API_DOMAIN + "/cb/autocomplete?sok=" + e.target.value
        )
        .then((res) => {
          for (let i = 0; i < res.data.general.length; i++) {
            if (
              res.data.general[i] === undefined ||
              res.data.general[i] === "undefined"
            ) {
              break;
            } else {
              newList.push({ autocomplete: res.data.general[i] });
            }
          }
          setResultFromSearch(newList);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleAutoCompleteExamples = () => {
    var newList = [];
    var headers = {
      "x-auth": cookieId,
    };
    if (resultFromSearch.length === 0) {
      if (cookieId !== "" && isLoggedIn) {
        axios
          .get(process.env.REACT_APP_API_DOMAIN + "/cb/autocomplete", {
            headers: headers,
          })
          .then((res) => {
            for (let i = 0; i < res.data.personal.length; i++) {
              if (
                res.data.personal[i] === undefined ||
                res.data.personal[i] === "undefined"
              ) {
                break;
              } else {
                newList.push({ autocomplete: res.data.personal[i] });
              }
            }
            for (let i = 0; res.data.general.length; i++) {
              if (
                res.data.general[i] === undefined ||
                res.data.general[i] === "undefined"
              ) {
                break;
              } else {
                newList.push({ autocomplete: res.data.general[i] });
              }
            }
            setExamplesFromYourHistory(newList);
          });
      } else {
        axios.get(process.env.REACT_APP_API_DOMAIN + "/cb/autocomplete").then((res) => {
          for (let i = 0; i < res.data.general.length; i++) {
            if (
              res.data.general[i] === undefined ||
              res.data.general[i] === "undefined"
            ) {
              break;
            } else {
              newList.push({ autocomplete: res.data.general[i] });
            }
          }
          setExamplesFromYourHistory(newList);
        });
      }
    }
  };

  const handleLogout = () => {
    if (cookieId !== "") {
      cookies.set("id", "", { path: "/" });
    }
    props.logoutUser();
    window.location.replace(homeLocation.pathname);
    return <Navigate to={homeLocation} />;
  };

  const showHideNavbar = () => {
    let x = document.getElementById("sidebar-container-id");

    if( x === null ) {
      window.location.assign("/");
    } else {
      document.body.scrollTop = 0; //Safari;
      document.documentElement.scrollTop = 0; //Chrome, Firefox, IE;
  
      x.classList.toggle( "open" );
    }
  };

  const DropdownMenu = () => {
    return (
      <div>
        {isLoggedIn ? (
          <ul
            className={
              dropdown ? "dropdown-container-active" : "dropdown-container"
            }
            onMouseLeave={() => setDropDown(false)}
          >
            <Link
              to={mypageLocation}
              className="menu-tabs"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div className="navbar-dropdown-block">
                <img
                  src={user.avatar !== "" ? user.avatar : emptyAvatar}
                  alt="avatar"
                  className="navbar-dropdown-avatar"
                />
                <span className="navbar-dropdown-text-avatar">
                  <strong>{user.name}</strong>
                  <br />
                  Gå till min sida
                </span>
              </div>
            </Link>
            <hr className="navbar-dropdown-divider" />
            <Link
              to={adLocation}
              className="menu-tabs"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div className="navbar-dropdown-block">
                <div className="navbar-dropdown-icons">
                  <AddIcon />
                </div>
                <span className="navbar-dropdown-text">
                  Lägg in annons gratis
                </span>
              </div>
            </Link>
            <hr className="navbar-dropdown-divider" />
            <Link
              to={messageLocation}
              className="menu-tabs"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div className="navbar-dropdown-block">
                <div className="navbar-dropdown-icons">
                  <InboxIcon />
                </div>
                <span className="navbar-dropdown-text">Inkorg</span>
              </div>
            </Link>
            <Link
              to={savedLocation}
              className="menu-tabs"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div className="navbar-dropdown-block">
                <div className="navbar-dropdown-icons">
                  <FavoriteIcon />
                </div>
                <span className="navbar-dropdown-text">Sparade annonser</span>
              </div>
            </Link>
            <Link
              to={searchHistory}
              className="menu-tabs"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div className="navbar-dropdown-block">
                <div className="navbar-dropdown-icons">
                  <HistoryIcon />
                </div>
                <span className="navbar-dropdown-text">Min sökhistorik</span>
              </div>
            </Link>
            <Link
              to={settingsLocation}
              className="menu-tabs"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div className="navbar-dropdown-block">
                <div className="navbar-dropdown-icons">
                  <SettingsIcon />
                </div>
                <span className="navbar-dropdown-text">Inställningar</span>
              </div>
            </Link>
            <Link
              to={homeLocation}
              className="my-page-links"
              onClick={handleLogout}
              style={{ textDecoration: "none" }}
            >
              <div className="my-page-wrapper-block">
                <ExitToAppIcon className="my-page-wrapper-block-icon" />
                <span className="my-page-wrapper-block-text">Logga ut</span>
              </div>
            </Link>
          </ul>
        ) : (
          <ul
            className={
              dropdown ? "dropdown-login-container" : "dropdown-container"
            }
          >
            <Link
              to={loginLocation}
              className="menu-tabs"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              <div className="navbar-dropdown-block">
                <div className="navbar-dropdown-icons">
                  <PersonIcon />
                </div>
                <span className="navbar-dropdown-text">Logga in</span>
              </div>
            </Link>
          </ul>
        )}
      </div>
    );
  };

  return (
    <nav className="responsive-toolbar">
      <div className="left-container">
        <a href="/" style={{ textDecoration: "none" }}>
          <figure
            className="image-logo"
            // onClick={() => {
            //   setNavOpen(!navOpen);
            // }}
          >
            <img
              src={citiboardLogo}
              alt="Citiboard logo"
            />
          </figure>
        </a>
      </div>
      <div className="middle-container">
          <li className="sidebar-hamburger">
            <MenuIcon onClick={() => showHideNavbar()} />
          </li>
          <div className="searchfield">
            <div className="searchfield-container">
              <input
                list="autocomplete"
                id="searchfield"
                type="text"
                className={"searchfield" + ( searchWord === "" ? "" : " search-made" )}
                placeholder="Vad söker du?"
                onKeyDown={(e) => handleKeyPress(e)}
                onChange={(e) => handleAutoComplete(e)}
                value={searchWord}
              />
              <div
                className={"searchfield-icon-container" + ( searchWord === "" ? "" : " search-made" )}
                onClick={() => handleSearch()}
              >
                <div className="searchfield-icon">
                  <SearchIcon />
                </div>
              </div>
            </div>
            <datalist id="autocomplete">
              {resultFromSearch.length > 0
                ? resultFromSearch.map((result, idx) => (
                    <option
                      key={idx}
                      value={result.autocomplete}
                      onClick={(e) => handleSearch(e.target.value)}
                    />
                  ))
                : resultFromSearch.length === 0 &&
                  examplesFromYourHistory.length > 0
                ? examplesFromYourHistory.map((result, idx) => (
                    <option
                      key={idx}
                      value={result.autocomplete}
                      onClick={(e) => handleSearch(e.target.value)}
                    />
                  ))
                : ""}
            </datalist>
          </div>
      </div>

      {loading ? (
        <Skeleton
          variant="rect"
          width={300}
          height={37}
          style={{ float: "right", marginTop: 10, marginRight: 20 }}
        />
      ) : (
        <div className="right-container">
          {isLoggedIn ? (
            <ul className={navOpen ? "active" : ""}>
              <Tooltip title="Annonsera gratis">
                <li className="link-text-icon">
                  <Link
                    to={adLocation}
                    className="navbar-links-not-logged create-ad"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      variant="contained"
                      color="default"
                      className="create-ad-btn"
                      startIcon={<AddIcon />}
                    >
                      <span className="create-ad-btn-txt">{ isMobile ? "Ny" : "Lägg in annons gratis" }</span>
                    </Button>
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title="Gå till min sida">
                <li className="link-text-icon">
                  <Link
                    to={mypageLocation}
                    className="navbar-links profile-btn"
                  >
                    {user.avatar !== "" ? (
                      <img
                        width="31"
                        height="31"
                        src={user.avatar}
                        className="profile-picture"
                        alt="Avatar"
                      />
                    ) : (
                      <PersonIcon />
                    )}
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title="Meddelanden">
                <li className="link-text-message">
                  <Link to={messageLocation} className="navbar-links">
                    {unreadMessages > 0 ? (
                      <Badge badgeContent={unreadMessages} color="error">
                        <ChatIcon />
                      </Badge>
                    ) : (
                      <ChatIcon />
                    )}
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title="Sparade">
                <li className="link-text-icon-notification">
                  <Link to={savedLocation} className="navbar-links">
                    <FavoriteIcon className="navbar-notification-icon" />
                  </Link>
                </li>
              </Tooltip>
              <li
                className="navbar-dropdown-menu"
                onClick={() => setDropDown(!dropdown)}
              >
                {dropdown ? (
                  <ArrowDropUpIcon className="navbar-dropdown-menu-icon" />
                ) : (
                  <ArrowDropDownIcon className="navbar-dropdown-menu-icon" />
                )}
                <DropdownMenu />
              </li>
            </ul>
          ) : (
            <div className="not-logged">
              <Tooltip title="Lägg in annons gratis">
                <Link
                  to={adLocation}
                  className="navbar-links-not-logged create-ad"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="contained"
                    color="default"
                    className="create-ad-btn"
                    startIcon={<AddIcon />}
                  >
                    <span className="create-ad-btn-txt">{ isMobile ? "Ny" : "Lägg in annons gratis" }</span>
                  </Button>
                </Link>
              </Tooltip>

              <Link
                to={loginLocation}
                className="navbar-login-button"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="default"
                  className="login-btn"
                  startIcon={<PersonIcon />}
                >
                  Logga in
                </Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllAds: (ads) => dispatch(fetchAllAds(ads)),
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(ResponsiveNavigation)
);
