import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ReportButton = withStyles({
  root: {
    background: "rgb(52,64,86)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 40,
    padding: "0 30px",
    margin: 10,
    "&:hover": {
      backgroundColor: "rgb(52, 80, 99)",
    },
  },
})(Button);

const ActionDialogComponent = ({ onSubmit, onClose, text, title }) => {
  const [openModal, setOpenModal] = useState(true);
  const [textValue, setTextValue] = useState("");

  const closeModal = () => {
    setOpenModal(false);
    onClose();
  };

  const submitReport = () => {
    setOpenModal(false);
    onSubmit(textValue);
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>
        <span>{title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {text === undefined ? (
            <textarea
              cols="40"
              rows="10"
              className="report"
              onChange={(e) => setTextValue(e.target.value)}
            />
          ) : (
            <span>{text}</span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ReportButton onClick={closeModal}>Stäng</ReportButton>
        <ReportButton onClick={submitReport} className="submit">
          {title === "Motivera din anmälan"
            ? "Anmäl"
            : title === "Skicka meddelande"
            ? "Skicka"
            : "OK"}
        </ReportButton>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialogComponent;
