import * as t from "../actionTypes";

export const fetchMyAds = (ads) => {
  return {
    type: t.FETCH_MY_ADS,
    payload: ads,
  };
};

export const fetchMyInactivatedAds = (ads) => {
  return {
    type: t.FETCH_MY_INACTIVATED_ADS,
    payload: ads,
  };
};

export const inActivateMyAd = (ad) => {
  return {
    type: t.INACTIVE_MY_AD,
    payload: ad,
  };
};

export const removeInactiveAd = (ad) => {
  return {
    type: t.REMOVE_INACTIVE_AD,
    payload: ad,
  };
};
