import React, { useState, useEffect } from "react";
import "../assets/scss/components/sidebar.scss";
import orter from "../dumpdata/newdumpdata/orter";
import categories from "../dumpdata/newdumpdata/categories";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import PlaceIcon from "@material-ui/icons/Place";

const Sidebar = () => {
  const navigate = useNavigate();
 
  const [locationUrl, setLocationUrl] = useState("");
  const [categoryUrl, setCategoryUrl] = useState("");
  
  const isMobile = useMediaQuery({ query: "(max-width: 759px)" });

  const decodeUrl = () => {
    let categories = document.querySelectorAll( "#sidebar-container-categories li" );
    let orter = document.querySelectorAll( "#sidebar-container-orter li" );
    let activeUrl = window.location.pathname;

    orter.forEach( (obj) => {
      let targeturl = obj.getAttribute( "targeturl" );

      if( activeUrl.includes( "/" + targeturl ) ) {
        showOrt( obj );
        setLocationUrl( "/" + targeturl );
      }
    } );

    categories.forEach( (obj) => {
      let targeturl = obj.getAttribute( "targeturl" );

      if( activeUrl.includes( targeturl ) ) {
        showCategory( obj );
        setCategoryUrl( "/" + targeturl );
      }
    } );

    return false;
  };

  const countyNavigation = (url, elem) => {
    if( elem !== false ) {
      showOrt(elem.target);
    }
    
    setLocationUrl("/" + url);
  };

  const categoryNavigation = (url, elem) => {
    if( elem !== false ) {
      showCategory(elem.target);
    }

    setCategoryUrl(url);
  };

  const showOrt = (elem) => {
    let x = elem.id;
    let siblings = document.querySelectorAll( '#sidebar-container-orter [parentid="' + x + '"]' );
    let parent = document.getElementById( x );

    if( elem.classList.contains('lan') ) {
      closeOrter();
    }

    if (parent.length > 0) {
      showOrt( parent );
    } 

    if (siblings.length > 0) {
      siblings.forEach((obj) => {
        obj.classList.add( "open" );
      });
    }

    if( isMobile ) {
      let sidebar = document.querySelectorAll( "ul.sidebar-container" )[0];
      sidebar.classList.remove( "open" );
    }
  };

  const ort = orter.map((obj, key) => {
    return (
      <li
        id={obj.id}
        key={key}
        className={obj.parent !== "0" && obj.parent !== "1" ? "ort" : "lan"}
        parentid={obj.parent}
        onClick={(elem) => countyNavigation(obj.url, elem)}
        targeturl={obj.url}
      >
        {obj.rubrik}
      </li>
    );
  });

  const closeOrter = () => {
    let closeOrt = document.querySelectorAll( '.ort.open' );
    
    closeOrt.forEach( (obj) => {
        obj.classList.remove( "open" );
    } );
  }

  const closeCategories = () => {
    let closeCategory = document.querySelectorAll( 'li.subCategory.open,li.subSubCategory.open' );
    
    closeCategory.forEach( (obj) => {
      obj.classList.remove( "open" );
    } );
  }

  const showCategory = (elem) => {
    let x = elem.id;
    let siblings = document.querySelectorAll( '#sidebar-container-categories [parentid="' + x + '"]' );
    let parent = document.getElementById( x );

    if( elem.classList.contains( "mainCategory" ) ) {
      closeCategories();
    }

    if (parent.length > 0) {
      showCategory( parent );
    } 

    if (siblings.length > 0) {
      siblings.forEach((obj) => {
        obj.classList.add("open");
      });
    }

    if( isMobile ) {
      let sidebar = document.querySelectorAll( "ul.sidebar-container" )[0];
      sidebar.classList.remove( "open" );
    }
  };

  const category = categories.map((obj, key) => {
    let hierarchy = obj.url.split("/").length - 1;
    let categorytype = 'mainCategory';

    if( hierarchy === 2 ) {
      categorytype = 'subCategory';
    } else if( hierarchy === 3 ) {
      categorytype = 'subSubCategory';
    }

    return (
      <li
        id={obj.id}
        key={key}
        className={categorytype}
        parentid={obj.parent}
        onClick={(elem) => categoryNavigation(obj.url, elem)}
        targeturl={obj.url}
      >
        {obj.rubrik}
      </li>
    );
  });

  const requestPosition = (e) => {
    e.preventDefault();
    navigator.geolocation.getCurrentPosition(usePosition);
  }

  const usePosition = (pos) => {
    try {
      axios
        .get(process.env.REACT_APP_API_DOMAIN + "/cb/getNearestOrt?lat=" + pos.coords.latitude + "&lon=" + pos.coords.longitude)
        .then((res) => {
          countyNavigation(res.data, false);
          decodeUrl();
        });
    } catch (err) {
      console.log(err.message);
    }
  }

  const resetCategory = () => {
    closeCategories();
    setCategoryUrl("");
  }

  const resetLocation = () => {
    closeOrter();
    setLocationUrl("");
  }

  useEffect( () => {
    decodeUrl();
  }, []);

  useEffect( () => {
    document.body.scrollTop = 0; //Safari;
    document.documentElement.scrollTop = 0; //Chrome, Firefox, IE;

    // Failsafe
    let completeUrl = (locationUrl + categoryUrl + window.location.search).replace("//", "/");

    if( (locationUrl + categoryUrl) === "" ) {
      completeUrl = "/" + window.location.search;
    }
    
    navigate(completeUrl);
  }, [locationUrl,categoryUrl]);

  return (
    <ul className="sidebar-container" id="sidebar-container-id">
      <div id="sidebar-container-categories">
        <div
          className="sidebar-container-title"
          id="sidebar-container-title-kategori"
        >
          <Link to="#" className="sidebar-container-title-link" onClick={resetCategory}>
            Alla kategorier
          </Link>
        </div>
        {category}
      </div>
      <div id="sidebar-container-orter">
        <div className="sidebar-container-title">
          <Link to="#" className="sidebar-container-title-link" onClick={resetLocation}>
            Hela Sverige
          </Link>
        </div>
        <li className="sidebar-findme-wrapper">
          <Link to="#" type="button" className="sidebar-findme" onClick={requestPosition}>
            Hitta min position <PlaceIcon />
          </Link>
        </li>
        {ort}
      </div>
    </ul>
  );
};

export default Sidebar;
