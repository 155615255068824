import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  return (
    <div className="aboutus-container mainwrapper">
      <div className="aboutus-wrapper">
        <div className="aboutus-title-container">
          <h1 className="aboutus-title">
            {loading ? (
              <Skeleton variant="text" width={250} height={50} />
            ) : (
              <span>Citiboard – Sveriges snabbaste växande gratis köp &amp; säljsite!</span>
            )}
          </h1>
        </div>
        <div className="aboutus-content-container">
          {loading ? (
            <Skeleton variant="text" width={250} height={20} />
          ) : (
            <p>På Citiboard kan du:</p>
          )}
          <ul>
            {loading ? (
              <Skeleton variant="text" width={500} height={20} />
            ) : (
              <li className="aboutus-link">
                Köpa & sälja snabbt, enkelt, gratis och säkert.
              </li>
            )}
            {loading ? (
              <Skeleton variant="text" width={500} height={20} />
            ) : (
              <li className="aboutus-link">
                Söka bland tusentals nya annonser dagligen – Buda, köpslå och
                gör ett klipp!
              </li>
            )}
          </ul>
          {loading ? (
            <Skeleton variant="text" width={300} height={20} />
          ) : (
            <p>
              Citiboard är HELT gratis utan dolda avgifter eller kommissioner.
            </p>
          )}
          <br />
          {loading ? (
            <Skeleton variant="text" width={350} height={20} />
          ) : (
            <strong>
              Börja ditt köp- och säljäventyr redan idag, lägg in en annons
              gratis!
            </strong>
          )}
          <br />

          <strong>
            {loading ? (
              <Skeleton variant="text" width={100} height={20} />
            ) : (
              <span>Citiboard AB</span>
            )}
            <br />
            <br />
            {loading ? (
              <Skeleton variant="text" width={450} height={20} />
            ) : (
              <span>
                Hemsida:{" "}
                <a href="https://www.citiboardab.se">www.citiboardab.se</a>
              </span>
            )}
          </strong>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
