const decodeHtml = (text) => {
  if (text && typeof text === "string") {
    text = text.replaceAll(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
    text = text.replaceAll(/<iframe[^>]*>([\S\s]*?)<\/iframe>/gim, "");
    text = text.replaceAll(/<div[^>]*>([\S\s]*?)<\/div>/gim, "");
    text = text.replaceAll(/<span[^>]*>([\S\s]*?)<\/span>/gim, "");
    text = text.replaceAll(/<p[^>]*>([\S\s]*?)<\/p>/gim, "");

    //text = text.replaceAll(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
    text = text.replaceAll("&aring;", "å");
    text = text.replaceAll("&auml;", "ä");
    text = text.replaceAll("&ouml;", "ö");
    text = text.replaceAll("&Aring;", "Å");
    text = text.replaceAll("&Auml;", "Ä");
    text = text.replaceAll("&Ouml;", "Ö");
    return text;
  }
};

export default decodeHtml;
