import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

import ProfileInfo from "../components/ProfileInfo";

const SearchHistory = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [loading, setLoading] = useState(true);
  const [words] = useState([]);
  const isLoggedIn = props.user.user_id !== undefined;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  const notLoggedInLocation = {
    pathname: "/inte-inloggad",
  };

  if ((cookieId === "" || cookieId === undefined) && !isLoggedIn) {
    return <Navigate to={notLoggedInLocation} />;
  }

  return (
    <div className="my-page-container">
      <div className="my-page-wrapper">
        <ProfileInfo />
        <div className="my-page-ads-wrapper">
          <div className="my-page-wrapper-ads">
            {loading ? (
              <Skeleton
                variant="rect"
                width={270}
                height={30}
                style={{ margin: "auto", marginTop: 30 }}
              />
            ) : (
              <div className="saved-title-container">
                <div>
                  <span className="saved-title">Sökhistorik</span>
                </div>
                {loading ? (
                  <Skeleton
                    variant="text"
                    width={470}
                    style={{ margin: "auto" }}
                  />
                ) : (
                  <div className="saved-subhead-container">
                    <span>
                      Här presenteras dina 50 senaste sökord. Klicka på ett
                      sökord för att köra sökningen igen.
                    </span>
                  </div>
                )}
                <div className="searchword-content-container">
                  <ul>
                    {words.map((word, index) => (
                      <Link
                        to={"/?sok=" + word.toLowerCase()}
                        className="searchword-links"
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        <li key={index} className="searchword-links">
                          {loading ? (
                            <Skeleton variant="text" />
                          ) : (
                            <span>{word}</span>
                          )}
                        </li>
                      </Link>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mypage-banner-container">
        <div className="mypage-banner-first-wrapper">
          {loading ? <Skeleton variant="rect" width={1200} height={250} /> : ""}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(SearchHistory)
);
