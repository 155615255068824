import * as t from "../actionTypes";

export const user = (user) => {
  return {
    type: t.SET_USER,
    payload: user,
  };
};

export const logoutUser = () => {
  return {
    type: t.REMOVE_USER,
  };
};
