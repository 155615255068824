import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const StyledButtonClose = withStyles({
  root: {},
})(Button);

const StyledButtonAccept = withStyles({
  root: {},
})(Button);

const DialogComponent = ({ text, title, onClose, accept }) => {
  const [openModal, setOpenModal] = useState(true);

  const closeModal = () => {
    setOpenModal(false);
    if (typeof onClose === "function") onClose();
  };

  const acceptModal = () => {
    setOpenModal(false);
    if (typeof accept === "function") {
      accept();
    }
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="dialog-button-container">
          <StyledButtonClose onClick={closeModal} color="primary">
            Stäng
          </StyledButtonClose>
          {accept === undefined ? (
            ""
          ) : (
            <StyledButtonAccept
              onClick={acceptModal}
              color="primary"
              className="submit"
            >
              Okej
            </StyledButtonAccept>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
