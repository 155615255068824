import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-scroll";

const ShopSafe = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  return (
    <div className="shopsafe-container mainwrapper">
      <div className="shopsafe-wrapper">
        <div className="shopsafe-title-container">
          <h1 className="shopsafe-title">
            {loading ? (
              <Skeleton variant="text" width={250} height={50} />
            ) : (
              <span>Handla tryggt</span>
            )}
          </h1>
        </div>
        <div className="shopsafe-content-container">
          {loading ? (
            <Skeleton variant="text" width={250} height={20} />
          ) : (
            <div className="shopsafe-block-title">
              <strong>Innehållsförteckning</strong>
            </div>
          )}
          <ul>
            {loading ? (
              <Skeleton variant="text" width={700} height={20} />
            ) : (
              <Link to="handlatryggt" spy={true} smooth={true} offset={-100}>
                <li className="shopsafe-content-link">
                  Handla tryggt – generella råd för säljare och köpare
                </li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={700} height={20} />
            ) : (
              <Link to="innandubetalar" spy={true} smooth={true} offset={-100}>
                <li className="shopsafe-content-link">Innan du betalar</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={700} height={20} />
            ) : (
              <Link to="forsiktig" spy={true} smooth={true} offset={-100}>
                <li className="shopsafe-content-link">Var extra försiktig</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={700} height={20} />
            ) : (
              <Link
                to="rekommendationer"
                spy={true}
                smooth={true}
                offset={-100}
              >
                <li className="shopsafe-content-link">
                  Rekommendationer vid bilköp och bilförsäljning
                </li>
              </Link>
            )}

            {loading ? (
              <Skeleton variant="text" width={700} height={20} />
            ) : (
              <Link to="vidhyra" spy={true} smooth={true} offset={-100}>
                <li className="shopsafe-content-link">Vid hyra</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={700} height={20} />
            ) : (
              <Link to="skahyra" spy={true} smooth={true} offset={-100}>
                <li className="shopsafe-content-link">Du som ska hyra</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={700} height={20} />
            ) : (
              <Link to="hyrut" spy={true} smooth={true} offset={-100}>
                <li className="shopsafe-content-link">Du som hyr ut</li>
              </Link>
            )}
            {loading ? (
              <Skeleton variant="text" width={700} height={20} />
            ) : (
              <Link to="merinfo" spy={true} smooth={true} offset={-100}>
                <li className="shopsafe-content-link">
                  Mer information för både uthyrare och hyresgäst
                </li>
              </Link>
            )}
          </ul>
          <div className="shopsafe-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="shopsafe-block-title">
                  <span id="handlatryggt">
                    Handla tryggt – generella råd för säljare och köpare
                  </span>
                </div>
              )}
            </strong>
            <ul>
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  För att öka säkerheten rekommenderar Citiboard att köpare och
                  säljare har direktkontakt med varandra.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Vi rekommenderar säljare och köpare att träffas och gör upp
                  affären med varandra.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Citiboard rekommenderar inte betalning i förskott till
                  obekanta. Överför eller skicka inte pengar med post eller ge
                  ut något bankkontonummer eller kreditkortsnummer.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Kom ihåg att Citiboard aldrig medverkar i affärer. Det är helt
                  och hållet upp till säljare och köpare att genomföra affären
                  inklusive betalningstransaktionen.
                </li>
              )}

              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Vid stora belopp - gör upp affären på en bank eller använd
                  bank-/postväxel. Alternativt, se till att träffas på en säker
                  offentlig plats, där ni båda kännare er trygga med
                  omgivningen.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Om du säljer ett fordon - följ alltid med vid provkörningar.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Begär originalkvitto och använd köpekontrakt.
                </li>
              )}
            </ul>
          </div>
          <div className="shopsafe-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="shopsafe-block-title">
                  <span id="innandubetalar">Innan du betalar:</span>
                </div>
              )}
            </strong>
            <ul>
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Se till att du har undersökt varan ordentligt
                </li>
              )}

              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Se till att det inte är en kopia, förfalskad eller stulen vara
                  du köper, fråga alltid efter intyg och originalkvitto på
                  varan. Kontakta polisen om du misstänker att varan är stulen.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Är du osäker på köpet - gör upp med säljaren skriftligt om
                  returrätt.
                </li>
              )}
            </ul>
          </div>
          <div className="shopsafe-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="shopsafe-block-title">
                  <span id="forsiktig">Var extra försiktig:</span>
                </div>
              )}
            </strong>
            <ul>
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Var försiktigt om köparen inte vill avslöja sin identitet,
                  telefonnummer eller namn.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Om erbjudandet är för bra för att vara sant.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Om köparen ber dig lämna ut ditt bankkonto eller
                  kreditkortsnummer, eller insisterar på banköverföring. Detta
                  är inget vi rekommenderar.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Vid köp av mobiltelefoner, datorer och annan typ av
                  elektronik. Vi rekommenderar starkt att man träffas och gör
                  upp dessa affärer då det oftast handlar om ganska stora
                  pengar.
                </li>
              )}
            </ul>
          </div>
          <div className="shopsafe-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="shopsafe-block-title">
                  <span id="rekommendationer">
                    Rekommendationer vid bilköp och bilfösäljning
                  </span>
                </div>
              )}
            </strong>
            <ul>
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Transport styrelsen
                  (https://fordonsfraga.transportstyrelsen.se/fragapaannatfordon.aspx)
                  kan tillhandahålla dig med information om:
                </li>
              )}
            </ul>
          </div>
          <div className="shopsafe-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="shopsafe-block-title">
                  <span id="vid hyra">Vid hyra</span>
                </div>
              )}
            </strong>
            <ul>
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  När du ska hyra /hyra ut lägenhet i andra hand är det viktigt
                  att du tänker dig för en extra gång. Det finns oseriösa
                  aktörer, se våra rekommendationer nedan:
                </li>
              )}
            </ul>
          </div>
          <div className="shopsafe-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="shopsafe-block-title">
                  <span id="skahyra">Du som ska hyra</span>
                </div>
              )}
            </strong>
            <ul>
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Kontrollera om hyran marknadsmässig.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Betala inga hyror eller deposition förrän du sett lägenheten
                  och skrivit kontrakt.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Kontrollera att bostaden verkligen disponeras av uthyraren och
                  att tillstånd hos värde eller bostadsrättsförening finns för
                  uthyrning.
                </li>
              )}
            </ul>
          </div>
          <div className="shopsafe-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="shopsafe-block-title">
                  <span id="hyrut">Du som hyr ut</span>
                </div>
              )}
            </strong>
            <ul>
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Eventuellt påslag på hyran måste vara skäligt. Du kan bli
                  återbetalningsskyldig! Skriv kontrakt!
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Fastighetsägaren (hyresvärden eller bostadsrättsföreningen)
                  måste alltid ge sitt godkännande.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Du kan komma att ansvara för den skada hyresgästen orsakar.
                </li>
              )}
            </ul>
          </div>
          <div className="shopsafe-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="shopsafe-block-title">
                  <span id="merinfo">
                    Mer information för både uthyrare och hyresgäst:
                  </span>
                </div>
              )}
            </strong>
            <ul>
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Hyresnämnden
                  http://www.hyresnamnden.se/Templates/Pages/DV_InfoPageNarrow.aspx?id=1009&epslanguage=sv{" "}
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Konsumentverket: http://www.omboende.se/sv/Hyra/Hyra-ut/
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="shopsafe-link">
                  Hyresgästföreningen:
                  http://www.hyresgastforeningen.se/Sidor/default.aspx
                  http://www.hyresgastforeningen.se/Om_Oss/ladda_hem/Sidor/blanketter.aspx
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopSafe;
