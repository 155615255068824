import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const StyledButton = withStyles({
  root: {
    background: "rgb(52,64,86)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 40,
    padding: "0 30px",
    margin: 10,
    "&:hover": {
      backgroundColor: "rgb(52, 80, 99)",
    },
  },
})(Button);

const StyledTitle = withStyles({
  root: {
    backgroundColor: "rgb(52,64,86)",
    borderRadius: 3,
    border: 0,
    color: "white",
  },
})(DialogTitle);

const MailInputHandler = ({ onSubmit, onClose, text, title }) => {
  const [openModal, setOpenModal] = useState(true);
  const [textValue, setTextValue] = useState("");

  const closeModal = () => {
    setOpenModal(false);
    onClose();
  };

  const submitReport = () => {
    setOpenModal(false);
    onSubmit(textValue);
  };

  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
    >
      <StyledTitle>
        <div className="forgotpassword-title">{title}</div>
      </StyledTitle>
      <DialogContent>
        <DialogContentText>
          {text}
          <input
            type="text"
            className="forgotpassword-mail"
            autoComplete="email"
            onChange={(e) => setTextValue(e.target.value)}
            placeholder="Din mailadress"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={closeModal}>Stäng</StyledButton>
        <StyledButton onClick={submitReport}>Skicka</StyledButton>
      </DialogActions>
    </Dialog>
  );
};

export default MailInputHandler;
