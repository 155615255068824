// Saved ads
export const FETCH_FAVORITE_ADS = "FETCH_FAVORITE_ADS";
export const ADD_FAVORITE_AD = "ADD_FAVORITE_AD";
export const REMOVE_FAVORITE_AD = "REMOVE_FAVORITE_AD";

// Favorite user
export const ADD_FAVORITE_USER = "ADD_FAVORITE_USER";
export const REMOVE_FAVORITE_USER = "REMOVE_FAVORITE_USER";

// Fetch all ads data
export const FETCH_ALL_ADS = "FETCH_ALL_ADS";

// Set user when it logs in
export const SET_USER = "SET_USER";
export const REMOVE_USER = "REMOVE_USER";

// Get users all messages
export const SET_MESSAGES = "SET_MESSAGES";
export const ADD_MESSAGE = "ADD_MESSAGE";

// Get my own ads
export const FETCH_MY_ADS = "FETCH_MY_ADS";
export const FETCH_MY_INACTIVATED_ADS = "FETCH_MY_INACTIVATED_ADS";
export const INACTIVE_MY_AD = "INACTIVE_MY_AD";
export const REMOVE_INACTIVE_AD = "REMOVE_INACTIVE_AD";
