import React, { useState } from "react";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Navigate } from "react-router-dom";
import FacebookLogin from "react17-facebook-login/dist/facebook-login-render-props";
import axios from "axios";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";

import { user } from "../redux/actions/user";

import MailInputHandler from "../components/MailInputHandler";
import DialogComponent from "../components/DialogComponent";

import ValidateMail from "../helpers/ValidateMail";
import ValidatePassword from "../helpers/ValidatePassword";

import Facebook from "@material-ui/icons/Facebook";
import createAccountPin from "../images/create-account-pin.png";

const appId = "759800704954534";

const LoginButton = withStyles(() => ({
  root: {},
}))(Button);

const ColorButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#344056",
    "&:hover": {
      backgroundColor: "#3f4d68",
    },
  },
}))(Button);

const Login = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [login, setLogin] = useState(true);
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [openHelp, setOpenHelp] = useState(false);
  const [wrongMessage, setWrongMessage] = useState(false);
  const [sameMail, setSameMail] = useState(false);
  const [validationPassword, setValidationPassword] = useState(false);
  const [createdUser, setCreatedUser] = useState(false);
  const [validationMail, setValidationMail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [mailLogin, setMailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isFbUser, setIsFbUser] = useState(false);
  const [accountNotFound, setAccountNotFound] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [wrongMessageForgotPassword, setWrongMessageForgotPassword] =
    useState(false);

  const mypageLocation = {
    pathname: "/min-sida",
    state: { fromLogin: true },
  };

  const closeAllModals = () => {
    setOpenHelp(false);
    setWrongMessage(false);
    setValidationPassword(false);
    setCreatedUser(false);
    setValidationMail(false);
    setWrongMessage(false);
    setForgotPassword(false);
    setIsFbUser(false);
    setAccountNotFound(false);
    setMailSent(false);
    setWrongMessageForgotPassword(false);
  };

  const submitMail = (mail) => {
    var obj = {
      email: mail,
    };
    try {
      axios
        .post(process.env.REACT_APP_API_DOMAIN + "/cb/passwordForgot", obj)
        .then((res) => {
          if (
            res.data.error ===
            "E-postadressen du angivit är inte knuten till något Citiboard-konto."
          ) {
            setAccountNotFound(true);
          } else if (
            res.data.error ===
            "Kontot är knutet till Facebook. Använd funktionen för att logga in via Facebook istället."
          ) {
            setIsFbUser(true);
          } else if (res.data === "🎉") {
            setMailSent(true);
          } else {
            setWrongMessageForgotPassword(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
    setForgotPassword(false);
  };

  const accept = () => {
    closeAllModals();
    window.location.replace(mypageLocation.pathname);
  };

  const createUser = () => {
    if (
      name === "" ||
      mail === "" ||
      password === "" ||
      passwordConfirm === ""
    ) {
      setWrongMessage(true);
      return;
    } else if (!ValidateMail(mail)) {
      setValidationMail(true);
      return;
    } else if (password === passwordConfirm && ValidatePassword(password)) {
      var userObject = {
        name,
        email: mail,
        password,
      };

      axios
        .post(process.env.REACT_APP_API_DOMAIN + "/cb/register", userObject)
        .then((res) => {
          if (
            res.data.error ===
            "Det finns redan en användare med denna e-postadress"
          ) {
            setSameMail(true);
            return;
          } else {
            setCreatedUser(true);
            if (cookieId !== res.data.referens) {
              cookies.set("id", res.data.referens, {
                path: "/",
                sameSite: true,
                secure: true,
              });
            }
            props.user(res.data);
          }
        });
    } else {
      setValidationPassword(true);
      return;
    }
  };

  const responseFacebook = (data) => {
    var jsonData = JSON.stringify(data);
    axios
      .post(process.env.REACT_APP_API_DOMAIN + "/cb/fblogin", jsonData)
      .then((res) => {
        if (cookieId !== res.data.referens) {
          cookies.set("id", res.data.referens, {
            path: "/",
            sameSite: true,
            secure: true,
          });
        }
        props.user(res.data);
      })
      .then(() => {
        window.location.replace(mypageLocation.pathname);
        return <Navigate to={mypageLocation} />;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    var userObject = {
      email: mailLogin,
      password: passwordLogin,
    };

    axios
      .post(process.env.REACT_APP_API_DOMAIN + "/cb/login", userObject)
      .then((res) => {
        if (
          res.data.error === "🤷" ||
          res.data.error === "Felaktigt användarnamn eller lösenord"
        ) {
          setWrongCredentials(true);
          return;
        } else {
          if (cookieId !== res.data.referens) {
            cookies.set("id", res.data.referens, {
              path: "/",
              sameSite: true,
              secure: true,
            });
          }
          props.user(res.data);
          window.location.replace(mypageLocation.pathname);
        }
      });
  };

  if (cookieId !== undefined && cookieId !== "") {
    return <Navigate to={mypageLocation} />;
  }

  return (
    <div className="login-container mainwrapper">
      <div className="login-container-subwrapper">
        <div className="login-actions">
          <div
            className={login ? "login-chosen" : "login-choice"}
            onClick={() => setLogin(true)}
          >
            Logga in på ditt konto
          </div>
          <div
            className={!login ? "login-chosen" : "login-choice"}
            onClick={() => setLogin(false)}
          >
            Skapa gratis konto
          </div>
        </div>
        <div className={login ? "login-wrapper" : "hide"}>
          <div className={"login-content"}>
            <div className="register-title">
              Logga in på ditt Citiboard-konto
            </div>
            <div className="login-form-container">
              <form>
                <input
                  type="text"
                  autoComplete="email"
                  placeholder="Mejladress"
                  className="mail-login-form"
                  name="mail"
                  required
                  value={mailLogin}
                  onChange={(e) => setMailLogin(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Lösenord"
                  autoComplete="current-password"
                  className="psw-login-form"
                  name="psw-login"
                  required
                  value={passwordLogin}
                  onChange={(e) => setPasswordLogin(e.target.value)}
                />
                <div className="login-problems-container">
                  <div
                    className="login-forgot-password"
                    onClick={() => setForgotPassword(true)}
                  >
                    <span>Glömt lösenord?</span>
                  </div>
                </div>
                <LoginButton
                  variant="contained"
                  onClick={(e) => handleLogin(e)}
                  color="primary"
                  className="login-button"
                  type="submit"
                >
                  Logga in
                </LoginButton>
                <FacebookLogin
                  appId={appId}
                  callback={responseFacebook}
                  disableMobileRedirect={true}
                  isMobile={false}
                  fields="name,email,picture"
                  size="large"
                  scope="public_profile, email,user_birthday"
                  render={(renderProps) => (
                    <div
                      className="login-choice-button-facebook"
                      onClick={renderProps.onClick}
                    >
                      <Facebook alt="facebook-logo" className="login-facebook-logo" />
                      <p className="login-choice-text-facebook">
                        Logga in med Facebook
                      </p>
                    </div>
                  )}
                />
              </form>
            </div>
          </div>
          <div className="register-images">
            <img
              src={createAccountPin}
              className="create-account-pin"
              alt="create-account-pin"
            />
          </div>
        </div>
        <div className={!login ? "register-wrapper" : "hide"}>
          <div className={"register-content"}>
            <div className="register-title">
              Skapa ett gratis Citiboard-konto
            </div>
            <form className="login-form">
              <input
                type="text"
                htmlFor="name"
                value={name}
                autoComplete="given-name"
                placeholder="Ditt namn"
                className={name === "" ? "name" : "name validated"}
                name="name"
                required
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                htmlFor="email"
                value={mail}
                autoComplete="email"
                placeholder="E-postadress"
                className={mail === "" ? "mail" : "mail validated"}
                name="mail"
                required
                onChange={(e) => setMail(e.target.value)}
              />
              <span
                className={
                  !ValidateMail(mail) && mail !== ""
                    ? "register-psw-warning"
                    : "hide"
                }
              >
                Mejladressen som angivits är inte giltig
              </span>
              <br />
              <input
                type={showPassword ? "text" : "password"}
                htmlFor="password"
                value={password}
                autoComplete="current-password"
                placeholder="Lösenord"
                className={
                  password === passwordConfirm && ValidatePassword(password)
                    ? "psw validated"
                    : "psw"
                }
                name="psw"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type={showPassword ? "text" : "password"}
                htmlFor="password"
                value={passwordConfirm}
                autoComplete="current-password"
                placeholder="Bekräfta lösenord"
                className={
                  passwordConfirm === password &&
                  ValidatePassword(passwordConfirm)
                    ? "psw validated"
                    : "psw"
                }
                name="psw"
                required
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
              <VisibilityOffIcon
                className={
                  showPassword ? "eye-icon-psw-active" : "eye-icon-psw"
                }
                onClick={() => setShowPassword(!showPassword)}
              />
              <span
                className={
                  password !== "" &&
                  passwordConfirm !== "" &&
                  password !== passwordConfirm
                    ? "register-psw-warning"
                    : "hide"
                }
              >
                Lösenorden stämmer inte överens{" "}
              </span>
            </form>
            <span className="register-information">
              Använd minst åtta tecken och en kombination av en stor bokstav, en
              liten bokstav och siffror.
            </span>
            <ColorButton
              variant="contained"
              color="primary"
              className="register-next-button"
              onClick={() => createUser()}
            >
              Skapa konto
            </ColorButton>
            {openHelp ? (
              <DialogComponent
                title="Företagskonto"
                text="Ett företagskonto skapas för företag som vill sälja sina produkter lala kostar 79kr per annons visas 10gr per vecka i valda kategorier uppgradera till 129kr för extra mycket marknadsföring"
                onClose={closeAllModals}
              />
            ) : wrongMessage ? (
              <DialogComponent
                title="Något gick fel"
                text="Alla fält måste vara ifyllda för att kunna skapa ett konto Kom ihåg att använda minst åtta tecken samt en kombination av bokstäver, siffror och symboler."
                onClose={closeAllModals}
              />
            ) : validationPassword ? (
              <DialogComponent
                title="Något gick fel"
                text="Lösenorden uppnår inte kraven. Kom ihåg att använda minst åtta tecken samt en kombination av bokstäver, siffror och symboler."
                onClose={closeAllModals}
              />
            ) : validationMail ? (
              <DialogComponent
                title="Något gick fel"
                text="Mejladressen som angivits är inte valid, försök igen."
                onClose={closeAllModals}
              />
            ) : createdUser ? (
              <DialogComponent
                title="Ett nytt konto har skapats"
                text="Ditt konto har nu skapats och du kommer att skickas till din sida."
                accept={accept}
                onClose={closeAllModals}
              />
            ) : sameMail ? (
              <DialogComponent
                title="Något fick fel"
                text="Mejladressen du angav finns redan i vårt system."
                onClose={closeAllModals}
              />
            ) : wrongCredentials ? (
              <DialogComponent
                title="Felaktiga inloggningsuppgifter"
                text="Felaktigt användarnamn eller lösenord"
                onClose={closeAllModals}
              />
            ) : forgotPassword ? (
              <MailInputHandler
                title="Glömt lösenord"
                text="Fyll i din mejladress så skickar vi ett återställningsmejl till dig."
                onClose={closeAllModals}
                onSubmit={submitMail}
              />
            ) : isFbUser ? (
              <DialogComponent
                title="Något fick fel"
                text="Kontot är knutet till Facebook. Använd funktionen för att logga in via Facebook istället."
                onClose={closeAllModals}
              />
            ) : accountNotFound ? (
              <DialogComponent
                title="Något fick fel"
                text="E-postadressen du angivit är inte knuten till något Citiboard-konto."
                onClose={closeAllModals}
              />
            ) : mailSent ? (
              <DialogComponent
                title="Återställningsmejlet skickat."
                text="Återställningsmejlet är nu skickat och du kommer att få det i din mejlkorg inom kort."
                onClose={closeAllModals}
              />
            ) : wrongMessageForgotPassword ? (
              <DialogComponent
                title="Något gick fel"
                text="Något gick fel när återställningsmejlet skulle skickas, försök igen."
                onClose={closeAllModals}
              />
            ) : (
              ""
            )}
          </div>
          <div className="register-images">
            <img
              src={createAccountPin}
              className="create-account-pin"
              alt="create-account-pin"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    user: (userData) => dispatch(user(userData)),
  };
};

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Login));
