import * as t from "../actionTypes";

export const fetchFavoriteAds = (ads) => {
  return {
    type: t.FETCH_FAVORITE_ADS,
    payload: ads,
  };
};

export const addFavoriteAd = (ad) => {
  return {
    type: t.ADD_FAVORITE_AD,
    payload: ad,
  };
};

export const removeFavoriteAd = (ad) => {
  return {
    type: t.REMOVE_FAVORITE_AD,
    payload: ad,
  };
};
