const orter = [
    {
        "id": "2",
        "rubrik": "Blekinge",
        "url": "blekinge",
        "parent": "1"
    },
    {
        "id": "23",
        "rubrik": "Karlshamn",
        "url": "blekinge/karlshamn",
        "parent": "2"
    },
    {
        "id": "24",
        "rubrik": "Karlskrona",
        "url": "blekinge/karlskrona",
        "parent": "2"
    },
    {
        "id": "25",
        "rubrik": "Olofström",
        "url": "blekinge/olofstrom",
        "parent": "2"
    },
    {
        "id": "26",
        "rubrik": "Ronneby",
        "url": "blekinge/ronneby",
        "parent": "2"
    },
    {
        "id": "27",
        "rubrik": "Sölvesborg",
        "url": "blekinge/solvesborg",
        "parent": "2"
    },
    {
        "id": "3",
        "rubrik": "Dalarna",
        "url": "dalarna",
        "parent": "1"
    },
    {
        "id": "28",
        "rubrik": "Älvdalen",
        "url": "dalarna/alvdalen",
        "parent": "3"
    },
    {
        "id": "29",
        "rubrik": "Avesta",
        "url": "dalarna/avesta",
        "parent": "3"
    },
    {
        "id": "318",
        "rubrik": "Borlänge",
        "url": "dalarna/borlange",
        "parent": "3"
    },
    {
        "id": "317",
        "rubrik": "Falun",
        "url": "dalarna/falun",
        "parent": "3"
    },
    {
        "id": "32",
        "rubrik": "Gagnef",
        "url": "dalarna/gagnef",
        "parent": "3"
    },
    {
        "id": "33",
        "rubrik": "Hedemora",
        "url": "dalarna/hedemora",
        "parent": "3"
    },
    {
        "id": "34",
        "rubrik": "Leksand",
        "url": "dalarna/leksand",
        "parent": "3"
    },
    {
        "id": "35",
        "rubrik": "Ludvika",
        "url": "dalarna/ludvika",
        "parent": "3"
    },
    {
        "id": "36",
        "rubrik": "Malung-Sälen",
        "url": "dalarna/malung-salen",
        "parent": "3"
    },
    {
        "id": "37",
        "rubrik": "Mora",
        "url": "dalarna/mora",
        "parent": "3"
    },
    {
        "id": "38",
        "rubrik": "Orsa",
        "url": "dalarna/orsa",
        "parent": "3"
    },
    {
        "id": "39",
        "rubrik": "Rättvik",
        "url": "dalarna/rattvik",
        "parent": "3"
    },
    {
        "id": "40",
        "rubrik": "Säter",
        "url": "dalarna/sater",
        "parent": "3"
    },
    {
        "id": "41",
        "rubrik": "Smedjebacken",
        "url": "dalarna/smedjebacken",
        "parent": "3"
    },
    {
        "id": "42",
        "rubrik": "Vansbro",
        "url": "dalarna/vansbro",
        "parent": "3"
    },
    {
        "id": "4",
        "rubrik": "Gävleborg",
        "url": "gavleborg",
        "parent": "1"
    },
    {
        "id": "43",
        "rubrik": "Bollnäs",
        "url": "gavleborg/bollnas",
        "parent": "4"
    },
    {
        "id": "322",
        "rubrik": "Gävle",
        "url": "gavleborg/gavle",
        "parent": "4"
    },
    {
        "id": "45",
        "rubrik": "Hofors",
        "url": "gavleborg/hofors",
        "parent": "4"
    },
    {
        "id": "324",
        "rubrik": "Hudiksvall",
        "url": "gavleborg/hudiksvall",
        "parent": "4"
    },
    {
        "id": "47",
        "rubrik": "Ljusdal",
        "url": "gavleborg/ljusdal",
        "parent": "4"
    },
    {
        "id": "48",
        "rubrik": "Nordanstig",
        "url": "gavleborg/nordanstig",
        "parent": "4"
    },
    {
        "id": "49",
        "rubrik": "Ockelbo",
        "url": "gavleborg/ockelbo",
        "parent": "4"
    },
    {
        "id": "50",
        "rubrik": "Ovanåker",
        "url": "gavleborg/ovanaker",
        "parent": "4"
    },
    {
        "id": "325",
        "rubrik": "Sandviken",
        "url": "gavleborg/sandviken",
        "parent": "4"
    },
    {
        "id": "323",
        "rubrik": "Söderhamn",
        "url": "gavleborg/soderhamn",
        "parent": "4"
    },
    {
        "id": "5",
        "rubrik": "Gotland",
        "url": "gotland",
        "parent": "1"
    },
    {
        "id": "329",
        "rubrik": "Gotland",
        "url": "gotland/gotland",
        "parent": "5"
    },
    {
        "id": "6",
        "rubrik": "Halland",
        "url": "halland",
        "parent": "1"
    },
    {
        "id": "55",
        "rubrik": "Falkenberg",
        "url": "halland/falkenberg",
        "parent": "6"
    },
    {
        "id": "56",
        "rubrik": "Halmstad",
        "url": "halland/halmstad",
        "parent": "6"
    },
    {
        "id": "57",
        "rubrik": "Hylte",
        "url": "halland/hylte",
        "parent": "6"
    },
    {
        "id": "58",
        "rubrik": "Kungsbacka",
        "url": "halland/kungsbacka",
        "parent": "6"
    },
    {
        "id": "59",
        "rubrik": "Laholm",
        "url": "halland/laholm",
        "parent": "6"
    },
    {
        "id": "60",
        "rubrik": "Onsala",
        "url": "halland/onsala",
        "parent": "6"
    },
    {
        "id": "61",
        "rubrik": "Varberg",
        "url": "halland/varberg",
        "parent": "6"
    },
    {
        "id": "7",
        "rubrik": "Jämtland",
        "url": "jamtland",
        "parent": "1"
    },
    {
        "id": "62",
        "rubrik": "Åre",
        "url": "jamtland/are",
        "parent": "7"
    },
    {
        "id": "63",
        "rubrik": "Berg",
        "url": "jamtland/berg",
        "parent": "7"
    },
    {
        "id": "64",
        "rubrik": "Bräcke",
        "url": "jamtland/bracke",
        "parent": "7"
    },
    {
        "id": "65",
        "rubrik": "Härjedalen",
        "url": "jamtland/harjedalen",
        "parent": "7"
    },
    {
        "id": "66",
        "rubrik": "Krokom",
        "url": "jamtland/krokom",
        "parent": "7"
    },
    {
        "id": "67",
        "rubrik": "Östersund",
        "url": "jamtland/ostersund",
        "parent": "7"
    },
    {
        "id": "68",
        "rubrik": "Ragunda",
        "url": "jamtland/ragunda",
        "parent": "7"
    },
    {
        "id": "69",
        "rubrik": "Strömsund",
        "url": "jamtland/stromsund",
        "parent": "7"
    },
    {
        "id": "8",
        "rubrik": "Jönköping",
        "url": "jonkoping",
        "parent": "1"
    },
    {
        "id": "70",
        "rubrik": "Aneby",
        "url": "jonkoping/aneby",
        "parent": "8"
    },
    {
        "id": "71",
        "rubrik": "Eksjö",
        "url": "jonkoping/eksjo",
        "parent": "8"
    },
    {
        "id": "72",
        "rubrik": "Gislaved",
        "url": "jonkoping/gislaved",
        "parent": "8"
    },
    {
        "id": "73",
        "rubrik": "Gnosjö",
        "url": "jonkoping/gnosjo",
        "parent": "8"
    },
    {
        "id": "74",
        "rubrik": "Habo",
        "url": "jonkoping/habo",
        "parent": "8"
    },
    {
        "id": "75",
        "rubrik": "Jönköping",
        "url": "jonkoping/jonkoping",
        "parent": "8"
    },
    {
        "id": "76",
        "rubrik": "Mullsjö",
        "url": "jonkoping/mullsjo",
        "parent": "8"
    },
    {
        "id": "77",
        "rubrik": "Nässjö",
        "url": "jonkoping/nassjo",
        "parent": "8"
    },
    {
        "id": "78",
        "rubrik": "Sävsjö",
        "url": "jonkoping/savsjo",
        "parent": "8"
    },
    {
        "id": "79",
        "rubrik": "Tranås",
        "url": "jonkoping/tranas",
        "parent": "8"
    },
    {
        "id": "80",
        "rubrik": "Vaggeryd",
        "url": "jonkoping/vaggeryd",
        "parent": "8"
    },
    {
        "id": "81",
        "rubrik": "Värnamo",
        "url": "jonkoping/varnamo",
        "parent": "8"
    },
    {
        "id": "82",
        "rubrik": "Vetlanda",
        "url": "jonkoping/vetlanda",
        "parent": "8"
    },
    {
        "id": "9",
        "rubrik": "Kalmar",
        "url": "kalmar",
        "parent": "1"
    },
    {
        "id": "83",
        "rubrik": "Borgholm",
        "url": "kalmar/borgholm",
        "parent": "9"
    },
    {
        "id": "84",
        "rubrik": "Emmaboda",
        "url": "kalmar/emmaboda",
        "parent": "9"
    },
    {
        "id": "85",
        "rubrik": "Högsby",
        "url": "kalmar/hogsby",
        "parent": "9"
    },
    {
        "id": "86",
        "rubrik": "Hultsfred",
        "url": "kalmar/hultsfred",
        "parent": "9"
    },
    {
        "id": "87",
        "rubrik": "Kalmar",
        "url": "kalmar/kalmar",
        "parent": "9"
    },
    {
        "id": "88",
        "rubrik": "Mönsterås",
        "url": "kalmar/monsteras",
        "parent": "9"
    },
    {
        "id": "89",
        "rubrik": "Mörbylånga",
        "url": "kalmar/morbylanga",
        "parent": "9"
    },
    {
        "id": "90",
        "rubrik": "Nybro",
        "url": "kalmar/nybro",
        "parent": "9"
    },
    {
        "id": "91",
        "rubrik": "Oskarshamn",
        "url": "kalmar/oskarshamn",
        "parent": "9"
    },
    {
        "id": "92",
        "rubrik": "Torsås",
        "url": "kalmar/torsas",
        "parent": "9"
    },
    {
        "id": "93",
        "rubrik": "Västervik",
        "url": "kalmar/vastervik",
        "parent": "9"
    },
    {
        "id": "94",
        "rubrik": "Vimmerby",
        "url": "kalmar/vimmerby",
        "parent": "9"
    },
    {
        "id": "10",
        "rubrik": "Kronoberg",
        "url": "kronoberg",
        "parent": "1"
    },
    {
        "id": "95",
        "rubrik": "Älmhult",
        "url": "kronoberg/almhult",
        "parent": "10"
    },
    {
        "id": "96",
        "rubrik": "Alvesta",
        "url": "kronoberg/alvesta",
        "parent": "10"
    },
    {
        "id": "97",
        "rubrik": "Lessebo",
        "url": "kronoberg/lessebo",
        "parent": "10"
    },
    {
        "id": "98",
        "rubrik": "Ljungby",
        "url": "kronoberg/ljungby",
        "parent": "10"
    },
    {
        "id": "99",
        "rubrik": "Markaryd",
        "url": "kronoberg/markaryd",
        "parent": "10"
    },
    {
        "id": "100",
        "rubrik": "Tingsryd",
        "url": "kronoberg/tingsryd",
        "parent": "10"
    },
    {
        "id": "101",
        "rubrik": "Uppvidinge",
        "url": "kronoberg/uppvidinge",
        "parent": "10"
    },
    {
        "id": "102",
        "rubrik": "Växjö",
        "url": "kronoberg/vaxjo",
        "parent": "10"
    },
    {
        "id": "11",
        "rubrik": "Norrbotten",
        "url": "norrbotten",
        "parent": "1"
    },
    {
        "id": "103",
        "rubrik": "Älvsbyn",
        "url": "norrbotten/alvsbyn",
        "parent": "11"
    },
    {
        "id": "104",
        "rubrik": "Arjeplog",
        "url": "norrbotten/arjeplog",
        "parent": "11"
    },
    {
        "id": "105",
        "rubrik": "Arvidsjaur",
        "url": "norrbotten/arvidsjaur",
        "parent": "11"
    },
    {
        "id": "106",
        "rubrik": "Boden",
        "url": "norrbotten/boden",
        "parent": "11"
    },
    {
        "id": "107",
        "rubrik": "Gällivare",
        "url": "norrbotten/gallivare",
        "parent": "11"
    },
    {
        "id": "108",
        "rubrik": "Haparanda",
        "url": "norrbotten/haparanda",
        "parent": "11"
    },
    {
        "id": "109",
        "rubrik": "Jokkmokk",
        "url": "norrbotten/jokkmokk",
        "parent": "11"
    },
    {
        "id": "110",
        "rubrik": "Kalix",
        "url": "norrbotten/kalix",
        "parent": "11"
    },
    {
        "id": "111",
        "rubrik": "Kiruna",
        "url": "norrbotten/kiruna",
        "parent": "11"
    },
    {
        "id": "112",
        "rubrik": "Luleå",
        "url": "norrbotten/lulea",
        "parent": "11"
    },
    {
        "id": "113",
        "rubrik": "Överkalix",
        "url": "norrbotten/overkalix",
        "parent": "11"
    },
    {
        "id": "114",
        "rubrik": "Övertorneå",
        "url": "norrbotten/overtornea",
        "parent": "11"
    },
    {
        "id": "115",
        "rubrik": "Pajala",
        "url": "norrbotten/pajala",
        "parent": "11"
    },
    {
        "id": "116",
        "rubrik": "Piteå",
        "url": "norrbotten/pitea",
        "parent": "11"
    },
    {
        "id": "12",
        "rubrik": "Örebro",
        "url": "orebro",
        "parent": "1"
    },
    {
        "id": "117",
        "rubrik": "Askersund",
        "url": "orebro/askersund",
        "parent": "12"
    },
    {
        "id": "118",
        "rubrik": "Degerfors",
        "url": "orebro/degerfors",
        "parent": "12"
    },
    {
        "id": "119",
        "rubrik": "Hällefors",
        "url": "orebro/hallefors",
        "parent": "12"
    },
    {
        "id": "120",
        "rubrik": "Hallsberg",
        "url": "orebro/hallsberg",
        "parent": "12"
    },
    {
        "id": "121",
        "rubrik": "Karlskoga",
        "url": "orebro/karlskoga",
        "parent": "12"
    },
    {
        "id": "122",
        "rubrik": "Kumla",
        "url": "orebro/kumla",
        "parent": "12"
    },
    {
        "id": "123",
        "rubrik": "Laxå",
        "url": "orebro/laxa",
        "parent": "12"
    },
    {
        "id": "124",
        "rubrik": "Lekeberg",
        "url": "orebro/lekeberg",
        "parent": "12"
    },
    {
        "id": "125",
        "rubrik": "Lindesberg",
        "url": "orebro/lindesberg",
        "parent": "12"
    },
    {
        "id": "126",
        "rubrik": "Ljusnarsberg",
        "url": "orebro/ljusnarsberg",
        "parent": "12"
    },
    {
        "id": "127",
        "rubrik": "Nora",
        "url": "orebro/nora",
        "parent": "12"
    },
    {
        "id": "128",
        "rubrik": "Örebro",
        "url": "orebro/orebro",
        "parent": "12"
    },
    {
        "id": "13",
        "rubrik": "Östergötland",
        "url": "ostergotland",
        "parent": "1"
    },
    {
        "id": "129",
        "rubrik": "Åtvidaberg",
        "url": "ostergotland/atvidaberg",
        "parent": "13"
    },
    {
        "id": "130",
        "rubrik": "Boxholm",
        "url": "ostergotland/boxholm",
        "parent": "13"
    },
    {
        "id": "131",
        "rubrik": "Finspång",
        "url": "ostergotland/finspang",
        "parent": "13"
    },
    {
        "id": "132",
        "rubrik": "Kinda",
        "url": "ostergotland/kinda",
        "parent": "13"
    },
    {
        "id": "133",
        "rubrik": "Linköping",
        "url": "ostergotland/linkoping",
        "parent": "13"
    },
    {
        "id": "134",
        "rubrik": "Mjölby",
        "url": "ostergotland/mjolby",
        "parent": "13"
    },
    {
        "id": "135",
        "rubrik": "Motala",
        "url": "ostergotland/motala",
        "parent": "13"
    },
    {
        "id": "136",
        "rubrik": "Norrköping",
        "url": "ostergotland/norrkoping",
        "parent": "13"
    },
    {
        "id": "137",
        "rubrik": "Ödeshög",
        "url": "ostergotland/odeshog",
        "parent": "13"
    },
    {
        "id": "138",
        "rubrik": "Söderköping",
        "url": "ostergotland/soderkoping",
        "parent": "13"
    },
    {
        "id": "139",
        "rubrik": "Vadstena",
        "url": "ostergotland/vadstena",
        "parent": "13"
    },
    {
        "id": "140",
        "rubrik": "Valdemarsvik",
        "url": "ostergotland/valdemarsvik",
        "parent": "13"
    },
    {
        "id": "141",
        "rubrik": "Ydre",
        "url": "ostergotland/ydre",
        "parent": "13"
    },
    {
        "id": "14",
        "rubrik": "Skåne",
        "url": "skane",
        "parent": "1"
    },
    {
        "id": "142",
        "rubrik": "Ängelholm",
        "url": "skane/angelholm",
        "parent": "14"
    },
    {
        "id": "143",
        "rubrik": "Åstorp",
        "url": "skane/astorp",
        "parent": "14"
    },
    {
        "id": "144",
        "rubrik": "Båstad",
        "url": "skane/bastad",
        "parent": "14"
    },
    {
        "id": "145",
        "rubrik": "Bjuv",
        "url": "skane/bjuv",
        "parent": "14"
    },
    {
        "id": "146",
        "rubrik": "Bromölla",
        "url": "skane/bromolla",
        "parent": "14"
    },
    {
        "id": "147",
        "rubrik": "Burlöv",
        "url": "skane/burlov",
        "parent": "14"
    },
    {
        "id": "148",
        "rubrik": "Eslöv",
        "url": "skane/eslov",
        "parent": "14"
    },
    {
        "id": "149",
        "rubrik": "Hässleholm",
        "url": "skane/hassleholm",
        "parent": "14"
    },
    {
        "id": "150",
        "rubrik": "Helsingborg",
        "url": "skane/helsingborg",
        "parent": "14"
    },
    {
        "id": "151",
        "rubrik": "Höganäs",
        "url": "skane/hoganas",
        "parent": "14"
    },
    {
        "id": "152",
        "rubrik": "Höör",
        "url": "skane/hoor",
        "parent": "14"
    },
    {
        "id": "153",
        "rubrik": "Hörby",
        "url": "skane/horby",
        "parent": "14"
    },
    {
        "id": "154",
        "rubrik": "Kävlinge",
        "url": "skane/kavlinge",
        "parent": "14"
    },
    {
        "id": "155",
        "rubrik": "Klippan",
        "url": "skane/klippan",
        "parent": "14"
    },
    {
        "id": "156",
        "rubrik": "Kristianstad",
        "url": "skane/kristianstad",
        "parent": "14"
    },
    {
        "id": "157",
        "rubrik": "Landskrona",
        "url": "skane/landskrona",
        "parent": "14"
    },
    {
        "id": "158",
        "rubrik": "Lomma",
        "url": "skane/lomma",
        "parent": "14"
    },
    {
        "id": "159",
        "rubrik": "Lund",
        "url": "skane/lund",
        "parent": "14"
    },
    {
        "id": "160",
        "rubrik": "Malmö",
        "url": "skane/malmo",
        "parent": "14"
    },
    {
        "id": "161",
        "rubrik": "Örkelljunga",
        "url": "skane/orkelljunga",
        "parent": "14"
    },
    {
        "id": "162",
        "rubrik": "Osby",
        "url": "skane/osby",
        "parent": "14"
    },
    {
        "id": "163",
        "rubrik": "Östra Göinge",
        "url": "skane/ostra-goinge",
        "parent": "14"
    },
    {
        "id": "164",
        "rubrik": "Perstorp",
        "url": "skane/perstorp",
        "parent": "14"
    },
    {
        "id": "165",
        "rubrik": "Simrishamn",
        "url": "skane/simrishamn",
        "parent": "14"
    },
    {
        "id": "166",
        "rubrik": "Sjöbo",
        "url": "skane/sjobo",
        "parent": "14"
    },
    {
        "id": "167",
        "rubrik": "Skurup",
        "url": "skane/skurup",
        "parent": "14"
    },
    {
        "id": "168",
        "rubrik": "Staffanstorp",
        "url": "skane/staffanstorp",
        "parent": "14"
    },
    {
        "id": "169",
        "rubrik": "Svalöv",
        "url": "skane/svalov",
        "parent": "14"
    },
    {
        "id": "170",
        "rubrik": "Svedala",
        "url": "skane/svedala",
        "parent": "14"
    },
    {
        "id": "171",
        "rubrik": "Tomelilla",
        "url": "skane/tomelilla",
        "parent": "14"
    },
    {
        "id": "172",
        "rubrik": "Trelleborg",
        "url": "skane/trelleborg",
        "parent": "14"
    },
    {
        "id": "173",
        "rubrik": "Vellinge",
        "url": "skane/vellinge",
        "parent": "14"
    },
    {
        "id": "174",
        "rubrik": "Ystad",
        "url": "skane/ystad",
        "parent": "14"
    },
    {
        "id": "15",
        "rubrik": "Södermanland",
        "url": "sodermanland",
        "parent": "1"
    },
    {
        "id": "175",
        "rubrik": "Eskilstuna",
        "url": "sodermanland/eskilstuna",
        "parent": "15"
    },
    {
        "id": "176",
        "rubrik": "Flen",
        "url": "sodermanland/flen",
        "parent": "15"
    },
    {
        "id": "177",
        "rubrik": "Gnesta",
        "url": "sodermanland/gnesta",
        "parent": "15"
    },
    {
        "id": "178",
        "rubrik": "Katrineholm",
        "url": "sodermanland/katrineholm",
        "parent": "15"
    },
    {
        "id": "179",
        "rubrik": "Nyköping",
        "url": "sodermanland/nykoping",
        "parent": "15"
    },
    {
        "id": "180",
        "rubrik": "Oxelösund",
        "url": "sodermanland/oxelosund",
        "parent": "15"
    },
    {
        "id": "181",
        "rubrik": "Strängnäs",
        "url": "sodermanland/strangnas",
        "parent": "15"
    },
    {
        "id": "182",
        "rubrik": "Trosa",
        "url": "sodermanland/trosa",
        "parent": "15"
    },
    {
        "id": "183",
        "rubrik": "Vingåker",
        "url": "sodermanland/vingaker",
        "parent": "15"
    },
    {
        "id": "16",
        "rubrik": "Stockholm",
        "url": "stockholm",
        "parent": "1"
    },
    {
        "id": "184",
        "rubrik": "Botkyrka",
        "url": "stockholm/botkyrka",
        "parent": "16"
    },
    {
        "id": "185",
        "rubrik": "Danderyd",
        "url": "stockholm/danderyd",
        "parent": "16"
    },
    {
        "id": "186",
        "rubrik": "Ekerö",
        "url": "stockholm/ekero",
        "parent": "16"
    },
    {
        "id": "187",
        "rubrik": "Haninge",
        "url": "stockholm/haninge",
        "parent": "16"
    },
    {
        "id": "188",
        "rubrik": "Huddinge",
        "url": "stockholm/huddinge",
        "parent": "16"
    },
    {
        "id": "189",
        "rubrik": "Järfälla",
        "url": "stockholm/jarfalla",
        "parent": "16"
    },
    {
        "id": "190",
        "rubrik": "Lidingö",
        "url": "stockholm/lidingo",
        "parent": "16"
    },
    {
        "id": "191",
        "rubrik": "Nacka",
        "url": "stockholm/nacka",
        "parent": "16"
    },
    {
        "id": "192",
        "rubrik": "Norrtälje",
        "url": "stockholm/norrtalje",
        "parent": "16"
    },
    {
        "id": "193",
        "rubrik": "Nykvarn",
        "url": "stockholm/nykvarn",
        "parent": "16"
    },
    {
        "id": "194",
        "rubrik": "Nynäshamn",
        "url": "stockholm/nynashamn",
        "parent": "16"
    },
    {
        "id": "195",
        "rubrik": "Österåker",
        "url": "stockholm/osteraker",
        "parent": "16"
    },
    {
        "id": "196",
        "rubrik": "Salem",
        "url": "stockholm/salem",
        "parent": "16"
    },
    {
        "id": "197",
        "rubrik": "Sigtuna",
        "url": "stockholm/sigtuna",
        "parent": "16"
    },
    {
        "id": "198",
        "rubrik": "Södertälje",
        "url": "stockholm/sodertalje",
        "parent": "16"
    },
    {
        "id": "199",
        "rubrik": "Sollentuna",
        "url": "stockholm/sollentuna",
        "parent": "16"
    },
    {
        "id": "200",
        "rubrik": "Solna",
        "url": "stockholm/solna",
        "parent": "16"
    },
    {
        "id": "201",
        "rubrik": "Stockholm",
        "url": "stockholm/stockholm",
        "parent": "16"
    },
    {
        "id": "202",
        "rubrik": "Sundbyberg",
        "url": "stockholm/sundbyberg",
        "parent": "16"
    },
    {
        "id": "203",
        "rubrik": "Täby",
        "url": "stockholm/taby",
        "parent": "16"
    },
    {
        "id": "204",
        "rubrik": "Tyresö",
        "url": "stockholm/tyreso",
        "parent": "16"
    },
    {
        "id": "206",
        "rubrik": "Upplands-Bro",
        "url": "stockholm/upplands-bro",
        "parent": "16"
    },
    {
        "id": "205",
        "rubrik": "Upplands Väsby",
        "url": "stockholm/upplands-vasby",
        "parent": "16"
    },
    {
        "id": "207",
        "rubrik": "Vallentuna",
        "url": "stockholm/vallentuna",
        "parent": "16"
    },
    {
        "id": "208",
        "rubrik": "Värmdö",
        "url": "stockholm/varmdo",
        "parent": "16"
    },
    {
        "id": "209",
        "rubrik": "Vaxholm",
        "url": "stockholm/vaxholm",
        "parent": "16"
    },
    {
        "id": "17",
        "rubrik": "Uppsala",
        "url": "uppsala",
        "parent": "1"
    },
    {
        "id": "210",
        "rubrik": "Älvkarleby",
        "url": "uppsala/alvkarleby",
        "parent": "17"
    },
    {
        "id": "211",
        "rubrik": "Enköping",
        "url": "uppsala/enkoping",
        "parent": "17"
    },
    {
        "id": "212",
        "rubrik": "Håbo",
        "url": "uppsala/habo",
        "parent": "17"
    },
    {
        "id": "213",
        "rubrik": "Heby",
        "url": "uppsala/heby",
        "parent": "17"
    },
    {
        "id": "214",
        "rubrik": "Knivsta",
        "url": "uppsala/knivsta",
        "parent": "17"
    },
    {
        "id": "215",
        "rubrik": "Östhammar",
        "url": "uppsala/osthammar",
        "parent": "17"
    },
    {
        "id": "216",
        "rubrik": "Tierp",
        "url": "uppsala/tierp",
        "parent": "17"
    },
    {
        "id": "217",
        "rubrik": "Uppsala",
        "url": "uppsala/uppsala",
        "parent": "17"
    },
    {
        "id": "18",
        "rubrik": "Värmland",
        "url": "varmland",
        "parent": "1"
    },
    {
        "id": "218",
        "rubrik": "Årjäng",
        "url": "varmland/arjang",
        "parent": "18"
    },
    {
        "id": "219",
        "rubrik": "Arvika",
        "url": "varmland/arvika",
        "parent": "18"
    },
    {
        "id": "220",
        "rubrik": "Eda",
        "url": "varmland/eda",
        "parent": "18"
    },
    {
        "id": "221",
        "rubrik": "Filipstad",
        "url": "varmland/filipstad",
        "parent": "18"
    },
    {
        "id": "222",
        "rubrik": "Forshaga",
        "url": "varmland/forshaga",
        "parent": "18"
    },
    {
        "id": "223",
        "rubrik": "Grums",
        "url": "varmland/grums",
        "parent": "18"
    },
    {
        "id": "224",
        "rubrik": "Hagfors",
        "url": "varmland/hagfors",
        "parent": "18"
    },
    {
        "id": "225",
        "rubrik": "Hammarö",
        "url": "varmland/hammaro",
        "parent": "18"
    },
    {
        "id": "226",
        "rubrik": "Karlstad",
        "url": "varmland/karlstad",
        "parent": "18"
    },
    {
        "id": "227",
        "rubrik": "Kil",
        "url": "varmland/kil",
        "parent": "18"
    },
    {
        "id": "228",
        "rubrik": "Kristinehamn",
        "url": "varmland/kristinehamn",
        "parent": "18"
    },
    {
        "id": "229",
        "rubrik": "Munkfors",
        "url": "varmland/munkfors",
        "parent": "18"
    },
    {
        "id": "230",
        "rubrik": "Säffle",
        "url": "varmland/saffle",
        "parent": "18"
    },
    {
        "id": "231",
        "rubrik": "Storfors",
        "url": "varmland/storfors",
        "parent": "18"
    },
    {
        "id": "232",
        "rubrik": "Sunne",
        "url": "varmland/sunne",
        "parent": "18"
    },
    {
        "id": "233",
        "rubrik": "Torsby",
        "url": "varmland/torsby",
        "parent": "18"
    },
    {
        "id": "19",
        "rubrik": "Västerbotten",
        "url": "vasterbotten",
        "parent": "1"
    },
    {
        "id": "234",
        "rubrik": "Åsele",
        "url": "vasterbotten/asele",
        "parent": "19"
    },
    {
        "id": "235",
        "rubrik": "Bjurholm",
        "url": "vasterbotten/bjurholm",
        "parent": "19"
    },
    {
        "id": "236",
        "rubrik": "Dorotea",
        "url": "vasterbotten/dorotea",
        "parent": "19"
    },
    {
        "id": "237",
        "rubrik": "Lycksele",
        "url": "vasterbotten/lycksele",
        "parent": "19"
    },
    {
        "id": "238",
        "rubrik": "Malå",
        "url": "vasterbotten/mala",
        "parent": "19"
    },
    {
        "id": "239",
        "rubrik": "Nordmaling",
        "url": "vasterbotten/nordmaling",
        "parent": "19"
    },
    {
        "id": "240",
        "rubrik": "Norsjö",
        "url": "vasterbotten/norsjo",
        "parent": "19"
    },
    {
        "id": "241",
        "rubrik": "Robertsfors",
        "url": "vasterbotten/robertsfors",
        "parent": "19"
    },
    {
        "id": "242",
        "rubrik": "Skellefteå",
        "url": "vasterbotten/skelleftea",
        "parent": "19"
    },
    {
        "id": "243",
        "rubrik": "Sorsele",
        "url": "vasterbotten/sorsele",
        "parent": "19"
    },
    {
        "id": "244",
        "rubrik": "Storuman",
        "url": "vasterbotten/storuman",
        "parent": "19"
    },
    {
        "id": "245",
        "rubrik": "Umeå",
        "url": "vasterbotten/umea",
        "parent": "19"
    },
    {
        "id": "246",
        "rubrik": "Vännäs",
        "url": "vasterbotten/vannas",
        "parent": "19"
    },
    {
        "id": "247",
        "rubrik": "Vilhelmina",
        "url": "vasterbotten/vilhelmina",
        "parent": "19"
    },
    {
        "id": "248",
        "rubrik": "Vindeln",
        "url": "vasterbotten/vindeln",
        "parent": "19"
    },
    {
        "id": "20",
        "rubrik": "Västernorrland",
        "url": "vasternorrland",
        "parent": "1"
    },
    {
        "id": "249",
        "rubrik": "Ånge",
        "url": "vasternorrland/ange",
        "parent": "20"
    },
    {
        "id": "250",
        "rubrik": "Härnösand",
        "url": "vasternorrland/harnosand",
        "parent": "20"
    },
    {
        "id": "251",
        "rubrik": "Kramfors",
        "url": "vasternorrland/kramfors",
        "parent": "20"
    },
    {
        "id": "252",
        "rubrik": "Örnsköldsvik",
        "url": "vasternorrland/ornskoldsvik",
        "parent": "20"
    },
    {
        "id": "253",
        "rubrik": "Sollefteå",
        "url": "vasternorrland/solleftea",
        "parent": "20"
    },
    {
        "id": "254",
        "rubrik": "Sundsvall",
        "url": "vasternorrland/sundsvall",
        "parent": "20"
    },
    {
        "id": "255",
        "rubrik": "Timrå",
        "url": "vasternorrland/timra",
        "parent": "20"
    },
    {
        "id": "21",
        "rubrik": "Västmanland",
        "url": "vastmanland",
        "parent": "1"
    },
    {
        "id": "256",
        "rubrik": "Arboga",
        "url": "vastmanland/arboga",
        "parent": "21"
    },
    {
        "id": "257",
        "rubrik": "Fagersta",
        "url": "vastmanland/fagersta",
        "parent": "21"
    },
    {
        "id": "258",
        "rubrik": "Hallstahammar",
        "url": "vastmanland/hallstahammar",
        "parent": "21"
    },
    {
        "id": "259",
        "rubrik": "Köping",
        "url": "vastmanland/koping",
        "parent": "21"
    },
    {
        "id": "260",
        "rubrik": "Kungsör",
        "url": "vastmanland/kungsor",
        "parent": "21"
    },
    {
        "id": "261",
        "rubrik": "Norberg",
        "url": "vastmanland/norberg",
        "parent": "21"
    },
    {
        "id": "262",
        "rubrik": "Sala",
        "url": "vastmanland/sala",
        "parent": "21"
    },
    {
        "id": "263",
        "rubrik": "Skinnskatteberg",
        "url": "vastmanland/skinnskatteberg",
        "parent": "21"
    },
    {
        "id": "264",
        "rubrik": "Surahammar",
        "url": "vastmanland/surahammar",
        "parent": "21"
    },
    {
        "id": "265",
        "rubrik": "Västerås",
        "url": "vastmanland/vasteras",
        "parent": "21"
    },
    {
        "id": "22",
        "rubrik": "Västra Götaland",
        "url": "vastra-gotaland",
        "parent": "1"
    },
    {
        "id": "266",
        "rubrik": "Ale",
        "url": "vastra-gotaland/ale",
        "parent": "22"
    },
    {
        "id": "267",
        "rubrik": "Alingsås",
        "url": "vastra-gotaland/alingsas",
        "parent": "22"
    },
    {
        "id": "268",
        "rubrik": "Åmål",
        "url": "vastra-gotaland/amal",
        "parent": "22"
    },
    {
        "id": "269",
        "rubrik": "Bengtsfors",
        "url": "vastra-gotaland/bengtsfors",
        "parent": "22"
    },
    {
        "id": "326",
        "rubrik": "Bollebygd",
        "url": "vastra-gotaland/bollebygd",
        "parent": "22"
    },
    {
        "id": "327",
        "rubrik": "Borås",
        "url": "vastra-gotaland/boras",
        "parent": "22"
    },
    {
        "id": "272",
        "rubrik": "Dals-Ed",
        "url": "vastra-gotaland/dals-ed",
        "parent": "22"
    },
    {
        "id": "273",
        "rubrik": "Essunga",
        "url": "vastra-gotaland/essunga",
        "parent": "22"
    },
    {
        "id": "274",
        "rubrik": "Falköping",
        "url": "vastra-gotaland/falkoping",
        "parent": "22"
    },
    {
        "id": "275",
        "rubrik": "Färgelanda",
        "url": "vastra-gotaland/fargelanda",
        "parent": "22"
    },
    {
        "id": "276",
        "rubrik": "Göteborg",
        "url": "vastra-gotaland/goteborg",
        "parent": "22"
    },
    {
        "id": "277",
        "rubrik": "Götene",
        "url": "vastra-gotaland/gotene",
        "parent": "22"
    },
    {
        "id": "278",
        "rubrik": "Grästorp",
        "url": "vastra-gotaland/grastorp",
        "parent": "22"
    },
    {
        "id": "279",
        "rubrik": "Gullspång",
        "url": "vastra-gotaland/gullspang",
        "parent": "22"
    },
    {
        "id": "280",
        "rubrik": "Härryda",
        "url": "vastra-gotaland/harryda",
        "parent": "22"
    },
    {
        "id": "281",
        "rubrik": "Herrljunga",
        "url": "vastra-gotaland/herrljunga",
        "parent": "22"
    },
    {
        "id": "282",
        "rubrik": "Hjo",
        "url": "vastra-gotaland/hjo",
        "parent": "22"
    },
    {
        "id": "283",
        "rubrik": "Karlsborg",
        "url": "vastra-gotaland/karlsborg",
        "parent": "22"
    },
    {
        "id": "284",
        "rubrik": "Kungälv",
        "url": "vastra-gotaland/kungalv",
        "parent": "22"
    },
    {
        "id": "285",
        "rubrik": "Lerum",
        "url": "vastra-gotaland/lerum",
        "parent": "22"
    },
    {
        "id": "286",
        "rubrik": "Lidköping",
        "url": "vastra-gotaland/lidkoping",
        "parent": "22"
    },
    {
        "id": "287",
        "rubrik": "Lilla Edet",
        "url": "vastra-gotaland/lilla-edet",
        "parent": "22"
    },
    {
        "id": "288",
        "rubrik": "Lysekil",
        "url": "vastra-gotaland/lysekil",
        "parent": "22"
    },
    {
        "id": "289",
        "rubrik": "Mariestad",
        "url": "vastra-gotaland/mariestad",
        "parent": "22"
    },
    {
        "id": "328",
        "rubrik": "Mark",
        "url": "vastra-gotaland/mark",
        "parent": "22"
    },
    {
        "id": "291",
        "rubrik": "Mellerud",
        "url": "vastra-gotaland/mellerud",
        "parent": "22"
    },
    {
        "id": "292",
        "rubrik": "Mölndal",
        "url": "vastra-gotaland/molndal",
        "parent": "22"
    },
    {
        "id": "293",
        "rubrik": "Munkedal",
        "url": "vastra-gotaland/munkedal",
        "parent": "22"
    },
    {
        "id": "294",
        "rubrik": "Öckerö",
        "url": "vastra-gotaland/ockero",
        "parent": "22"
    },
    {
        "id": "295",
        "rubrik": "Orust",
        "url": "vastra-gotaland/orust",
        "parent": "22"
    },
    {
        "id": "296",
        "rubrik": "Partille",
        "url": "vastra-gotaland/partille",
        "parent": "22"
    },
    {
        "id": "297",
        "rubrik": "Skara",
        "url": "vastra-gotaland/skara",
        "parent": "22"
    },
    {
        "id": "298",
        "rubrik": "Skövde",
        "url": "vastra-gotaland/skovde",
        "parent": "22"
    },
    {
        "id": "299",
        "rubrik": "Sotenäs",
        "url": "vastra-gotaland/sotenas",
        "parent": "22"
    },
    {
        "id": "300",
        "rubrik": "Stenungsund",
        "url": "vastra-gotaland/stenungsund",
        "parent": "22"
    },
    {
        "id": "301",
        "rubrik": "Strömstad",
        "url": "vastra-gotaland/stromstad",
        "parent": "22"
    },
    {
        "id": "302",
        "rubrik": "Svenljunga",
        "url": "vastra-gotaland/svenljunga",
        "parent": "22"
    },
    {
        "id": "303",
        "rubrik": "Tanum",
        "url": "vastra-gotaland/tanum",
        "parent": "22"
    },
    {
        "id": "304",
        "rubrik": "Tibro",
        "url": "vastra-gotaland/tibro",
        "parent": "22"
    },
    {
        "id": "305",
        "rubrik": "Tidaholm",
        "url": "vastra-gotaland/tidaholm",
        "parent": "22"
    },
    {
        "id": "306",
        "rubrik": "Tjörn",
        "url": "vastra-gotaland/tjorn",
        "parent": "22"
    },
    {
        "id": "307",
        "rubrik": "Töreboda",
        "url": "vastra-gotaland/toreboda",
        "parent": "22"
    },
    {
        "id": "308",
        "rubrik": "Tranemo",
        "url": "vastra-gotaland/tranemo",
        "parent": "22"
    },
    {
        "id": "309",
        "rubrik": "Trollhättan",
        "url": "vastra-gotaland/trollhattan",
        "parent": "22"
    },
    {
        "id": "312",
        "rubrik": "Uddevalla",
        "url": "vastra-gotaland/uddevalla",
        "parent": "22"
    },
    {
        "id": "311",
        "rubrik": "Ulricehamn",
        "url": "vastra-gotaland/ulricehamn",
        "parent": "22"
    },
    {
        "id": "310",
        "rubrik": "Vänersborg",
        "url": "vastra-gotaland/vanersborg",
        "parent": "22"
    },
    {
        "id": "313",
        "rubrik": "Vara",
        "url": "vastra-gotaland/vara",
        "parent": "22"
    },
    {
        "id": "314",
        "rubrik": "Vårgårda",
        "url": "vastra-gotaland/vargarda",
        "parent": "22"
    }
]

export default orter