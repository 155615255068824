import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Skeleton from "@material-ui/lab/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import axios from "axios";

import {
  fetchMyAds,
  fetchMyInactivatedAds,
  inActivateMyAd,
  removeInactiveAd,
} from "../redux/actions/myAds";

import ProfileInfo from "../components/ProfileInfo";
import ActionDialogComponent from "../components/ActionDialogComponent";

import UnixTimeHandler from "../helpers/UnixTimeHandler";

const mediaUrl = process.env.REACT_APP_MEDIA_DOMAIN + "/a/medium/";

const MyPage = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [loading, setLoading] = useState(true);
  const [inactivate, setInactivate] = useState(false);
  const [activateAd, setActivateAd] = useState(false);
  const [removeForInactiveAds, setRemoveForInactiveAds] = useState(false);
  const [chosenAdForInactivity, setChosenAdForInactivity] = useState({});
  const [chosenAdForRemoval, setChosenAdForRemoval] = useState({});
  const [chosenAdForActivate, setChosenAdForActivate] = useState({});
  const [removedLastOne, setRemovedLastOne] = useState(false);

  const isLoggedIn = props.user.user_id !== undefined;
  const myAds = props.myAds;
  const user = props.user;
  const allAds = props.allAds;

  const notLoggedInLocation = {
    pathname: "/inte-inloggad",
    state: { fromMyPage: true },
  };

  var headers = {
    "x-auth": cookieId,
  };

  useEffect(() => {
    const fetchMyAds = () => {
      if (user.user_id !== undefined) {
        try {
          axios
            .all([
              axios.get(
                process.env.REACT_APP_API_DOMAIN + "/cb/annonslista.user?user_id=" +
                  user.user_id,
                { headers: headers }
              ),
              axios.get(
                process.env.REACT_APP_API_DOMAIN + "/cb/annonslista.user?user_id=" +
                  user.user_id +
                  "&inactive=1",
                { headers: headers }
              ),
            ])
            .then(
              axios.spread((res1, res2) => {
                if (res1.data.annonser.length !== props.allAds.length) {
                  props.fetchMyAds(res1.data.annonser);
                } else {
                  setRemovedLastOne(true);
                }
                props.fetchMyInactivatedAds(res2.data.annonser);
                setLoading(false);
              })
            );
        } catch (err) {
          console.log(err.message);
        }
      }
    };

    if (myAds.activeAds.length === 0 && !removedLastOne) {
      fetchMyAds();
    }
  }, [myAds, user, headers, props, removedLastOne, allAds]);

  const closeAllModals = () => {
    setInactivate(false);
    setActivateAd(false);
    setRemoveForInactiveAds(false);
  };

  const updatePageContent = () => {
    try {
      axios
        .all([
          axios.get(
            process.env.REACT_APP_API_DOMAIN + "/cb/annonslista.user?user_id=" +
              user.user_id,
            { headers: headers }
          ),
          axios.get(
            process.env.REACT_APP_API_DOMAIN + "/cb/annonslista.user?user_id=" +
              user.user_id +
              "&inactive=1",
            { headers: headers }
          ),
        ])
        .then(
          axios.spread((res1, res2) => {
            if (res1.data.annonser.length !== props.allAds.length) {
              props.fetchMyAds(res1.data.annonser);
            } else {
              setRemovedLastOne(true);
            }
            props.fetchMyInactivatedAds(res2.data.annonser);
          })
        );
    } catch (err) {
      console.log(err.message);
    }
  };

  const inactivateAd = () => {
    if (myAds.activeAds.length === 1) {
      setRemovedLastOne(true);
    }
    props.inActivateMyAd(chosenAdForInactivity);

    var obj = {
      id: chosenAdForInactivity.annons_id,
    };
    try {
      axios
        .post(process.env.REACT_APP_API_DOMAIN + "/cb/annons.inactivate", obj, {
          headers: headers,
        })
        .then((res) => {
          if (res.data === "🎉") {
            axios
              .get(
                process.env.REACT_APP_API_DOMAIN + "/cb/annonslista.user?user_id=" +
                  user.user_id,
                { headers: headers }
              )
          }
        });
    } catch (err) {
      console.log(err.message);
    }
    setInactivate(false);
  };

  const handleEdit = (e, ad) => {
    e.stopPropagation();
  };

  const handleRemove = (e, ad) => {
    e.preventDefault();
    e.stopPropagation();
    setChosenAdForInactivity(ad);
    setInactivate(true);
  };

  const handleActivateAdAgain = (ad) => {
    setActivateAd(true);
    setChosenAdForActivate(ad);
  };

  const activateAdAgain = () => {
    var obj = {
      id: chosenAdForActivate.annons_id,
    };
    try {
      axios
        .post(process.env.REACT_APP_API_DOMAIN + "/cb/annons.activate", obj, {
          headers: headers,
        })
        .then((res) => {
          if (res.data === "🎉") {
            updatePageContent();
          }
        });
    } catch (err) {
      console.log(err.message);
    }
    setActivateAd(false);
  };

  const handleRemoveFromInactivity = (ad) => {
    setChosenAdForRemoval(ad);
    setRemoveForInactiveAds(true);
  };

  const removeAdFromInactivity = () => {
    var obj = {
      id: chosenAdForRemoval.annons_id,
    };
    try {
      axios
        .post(process.env.REACT_APP_API_DOMAIN + "/cb/annons.remove", obj, {
          headers: headers,
        })
        .then((res) => {
          if (res.data === "🎉") {
            updatePageContent();
          }
        });
    } catch (err) {
      console.log(err.message);
    }
    setRemoveForInactiveAds(false);
  };

  const getAdsSum = (props) => {
    var total = 0;

    for (let i = 0; i < props.length; i++) {
      total += Number(props[i].pris);
    }
    return total;
  };

  if ((cookieId === "" || cookieId === undefined) && !isLoggedIn) {
    return <Navigate to={notLoggedInLocation} />;
  }

  return (
    <div className="my-page-container">
      <div className="my-page-wrapper">
        <ProfileInfo />
        <div className="my-page-ads-wrapper">
          <div className="my-page-wrapper-ads">
            <div className="member-page-main-title-container">
              <h1 className="member-page-main-title">
                Hej {user.name.split(" ")[0]}!
              </h1>
            </div>
            <div className="member-page-top-info">
              <p>Aktiva annonser: {myAds.activeAds.length} </p>
              <p>Möjlig förtjänst: {getAdsSum(myAds.activeAds)} kr </p>
            </div>
            <div className="my-page-ads">
              {myAds.activeAds.length > 0
                ? myAds.activeAds.map((ad, index) => (
                    <div className="my-page-wrapper-ads-block" key={index}>
                      <div className="my-page-wrapper-ads-block-wrapper">
                          <img
                            src={ ad.thumb !== null ? mediaUrl + ad.thumb.split(",")[0] : '/static/media/noImage.7d821ab9a921958f3766.png' }
                            alt={ad.rubrik}
                            className="my-page-ads-image"
                          />
                        <div className="my-page-wrapper-ads-block-info">
                          <div className="my-page-block-info-title">
                            <span>{ad.rubrik}</span>
                          </div>
                          <div className="my-page-block-info-price">
                              <p> {ad.pris} kr</p>
                          </div>
                          <div className="my-page-block-info-created">
                              <p>Skapad {UnixTimeHandler(ad.skapad)}</p>
                          </div>
                          <div className="my-page-actions-container">
                                <Tooltip title="Uppdatera">
                                  <Link
                                    to={"/redigera/" + ad.annons_id}
                                    style={{ color: "inherit" }}
                                  >
                                    <div
                                      className="my-page-action-container"
                                      onClick={(e) => handleEdit(e, ad)}
                                    >
                                      <EditIcon />
                                    </div>
                                  </Link>
                                </Tooltip>
                                <Tooltip title="Ta bort">
                                  <div
                                    className="my-page-action-container"
                                    onClick={(e) => handleRemove(e, ad)}
                                  >
                                    <DeleteForeverIcon />
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  title="Gå till annons"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "none",
                                  }}
                                >
                                  <Link to={"/annons/" + ad.annons_id}>
                                    <div className="my-page-action-container">
                                      <ArrowRightIcon />
                                    </div>
                                  </Link>
                                </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : loading ? <Skeleton
                variant="rect"
                height={295}
                width={280} 
              /> : ""}
            </div>
          </div>
          <div className="my-page-wrapper-old-ads">
            <div className="member-page-bottom-info">
              <p>Gamla annonser: {myAds.inActiveAds.length}</p>
            </div>
            <div className="my-page-old-ads">
              {myAds.inActiveAds.length > 0
                ? myAds.inActiveAds.map((ad, index) => (
                    <div className="my-page-wrapper-ads-block" key={index}>
                      <div className="my-page-wrapper-ads-block-wrapper">
                          <img
                            src={ad.thumb !== null ? mediaUrl + ad.thumb.split(",")[0] : '/static/media/noImage.7d821ab9a921958f3766.png'}
                            alt={ad.rubrik}
                            className="my-page-ads-image"
                          />
                        <div className="my-page-wrapper-ads-block-info">
                          <div className="my-page-block-info-title">
                            <span>{ad.rubrik}</span>
                          </div>
                          <div className="my-page-block-info-price">
                              <p> {ad.pris} kr</p>
                          </div>
                          <div className="my-page-block-info-created">
                              <p>Skapad {UnixTimeHandler(ad.skapad)}</p>
                          </div>
                          <div className="my-page-actions-container">
                                <Tooltip title="Uppdatera">
                                  <Link
                                    to={"/redigera/" + ad.annons_id}
                                    style={{ color: "inherit" }}
                                  >
                                    <div className="my-page-action-container">
                                      <EditIcon />
                                    </div>
                                  </Link>
                                </Tooltip>
                                <Tooltip
                                  title="Aktivera"
                                  onClick={() => handleActivateAdAgain(ad)}
                                >
                                  <div className="my-page-action-container">
                                    <AddIcon />
                                  </div>
                                </Tooltip>
                                <Tooltip title="Ta bort">
                                  <div
                                    className="my-page-action-container"
                                    onClick={() =>
                                      handleRemoveFromInactivity(ad)
                                    }
                                  >
                                    <DeleteForeverIcon />
                                  </div>
                                </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : loading ? <Skeleton
                variant="rect"
                height={295}
                width={280} 
              /> : ""}
            </div>
          </div>
        </div>
      </div>
      {inactivate ? (
        <ActionDialogComponent
          text="Är du säker att du vill ta bort den här annonsen? Annonsen kommer att lägga sig under inaktiverade annonser"
          title="Ta bort"
          onSubmit={() => inactivateAd()}
          onClose={closeAllModals}
        />
      ) : removeForInactiveAds ? (
        <ActionDialogComponent
          text="Är du säker att du vill ta bort den här annonsen från inaktiverade annonser? Annonsen kommer tas bort permanent."
          title="Ta bort"
          onSubmit={() => removeAdFromInactivity()}
          onClose={closeAllModals}
        />
      ) : activateAd ? (
        <ActionDialogComponent
          text="Är du säker att du vill återaktivera den här annonsern? Annonsen kommer vara synlig för andra användare."
          title="Återaktivera annons"
          onSubmit={() => activateAdAgain()}
          onClose={closeAllModals}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    myAds: state.myAds,
    allAds: state.fetchAllAds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMyAds: (ads) => dispatch(fetchMyAds(ads)),
    fetchMyInactivatedAds: (ads) => dispatch(fetchMyInactivatedAds(ads)),
    inActivateMyAd: (ad) => dispatch(inActivateMyAd(ad)),
    removeInactiveAd: (ad) => dispatch(removeInactiveAd(ad)),
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(MyPage)
);
