import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import {
  fetchFavoriteAds,
  addFavoriteAd,
  removeFavoriteAd,
} from "../redux/actions/favoriteAds";
import ProfileInfo from "../components/ProfileInfo";

import DialogComponent from "../components/DialogComponent";

import UnixTimeHandler from "../helpers/UnixTimeHandler";

const base64 = "data:image/jpeg;base64, ";

const Saved = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [loading, setLoading] = useState(true);
  const [chosenAdForRemoval, setChosenAdForRemoval] = useState({});
  const [remove, setRemove] = useState(false);
  const [removedLastOne, setRemovedLastOne] = useState(false);

  const isLoggedIn = props.user.user_id !== undefined;
  var savedAds = props.favoriteAds;

  var headers = {
    "x-auth": cookieId,
  };

  const notLoggedInLocation = {
    pathname: "/inte-inloggad",
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  useEffect(() => {
    if (savedAds.length === 0 && !removedLastOne) {
      try {
        axios
          .get(process.env.REACT_APP_API_DOMAIN + "/cb/fav.list", { headers: headers })
          .then((res) => {
            props.fetchFavoriteAds(res.data);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [headers, props, savedAds.length, removedLastOne]);

  if ((cookieId === "" || cookieId === undefined) && !isLoggedIn) {
    return <Navigate to={notLoggedInLocation} />;
  }

  const closeAllModals = () => {
    setRemove(false);
  };

  const handleRemove = (ad) => {
    setChosenAdForRemoval(ad);
    setRemove(true);
  };

  const removeAsFavourite = () => {
    var obj = {
      id: chosenAdForRemoval.annons_id,
    };

    if (savedAds.length === 1) {
      setRemovedLastOne(true);
    }

    props.removeFavoriteAd(chosenAdForRemoval.annons_id);
    try {
      axios
        .post(process.env.REACT_APP_API_DOMAIN + "/cb/fav.remove", obj, {
          headers: headers,
        })
        .then((res) => {
          if (res.data === "🎉") {
            axios
              .get(process.env.REACT_APP_API_DOMAIN + "/cb/fav.list", {
                headers: headers,
              })
              .then((res) => {
                props.fetchFavoriteAds(res.data);
              });
          }
        });
    } catch (err) {
      console.log(err.message);
    }
    setRemove(false);
  };

  return (
    <div className="my-page-container">
      <div className="my-page-wrapper">
        <ProfileInfo />
        <div className="my-page-ads-wrapper">
          <div className="my-page-wrapper-ads">
            {loading ? (
              <Skeleton
                variant="rect"
                width={270}
                height={30}
                style={{ margin: "auto", marginTop: 30 }}
              />
            ) : (
              <div className="saved-title-container">
                <div>
                  <span className="saved-title">Sparade annonser</span>
                </div>
                {loading ? (
                  <Skeleton
                    variant="text"
                    width={470}
                    style={{ margin: "auto" }}
                  />
                ) : (
                  <div className="saved-subhead-container">
                    <span>
                      Här visas alla annonser som du sparat från andra användare
                    </span>
                  </div>
                )}
                <div className="savedads-container">
                  <div className="my-page-old-ads">
                    {savedAds.length > 0
                      ? savedAds.map((ad, index) => (
                          <div
                            className="my-page-wrapper-ads-block"
                            key={index}
                          >
                            <div className="my-page-wrapper-ads-block-wrapper">
                              {loading ? (
                                <Skeleton variant="rect" height={150} />
                              ) : (
                                <img
                                  src={base64 + ad.thumb.split(",")[0]}
                                  alt={ad.title}
                                  className="my-page-ads-image"
                                />
                              )}
                              <div className="my-page-wrapper-ads-block-info">
                                <div className="my-page-block-info-title">
                                  {loading ? (
                                    <Skeleton
                                      variant="text"
                                      width={140}
                                      height={30}
                                    />
                                  ) : (
                                    <Tooltip title={ad.rubrik}>
                                      <span>{ad.rubrik}</span>
                                    </Tooltip>
                                  )}
                                </div>
                                <div className="my-page-block-info-price">
                                  {loading ? (
                                    <Skeleton
                                      variant="text"
                                      width={130}
                                      height={17}
                                    />
                                  ) : (
                                    <p> {ad.pris} kr</p>
                                  )}
                                </div>
                                <div className="my-page-block-info-created">
                                  {loading ? (
                                    <Skeleton
                                      variant="text"
                                      width={130}
                                      height={17}
                                    />
                                  ) : (
                                    <p>
                                      Uppdaterad{" "}
                                      {UnixTimeHandler(ad.uppdaterad)}
                                    </p>
                                  )}
                                </div>
                                <div className="my-page-actions-container">
                                  {loading ? (
                                    <Skeleton
                                      variant="rect"
                                      width={130}
                                      height={28}
                                    />
                                  ) : (
                                    <>
                                      <Tooltip title="Ta bort">
                                        <div
                                          className="my-page-action-container"
                                          onClick={() => handleRemove(ad)}
                                        >
                                          <DeleteForeverIcon />
                                        </div>
                                      </Tooltip>
                                      <Tooltip title="Gå till annons">
                                        <Link
                                          to={"/annons/" + ad.annons_id}
                                          style={{ color: "inherit" }}
                                        >
                                          <div className="my-page-action-container">
                                            <ArrowRightIcon />
                                          </div>
                                        </Link>
                                      </Tooltip>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mypage-banner-container">
        <div className="mypage-banner-first-wrapper">
          {loading ? <Skeleton variant="rect" width={1200} height={250} /> : ""}
        </div>
      </div>
      {remove ? (
        <DialogComponent
          title="Ta bort en annons"
          text="Är du säker att du vill ta bort den här annonsen som sparad?"
          accept={removeAsFavourite}
          onClose={closeAllModals}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    favoriteAds: state.favoriteAds.arr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFavoriteAds: (ads) => dispatch(fetchFavoriteAds(ads)),
    addFavoriteAd: (ad) => dispatch(addFavoriteAd(ad)),
    removeFavoriteAd: (ad) => dispatch(removeFavoriteAd(ad)),
  };
};

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Saved));
