import orter from "../dumpdata/orter.js";

const CityHandler = (props) => {
  const city = parseInt(props);

  if (city in orter) {
    return orter[city].name;
  }

  return;
};

export default CityHandler;
