import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import tipsTricks from "../images/tipstricks.jpeg";

const LoadingComponent = () => {
  return (
    <div className="adtips-block">
      <div className="adtips-block-title">
        <Skeleton
          variant="text"
          width={300}
          height={14}
          style={{ paddingTop: 10, paddingBottom: 10 }}
        />
      </div>
      <div className="adtips-block-list">
        <Skeleton
          variant="text"
          width={Math.floor(Math.random() * 100 + 150)}
          style={{ marginLeft: 50 }}
        />
        <Skeleton
          variant="text"
          width={Math.floor(Math.random() * 100 + 150)}
          style={{ marginLeft: 50 }}
        />
        <Skeleton
          variant="text"
          width={Math.floor(Math.random() * 100 + 150)}
          style={{ marginLeft: 50 }}
        />
      </div>
    </div>
  );
};

const GoBackButton = withStyles({
  root: {
    background: "rgb(52,64,86)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 40,
    padding: "0 30px",
    margin: 10,
    "&:hover": {
      backgroundColor: "rgb(234,85,37)",
    },
  },
})(Button);

const AdTips = () => {
  const [loading, setLoading] = useState(true);
  const [imgURL] = useState(tipsTricks);

  const adLocation = {
    pathname: "/annons",
    state: { fromTips: true },
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  return (
    <div className="adtips mainwrapper">
      <div className="adtips-container">
        <div className="adtips-title-container">
          <h3>{loading ? <Skeleton /> : "Annonsering"}</h3>
        </div>
        <div className="adtips-content">
          <div className="adtips-wrapper">
            {loading ? (
              <div className="adtips-block">
                <div className="adtips-block-title">
                  <Skeleton
                    variant="text"
                    width={200}
                    style={{ paddingTop: 10, paddingBottom: 10 }}
                  />
                </div>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
              </div>
            ) : (
              <div className="adtips-block">
                <div className="adtips-block-title">
                  <span>Annonstips</span>
                </div>
                <span>
                  Lägg in annons gratis på Citiboard, enkelt och lokalt. <br />
                </span>
                <span>
                  Här kommer några säljtips som hjälper dig att öka chansen och
                  intresset kring din pryl.
                </span>
              </div>
            )}
            <div className="adtips-image-container">
              {loading ? (
                <Skeleton
                  variant="rect"
                  width={500}
                  height={300}
                  style={{ margin: 10 }}
                />
              ) : (
                <img src={imgURL} className="adtips-image" alt="tipstricks" />
              )}
            </div>
            {loading ? (
              <div>
                <LoadingComponent />
                <LoadingComponent />
                <LoadingComponent />
                <LoadingComponent />
              </div>
            ) : (
              <div>
                <div className="adtips-block">
                  <div className="adtips-block-title">
                    <span>1 - Sätt rätt pris</span>
                  </div>
                  <span>
                    Att sätta rätt pris är A och O, det mesta går att sälja till
                    rätt pris.
                  </span>
                  <br />
                  <ul className="adtips-block-list">
                    <li>
                      Kolla vad andra har satt för pris för liknande prylar.
                    </li>
                    <li>
                      För att få flera intresserade och snabbare försäljning,
                      sätt ett lägre pris.
                    </li>
                  </ul>
                </div>
                <div className="adtips-block">
                  <div className="adtips-block-title">
                    <span>2 - Bilderna avgör</span>
                  </div>
                  <span>
                    En bra bild kan hjälpa dig till snabbare försäljning.
                  </span>
                  <br />
                  <ul className="adtips-block-list">
                    <li>Placera objektet i en attraktiv miljö.</li>
                    <li>Fotografera gärna med dagsljus.</li>
                    <li>Ta gärna bilder ur olika vinklar.</li>
                  </ul>
                </div>

                <div className="adtips-block">
                  <div className="adtips-block-title">
                    <span>3 - Tydlig titel och beskrivning</span>
                  </div>
                  <span>
                    Tänk på vad du själv skulle vilja veta om det var du som
                    skulle köpa.
                  </span>
                  <br />
                  <ul className="adtips-block-list">
                    <li>Skriv en tydlig och säljande rubrik.</li>
                    <li>Skriv en tydlig och relevant annonstext.</li>
                    <li>Skriv gärna med eventuella fel och skador.</li>
                  </ul>
                </div>
              </div>
            )}
            <div className="adtips-button-container">
              {loading ? (
                <Skeleton
                  variant="rect"
                  width={250}
                  height={40}
                  style={{ paddingLeft: 10 }}
                />
              ) : (
                <Link
                  to={adLocation}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <GoBackButton>Gå tillbaka till annons</GoBackButton>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdTips;
