const UrlHandler = (props) => {
  const category = parseInt(props);
  switch (category) {
    case 1:
      return;
    case 6:
      return "/mode";
    case 7:
      return "/hem-inredning";
    case 8:
      return "/for-barn";
    case 9:
      return "/fritid-hobby";
    case 10:
      return "/elektronik";
    case 11:
      return "/djur";
    case 12:
      return "/fordon";
    case 13:
      return "/bostad";
    case 16:
      return "/bostad/hyresmarknad";
    case 17:
      return "/bostad/fritidsboende";
    case 18:
      return "/bostad/lagenheter";
    case 19:
      return "/bostad/villor-radhus";
    case 20:
      return "/bostad/ovrigt";
    case 21:
      return "/djur/fagel";
    case 22:
      return "/djur/fisk";
    case 23:
      return "/djur/gnagare-kaniner";
    case 24:
      return "/djur/hund";
    case 25:
      return "/djur/katt-hundtillbehor";
    case 26:
      return "/djur/katt";
    case 27:
      return "/djur/lantbruksdjur";
    case 28:
      return "/djur/reptil";
    case 29:
      return "/djur/tillbehor";
    case 30:
      return "/djur/ovrigt";
    case 31:
      return "/elektronik/datorer-tillbehor";
    case 32:
      return "/elektronik/ljud-bild";
    case 33:
      return "/elektronik/mobiler-tillbehor";
    case 34:
      return "/elektronik/ovrigt";
    case 38:
      return "/fritid-hobby/biljetter/konserter";
    case 40:
      return "/fritid-hobby/biljetter/teater-opera-ballet";
    case 42:
      return "/for-barn/barnklader";
    case 43:
      return "/for-barn/barnmobler";
    case 44:
      return "/for-barn/barnskor";
    case 45:
      return "/for-barn/barnvagnar-tillbehor";
    case 46:
      return "/for-barn/bilbarnstolar";
    case 47:
      return "/for-barn/leksaker";
    case 48:
      return "/for-barn/ovrigt";
    case 49:
      return "/fordon/batar";
    case 50:
      return "/fordon/batdelar-tillbehor";
    case 51:
      return "/fordon/bilar";
    case 52:
      return "/fordon/bildelar-tillbehor";
    case 53:
      return "/fordon/husvagnar-husbilar";
    case 54:
      return "/fordon/mc-delar-tillbehor";
    case 55:
      return "/fordon/mopeder-scooters";
    case 56:
      return "/fordon/motorcyklar";
    case 57:
      return "/fordon/ovriga-fordon";
    case 58:
      return "/fordon/snoskoterdelar-tillbehor";
    case 59:
      return "/fordon/snoskotrar";
    case 60:
      return "/fritid-hobby/biljetter";
    case 61:
      return "/fritid-hobby/bocker-studentlitteratur";
    case 62:
      return "/fritid-hobby/cyklar";
    case 63:
      return "/fritid-hobby/hast-ridsport";
    case 64:
      return "/fritid-hobby/hobby-samlarprylar";
    case 65:
      return "/fritid-hobby/jakt-fiske";
    case 66:
      return "/fritid-hobby/musik, film-spel";
    case 67:
      return "/fritid-hobby/musikinstrument";
    case 68:
      return "/fritid-hobby/resor";
    case 69:
      return "/fritid-hobby/sport-fritid ";
    case 70:
      return "/fritid-hobby/ovrigt";
    case 71:
      return "/hem-inredning/antikt-konst";
    case 72:
      return "/hem-inredning/belysning";
    case 73:
      return "/hem-inredning/bord-stolar";
    case 74:
      return "/hem-inredning/bygg-tradgard ";
    case 75:
      return "/hem-inredning/heminredning";
    case 76:
      return "/hem-inredning/hyllor-forvaring";
    case 77:
      return "/hem-inredning/sang-sovrum";
    case 78:
      return "/hem-inredning/soffa/fatolj/soffmobler";
    case 79:
      return "/hem-inredning/tv-stereomobler";
    case 80:
      return "/hem-inredning/vitvaror-husgerad";
    case 81:
      return "/hem-inredning/ovrigt";
    case 82:
      return "/bostad/hyresmarknad/fritidsboende";
    case 83:
      return "/bostad/hyresmarknad/lagenheter ";
    case 84:
      return "/bostad/hyresmarknad/villor-radhus";
    case 85:
      return "/bostad/hyresmarknad/ovrigt";
    case 99:
      return "/mode/accessoarer";
    case 103:
      return "/mode/klockor";
    case 107:
      return "/mode/smycken";
    case 110:
      return "/mode/vaskor";
    case 111:
      return "/mode/ovrigt";
    case 164:
      return "/elektronik/datorer-tillbehor/barbara datorer";
    case 165:
      return "/elektronik/datorer-tillbehor/datortillbehor-program";
    case 166:
      return "/elektronik/datorer-tillbehor/stationara datorer";
    case 167:
      return "/elektronik/datorer-tillbehor/ovrigt";
    case 168:
      return "/elektronik/datorer-tillbehor/dvd-spelare-mp3-spelare";
    case 169:
      return "/elektronik/datorer-tillbehor/kameror-videokameror";
    case 170:
      return "/elektronik/datorer-tillbehor/stereo-surround";
    case 171:
      return "/elektronik/datorer-tillbehor/tv-projektorer";
    case 172:
      return "/elektronik/datorer-tillbehor/ovrigt";
    case 173:
      return "/elektronik/mobiler-tillbehor/mobiler";
    case 174:
      return "/elektronik/mobiler-tillbehor/tillbehor";
    case 175:
      return "/elektronik/mobiler-tillbehor/ovrigt";
    case 180:
      return "/fordon/batar/motorbat";
    case 181:
      return "/fordon/batar/segelbat";
    case 182:
      return "/fordon/batar/ovrigt";
    case 183:
      return "/fordon/batdelar-tillbehor/motor-propeller";
    case 226:
      return "/fordon/bildelar-tillbehor/bilstereo";
    case 227:
      return "/fordon/bildelar-tillbehor/dack-falgar";
    case 228:
      return "/fordon/bildelar-tillbehor/gps";
    case 229:
      return "/fordon/bildelar-tillbehor/reservdelar";
    case 230:
      return "/fordon/bildelar-tillbehor/slap";
    case 231:
      return "/fordon/bildelar-tillbehor/takboxar-takracken";
    case 232:
      return "/fordon/bildelar-tillbehor/ovrigt";
    case 233:
      return "/fordon/husvagnar-husbilar/husbil";
    case 234:
      return "/fordon/husvagnar-husbilar/husvagn";
    case 235:
      return "/fordon/husvagnar-husbilar/tillbehor";
    case 236:
      return "/fordon/husvagnar-husbilar/ovrigt";
    case 237:
      return "/fordon/mc-delar-tillbehor/dack-falgar";
    case 238:
      return "/fordon/mc-delar-tillbehor/hjalm/skydd/klader";
    case 239:
      return "/fordon/mc-delar-tillbehor/reservdelar";
    case 240:
      return "/fordon/mc-delar-tillbehor/ovrigt";
    case 241:
      return "/fordon/motorcyklar/cross/enduro";
    case 242:
      return "/fordon/motorcyklar/custom";
    case 243:
      return "/fordon/motorcyklar/fyrhjuling/atv";
    case 244:
      return "/fordon/motorcyklar/sport";
    case 245:
      return "/fordon/motorcyklar/touring";
    case 246:
      return "/fordon/motorcyklar/ovrigt";
    case 247:
      return "/fritid-hobby/hast-ridsport/foder-stall";
    case 248:
      return "/fritid-hobby/hast-ridsport/hastar";
    case 249:
      return "/fritid-hobby/hast-ridsport/slap-transport";
    case 250:
      return "/fritid-hobby/hast-ridsport/utrustning";
    case 251:
      return "/fritid-hobby/hast-ridsport/ovrigt";
    case 252:
      return "/fritid-hobby/jakt-fiske/fiske";
    case 253:
      return "/fritid-hobby/jakt-fiske/jakt";
    case 254:
      return "/fritid-hobby/jakt-fiske/ovrigt";
    case 255:
      return "/fritid-hobby/musik-film-spel/cd-lp-skivor";
    case 256:
      return "/fritid-hobby/musik-film-spel/dvd-vhs-filmer";
    case 257:
      return "/fritid-hobby/musik-film-spel/pc-spel";
    case 258:
      return "/fritid-hobby/musik-film-spel/tv-spel-tv-spelskonsoler";
    case 259:
      return "/fritid-hobby/musik-film-spel/ovrigt";
    case 260:
      return "/fritid-hobby/musikinstrument/gitarr/bas/forstarkare";
    case 261:
      return "/fritid-hobby/musikinstrument/studio/scenutrustning";
    case 262:
      return "/fritid-hobby/musikinstrument/ovrigt";
    case 263:
      return "/fritid-hobby/sport-fritid/bollsport";
    case 264:
      return "/fritid-hobby/sport-fritid/golf";
    case 265:
      return "/fritid-hobby/sport-fritid/traning-halsa";
    case 266:
      return "/fritid-hobby/sport-fritid/vintersport";
    case 267:
      return "/fritid-hobby/sport-fritid/ovrigt";
    case 268:
      return "/hem-inredning/bygg-tradgard/badrum/wc/bastu";
    case 269:
      return "/hem-inredning/bygg-tradgard/byggvaror";
    case 270:
      return "/hem-inredning/bygg-tradgard/kamin-varme";
    case 271:
      return "/hem-inredning/bygg-tradgard/kok";
    case 272:
      return "/hem-inredning/bygg-tradgard/tradgard-uteplats";
    case 273:
      return "/hem-inredning/bygg-tradgard/verktyg";
    case 274:
      return "/hem-inredning/bygg-tradgard/ovrigt";
    case 275:
      return "/hem-inredning/bygg-tradgard/diskmaskin";
    case 276:
      return "/hem-inredning/bygg-tradgard/kokstillbehor-porslin";
    case 277:
      return "/hem-inredning/bygg-tradgard/kyl-frys";
    case 278:
      return "/hem-inredning/bygg-tradgard/spis-micro";
    case 279:
      return "/hem-inredning/bygg-tradgard/tvattmaskiner-torktumlare";
    case 280:
      return "/hem-inredning/bygg-tradgard/ovrigt";
    case 301:
      return "/ovrigt";
    case 306:
      return "/fritid-hobby/biljetter/sport";
    case 311:
      return "/fritid-hobby/biljetter/ovrigt";
    case 312:
      return "/mode/damklader-damskor";
    case 313:
      return "/mode/herrklader-herrskor";
    case 314:
      return "/mode/damklader-damskor/skor";
    case 316:
      return "/mode/damklader-damskor/jackor-ytterplagg";
    case 317:
      return "/mode/damklader-damskor/byxor";
    case 318:
      return "/mode/damklader-damskor/jeans";
    case 319:
      return "/mode/damklader-damskor/kostymer-kavajer";
    case 320:
      return "/mode/damklader-damskor/skjortor";
    case 321:
      return "/mode/damklader-damskor/toppar";
    case 327:
      return "/mode/damklader-damskor/trojor";
    case 331:
      return "/mode/damklader-damskor/ovrigt";
    case 332:
      return "/mode/damklader-damskor/kjolar-klanningar";
    case 333:
      return "/mode/herrklader-herrskor/skor";
    case 335:
      return "/mode/herrklader-herrskor/jackor-ytterplagg";
    case 336:
      return "/mode/herrklader-herrskor/byxor";
    case 337:
      return "/mode/herrklader-herrskor/jeans";
    case 338:
      return "/mode/herrklader-herrskor/kostymer-kavajer";
    case 339:
      return "/mode/herrklader-herrskor/skjortor";
    case 340:
      return "/mode/herrklader-herrskor/trojor-t-shirts";
    case 342:
      return "/mode/herrklader-herrskor/ovrigt";
    default:
      return;
  }
};

export default UrlHandler;
