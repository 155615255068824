const dateHandler = (props) => {
  const date = props;
  switch (date) {
    case 0:
      return "januari";
    case 1:
      return "februari";
    case 2:
      return "mars";
    case 3:
      return "april";
    case 4:
      return "maj";
    case 5:
      return "juni";
    case 6:
      return "juli";
    case 7:
      return "augusti";
    case 8:
      return "september";
    case 9:
      return "oktober";
    case 10:
      return "november";
    case 11:
      return "december";
    default:
      return;
  }
};

export default dateHandler;
