var categories = {
  'categories': [
    {
      label: 'Alla kategorier',
      value: 1
    },
    {
      label: 'Mode',
      value: 6

    },
    {
      label: 'Hem & Inredning',
      value: 7

    },
    {
      label: 'För barn',
      value: 8

    },
    {
      label: 'Fritid & Hobby',
      value: 9

    },
    {
      label: 'Elektronik',
      value: 10

    },
    {
      label: 'Djur',
      value: 11

    },
    {
      label: 'Fordon',
      value: 12

    },
    {
      label: 'Bostad',
      value: 13

    },
    {
      label: 'Övrigt',
      value: 20

    }
  ],
  'region': [
    'Hela Sverige',
    'Blekinge',
    'Dalarna',
    'Gotland',
    'Gävleborg',
    'Halland',
    'Jämtland',
    'Jönköping',
    'Kalmar',
    'Kronoberg',
    'Norrbotten',
    'Skåne',
    'Stockholm',
    'Södermanland',
    'Uppsala',
    'Värmland',
    'Västerbotten',
    'Västernorrland',
    'Västmanland',
    'Västra Götaland',
    'Örebro',
    'Östergötland'
  ],
  'mode': [
    {
      label: 'Allt inom Mode',
      value: 6
    },
    {
      label: 'Accessoarer',
      value: 99
    },
    {
      label: 'Klockor',
      value: 103
    },
    {
      label: 'Smycken',
      value: 107
    },
    {
      label: 'Väskor',
      value: 110
    },
    {
      label: 'Övrigt',
      value: 111
    },
    {
      label: 'Damkläder & damskor',
      value: 312
    },
    {
      label: 'Herrkläder & herrskor',
      value: 313
    }
  ],
  'hem': [
    {
      label: 'Allt inom Hem & Inredning',
      value: 7
    },
    {
      label: 'Antikt & Konst',
      value: 71
    },
    {
      label: 'Belysning ',
      value: 72
    },
    {
      label: 'Bord & Stolar ',
      value: 73
    },
    {
      label: 'Bygg & Trädgård',
      value: 74
    },
    {
      label: 'Heminredning',
      value: 75
    },
    {
      label: 'Hyllor & Förvaring',
      value: 76
    },
    {
      label: 'Säng & Sovrum',
      value: 77
    },
    {
      label: 'Soffa/Fåtölj/Soffmöbler',
      value: 78
    },
    {
      label: 'TV & Stereomöbler',
      value: 79
    },
    {
      label: 'Vitvaror & Husgeråd',
      value: 80
    },
    {
      label: 'Övrigt',
      value: 81
    }
  ],
  'barn': [
    {
      label: 'Allt inom Barn',
      value: 8
    },
    {
      label: 'Barnkläder',
      value: 42
    },
    {
      label: 'Barnmöbler',
      value: 43
    },
    {
      label: 'Barnskor',
      value: 44
    },
    {
      label: 'Barnvagnar & Tillbehör',
      value: 45
    },
    {
      label: 'Bilbarnstolar',
      value: 46
    },
    {
      label: 'Leksaker',
      value: 47
    },
    {
      label: 'Övrigt',
      value: 48
    }
  ],
  'fritidhobby': [
    {
      label: 'Allt inom Fritid & Hobby',
      value: 9
    },
    {
      label: 'Konserter',
      value: 38
    },
    {
      label: 'Teater, Opera & Ballet ',
      value: 40
    },
    {
      label: 'Biljetter',
      value: 60
    },
    {
      label: 'Böcker & Studentlitteratur',
      value: 61
    },
    {
      label: 'Cyklar',
      value: 62
    },
    {
      label: 'Hobby & Samlarprylar',
      value: 64
    },
    {
      label: 'Häst & Ridsport',
      value: 63
    },
    {
      label: 'Jakt & Fiske',
      value: 65
    },
    {
      label: 'Musik, Film & Spel',
      value: 66
    },
    {
      label: 'Musikinstrument',
      value: 67
    },
    {
      label: 'Resor',
      value: 68
    },
    {
      label: 'Sport & Fritid',
      value: 69
    },
    {
      label: 'Övrigt',
      value: 70
    }
  ],
  'elektronik': [
    {
      label: 'Allt inom Elektronik',
      value: 10
    },
    {
      label: 'Bärbara datorer',
      value: 164
    },
    {
      label: 'Datorer & Tillbehör',
      value: 31
    },
    {
      label: 'Ljud & Bild',
      value: 32
    },
    {
      label: 'Mobiler',
      value: 173
    },
    {
      label: 'Mobiler & Tillbehör',
      value: 33
    },
    {
      label: 'Datortillbehör & Program ',
      value: 165
    },
    {
      label: 'Stationära datorer',
      value: 166
    },
    {
      label: 'DVD-spelare & MP3-spelare',
      value: 168
    },
    {
      label: 'Kameror & Videokameror',
      value: 169
    }
    ,
    {
      label: 'Stereo & Surround',
      value: 170
    },
    {
      label: 'TV & Projektorer',
      value: 171
    },
    {
      label: 'Tillbehör',
      value: 174
    },
    {
      label: 'Övrigt',
      value: 34
    },
  ],
  'djur': [
    {
      label: 'Allt inom Djur',
      value: 11
    },
    {
      label: 'Fågel',
      value: 21
    },
    {
      label: 'Fisk',
      value: 22
    },
    {
      label: 'Gnagare & Kaniner',
      value: 23
    },
    {
      label: 'Hund',
      value: 24
    },
    {
      label: 'Katt & Hundtillbehör',
      value: 25
    },
    {
      label: 'Katt',
      value: 26
    },
    {
      label: 'Lantbruksdjur',
      value: 27
    },
    {
      label: 'Reptil',
      value: 28
    },
    {
      label: 'Tillbehör',
      value: 29
    },
    {
      label: 'Övrigt',
      value: 30
    }
  ],
  'fordon': [
    {
      label: 'Allt inom Fordon',
      value: 12
    },
    {
      label: 'Båtar',
      value: 49
    },
    {
      label: 'Båtar & Tillbehör',
      value: 50
    },
    {
      label: 'Bilar',
      value: 51
    },
    {
      label: 'Bilar & Tillbehör',
      value: 52
    },
    {
      label: 'Husvagnar & Husbilar',
      value: 53
    },
    {
      label: 'MC-delar & Tillbehör',
      value: 54
    },
    {
      label: 'Mopeder & Scooters',
      value: 55
    },
    {
      label: 'Motorcyklar',
      value: 56
    },
    {
      label: 'Snöskoterdelar & Tillbehör',
      value: 58
    },
    {
      label: 'Snöskotrar',
      value: 59
    },
    {
      label: 'Övriga fordon ',
      value: 57
    },
    {
      label: 'Husvagnar',
      value: 233
    },
    {
      label: 'Husbilar',
      value: 234
    },
    {
      label: 'Tillbehör',
      value: 235
    }
  ],
  'boende': [
    {
      label: 'Allt inom Bostad',
      value: 13
    },
    {
      label: 'Hyresmarknad',
      value: 16,
    },
    {
      label: 'Fritidsboende',
      value: 17,
    },
    {
      label: 'Lägenheter',
      value: 18,
    },
    {
      label: 'Villor & Radhus',
      value: 19,
    },
    {
      label: 'Övrigt',
      value: 85,
    }
  ],
  'bygg': [
    {
      label: 'Allt i Bygg & Trädgård',
      value: 74
    },
    {
      label: 'Badrum/WC/Bastu',
      value: 268
    },
    {
      label: 'Byggvaror',
      value: 269
    },
    {
      label: 'Kamin & Värme',
      value: 270
    },
    {
      label: 'Kök',
      value: 271
    },
    {
      label: 'Trädgård & Uteplats',
      value: 272
    },
    {
      label: 'Verktyg',
      value: 273
    },
    {
      label: 'Övrigt',
      value: 274
    }
  ],
  'vitvaror': [
    {
      label: 'Allt i Vitvaror & Husgeråd',
      value: 80
    },
    {
      label: 'Diskmaskin',
      value: 275
    },
    {
      label: 'Kökstillbehör & Porslin',
      value: 276
    },
    {
      label: 'Kyl & Frys',
      value: 277
    },
    {
      label: 'Spis & Micro',
      value: 278
    },
    {
      label: 'Tvättmaskiner & Torktumlare',
      value: 279
    },
    {
      label: 'Övrigt',
      value: 280
    }
  ],
  'sporter': [
    {
      label: 'Allt i Sport & Fritid',
      value: 69
    },
    {
      label: 'Bollsport',
      value: 263,      
    },
    {
      label: 'Golf',
      value: 264
    },
    {
      label:'Träning & Hälsa',
      value: 265
    },
    {
      label: 'Vintersport',
      value: 266
    },
    {
      label: 'Övrigt',
      value: 267
    }
  ],
  'biltillbehör': [
    {
      label: 'Allt i Bilar & Tillbehör',
      value: 52
    },
    {
      label: 'Bilstereo',
      value: 226
    },
    {
      label: 'Däck & Fälgar',
      value: 227
    },
    {
      label: 'GPS',
      value: 228
    },
    {
      label: 'Reservdelar',
      value: 229
    },
    {
      label: 'Släp',
      value: 226
    },
    {
      label: 'Takboxar & Takräcken',
      value: 231
    },
    {
      label: 'Övrigt',
      value: 231
    },
  ],
  'horseSports': [
    {
      label: 'Allt i Häst & Ridsport',
      value: 63
    },
    {
      label: 'Foder & Stall',
      value: 247
    },
    {
      label: 'Hästar',
      value: 248 
    },
    {
      label: 'Släp & Transport',
      value: 249
    },
    {
      label: 'Utrustning',
      value: 250    
    },
    {
      label: 'Övrigt',
      value: 251
    }
  ],
  'jaktfiske': [
    {
      label: 'Allt i Jakt & Fiske',
      value: 65
    },
    {
      label: 'Fiske',
      value: 252
    },
    {
      label: 'Jakt',
      value: 253
    },
    {
      label: 'Övrigt',
      value: 254
    }
  ],
  'musikfilmspel': [
    {
      label: 'Allt i Musik, Film & Spel',
      value: 66
    },
    {
      label: 'CD & LP skivor',
      value: 255
    },
    {
      label: 'DVD & VHS filmer',
      value: 256
    },
    {
      label: 'PC-spel',
      value: 257
    },
    {
      label: 'TV-spel & TV -spelskonsoler',
      value: 258
    },
    {
      label: 'Övrigt',
      value: 259
    }
  ],
  'musik': [
    {
      label: 'Allt i Musikinstrument',
      value: 67
    },
    {
      label: 'Gitarr/Bas/Förstärkare',
      value: 260
    },
    {
      label: 'Studio/Scenutrustning',
      value: 261 
    },
    {
      label: 'Övrigt',
      value: 262
    }
  ],
  'carModels': [
    'Alfa Romeo',
    'Audi',
    'BMW',
    'Cadillac',
    'Chevrolet',
    'Chrysler',
    'Citroen',
    'Dodge',
    'Ferrari',
    'Fiat',
    'Ford',
    'Honda',
    'Hyundai',
    'Jaguar',
    'Jeep',
    'Kia',
    'Lamborghini',
    'Land rover',
    'Lexus',
    'Lincoln',
    'Maserati',
    'Mazda',
    'Mercedes Benz',
    'Mini',
    'Mitsubishi',
    'Nissan',
    'Opel',
    'Peugeot',
    'Pontiac',
    'Porsche',
    'Renault',
    'Rover',
    'Saab',
    'Seat',
    'Skoda',
    'Smart',
    'Subaru',
    'Suzuki',
    'Toyota',
    'Volkswagen',
    'Volvo',
    'Övriga märken'
  ],
  'carColor': [
    'Vit',
    'Brun',
    'Blå',
    'Röd',
    'Svart',
    'Grön',
    'Grå/silvrig',
    'Vit',
    'Gul',
    'Övrig'
  ],
  'carType': [
    'Småbil',
    'Sedan',
    'Halvkombi',
    'Kombi',
    'Coupé',
    'Cab',
    'SUV',
    'Familjebuss',
    'Yrkesfordon',
    'Övrigt'
  ],
  'carFuel': [
    'Bensin',
    'Diesel',
    'Hybrid',
    'Elektricitet',
    'Etanol'
  ],
  'gearBox': [
    'Automat',
    'Manuell'
  ],
  'mc': [
    {
      label: 'Allt i Motorcyklar',
      value: 54
    },
    {
      label: 'Cross/Enduro',
      value: 241
    },
    {
      label: 'Custom',
      value: 242
    },
    {
      label: 'Fyrhjuling/ATV',
      value: 243
    },
    {
      label: 'Sport',
      value: 244
    },
    {
      label: 'Touring',
      value: 245
    },
    {
      label: 'Övrigt',
      value: 246
    }
  ],
  'mcthings': [
    {
      label: 'Allt i MC-delar & Tillbehör',
      value: 54
    },
    {
      label: 'Däck & Fälgar',
      value: 237
    },
    {
      label: 'Hjälm/Skydd/Kläder',
      value: 238
    },
    {
      label: 'Reservdelar',
      value: 239
    },
    {
      label: 'Övrigt',
      value: 240
    }
  ],
  'livingType': [
    {
      label: 'Allt i Lägenheter',
      value: 18,
    },
    {
      label: 'Bostadsrätt',
      value: 13
    },
    {
      label: 'Hyresrätt',
      value: 14
    }
  ],
  'boatType': [
    {
      label: 'Allt i Båtar & Tillbehör',
      value: 50
    },
    {
      label: 'Motorbåt',
      value: 180
    },
    {
      label: 'Segelbåt',
      value: 181
    },
    {
      label: 'Motor & Propeller',
      value: 183
    },
    {
      label: 'Övrigt',
      value: 182
    }
  ],
  'womenClothing': [
    {
      label: 'Allt i Damkläder  & Damskor ',
      value: 312
    },
    {
      label: 'Kjolar & Klänningar',
      value: 332
    },
    {
      label: 'Blusar & Toppar',
      value: 320
    },
    {
      label: 'Tröjor',
      value: 327
    },
    {
      label: 'Skor',
      value: 333
    },
    {
      label: 'Jackor & Kappor',
      value: 335
    },
    {
      label: 'Jeans',
      value: 337
    },
    {
      label: 'Kostymer & Kavajer',
      value: 338
    },
    {
      label: 'Skjortor',
      value: 339
    },
    {
      label: 'Övrigt',
      value: 342
    }
  ],
  'menClothing': [
    {
      label: 'Allt i Herrkläder & Herrskor',
      value: 313
    },
    {
      label: 'Skor',
      value: 314,
    },
    {
      label: 'Jackor & Ytterplagg',
      value: 316
    },
    {
      label: 'Byxor',
      value: 317
    },
    {
      label: 'Jeans',
      value: 318 
    },
    {
      label: 'Kostymer & Kavajer',
      value: 319
    },
    {
      label: 'Skjortor',
      value: 320
    },
    {
      label: 'Tröjor & T-shirts',
      value: 340
    },
    {
      label: 'Övrigt',
      value: 331
    }
  ],
  'womenShoes': [
    'Sneakers',
    'Ballerina',
    'Vinterskor',
    'Högklackat',
    'Gympaskor',
    'Övrigt'
  ],
  'womenClothingSize': [
    'XS (32-34)',
    'S (36-38)',
    'M (40-42)',
    'L (44-46)',
    'XL (48-50',
    'Övriga storlekar'
  ],
  'menClothingSize': [
    'S (48-50)',
    'M (52-54)',
    'L (56-58)',
    'XL+ (58+)',
    'Övriga storlekar'
  ],
  'shoeSize': [
    '-35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44+'
  ],
  'childrenShoeSize': [
    '-19',
    '20-21',
    '22-23',
    '24-25',
    '26-27',
    '28-29',
    '30-31',
    '32-33',
    '34-35',
    '36+'
  ],
  'childrenClothingSizes': [
    '50/56 (0-2 månader)',
    '62/68 (2-6 månader)',
    '74/80 (6-12 månader)',
    '86/92 (1-2 år)',
    '98/104 (2-4 år)',
    '110-116 (4-6 år)',
    '122-128 (6-8 år)',
    '134/140 (8-10 år)',
    '146/152 (10-12 år)',
    '162-164 (12-14 år)',
    '170/174 (14-16 år)'
  ],
  'choiceRegion': [
    {
      label: 'Ale',
      value: '266'
    },
    {
      label: 'Alingsås',
      value: '267'
    },
    {
      label: 'Alvesta',
      value: '96'
    },
    {
      label: 'Aneby',
      value: '70'
    },
    {
      label: 'Arboga',
      value: '256'
    },
    {
      label: 'Arjeplog',
      value: '104'
    },
    {
      label: 'Arvidsjaur',
      value: '105'
    },
    {
      label: 'Arvika',
      value: '219'
    },
    {
      label: 'Askersund',
      value: '117'
    },
    {
      label: 'Avesta',
      value: '29'
    },
    {
      label: 'Bengtsfors',
      value: '269'
    },
    {
      label: 'Berg',
      value: '63'
    },
    {
      label: 'Bjurholm',
      value: '235'
    },
    {
      label: 'Bjuv',
      value: '145'
    },
    {
      label: 'Boden',
      value: '106'
    },
    {
      label: 'Bollnäs',
      value: '43'
    },
    {
      label: 'Borgholm',
      value: '83'
    },
    {
      label: 'Borås',
      value: '327'
    },
    {
      label: 'Mark',
      value: '328'
    },
    {
      label: 'Bollebygd',
      value: '326'
    },
    {
      label: 'Botkyrka',
      value: '184'
    },
    {
      label: 'Boxholm',
      value: '130'
    },
    {
      label: 'Bromölla',
      value: '146'
    },
    {
      label: 'Bräcke',
      value: '64'
    },
    {
      label: 'Burlöv',
      value: '147'
    },
    {
      label: 'Båstad',
      value: '144'
    },
    {
      label: 'Dals-Ed',
      value: 'Dals-Ed'
    },
    {
      label: 'Danderyd',
      value: '185'
    },
    {
      label: 'Degerfors',
      value: '118'
    },
    {
      label: 'Dorotea',
      value: '236'
    },
    {
      label: 'Eda',
      value: '220'
    },
    {
      label: 'Ekerö',
      value: '186'
    },
    {
      label: 'Eksjö',
      value: '71'
    },
    {
      label: 'Emmaboda',
      value: '84'
    },
    {
      label: 'Enköping',
      value: '211'
    },
    {
      label: 'Eskilstuna',
      value: '175'
    },
    {
      label: 'Eslöv',
      value: '148'
    },
    {
      label: 'Essunga',
      value: '273'
    },
    {
      label: 'Fagersta',
      value: '257'
    },
    {
      label: 'Falkenberg',
      value: '55'
    },
    {
      label: 'Falköping',
      value: '274'
    },
    {
      label: 'Falun',
      value: '317'
    },
    {
      label: 'Borlänge',
      value: '318'
    },
    {
      label: 'Filipstad',
      value: '221'
    },
    {
      label: 'Finspång',
      value: '131'
    },
    {
      label: 'Flen',
      value: '176'
    },
    {
      label: 'Forshaga',
      value: '222'
    },
    {
      label: 'Färgelanda',
      value: '275'
    },
    {
      label: 'Gagnef',
      value: '32'
    },
    {
      label: 'Gislaved',
      value: '72'
    },
    {
      label: 'Gnesta',
      value: '177'
    },
    {
      label: 'Gnosjö',
      value: '73'
    },
    {
      label: 'Gotland',
      value: '329'
    },
    {
      label: 'Grums',
      value: '223'
    },
    {
      label: 'Grästorp',
      value: '278'
    },
    {
      label: 'Gullspång',
      value: '279'
    },
    {
      label: 'Gällivare',
      value: '107'
    },
    {
      label: 'Gävle',
      value: '322'
    },
    {
      label: 'Söderhamn',
      value: '323'
    },
    {
      label: 'Hudiksvall',
      value: '324'
    },
    {
      label: 'Sandviken',
      value: '325'
    },
    {
      label: 'Göteborg',
      value: '276'
    },
    {
      label: 'Götene',
      value: '277'
    },
    {
      label: 'Habo',
      value: '74'
    },
    {
      label: 'Hagfors',
      value: '224'
    },
    {
      label: 'Hallsberg',
      value: '120'
    },
    {
      label: 'Hallstahammar',
      value: '258'
    },
    {
      label: 'Halmstad',
      value: '56'
    },
    {
      label: 'Hammarö',
      value: '225'
    },
    {
      label: 'Haninge',
      value: '187'
    },
    {
      label: 'Haparanda',
      value: '108'
    },
    {
      label: 'Heby',
      value: '213'
    },
    {
      label: 'Hedemora',
      value: '33'
    },
    {
      label: 'Helsingborg',
      value: '150'
    },
    {
      label: 'Herrljunga',
      value: '281'
    },
    {
      label: 'Hjo',
      value: '282'
    },
    {
      label: 'Hofors',
      value: '45'
    },
    {
      label: 'Huddinge',
      value: '188'
    },
    {
      label: 'Hultsfred',
      value: '86'
    },
    {
      label: 'Hylte',
      value: '57'
    },
    {
      label: 'Hällefors',
      value: '119'
    },
    {
      label: 'Härjedalen',
      value: '65'
    },
    {
      label: 'Härnösand',
      value: '250'
    },
    {
      label: 'Härryda',
      value: '280'
    },
    {
      label: 'Hässleholm',
      value: '149'
    },
    {
      label: 'Håbo',
      value: '212'
    },
    {
      label: 'Höganäs',
      value: '151'
    },
    {
      label: 'Högsby',
      value: '85'
    },
    {
      label: 'Hörby',
      value: '153'
    },
    {
      label: 'Höör',
      value: '152'
    },
    {
      label: 'Jokkmokk',
      value: '109'
    },
    {
      label: 'Järfälla',
      value: '189'
    },
    {
      label: 'Jönköping',
      value: '8'
    },
    {
      label: 'Kalix',
      value: '110'
    },
    {
      label: 'Kalmar',
      value: '9'
    },
    {
      label: 'Karlsborg',
      value: '283'
    },
    {
      label: 'Karlshamn',
      value: '23'
    },
    {
      label: 'Karlskoga',
      value: '121'
    },
    {
      label: 'Karlskrona',
      value: '24'
    },
    {
      label: 'Karlstad',
      value: '226'
    },
    {
      label: 'Katrineholm',
      value: '178'
    },
    {
      label: 'Kil',
      value: '227'
    },
    {
      label: 'Kinda',
      value: '132'
    },
    {
      label: 'Kiruna',
      value: '111'
    },
    {
      label: 'Klippan',
      value: '155'
    },
    {
      label: 'Knivsta',
      value: '214'
    },
    {
      label: 'Kramfors',
      value: '251'
    },
    {
      label: 'Kristianstad',
      value: '156'
    },
    {
      label: 'Kristinehamn',
      value: '228'
    },
    {
      label: 'Krokom',
      value: '66'
    },
    {
      label: 'Kumla',
      value: '122'
    },
    {
      label: 'Kungsbacka',
      value: '58'
    },
    {
      label: 'Kungsör',
      value: '260'
    },
    {
      label: 'Kungälv',
      value: '284'
    },
    {
      label: 'Kävlinge',
      value: '154'
    },
    {
      label: 'Köping',
      value: '259'
    },
    {
      label: 'Laholm',
      value: '59'
    },
    {
      label: 'Landskrona',
      value: '157'
    },
    {
      label: 'Laxå',
      value: '123'
    },
    {
      label: 'Lekeberg',
      value: '124'
    },
    {
      label: 'Leksand',
      value: '34'
    },
    {
      label: 'Lerum',
      value: '285'
    },
    {
      label: 'Lessebo',
      value: '97'
    },
    {
      label: 'Lidingö',
      value: '190'
    },
    {
      label: 'Lidköping',
      value: '286'
    },
    {
      label: 'Lilla Edet',
      value: '287'
    },
    {
      label: 'Lindesberg',
      value: '125'
    },
    {
      label: 'Linköping',
      value: '133'
    },
    {
      label: 'Ljungby',
      value: '98'
    },
    {
      label: 'Ljusdal',
      value: '47'
    },
    {
      label: 'Ljusnarsberg',
      value: '126'
    },
    {
      label: 'Lomma',
      value: '158'
    },
    {
      label: 'Ludvika',
      value: '35'
    },
    {
      label: 'Luleå',
      value: '112'
    },
    {
      label: 'Lund',
      value: '159'
    },
    {
      label: 'Lycksele',
      value: '237'
    },
    {
      label: 'Lysekil',
      value: '288'
    },
    {
      label: 'Malmö',
      value: '160'
    },
    {
      label: 'Malung-Sälen',
      value: '36'
    },
    {
      label: 'Malå',
      value: '238'
    },
    {
      label: 'Mariestad',
      value: '289'
    },
    {
      label: 'Markaryd',
      value: '99'
    },
    {
      label: 'Mellerud',
      value: '291'
    },
    {
      label: 'Mjölby',
      value: '134'
    },
    {
      label: 'Mora',
      value: '37'
    },
    {
      label: 'Motala',
      value: '135'
    },
    {
      label: 'Mullsjö',
      value: '76'
    },
    {
      label: 'Munkedal',
      value: '293'
    },
    {
      label: 'Munkfors',
      value: '229'
    },
    {
      label: 'Mölndal',
      value: '292'
    },
    {
      label: 'Mönsterås',
      value: '88'
    },
    {
      label: 'Hallsberg',
      value: '120'
    },
    {
      label: 'Hallstahammar',
      value: '258'
    },
    {
      label: 'Mörbylånga',
      value: '89'
    },
    {
      label: 'Nacka',
      value: '191'
    },
    {
      label: 'Nora',
      value: '127'
    },
    {
      label: 'Norberg',
      value: '261'
    },
    {
      label: 'Nordanstig',
      value: '48'
    },
    {
      label: 'Nordmaling',
      value: '239'
    },
    {
      label: 'Norrköping',
      value: '136'
    },
    {
      label: 'Norrtälje',
      value: '192'
    },
    {
      label: 'Norsjö',
      value: '240'
    },
    {
      label: 'Nybro',
      value: '90'
    },
    {
      label: 'Nykvarn',
      value: '193'
    },
    {
      label: 'Nyköping',
      value: '179'
    },
    {
      label: 'Oxelösund',
      value: '180'
    },
    {
      label: 'Nynäshamn',
      value: '194'
    },
    {
      label: 'Nässjö',
      value: '77'
    },
    {
      label: 'Ockelbo',
      value: '49'
    },
    {
      label: 'Olofström',
      value: '25'
    },
    {
      label: 'Onsala',
      value: '60'
    },
    {
      label: 'Orsa',
      value: '38'
    },
    {
      label: 'Orust',
      value: '295'
    },
    {
      label: 'Osby',
      value: '162'
    },
    {
      label: 'Oskarshamn',
      value: '91'
    },
    {
      label: 'Ovanåker',
      value: '50'
    },
    {
      label: 'Pajala',
      value: '115'
    },
    {
      label: 'Partille',
      value: '296'
    },
    {
      label: 'Perstorp',
      value: '164'
    },
    {
      label: 'Piteå',
      value: '116'
    },
    {
      label: 'Ragunda',
      value: '68'
    },
    {
      label: 'Robertsfors',
      value: '241'
    },
    {
      label: 'Ronneby',
      value: '26'
    },
    {
      label: 'Rättvik',
      value: '39'
    },
    {
      label: 'Sala',
      value: '262'
    },
    {
      label: 'Salem',
      value: '196'
    },
    {
      label: 'Sigtuna',
      value: '197'
    },
    {
      label: 'Simrishamn',
      value: '165'
    },
    {
      label: 'Sjöbo',
      value: '166'
    },
    {
      label: 'Skara',
      value: '297'
    },
    {
      label: 'Skellefteå',
      value: '242'
    },
    {
      label: 'Skinnskatteberg',
      value: '263'
    },
    {
      label: 'Skurup',
      value: '167'
    },
    {
      label: 'Skövde',
      value: '298'
    },
    {
      label: 'Smedjebacken',
      value: '41'
    },
    {
      label: 'Sollefteå',
      value: '253'
    },
    {
      label: 'Sollentuna',
      value: '199'
    },
    {
      label: 'Solna',
      value: '200'
    },
    {
      label: 'Sorsele',
      value: '243'
    },
    {
      label: 'Sotenäs',
      value: '299'
    },
    {
      label: 'Staffanstorp',
      value: '168'
    },
    {
      label: 'Stenungsund',
      value: '300'
    },
    {
      label: 'Stockholm',
      value: '16'
    },
    {
      label: 'Storfors',
      value: '231'
    },
    {
      label: 'Storuman',
      value: '244'
    },
    {
      label: 'Strängnäs',
      value: '181'
    },
    {
      label: 'Strömstad',
      value: '301'
    },
    {
      label: 'Strömsund',
      value: '69'
    },
    {
      label: 'Sundbyberg',
      value: '202'
    },
    {
      label: 'Sundsvall',
      value: '254'
    },
    {
      label: 'Sunne',
      value: '232'
    },
    {
      label: 'Surahammar',
      value: '264'
    },
    {
      label: 'Svalöv',
      value: '169'
    },
    {
      label: 'Svedala',
      value: '170'
    },
    {
      label: 'Svenljunga',
      value: '302'
    },
    {
      label: 'Säffle',
      value: '230'
    },
    {
      label: 'Säter',
      value: '40'
    },
    {
      label: 'Sävsjö',
      value: '78'
    },
    {
      label: 'Söderköping',
      value: '138'
    },
    {
      label: 'Södertälje',
      value: '198'
    },
    {
      label: 'Sölvesborg',
      value: '27'
    },
    {
      label: 'Tanum',
      value: '303'
    },
    {
      label: 'Tibro',
      value: '304'
    },
    {
      label: 'Tidaholm',
      value: '305'
    },
    {
      label: 'Tierp',
      value: '216'
    },
    {
      label: 'Timrå',
      value: '255'
    },
    {
      label: 'Tingsryd',
      value: '100'
    },
    {
      label: 'Tjörn',
      value: '306'
    },
    {
      label: 'Tomelilla',
      value: '171'
    },
    {
      label: 'Torsby',
      value: '233'
    },
    {
      label: 'Torsås',
      value: '92'
    },
    {
      label: 'Tranemo',
      value: '308'
    },
    {
      label: 'Tranås',
      value: '79'
    },
    {
      label: 'Trelleborg',
      value: '172'
    },
    {
      label: 'Trollhättan',
      value: '309'
    },
    {
      label: 'Vänersborg',
      value: '310'
    },
    {
      label: 'Uddevalla',
      value: '312'
    },
    {
      label: 'Trosa',
      value: '182'
    },
    {
      label: 'Tyresö',
      value: '204'
    },
    {
      label: 'Täby',
      value: '203'
    },
    {
      label: 'Töreboda',
      value: '307'
    },
    {
      label: 'Ulricehamn',
      value: '311'
    },
    {
      label: 'Umeå',
      value: '245'
    },
    {
      label: 'Upplands Väsby',
      value: '205'
    },
    {
      label: 'Upplands-Bro',
      value: '206'
    },
    {
      label: 'Uppsala',
      value: '217'
    },
    {
      label: 'Uppvidinge',
      value: 'Uppvidinge'
    },
    {
      label: 'Vadstena',
      value: '139'
    },
    {
      label: 'Vaggeryd',
      value: '80'
    },
    {
      label: 'Valdemarsvik',
      value: '140'
    },
    {
      label: 'Vallentuna',
      value: '207'
    },
    {
      label: 'Vansbro',
      value: '42'
    },
    {
      label: 'Vara',
      value: '313'
    },
    {
      label: 'Varberg',
      value: '61'
    },
    {
      label: 'Vaxholm',
      value: '209'
    },
    {
      label: 'Vellinge',
      value: '173'
    },
    {
      label: 'Vetlanda',
      value: '82'
    },
    {
      label: 'Vilhelmina',
      value: '247'
    },
    {
      label: 'Vimmerby',
      value: '94'
    },
    {
      label: 'Vindeln',
      value: '248'
    },
    {
      label: 'Vingåker',
      value: '183'
    },
    {
      label: 'Vännäs',
      value: '246'
    },
    {
      label: 'Värmdö',
      value: '208'
    },
    {
      label: 'Värnamo',
      value: '81'
    },
    {
      label: 'Västervik',
      value: '93'
    },
    {
      label: 'Västerås',
      value: '265'
    },
    {
      label: 'Växjö',
      value: '102'
    },
    {
      label: 'Vårgårda',
      value: '314'
    },
    {
      label: 'Ydre',
      value: '141'
    },
    {
      label: 'Älmhult',
      value: '95'
    },
    {
      label: 'Älvdalen',
      value: '28'
    },
    {
      label: 'Älvkarleby',
      value: '210'
    },
    {
      label: 'Älvsbyn',
      value: '103'
    },
    {
      label: 'Ängelholm',
      value: '142'
    },
    {
      label: 'Åmål',
      value: '268'
    },
    {
      label: 'Ånge',
      value: '249'
    },
    {
      label: 'Åre',
      value: '62'
    },
    {
      label: 'Årjäng',
      value: '218'
    },
    {
      label: 'Åsele',
      value: '234'
    },
    {
      label: 'Åstorp',
      value: '143'
    },
    {
      label: 'Åtvidaberg',
      value: '129'
    },
    {
      label: 'Öckerö',
      value: '294'
    },
    {
      label: 'Ödeshög',
      value: '137'
    },
    {
      label: 'Örebro',
      value: '128'
    },
    {
      label: 'Örkelljunga',
      value: '161'
    },
    {
      label: 'Örnsköldsvik',
      value: '252'
    },
    {
      label: 'Östersund',
      value: '67'
    },
    {
      label: 'Österåker',
      value: '195'
    },
    {
      label: 'Östhammar',
      value: '215'
    },
    {
      label: 'Östra Göinge',
      value: '163'
    },
    {
      label: 'Överkalix',
      value: '113'
    },
    {
      label: 'Övertorneå',
      value: '114'
    }
  ]
}

export default categories