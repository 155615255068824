import * as t from "../actionTypes";

const initialState = {
  arr: [],
};

const favoriteAds = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCH_FAVORITE_ADS:
      return {
        ...state,
        arr: action.payload,
      };
    case t.ADD_FAVORITE_AD:
      return {
        ...state,
        arr: [...state.arr, action.payload],
      };
    case t.REMOVE_FAVORITE_AD:
      return {
        ...state,
        arr: [...state.arr.filter((ad) => ad !== action.payload)],
      };
    default:
      return state;
  }
};

export default favoriteAds;
