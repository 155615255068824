import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const YrkesAdsLoading = () => {
  var loadingads = [];

  for (let i = 0; i < 6; i++) {
    loadingads.push( <Skeleton className={"yrkesAdLoadingSkeleton yrkesguide-wrapper-three" } key={i} /> );
  }

  return (
    <div className="yrkesannonserna">
      {loadingads}
    </div>
  );
};

export default YrkesAdsLoading;
