const categories = [
    {
        "id": "13",
        "rubrik": "Bostad",
        "url": "/bostad",
        "parent": "0"
    },
    {
        "id": "17",
        "rubrik": "Fritidsboende",
        "url": "/bostad/fritidsboende",
        "parent": "13"
    },
    {
        "id": "16",
        "rubrik": "Hyresmarknad",
        "url": "/bostad/hyresmarknad",
        "parent": "13"
    },
    {
        "id": "82",
        "rubrik": "Fritidsboende",
        "url": "/bostad/hyresmarknad/fritidsboende",
        "parent": "16"
    },
    {
        "id": "83",
        "rubrik": "Lägenheter",
        "url": "/bostad/hyresmarknad/lagenheter",
        "parent": "16"
    },
    {
        "id": "85",
        "rubrik": "Övrigt",
        "url": "/bostad/hyresmarknad/ovrigt",
        "parent": "16"
    },
    {
        "id": "84",
        "rubrik": "Villor & Radhus",
        "url": "/bostad/hyresmarknad/villor-radhus",
        "parent": "16"
    },
    {
        "id": "18",
        "rubrik": "Lägenheter",
        "url": "/bostad/lagenheter",
        "parent": "13"
    },
    {
        "id": "20",
        "rubrik": "Övrigt",
        "url": "/bostad/ovrigt",
        "parent": "13"
    },
    {
        "id": "19",
        "rubrik": "Villor & Radhus",
        "url": "/bostad/villor-radhus",
        "parent": "13"
    },
    {
        "id": "11",
        "rubrik": "Djur",
        "url": "/djur",
        "parent": "0"
    },
    {
        "id": "21",
        "rubrik": "Fågel",
        "url": "/djur/fagel",
        "parent": "11"
    },
    {
        "id": "22",
        "rubrik": "Fisk",
        "url": "/djur/fisk",
        "parent": "11"
    },
    {
        "id": "23",
        "rubrik": "Gnagare & Kaniner",
        "url": "/djur/gnagare-kaniner",
        "parent": "11"
    },
    {
        "id": "24",
        "rubrik": "Hund",
        "url": "/djur/hund",
        "parent": "11"
    },
    {
        "id": "26",
        "rubrik": "Katt",
        "url": "/djur/katt",
        "parent": "11"
    },
    {
        "id": "25",
        "rubrik": "Katt & Hundtillbehör",
        "url": "/djur/katt-hundtillbehor",
        "parent": "11"
    },
    {
        "id": "27",
        "rubrik": "Lantbruksdjur",
        "url": "/djur/lantbruksdjur",
        "parent": "11"
    },
    {
        "id": "30",
        "rubrik": "Övrigt",
        "url": "/djur/ovrigt",
        "parent": "11"
    },
    {
        "id": "28",
        "rubrik": "Reptil",
        "url": "/djur/reptil",
        "parent": "11"
    },
    {
        "id": "29",
        "rubrik": "Tillbehör",
        "url": "/djur/tillbehor",
        "parent": "11"
    },
    {
        "id": "10",
        "rubrik": "Elektronik",
        "url": "/elektronik",
        "parent": "0"
    },
    {
        "id": "31",
        "rubrik": "Datorer & Tillbehör",
        "url": "/elektronik/datorer-tillbehor",
        "parent": "10"
    },
    {
        "id": "164",
        "rubrik": "Bärbara datorer",
        "url": "/elektronik/datorer-tillbehor/barbara-datorer",
        "parent": "31"
    },
    {
        "id": "165",
        "rubrik": "Datortillbehör & Program",
        "url": "/elektronik/datorer-tillbehor/datortillbehor-progr",
        "parent": "31"
    },
    {
        "id": "167",
        "rubrik": "Övrigt",
        "url": "/elektronik/datorer-tillbehor/ovrigt",
        "parent": "31"
    },
    {
        "id": "166",
        "rubrik": "Stationära datorer",
        "url": "/elektronik/datorer-tillbehor/stationara-datorer",
        "parent": "31"
    },
    {
        "id": "32",
        "rubrik": "Ljud & Bild",
        "url": "/elektronik/ljud-bild",
        "parent": "10"
    },
    {
        "id": "168",
        "rubrik": "DVD-spelare & MP3 -spelare",
        "url": "/elektronik/ljud-bild/dvd-spelare-mp3-spelare",
        "parent": "32"
    },
    {
        "id": "169",
        "rubrik": "Kameror & Videokameror",
        "url": "/elektronik/ljud-bild/kameror-videokameror",
        "parent": "32"
    },
    {
        "id": "172",
        "rubrik": "Övrigt",
        "url": "/elektronik/ljud-bild/ovrigt",
        "parent": "32"
    },
    {
        "id": "170",
        "rubrik": "Stereo & Surround",
        "url": "/elektronik/ljud-bild/stereo-surround",
        "parent": "32"
    },
    {
        "id": "171",
        "rubrik": "TV & Projektorer",
        "url": "/elektronik/ljud-bild/tv-projektorer",
        "parent": "32"
    },
    {
        "id": "33",
        "rubrik": "Mobiler & Tillbehör",
        "url": "/elektronik/mobiler-tillbehor",
        "parent": "10"
    },
    {
        "id": "173",
        "rubrik": "Mobiler",
        "url": "/elektronik/mobiler-tillbehor/mobiler",
        "parent": "33"
    },
    {
        "id": "175",
        "rubrik": "Övrigt",
        "url": "/elektronik/mobiler-tillbehor/ovrigt",
        "parent": "33"
    },
    {
        "id": "174",
        "rubrik": "Tillbehör",
        "url": "/elektronik/mobiler-tillbehor/tillbehor",
        "parent": "33"
    },
    {
        "id": "34",
        "rubrik": "Övrigt",
        "url": "/elektronik/ovrigt",
        "parent": "10"
    },
    {
        "id": "344",
        "rubrik": "Surfplattor",
        "url": "/elektronik/surfplattor",
        "parent": "10"
    },
    {
        "id": "8",
        "rubrik": "För barn",
        "url": "/for-barn",
        "parent": "0"
    },
    {
        "id": "42",
        "rubrik": "Barnkläder",
        "url": "/for-barn/barnklader",
        "parent": "8"
    },
    {
        "id": "43",
        "rubrik": "Barnmöbler",
        "url": "/for-barn/barnmobler",
        "parent": "8"
    },
    {
        "id": "44",
        "rubrik": "Barnskor",
        "url": "/for-barn/barnskor",
        "parent": "8"
    },
    {
        "id": "45",
        "rubrik": "Barnvagnar & Tillbehör",
        "url": "/for-barn/barnvagnar-tillbehor",
        "parent": "8"
    },
    {
        "id": "46",
        "rubrik": "Bilbarnstolar",
        "url": "/for-barn/bilbarnstolar",
        "parent": "8"
    },
    {
        "id": "47",
        "rubrik": "Leksaker",
        "url": "/for-barn/leksaker",
        "parent": "8"
    },
    {
        "id": "48",
        "rubrik": "Övrigt",
        "url": "/for-barn/ovrigt",
        "parent": "8"
    },
    {
        "id": "12",
        "rubrik": "Fordon",
        "url": "/fordon",
        "parent": "0"
    },
    {
        "id": "49",
        "rubrik": "Båtar",
        "url": "/fordon/batar",
        "parent": "12"
    },
    {
        "id": "180",
        "rubrik": "Motorbåt",
        "url": "/fordon/batar/motorbat",
        "parent": "49"
    },
    {
        "id": "182",
        "rubrik": "Övrigt",
        "url": "/fordon/batar/ovrigt",
        "parent": "49"
    },
    {
        "id": "181",
        "rubrik": "Segelbåt",
        "url": "/fordon/batar/segelbat",
        "parent": "49"
    },
    {
        "id": "50",
        "rubrik": "Båtdelar & Tillbehör",
        "url": "/fordon/batdelar-tillbehor",
        "parent": "12"
    },
    {
        "id": "183",
        "rubrik": "Motor & propeller",
        "url": "/fordon/batdelar-tillbehor/motor-propeller",
        "parent": "50"
    },
    {
        "id": "51",
        "rubrik": "Bilar",
        "url": "/fordon/bilar",
        "parent": "12"
    },
    {
        "id": "52",
        "rubrik": "Bildelar & Tillbehör",
        "url": "/fordon/bildelar-tillbehor",
        "parent": "12"
    },
    {
        "id": "226",
        "rubrik": "Bilstereo",
        "url": "/fordon/bildelar-tillbehor/bilstereo",
        "parent": "52"
    },
    {
        "id": "227",
        "rubrik": "Däck & Fälgar",
        "url": "/fordon/bildelar-tillbehor/dack-falgar",
        "parent": "52"
    },
    {
        "id": "228",
        "rubrik": "GPS",
        "url": "/fordon/bildelar-tillbehor/gps",
        "parent": "52"
    },
    {
        "id": "232",
        "rubrik": "Övrigt",
        "url": "/fordon/bildelar-tillbehor/ovrigt",
        "parent": "52"
    },
    {
        "id": "229",
        "rubrik": "Reservdelar",
        "url": "/fordon/bildelar-tillbehor/reservdelar",
        "parent": "52"
    },
    {
        "id": "230",
        "rubrik": "Släp",
        "url": "/fordon/bildelar-tillbehor/slap",
        "parent": "52"
    },
    {
        "id": "231",
        "rubrik": "Takboxar & Takräcken",
        "url": "/fordon/bildelar-tillbehor/takboxar-takracken",
        "parent": "52"
    },
    {
        "id": "53",
        "rubrik": "Husvagnar & Husbilar",
        "url": "/fordon/husvagnar-husbilar",
        "parent": "12"
    },
    {
        "id": "233",
        "rubrik": "Husbil",
        "url": "/fordon/husvagnar-husbilar/husbil",
        "parent": "53"
    },
    {
        "id": "234",
        "rubrik": "Husvagn",
        "url": "/fordon/husvagnar-husbilar/husvagn",
        "parent": "53"
    },
    {
        "id": "236",
        "rubrik": "Övrigt",
        "url": "/fordon/husvagnar-husbilar/ovrigt",
        "parent": "53"
    },
    {
        "id": "235",
        "rubrik": "Tillbehör",
        "url": "/fordon/husvagnar-husbilar/tillbehor",
        "parent": "53"
    },
    {
        "id": "54",
        "rubrik": "MC-delar & Tillbehör",
        "url": "/fordon/mc-delar-tillbehor",
        "parent": "12"
    },
    {
        "id": "237",
        "rubrik": "Däck & Fälgar",
        "url": "/fordon/mc-delar-tillbehor/dack-falgar",
        "parent": "54"
    },
    {
        "id": "238",
        "rubrik": "Hjälm/Skydd/Kläder",
        "url": "/fordon/mc-delar-tillbehor/hjalm-skydd-klader",
        "parent": "54"
    },
    {
        "id": "240",
        "rubrik": "Övrigt",
        "url": "/fordon/mc-delar-tillbehor/ovrigt",
        "parent": "54"
    },
    {
        "id": "239",
        "rubrik": "Reservdelar",
        "url": "/fordon/mc-delar-tillbehor/reservdelar",
        "parent": "54"
    },
    {
        "id": "55",
        "rubrik": "Mopeder & Scooters",
        "url": "/fordon/mopeder-scooters",
        "parent": "12"
    },
    {
        "id": "56",
        "rubrik": "Motorcyklar",
        "url": "/fordon/motorcyklar",
        "parent": "12"
    },
    {
        "id": "241",
        "rubrik": "Cross/Enduro",
        "url": "/fordon/motorcyklar/cross-enduro",
        "parent": "56"
    },
    {
        "id": "242",
        "rubrik": "Custom",
        "url": "/fordon/motorcyklar/custom",
        "parent": "56"
    },
    {
        "id": "243",
        "rubrik": "Fyrhjuling/ATV",
        "url": "/fordon/motorcyklar/fyrhjuling-atv",
        "parent": "56"
    },
    {
        "id": "246",
        "rubrik": "Övrigt",
        "url": "/fordon/motorcyklar/ovrigt",
        "parent": "56"
    },
    {
        "id": "244",
        "rubrik": "Sport",
        "url": "/fordon/motorcyklar/sport",
        "parent": "56"
    },
    {
        "id": "245",
        "rubrik": "Touring",
        "url": "/fordon/motorcyklar/touring",
        "parent": "56"
    },
    {
        "id": "57",
        "rubrik": "Övriga fordon",
        "url": "/fordon/ovriga-fordon",
        "parent": "12"
    },
    {
        "id": "58",
        "rubrik": "Snöskoterdelar & tillbehör",
        "url": "/fordon/snoskoterdelar-tillbehor",
        "parent": "12"
    },
    {
        "id": "59",
        "rubrik": "Snöskotrar",
        "url": "/fordon/snoskotrar",
        "parent": "12"
    },
    {
        "id": "9",
        "rubrik": "Fritid & Hobby",
        "url": "/fritid-hobby",
        "parent": "0"
    },
    {
        "id": "60",
        "rubrik": "Biljetter",
        "url": "/fritid-hobby/biljetter",
        "parent": "9"
    },
    {
        "id": "38",
        "rubrik": "Konserter",
        "url": "/fritid-hobby/biljetter/konserter",
        "parent": "60"
    },
    {
        "id": "311",
        "rubrik": "Övrigt",
        "url": "/fritid-hobby/biljetter/ovrigt",
        "parent": "60"
    },
    {
        "id": "306",
        "rubrik": "Sport",
        "url": "/fritid-hobby/biljetter/sport",
        "parent": "60"
    },
    {
        "id": "40",
        "rubrik": "Teater, Opera & Ballet",
        "url": "/fritid-hobby/biljetter/teater-opera-ballet",
        "parent": "60"
    },
    {
        "id": "61",
        "rubrik": "Böcker & Studentlitteratur",
        "url": "/fritid-hobby/bocker-studentlitteratur",
        "parent": "9"
    },
    {
        "id": "62",
        "rubrik": "Cyklar",
        "url": "/fritid-hobby/cyklar",
        "parent": "9"
    },
    {
        "id": "63",
        "rubrik": "Häst & Ridsport",
        "url": "/fritid-hobby/hast-ridsport",
        "parent": "9"
    },
    {
        "id": "247",
        "rubrik": "Foder & Stall",
        "url": "/fritid-hobby/hast-ridsport/foder-stall",
        "parent": "63"
    },
    {
        "id": "248",
        "rubrik": "Hästar",
        "url": "/fritid-hobby/hast-ridsport/hastar",
        "parent": "63"
    },
    {
        "id": "251",
        "rubrik": "Övrigt",
        "url": "/fritid-hobby/hast-ridsport/ovrigt",
        "parent": "63"
    },
    {
        "id": "249",
        "rubrik": "Släp & Transport",
        "url": "/fritid-hobby/hast-ridsport/slap-transport",
        "parent": "63"
    },
    {
        "id": "250",
        "rubrik": "Utrustning",
        "url": "/fritid-hobby/hast-ridsport/utrustning",
        "parent": "63"
    },
    {
        "id": "64",
        "rubrik": "Hobby & Samlarprylar",
        "url": "/fritid-hobby/hobby-samlarprylar",
        "parent": "9"
    },
    {
        "id": "65",
        "rubrik": "Jakt & Fiske",
        "url": "/fritid-hobby/jakt-fiske",
        "parent": "9"
    },
    {
        "id": "252",
        "rubrik": "Fiske",
        "url": "/fritid-hobby/jakt-fiske/fiske",
        "parent": "65"
    },
    {
        "id": "253",
        "rubrik": "Jakt",
        "url": "/fritid-hobby/jakt-fiske/jakt",
        "parent": "65"
    },
    {
        "id": "254",
        "rubrik": "Övrigt",
        "url": "/fritid-hobby/jakt-fiske/ovrigt",
        "parent": "65"
    },
    {
        "id": "66",
        "rubrik": "Musik, Film & Spel",
        "url": "/fritid-hobby/musik-film-spel",
        "parent": "9"
    },
    {
        "id": "255",
        "rubrik": "CD & LP skivor",
        "url": "/fritid-hobby/musik-film-spel/cd-lp-skivor",
        "parent": "66"
    },
    {
        "id": "256",
        "rubrik": "DVD & VHS filmer",
        "url": "/fritid-hobby/musik-film-spel/dvd-vhs-filmer",
        "parent": "66"
    },
    {
        "id": "259",
        "rubrik": "Övrigt",
        "url": "/fritid-hobby/musik-film-spel/ovrigt",
        "parent": "66"
    },
    {
        "id": "257",
        "rubrik": "PC-spel",
        "url": "/fritid-hobby/musik-film-spel/pc-spel",
        "parent": "66"
    },
    {
        "id": "258",
        "rubrik": "TV-spel & TV -spelskonsoler",
        "url": "/fritid-hobby/musik-film-spel/tv-spel-tv-spelskons",
        "parent": "66"
    },
    {
        "id": "67",
        "rubrik": "Musikinstrument",
        "url": "/fritid-hobby/musikinstrument",
        "parent": "9"
    },
    {
        "id": "260",
        "rubrik": "Gitarr/Bas/Förstärkare",
        "url": "/fritid-hobby/musikinstrument/gitarr-bas-forstarka",
        "parent": "67"
    },
    {
        "id": "262",
        "rubrik": "Övrigt",
        "url": "/fritid-hobby/musikinstrument/ovrigt",
        "parent": "67"
    },
    {
        "id": "261",
        "rubrik": "Studio/Scenutrustning",
        "url": "/fritid-hobby/musikinstrument/studio-scenutrustnin",
        "parent": "67"
    },
    {
        "id": "70",
        "rubrik": "Övrigt",
        "url": "/fritid-hobby/ovrigt",
        "parent": "9"
    },
    {
        "id": "68",
        "rubrik": "Resor",
        "url": "/fritid-hobby/resor",
        "parent": "9"
    },
    {
        "id": "69",
        "rubrik": "Sport & Fritid",
        "url": "/fritid-hobby/sport-fritid",
        "parent": "9"
    },
    {
        "id": "263",
        "rubrik": "Bollsport",
        "url": "/fritid-hobby/sport-fritid/bollsport",
        "parent": "69"
    },
    {
        "id": "264",
        "rubrik": "Golf",
        "url": "/fritid-hobby/sport-fritid/golf",
        "parent": "69"
    },
    {
        "id": "267",
        "rubrik": "Övrigt",
        "url": "/fritid-hobby/sport-fritid/ovrigt",
        "parent": "69"
    },
    {
        "id": "265",
        "rubrik": "Träning & Hälsa",
        "url": "/fritid-hobby/sport-fritid/traning-halsa",
        "parent": "69"
    },
    {
        "id": "266",
        "rubrik": "Vintersport",
        "url": "/fritid-hobby/sport-fritid/vintersport",
        "parent": "69"
    },
    {
        "id": "7",
        "rubrik": "Hem & Inredning",
        "url": "/hem-inredning",
        "parent": "0"
    },
    {
        "id": "71",
        "rubrik": "Antikt & Konst",
        "url": "/hem-inredning/antikt-konst",
        "parent": "7"
    },
    {
        "id": "72",
        "rubrik": "Belysning",
        "url": "/hem-inredning/belysning",
        "parent": "7"
    },
    {
        "id": "73",
        "rubrik": "Bord & Stolar",
        "url": "/hem-inredning/bord-stolar",
        "parent": "7"
    },
    {
        "id": "74",
        "rubrik": "Bygg & Trädgård",
        "url": "/hem-inredning/bygg-tradgard",
        "parent": "7"
    },
    {
        "id": "268",
        "rubrik": "Badrum/WC/Bastu",
        "url": "/hem-inredning/bygg-tradgard/badrum-wc-bastu",
        "parent": "74"
    },
    {
        "id": "269",
        "rubrik": "Byggvaror",
        "url": "/hem-inredning/bygg-tradgard/byggvaror",
        "parent": "74"
    },
    {
        "id": "270",
        "rubrik": "Kamin & Värme",
        "url": "/hem-inredning/bygg-tradgard/kamin-varme",
        "parent": "74"
    },
    {
        "id": "271",
        "rubrik": "Kök",
        "url": "/hem-inredning/bygg-tradgard/kok",
        "parent": "74"
    },
    {
        "id": "274",
        "rubrik": "Övrigt",
        "url": "/hem-inredning/bygg-tradgard/ovrigt",
        "parent": "74"
    },
    {
        "id": "272",
        "rubrik": "Trädgård & Uteplats",
        "url": "/hem-inredning/bygg-tradgard/tradgard-uteplats",
        "parent": "74"
    },
    {
        "id": "273",
        "rubrik": "Verktyg",
        "url": "/hem-inredning/bygg-tradgard/verktyg",
        "parent": "74"
    },
    {
        "id": "75",
        "rubrik": "Heminredning",
        "url": "/hem-inredning/heminredning",
        "parent": "7"
    },
    {
        "id": "76",
        "rubrik": "Hyllor & Förvaring",
        "url": "/hem-inredning/hyllor-forvaring",
        "parent": "7"
    },
    {
        "id": "81",
        "rubrik": "Övrigt",
        "url": "/hem-inredning/ovrigt",
        "parent": "7"
    },
    {
        "id": "77",
        "rubrik": "Säng & Sovrum",
        "url": "/hem-inredning/sang-sovrum",
        "parent": "7"
    },
    {
        "id": "78",
        "rubrik": "Soffa/Fåtölj/Soffmöbler",
        "url": "/hem-inredning/soffa-fatolj-soffmobler",
        "parent": "7"
    },
    {
        "id": "79",
        "rubrik": "TV & Stereomöbler",
        "url": "/hem-inredning/tv-stereomobler",
        "parent": "7"
    },
    {
        "id": "80",
        "rubrik": "Vitvaror & Husgeråd",
        "url": "/hem-inredning/vitvaror-husgerad",
        "parent": "7"
    },
    {
        "id": "275",
        "rubrik": "Diskmaskin",
        "url": "/hem-inredning/vitvaror-husgerad/diskmaskin",
        "parent": "80"
    },
    {
        "id": "276",
        "rubrik": "Kökstillbehör & Porslin",
        "url": "/hem-inredning/vitvaror-husgerad/kokstillbehor-por",
        "parent": "80"
    },
    {
        "id": "277",
        "rubrik": "Kyl & Frys",
        "url": "/hem-inredning/vitvaror-husgerad/kyl-frys",
        "parent": "80"
    },
    {
        "id": "280",
        "rubrik": "Övrigt",
        "url": "/hem-inredning/vitvaror-husgerad/ovrigt",
        "parent": "80"
    },
    {
        "id": "278",
        "rubrik": "Spis & Micro",
        "url": "/hem-inredning/vitvaror-husgerad/spis-micro",
        "parent": "80"
    },
    {
        "id": "279",
        "rubrik": "Tvättmaskiner & Torktumlare",
        "url": "/hem-inredning/vitvaror-husgerad/tvattmaskiner-tor",
        "parent": "80"
    },
    {
        "id": "6",
        "rubrik": "Mode",
        "url": "/mode",
        "parent": "0"
    },
    {
        "id": "99",
        "rubrik": "Accessoarer",
        "url": "/mode/accessoarer",
        "parent": "6"
    },
    {
        "id": "312",
        "rubrik": "Damkläder  & Damskor",
        "url": "/mode/damklader-damskor",
        "parent": "6"
    },
    {
        "id": "317",
        "rubrik": "Byxor",
        "url": "/mode/damklader-damskor/byxor",
        "parent": "312"
    },
    {
        "id": "316",
        "rubrik": "Jackor & Ytterplagg",
        "url": "/mode/damklader-damskor/jackor-ytterplagg",
        "parent": "312"
    },
    {
        "id": "318",
        "rubrik": "Jeans",
        "url": "/mode/damklader-damskor/jeans",
        "parent": "312"
    },
    {
        "id": "332",
        "rubrik": "Kjolar & Klänningar",
        "url": "/mode/damklader-damskor/kjolar-klanningar",
        "parent": "312"
    },
    {
        "id": "319",
        "rubrik": "Kostymer & Kavajer",
        "url": "/mode/damklader-damskor/kostymer-kavajer",
        "parent": "312"
    },
    {
        "id": "331",
        "rubrik": "Övrigt",
        "url": "/mode/damklader-damskor/ovrigt",
        "parent": "312"
    },
    {
        "id": "320",
        "rubrik": "Skjortor",
        "url": "/mode/damklader-damskor/skjortor",
        "parent": "312"
    },
    {
        "id": "314",
        "rubrik": "Skor",
        "url": "/mode/damklader-damskor/skor",
        "parent": "312"
    },
    {
        "id": "321",
        "rubrik": "Toppar",
        "url": "/mode/damklader-damskor/toppar",
        "parent": "312"
    },
    {
        "id": "327",
        "rubrik": "Tröjor",
        "url": "/mode/damklader-damskor/trojor",
        "parent": "312"
    },
    {
        "id": "313",
        "rubrik": "Herrkläder & Herrskor",
        "url": "/mode/herrklader-herrskor",
        "parent": "6"
    },
    {
        "id": "336",
        "rubrik": "Byxor",
        "url": "/mode/herrklader-herrskor/byxor",
        "parent": "313"
    },
    {
        "id": "335",
        "rubrik": "Jackor & Ytterplagg",
        "url": "/mode/herrklader-herrskor/jackor-ytterplagg",
        "parent": "313"
    },
    {
        "id": "337",
        "rubrik": "Jeans",
        "url": "/mode/herrklader-herrskor/jeans",
        "parent": "313"
    },
    {
        "id": "338",
        "rubrik": "Kostymer & Kavajer",
        "url": "/mode/herrklader-herrskor/kostymer-kavajer",
        "parent": "313"
    },
    {
        "id": "342",
        "rubrik": "Övrigt",
        "url": "/mode/herrklader-herrskor/ovrigt",
        "parent": "313"
    },
    {
        "id": "339",
        "rubrik": "Skjortor",
        "url": "/mode/herrklader-herrskor/skjortor",
        "parent": "313"
    },
    {
        "id": "333",
        "rubrik": "Skor",
        "url": "/mode/herrklader-herrskor/skor",
        "parent": "313"
    },
    {
        "id": "340",
        "rubrik": "Tröjor & T-shirts",
        "url": "/mode/herrklader-herrskor/trojor-t-shirts",
        "parent": "313"
    },
    {
        "id": "103",
        "rubrik": "Klockor",
        "url": "/mode/klockor",
        "parent": "6"
    },
    {
        "id": "111",
        "rubrik": "Övrigt",
        "url": "/mode/ovrigt",
        "parent": "6"
    },
    {
        "id": "107",
        "rubrik": "Smycken",
        "url": "/mode/smycken",
        "parent": "6"
    },
    {
        "id": "110",
        "rubrik": "Väskor",
        "url": "/mode/vaskor",
        "parent": "6"
    },
    {
        "id": "301",
        "rubrik": "Övrigt",
        "url": "/ovrigt",
        "parent": "0"
    }
]

export default categories