import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import CreateAd from "../pages/CreateAd";
import Meddelanden from "../pages/Messages";
import Home from "../pages/Home";
import Login from "../pages/Login";
import MyPage from "../pages/MyPage";
import AnnonsTips from "../pages/AdTips";
import Settings from "../pages/Settings";
import Saved from "../pages/Saved";
import SearchHistory from "../pages/SearchHistory";
import NotLoggedIn from "../pages/NotLoggedIn";
import NotFound from "../pages/NotFound";
import ChosenAd from "../pages/ChosenAd";
import UserProfile from "../pages/[profile]/UserProfile";
import Advertising from "../pages/Advertising";
import CommonQuestions from "../pages/CommonQuestions";
import Cookies from "../pages/Cookies";
import ShopSafe from "../pages/ShopSafe";
import TermsOfUse from "../pages/TermsOfUse";
import IntegrityPolicy from "../pages/IntegrityPolicy";
import ContactUs from "../pages/ContactUs";
import AboutUs from "../pages/AboutUs";
import EditAd from "../pages/EditAd";
import ResetPassword from "../pages/ResetPassword";
import Yrkesguiden from "../pages/Yrkesguide";
import Yrkesguider from "../pages/Yrkesguider";
import Foretagssidor from "../pages/Foretagssidor";
// import ScrollToTop from "../helpers/ScrollToTop";

import ResponsiveNavigation from "../components/ResponsiveNavigation";
//import CategoriesIcons from "../components/CategoriesIcons";
import Footer from "../components/Footer";
//import ScrollToTopButton from "../components/ScrollToTopButton";

const Routing = () => {
  return (
    <Router>
      <div className="App">
        {/* <BannerHandler /> */}
        {/* <ScrollToTop /> */}
        <ResponsiveNavigation background="#fff" hoverBackground="#ddd" />
        {/* <CategoriesIcons /> */}

        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="/meddelanden" element={<Meddelanden />} />

          <Route path="/annons" element={<CreateAd />} />

          <Route path="/loggain" element={<Login />} />

          <Route path="/min-sida" element={<MyPage />} />

          <Route path="/tips" element={<AnnonsTips />} />

          <Route path="/installningar" element={<Settings />} />

          <Route path="/sparade" element={<Saved />} />

          <Route path="/sokhistorik" element={<SearchHistory />} />

          <Route path="/annonsering" element={<Advertising />} />

          <Route path="/inte-inloggad" element={<NotLoggedIn />} />

          <Route path="/sidan-finns-ej" element={<NotFound />} />

          <Route path="/vanliga-fragor" element={<CommonQuestions />} />

          <Route path="/handla-tryggt" element={<ShopSafe />} />

          <Route path="/anvandarvillkor" element={<TermsOfUse />} />

          <Route path="/integritetspolicy" element={<IntegrityPolicy />} />

          <Route path="/cookies" element={<Cookies />} />

          <Route path="/kontakta-oss" element={<ContactUs />} />

          <Route path="/om-oss" element={<AboutUs />} />

          <Route path="/aterstall-losenord" element={<ResetPassword />} />

          <Route path="/annons/:id" element={<ChosenAd />} />

          <Route path="/profil/:id" element={<UserProfile />} />

          <Route path="/redigera/:id" element={<EditAd />} />

          <Route path="/:region" element={<Home />} />

          <Route path="/:region/:ort" element={<Home />} />

          <Route path="/:region/:ort/:category" element={<Home />} />

          <Route path="/:region/:ort/:category/:undercategory" element={<Home />} />

          <Route path="/:region/:ort/:category/:undercategory/:choice" element={<Home />} />

          <Route path="/:region/:annonstitel,:id" element={<ChosenAd />} />
          
          <Route path="/bra-hantverkare/:ort" element={<Yrkesguiden />} />
          
          <Route path="/bra-restauranger/:ort" element={<Yrkesguiden />} />
          
          <Route path="/yrkesguider" element={<Yrkesguider />} />
          
          <Route path="/foretagssidor" element={<Foretagssidor />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer className="footer-in-root" />
      </div>
    </Router>
  );
};

export default Routing;
