import React from "react";
import { Link } from "react-scroll";

const TermsOfUse = () => {
  return (
    <div className="termsofuse-container mainwrapper">
      <div className="termsofuse-wrapper">
        <div className="termsofuse-title-container">
          <h1 className="termsofuse-title">Citiboard – Användarvillkor</h1>
        </div>
        <div className="termsofuse-content-container">
          <div className="termsofuse-block-title">
              <strong>Innehållsförteckning</strong>
            </div>
          <ul>
            <Link to="generella" spy={true} smooth={true} offset={-100}>
                <li className="termsofuse-content-link">
                  Generella användarvillkor
                </li>
              </Link>
            <Link to="regler" spy={true} smooth={true} offset={-100}>
                <li className="termsofuse-content-link">
                  Regler för att lägga in en annons
                </li>
              </Link>
            <Link to="forbjuden" spy={true} smooth={true} offset={-100}>
                <li className="termsofuse-content-link">
                  Följande produkter och tjänster är förbjudna att annonsera,
                  diskutera eller nämna på plattformen
                </li>
              </Link>
            <Link to="agare" spy={true} smooth={true} offset={-100}>
                <li className="termsofuse-content-link">Plattformens ägare</li>
              </Link>
            
            <Link to="fragor" spy={true} smooth={true} offset={-100}>
                <li className="termsofuse-content-link">
                  Synpunkter och frågor
                </li>
              </Link>
            <Link to="facebook" spy={true} smooth={true} offset={-100}>
                <li className="termsofuse-content-link">
                  Koppling till den sociala domänen Facebook
                </li>
              </Link>
            <Link to="personuppgifter" spy={true} smooth={true} offset={-100}>
                <li className="termsofuse-content-link">Personuppgifter</li>
              </Link>
          </ul>
          <div className="termsofuse-general-container">
            <strong>
              <div className="termsofuse-block-title">
                  <span id="generella">Generella användarvillkor</span>
                </div>
            </strong>
            <ul>
              <li className="termsofuse-link">Generella användarvillkor</li>
              <li className="termsofuse-link">
                  Citiboard är en marknadsplats där Citiboard möjliggör för
                  enskilda, köpare och säljare, att handla med varandra.
                </li>
              <li className="termsofuse-link">
                  Citiboard är inte till någon del part i de avtal eller
                  förhandlingar som förs mellan säljare och köpare.
                </li>
              <li className="termsofuse-link">
                  Citiboard är fri från avgifter och provisioner och brukande av
                  plattformen är helt gratis, utan avgifter för inlagda annonser
                  och Citiboard tar inga provisioner för transaktioner mellan
                  användare.
                </li>
              <li className="termsofuse-link">
                  Användaren ansvarar för egna handlingar som utförs inom och i
                  samband med användandet av plattformen. Citiboard kan fritt
                  och helt efter egen bedömning helt eller delvis begränsa
                  användarens brukande av plattformen.
                </li>
              <li className="termsofuse-link">
                  Citiboard kan när som helst utan annonsörens medgivande
                  återkalla annonsen och övrigt innehåll upplagt på plattformen.
                  Bolaget bär inget ansvar för annonsens innehåll, utan
                  innehållet är annonsören personligen ensam ansvarig för.
                  Annonsens innebörd, innehåll och sammanhang samt annat
                  material och information med koppling till enskild annons
                  ligger således utanför Citiboards ansvar.
                </li>
              <li className="termsofuse-link">
                  Interaktion på Citiboard kräver att användaren har ett
                  Facebook konto och därmed har accepterat Facebooks
                  användarvillkor. Det går även att registrera sig med
                  e-postkonto på Citiboard.
                </li>
              <li className="termsofuse-link">
                  Det är inte tillåtet att marknadsföra eller länka till sina
                  egna annonser eller produkter under andras annonser.
                </li>
              <li className="termsofuse-link">
                  Citiboard tillåter inte att användare länkar, marknadsför
                  eller refererar till konkurrerande verksamheter såsom tex
                  andra annons-, auktionsplattformar eller Facebook - grupper.
                </li>
              <li className="termsofuse-link">
                  Citiboard äger rätt att använda olika typer av annonser, såväl
                  aktivt som inaktivt material och dess innehåll i samband med
                  plattformens marknadsföring.
                </li>
              <li className="termsofuse-link">
                  Citiboard förbehåller sig rätten att spara annonser vars
                  annonstid har avslutats.
                </li>
              <li className="termsofuse-link">
                  Vidare förbehåller Citiboard sig rätten att spara upplagda
                  frågor från användarna.
                </li>
              <li className="termsofuse-link">
                  Citiboard publicerar alla bilder med vattenstämpel. Detta görs
                  för att förhindra att bilderna används i sammanhang som inte
                  medgivits av annonsören.
                </li>
              <li className="termsofuse-link">
                  Alla rättigheter till såväl annonstexten som bilden överlåts
                  till Citiboard och Citiboard har rätt att använda såväl text
                  som bild fritt var för sig eller tillsammans såväl i dess
                  ursprungliga format som beskuret eller i delar.
                </li>
              <li className="termsofuse-link">
                  Citiboard förbehåller sig rätten att när som helst kunna
                  skicka nyhetsbrev till användarna via e-post eller
                  notifikation på Facebook.
                </li>
              <li className="termsofuse-link">
                  Citiboard använder endast e-postadress mellan användare ifall
                  att de väljer att kontakta säljare via ”sänd privat
                  meddelande”. Härvid är användarens e-postadress helt osynlig
                  för plattformens användare.
                </li>
              <li className="termsofuse-link">
                  Citiboard bär inget som helst ansvar för icke utförda
                  transaktioner, brister, skador eller ej uppfyllda åtaganden i
                  något som helst avseende.
                </li>
              <li className="termsofuse-link">
                  Citiboard bär inget ansvar för användarens eller tredje mans
                  handlingar och agerande, som bryter mot gällande lagstiftning,
                  Facebooks användarvillkor eller eventuella skador som har
                  uppstått efter användande av plattformen.
                </li>
             <li className="termsofuse-link">
                  Enligt skatteverkets regler skall annonsörens under vissa
                  omständigheter deklarera sin försäljning. Läs mer hos
                  Skatteverket{" "}
                  <a
                    href="https://www.skatteverket.se/funktioner/svarpavanligafragor/privatperson/ehandel/privatehandel.4.dfe345a107ebcc9baf80007325.html"
                    className="termsofuse-a-href"
                  >
                    här.
                  </a>{" "}
                </li>
              <li className="termsofuse-link">
                  Citiboard förbehåller sig rätten att när som helst, utan
                  särskilt meddelande härom, ändra föreliggande användarvillkor.
                </li>
              <li className="termsofuse-link">
                  Vid prövning av tvist mellan användare och Citiboard skall
                  svensk lag tillämpas och domstols behörighet enligt vedertagna
                  internationella privaträttsliga regler.
                </li>
            </ul>
          </div>
          <div className="termsofuse-general-container">
            <strong>
              <div className="termsofuse-block-title">
                  <span id="regler">Regler för att lägga in en annons</span>
                </div>
            </strong>
            <ul>
              <li className="termsofuse-link">
                  För att lägga in en annons kräver ingen registrering eller
                  inloggning, men det krävs ett godkännade av
                  Citiboards-applikationen för att använda Citiboard.
                </li>
              <li className="termsofuse-link">
                  Endast en annons per produkt/tjänst och användare tillåts.
                  Dubbletter av annonser kommer att raderas av Citiboard.
                  Annonsör som vill lägga in sin annons på nytt skall radera sin
                  gamla annons först. Annonsören får inte lägga in samma annons
                  i flera distrikt eller flera städer.
                </li>
              <li className="termsofuse-link">
                  Privatpersoner använder plattformen för att utannonsera varor,
                  produkter, samt köpa och sälja - allt i enlighet med svensk
                  lag.
                </li>
              <li className="termsofuse-link">
                  Företag som vill sälja är också välkomna att använda
                  plattformen. Till företag hör: Juridiska personer, enskilda
                  firmor, ideella- och ekonomiska föreningar, hobby verksamheter
                  och annonsörer som tillverkar på beställning. Citiboard
                  förbehåller sig rätten att avgöra när en annons är
                  företagsliknande. Alla varor ska annonseras och utbjudas med
                  pris inklusive moms.
                </li>
              <li className="termsofuse-link">
                  Det är endast tillåtet att annonsera om försäljning, uthyrning
                  och byten. Annonstexten ska användas till att beskriva den
                  specifika varan som erbjuds. Övrig information, såsom sökord,
                  beskrivning av verksamhet eller om företag tillåts inte i
                  annonstexten. Citiboard tillåter inte heller någon länkning i
                  annons texterna. Endast annonser på något av de skandinaviska
                  språken eller det engelska språket är godkänt.
                </li>
              <li className="termsofuse-link">
                  Beskrivning av produkt eller tjänst: Försök beskriva produkten
                  i rubriken, detta gör din annons synligare för eventuella
                  köpare. Beskriv varan eller tjänsten så mycket som möjligt:
                  Storlek, mått, färg, pris och var varan finns att hämta. Det
                  är inte tillåtet att länka till en annan sida.
                </li>
              <li className="termsofuse-link">
                  Texter får inte kopieras från andra annonser, dessa är
                  skyddade enligt lag.
                </li>
              <li className="termsofuse-link">
                  Annonser med foto kommer att göra ditt inlägg mer attraktivt.
                  Använd relevanta bilder för just din vara. Dina bilder får ej
                  användas i fler än en annons. Inga företags logotyper får vara
                  inkluderade i bilderna. Det är inte heller tillåtet att
                  använda modeller för utannonsering av underkläder eller
                  baddräkter eller i liknande sammanhang.
                </li>

                <li className="termsofuse-link">
                  Citiboard tillåter ej filmer innehållande logotyper,
                  företagsnamn, URL:er, eller annat som kan tolkas som
                  företagsmarknadsföring.
                </li>

                <li className="termsofuse-link">
                  Det är inte tillåtet att ta bilder och filmer från andra
                  annonser utan annonsörens medgivande. Dessa bilder är skyddade
                  under upphovsrättslagen.
                </li>

                <li className="termsofuse-link">
                  Pälsar och andra produkter från djur som i CITES listning
                  http://www.cites.org/ anses sårbara, t.ex. varg och leopard,
                  kräver tillstånd från Jordbruksverket
                  http://www.jordbruksverket.se/amnesomraden/djur/hotadeartercites.4.7caa00cc126738ac4e880002389.html.
                  Om sådant tillstånd finns, skall detta framgå i annonstexten.
                  Olaglig annonsering av sådana produkter kan straffas med
                  fängelse eller böter och om tillstånd saknas kommer annonsen
                  att tas bort av Citiboard.
                </li>

                <li className="termsofuse-link">
                  Annonsören svarar för att han är ägare av upphovsrätt, bilder
                  och samtliga rättigheter avseende immateriell egendom.
                  Innehållet skall ej bryta mot tredje mans rätt eller gällande
                  lagstiftning.
                </li>

                <li className="termsofuse-link">
                  Vid försäljning av spelautomater så skall det framgå i
                  annonsen att innehav kan kräva tillstånd från
                  lotteriinspektionen.
                </li>

                <li className="termsofuse-link">
                  Citiboard har restriktioner mot försäljning eller överlåtelse
                  av vissa djur. Pitbull och varghybrider är inte tillåtna. I
                  enlighet med djurskyddslagen samt organisationerna Sveraks
                  Sveriges Kattklubbars Riksförbund och SKKs Svenska
                  Kennelklubben rekommendationer tillåter Citiboard inte
                  överlåtelse av kattungar yngre än 12 veckor eller hundvalpar
                  yngre än 8 veckor. Priset måste vara minst 100 kronor per katt
                  och 500 kronor per hund. Vid förfrågan måste en hundannonsör
                  kunna uppge hundens registreringsnummer från Jordbruksverket.
                  I de fall registrering ej hunnit ske, måste valpens mammas
                  registreringsnummer kunna anges. Importerade hundar får inte
                  säljas på Citiboard. Vid annonsering av rovdjur eller
                  zoologiska djur som kräver tillstånd från jordbruksverket
                  måste tillståndet inklusive nummer anges i annonsen. Här kan
                  du läsa mer om jordbruksverkets regler:
                  http://www.sjv.se/amnesomraden/djur.4.5abb9acc11c89b20e9e8000333.html
                </li>

                <li className="termsofuse-link">
                  Vid försäljning av licenspliktigt vapen måste det framgå i
                  annonstexten att vapnet kräver licens. Replikor är inte
                  tillåtna historiska föremål föreställande vapen framtagna
                  innan 1890 undantaget. Annonsering och försäljning av
                  soft-air-guns, luftpistoler och kolsyrevapen får endast ske om
                  anslagsenergin är under 10 joule och enbart säljas till
                  personer över 18 år. Vapenreplikor, startvapen och andra vapen
                  som vi bedömer liknar riktiga vapen är inte tillåtna att sälja
                  eller annonsera om på Citiboard.
                </li>
            </ul>
          </div>
          <div className="termsofuse-general-container">
            <strong>
              <div className="termsofuse-block-title">
                  <span id="forbjuden">
                    Följande produkter och tjänster är förbjudna att annonsera,
                    diskutera eller nämna på plattformen:
                  </span>
                </div>
            </strong>
            <ul>

                <li className="termsofuse-link">
                  Det är ej tillåtet med annonser innehållande förfalskade mynt
                  och sedlar, piratkopior och förfalskningar av t.ex
                  märkesprodukter, konst, CD-skivor eller DVD-filmer,
                  programvaror för datorer eller TV- apparater etc.
                </li>

                <li className="termsofuse-link">
                  Annonser om donationer eller hjälporganisationer.
                </li>

                <li className="termsofuse-link">Livsmedel.</li>

                <li className="termsofuse-link">
                  Alkohol, narkotika och tobaksvaror.
                </li>

                <li className="termsofuse-link">Läkemedel.</li>

                <li className="termsofuse-link">Kosttillskott.</li>

                <li className="termsofuse-link">
                  Föremål med nazistiska, kommunistiska eller andra totalitära
                  symboler och kännetecken.
                </li>

                <li className="termsofuse-link">
                  Produkter med pornografiskt innehåll eller innebörd.
                </li>

                <li className="termsofuse-link">
                  Bilder och filmer av karaktär som vi bedömer är ägnad att vara
                  kränkande tillåts inte.
                </li>

                <li className="termsofuse-link">
                  Sprängämnen och pyrotekniskt material, skjutvapen, ammunition
                  vars ägande eller förvärv kräver ett tillstånd eller
                  registrering, samt eldkastare med peppargas etc får ej
                  utannonseras.
                </li>

                <li className="termsofuse-link">
                  Varor som kommer från stöld, häleri, rån eller annat brott
                  eller vars import, export eller förvarande är förbjudet genom
                  gällande svensk lagstiftning.
                </li>

                <li className="termsofuse-link">
                  Vi godkänner inga annonser innehållande lik, likrester,
                  djurrester, mänskliga organ eller djurorgan.
                </li>
            </ul>
          </div>
          <div className="termsofuse-general-container">
            <strong>
              <div className="termsofuse-block-title">
                  <span id="agare">Plattformens ägare</span>
                </div>
            </strong>
            <ul>
                <li className="termsofuse-link">
                  Plattformens ägare är Citiboard AB med säte i Halmstad,
                  Sverige. Momsnr: 559165-0568.
                </li>
            </ul>
          </div>
          <div className="termsofuse-general-container">
            <strong>
              <div className="termsofuse-block-title">
                  <span id="fragor">Synpunkter och frågor</span>
                </div>
            </strong>
            <ul>
                <li className="termsofuse-link">
                  Vi kommer att ta bort samtliga frågor och synpunkter från
                  annonstavlan. Vi hänvisar frågor och eventuella synpunkter
                  till kontakt, som ni finner längst ner på sidan. Om du
                  fortfarande saknar svar på någon av dina frågor, så ber vi dig
                  skicka oss ett mail så besvarar vi din fråga inom 24 timmar.
                </li>

                <li className="termsofuse-link">
                  Vid synpunkter bifoga förnamn, efternamn samt detaljerad
                  beskrivning och kontaktorsak.
                </li>

                <li className="termsofuse-link">
                  Bolaget utvärderar synpunkter skyndsamt. Vanligtvis svarar
                  Citiboard inom sju 7 arbetsdagar från ankomst av meddelande.
                  Ifall det finns frågor eller krävs ytterligare handlingar
                  eller information kan svarstiden komma förlängas.
                </li>
            </ul>
          </div>
          <div className="termsofuse-general-container">
            <strong>
              <div className="termsofuse-block-title">
                  <span id="facebook">
                    Koppling till den sociala domänen Facebook Härefter kallat
                    ”FB”
                  </span>
                </div>
            </strong>
            <ul>
                <li className="termsofuse-link">
                  Användaren är förpliktigad att bruka FB-applikationen i
                  enlighet med användaravtalet avseende Facebook.
                </li>

                <li className="termsofuse-link">
                  Citiboard specificerar information användarna behöver dela med
                  sig av vid ett godkännande av applikationen. Detta sker med
                  hjälp av Facebooks nativa funktioner.
                </li>

                <li className="termsofuse-link">
                  Användare av FB-applikationen förpliktigar användaren att inte
                  använda annan persons användarkonton.
                </li>

                <li className="termsofuse-link">
                  Det är inte heller tillåtet att använda falska FB-konton som
                  inte anger användaren själv.
                </li>

                <>
                  <li className="termsofuse-link">
                    För användare som är kopplade till FB ges följande
                    möjligheter:
                  </li>
                  <ul>
                    <li className="termsofuse-link-link">Förnamn</li>
                    <li className="termsofuse-link-link">Efternamn</li>
                    <li className="termsofuse-link-link">Hemstad</li>
                    <li className="termsofuse-link-link">Mejladress</li>
                    <li className="termsofuse-link-link">Bilder</li>
                  </ul>
                </>

                <li className="termsofuse-link">
                  Användarens e-postadress eller foton kommer aldrig att vara
                  synliga eller publiceras på applikationen eller plattformen.
                </li>

                <li className="termsofuse-link">
                  Citiboard bär inget ansvar för annonsens innehåll, innebörd
                  eller för dess överenstämmelse med Facebooks användarvillkor.
                </li>

                <li className="termsofuse-link">
                  Citiboard bär inget ansvar för återkallelse av användarens
                  upplagda annonser genom FB.
                </li>

                <li className="termsofuse-link">
                  Citiboard bär inget ansvar för av Facebooks beslutade
                  handlingar riktade mot användare på grund av deras annonser,
                  frågor och dess innehåll på Citiboard.
                </li>

                <li className="termsofuse-link">
                  Citiboard bär inget ansvar för Facebooks handlingar riktade
                  mot användarens konto eller svårigheter och dröjsmål i dess
                  tjänster.
                </li>

                <li className="termsofuse-link">
                  Användaren har alltid möjligheten att återkalla rätten till
                  brukande av applikationen enligt föreskrifter och med hjälp av
                  Facebooks mekanismer eller med hjälp av en skriftlig anmälan
                  till Citiboard om återkallat medgivande. I samband med detta
                  försvinner möjligheten att använda plattformen.
                </li>
            </ul>
          </div>
          <div className="termsofuse-general-container">
            <strong>
                <div className="termsofuse-block-title">
                  <span id="personuppgifter">Personuppgifter</span>
                </div>
            </strong>
            <ul>

                <li className="termsofuse-link">
                  När Du registrerar Dig som användare hos Citiboard medför
                  registreringen att Citiboard eller bolag inom samma koncern
                  lagrar och använder dina namn- och adressuppgifter och andra
                  kontaktuppgifter.
                </li>

                <li className="termsofuse-link">
                  De personuppgifter som du lämnar behandlas konfidentiellt och
                  enligt personuppgiftslagen PUL. Kunden har rätt att
                  kostnadsfritt en gång per år ta del av sina personuppgifter
                  som finns registrerade hos oss. Skulle dessa vara felaktiga,
                  ofullständiga eller inte längre aktuella har Kunden rätt att
                  begära att uppgifterna ska rättas till eller raderas.
                </li>

                <li className="termsofuse-link">
                  Användarerns personuppgifter behandlas för följande ändamål:
                  fastställande av identitet, avtalets uppläggning och
                  administration, fakturerings-, reskontra och eventuell
                  inkassohantering samt för kundanalyser. Adressuppgifter
                  och/eller e-postadress eller andra uppgifter om dig som Kund
                  kan komma att användas i marknadsföringssyfte mellan
                  använadern och Citiboard. Citiboard kan som en del i
                  personuppgiftsbehandlingen för nyss angivna ändamål komma att
                  överlämna Kundens personuppgifter till samarbetspartners
                  tredje part. Dina personuppgifter kan också komma att lämnas
                  ut till tredje part vid eventuella rättsliga ärenden.
                </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
