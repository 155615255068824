import * as t from "../actionTypes";

const initialState = [];

const setMessages = (state = initialState, action) => {
  switch (action.type) {
    case t.SET_MESSAGES:
      return action.payload;
    case t.ADD_MESSAGE:
      return [...state, action.payload];
    default:
      return state;
  }
};

export default setMessages;
