import * as t from "../actionTypes";

export const setMessages = (messages) => {
  return {
    type: t.SET_MESSAGES,
    payload: messages,
  };
};

export const addMessage = (message) => {
  return {
    type: t.ADD_MESSAGE,
    payload: message,
  };
};
