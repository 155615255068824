import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const AdsLoading = ({ defaultGrid }) => {
  var loadingads = [];

  for (let i = 0; i < 12; i++) {
    loadingads.push( <Skeleton className={"adLoadingSkeleton " + ( defaultGrid ? "annons-wrapper-sing" : "annons-wrapper-three" )} key={i} /> );
  }

  return (
    <div className="annonserna">
      {loadingads}
    </div>
  );
};

export default AdsLoading;
