import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { withCookies } from "react-cookie";

import axios from "axios";
import { connect } from "react-redux";

import { setMessages } from "../redux/actions/usersInbox";

import ProfileInfo from "../components/ProfileInfo";

import UnixTimeHandler from "../helpers/UnixTimeHandler";
import MessagesAutoScroll from "./MessagesAutoScroll";
import "../assets/scss/pages/messageschatwindowheader.scss";

const Messages = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [loading, setLoading] = useState(true);
  const [clickedID, setClickedID] = useState("");
  const [noMessages, setNoMessages] = useState(false);
  const [message, setMessage] = useState("");
  const [conversationClickedValue, setConversationClickedValue] = useState([]);
  const [showConvoMobile, setShowConvoMobile] = useState(true);
  const [showChatMobile, setShowChatMobile] = useState(true);
  const [isDesktop, setIsDesktop] = useState(false);
  const [viewWidth, setViewWidth] = useState();
  const [liveMessage, setLiveMessage] = useState();
  const [liveMessageInterval, setLiveMessageInterval] = useState(0);
  const isLoggedIn = props.user.user_id !== undefined;
  const messages = props.messages;

  const headers = { "x-auth": cookieId };

  const notLoggedInLocation = {
    pathname: "/inte-inloggad",
    state: { fromMessages: true },
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  useEffect(() => {
    const fetchData = () => {
      try {
        axios
          .get(process.env.REACT_APP_API_DOMAIN + "/cb/msg_threads", {
            headers: headers,
          })
          .then((res) => {
            if (typeof res.data.errors !== 'undefined') {
              setNoMessages(true);
            } else {
              setNoMessages(false);
            }

            props.setMessages(res.data);
          });
      } catch (err) {
        console.log(err.message);
      }
    };

    if (isLoggedIn && messages.length === 0) {
      fetchData();
    }
  }, [isLoggedIn, cookieId, props, headers, messages]);

  const goToAd = (ad_id) => {
    let url = "/annons/" + ad_id;

    return (window.location.href = url);
  };

  useEffect(() => {
    setViewWidth(window.innerWidth);
    !viewWidth <= 1000 ? setIsDesktop(true) : setIsDesktop(false);
  }, [viewWidth]);

  const showConversation = () => {
    setShowConvoMobile(false);
    setShowChatMobile(true);
  };

  const backToConversation = () => {
    window.scrollTo({ top: 0 });
    setShowConvoMobile(true);
    setShowChatMobile(false);
  };

  const handleClicked = (ad_id, thread_id) => {
    if (window.innerWidth < 800) {
      showConversation();
    }
    try {
      axios
        .get(process.env.REACT_APP_API_DOMAIN + "/cb/msgs?thread_id=" + thread_id, {
          headers: headers,
          thread_id: thread_id,
        })
        .then((res) => {
          setConversationClickedValue(res.data);
          MessagesAutoScroll();
        });
    } catch (err) {
      console.log(err.message);
    }
    setClickedID(ad_id);
    setLiveMessage(thread_id);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setLiveMessageInterval((liveMessageInterval) => liveMessageInterval + 1);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if( liveMessage !== undefined ) {
      try {
        axios
          .get(process.env.REACT_APP_API_DOMAIN + "/cb/msgs?thread_id=" + liveMessage, {
            headers: headers,
            thread_id: liveMessage,
          })
          .then((res) => {
            setConversationClickedValue(res.data);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [liveMessageInterval]);

  const messageInput = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = (thread_id) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_DOMAIN + "/cb/msg_reply",
          {
            thread_id: thread_id,
            content: message,
          },
          { headers: headers }
        )
        .then((res) => {
          setConversationClickedValue((prevState) => [...prevState, res.data]);
        });
    } catch (err) {
      console.log(err.message);
    }
    setMessage("");
  };

  const handleSendMessageOnEnterKey = (thread_id, event) => {
    if (event.key === "Enter") {
      handleSendMessage(thread_id);
    }
  };

  if (cookieId === "" && !isLoggedIn) {
    return <Navigate to={notLoggedInLocation} />;
  }

  const isYou = (user_id) => {
    return user_id === props.user.user_id;
  };

  return (
    <div className="my-page-container mainwrapper">
      <div className="my-page-wrapper">
        <ProfileInfo />
        <div className="my-page-ads-wrapper">
          <div className="messenger">
            {loading ? (
              <Skeleton
                variant="rect"
                width={350}
                height={500}
                style={{ marginRight: 10, marginLeft: 10 }}
              />
            ) : (
              showConvoMobile === true && (
                <div className="chatMenu" id="chatMenuId">
                  <h1>Meddelanden</h1>
                  <div className="chatMenuWrapper">
                    {noMessages ? (
                      <h4>Det ser ut som du inte har några meddelanden</h4>
                    ) : (
                      <ul className="conversation-ulist-svante">
                        {messages.map((ad, index) => (
                          <li className="conversation-svante" key={index} onClick={() => handleClicked(ad.annons_id, ad.thread_id) }>
                              <img
                                src={(ad.thumb === "" ? "https://media.citiboard.se/layout/no_photo.png" : "data:image/jpeg;base64, " + ad.thumb)}
                                className="conversationImg-svante"
                                alt="ad"
                                onClick={() => goToAd(ad.annons_id)}
                              />
                              <div className="conversationDataWrapper">
                                <div className="conversationName-svante">
                                  {ad.rubrik}
                                </div>
                                <div className="adListName">
                                  {ad.convo_name}
                                </div>
                              </div>
                              {ad.unread > 0 ? (
                                <div className="message-unread">
                                  <span>{ad.unread}</span>
                                </div>
                              ) : (
                                ""
                              ) }
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )
            )}

            {loading ? (
              <Skeleton
                variant="rect"
                width={500}
                height={500}
                style={{ marginLeft: 30 }}
              />
            ) : (
              showChatMobile === true &&
              isDesktop === true && (
                <div className="chatBox-svante" id="chatBox-svante-id">
                  <div className="chatBoxWrapper" id="chatBoxWrapper-id">
                    {clickedID !== "" ? (
                      messages.map((ad, index) =>
                        ad.annons_id === clickedID ? (
                          <div className="chatBoxTop-svante" key={index}>
                            <div className="chat_header-wrapper">
                              <div
                                className="chat_header_container"
                                id="chat_header_container-id"
                              >
                                <div className="chat_header_container-profilepicture">
                                  <img
                                    src={
                                      (ad.thumb === "" ? "https://media.citiboard.se/layout/no_photo.png" : "data:image/jpeg;base64, " + ad.thumb)
                                    }
                                    className="conversationImg-svante"
                                    alt="ad"
                                    onClick={() => goToAd(ad.annons_id)}
                                  />
                                </div>
                                <div>
                                  <div className="chat_header_container-name-adress">
                                    {ad.convo_name}
                                  </div>
                                  <div className="chat_header_container-profilepicture-go-text">
                                    <p className="hideForDesktop"
                                      onClick={() => backToConversation()}
                                    >
                                      &laquo; Åter till konversationer
                                    </p>
                                    <p className="align-right"
                                      onClick={() => goToAd(ad.annons_id)}
                                    >
                                      Gå till annons &raquo;
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {conversationClickedValue.map(
                              (message, index) => (
                                <div
                                  key={index}
                                  className={
                                    isYou(message.sender_uid)
                                      ? "message own"
                                      : "message"
                                  }
                                >
                                  <p className="messageText">
                                    {message.content}
                                  </p>
                                  <span
                                    className={
                                      isYou(message.sender_uid)
                                        ? "my-date"
                                        : "yours-date"
                                    }
                                  >
                                    {UnixTimeHandler(message.message_time)}
                                  </span>
                                </div>
                              )
                            )}
                            <div className="chatBoxBottom">
                              <textarea
                                className="chatMessageInput"
                                onChange={(e) => messageInput(e)}
                                value={message}
                                onKeyPress={(e) =>
                                  handleSendMessageOnEnterKey(
                                    ad.thread_id,
                                    e
                                  )
                                }
                                placeholder="skriv något..."
                              ></textarea>
                              <button
                                className="chatSubmitButton"
                                onClick={() =>
                                  handleSendMessage(ad.thread_id)
                                }
                              >
                                Skicka
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )
                      )
                    ) : (
                      <h5>Klicka på en konversation för att läsa den</h5>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    messages: state.messages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessages: (messages) => dispatch(setMessages(messages)),
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(Messages)
);
