import * as t from "../actionTypes";

const initialState = {
  activeAds: [],
  inActiveAds: [],
};

const fetchMyAds = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCH_MY_ADS:
      return {
        ...state,
        activeAds: action.payload,
      };
    case t.FETCH_MY_INACTIVATED_ADS:
      return {
        ...state,
        inActiveAds: action.payload,
      };
    case t.INACTIVE_MY_AD:
      return {
        ...state,
        activeAds: state.activeAds.filter(
          (ad) => ad.annons_id !== action.payload.annons_id
        ),
        inActiveAds: [...state.inActiveAds, action.payload],
      };
    case t.REMOVE_INACTIVE_AD:
      return {
        ...state,
        inActiveAds: [
          ...state.inActiveAds.filter(
            (ad) => ad.annons_id !== action.payload.annons_id
          ),
        ],
      };
    default:
      return state;
  }
};

export default fetchMyAds;
