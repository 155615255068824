import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";

const NotLoggedIn = (props) => {
  const [loading, setLoading] = useState(true);
  const isLoggedIn = props.user.user_id !== undefined;

  const loginLocation = {
    pathname: "/loggain",
    state: { fromNotLoggedIn: true },
  };

  const mypageLocation = {
    pathname: "/min-sida",
    state: { NotLoggedIn: true },
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  if (isLoggedIn) {
    return <Navigate to={mypageLocation} />;
  }

  return (
    <div className="notloggedin mainwrapper">
      <div className="notloggedin-container">
        <div className="notloggedin-title-container">
          <h3>{loading ? <Skeleton variant="text" /> : "Hoppsan!"}</h3>
        </div>
        <div className="notloggedin-content">
          <div className="notloggedin-wrapper">
            {loading ? (
              <div className="notloggedin-block">
                <div className="notloggedin-block-title">
                  <Skeleton variant="text" width={350} />
                  <Skeleton variant="text" width={400} />
                </div>
              </div>
            ) : (
              <div className="notloggedin-block">
                <div className="notloggedin-block-title">
                  <span>Det verkar som att du inte är inloggad.</span>
                  <br />
                  <span>
                    För den här sidan måste du vara en inloggad användare.
                  </span>
                </div>
              </div>
            )}

            <div className="notloggedin-button-container">
              {loading ? (
                <Skeleton
                  variant="rect"
                  width={250}
                  height={40}
                  style={{ paddingLeft: 10 }}
                />
              ) : (
                <Link
                  to={loginLocation}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Button className="registerlogin">
                    Logga in eller skapa konto här
                  </Button>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotLoggedIn);
