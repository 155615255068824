import React from "react";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useMediaQuery } from "react-responsive";

const Pagination = ({ adsPerPage, totalAds, paginate, currentPage, props }) => {
  const pageNumbers = [];
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 759px)" });
  const range = isTabletOrMobile ? 4 : 8;

  const lastPage = Math.ceil(totalAds / adsPerPage);

  for (let i = 1; i <= lastPage; i++) {
    pageNumbers.push(i);
  }

  const isShowing = (number) => {
    if (number >= currentPage - Math.ceil(range / 2) && number <= currentPage + Math.ceil(range / 2) ) {
      return true;
    }
    return false;
  };

  const isShowingDotsAfter = () => {
    const gap = lastPage - 5;
    if (currentPage <= gap) {
      return true;
    }
    return false;
  };

  return (
    <div className="pagination-container">
      <div
        className="pagination-before-button"
        onClick={() => paginate(currentPage === 1 ? 1 : currentPage - 1)}
      >
        <ArrowLeftIcon />
      </div>
      <div className="pagination-wrapper">
        {currentPage > Math.ceil( range/2 ) + 1 ? (
          <div className="pagination-block firstPage" onClick={() => paginate(1)}>
            1
          </div>
        ) : (
          ""
        )}
        {currentPage > range && !isTabletOrMobile ? (
          <div
            className="pagination-block"
            onClick={
              currentPage > range
                ? () => paginate(1)
                : () => paginate(currentPage - Math.ceil( range/2 ) )
            }
          >
            ...
          </div>
        ) : (
          ""
        )}
        {pageNumbers.map((number) => (
          isShowing(number) ? (
            <div key={number}>
              <div
                className={
                  currentPage === number
                    ? "pagination-block-active"
                    : "pagination-block"
                }
                onClick={() => paginate(number)}
              >
                {number}
                </div>
              </div>
            ) : (
              ""
            )
        ))}
        {isShowingDotsAfter() && !isTabletOrMobile ? (
          <div
            className="pagination-block"
            onClick={() => paginate(currentPage + Math.ceil( range/2 ))}
          >
            ...
          </div>
        ) : (
          ""
        )}
        {currentPage !== lastPage && !isShowing(lastPage) ? (
          <div className="pagination-block lastPage" onClick={() => paginate(lastPage)}>
            {lastPage}
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className="pagination-next-button"
        onClick={() =>
          paginate(currentPage === lastPage ? lastPage : currentPage + 1)
        }
      >
        <ArrowRightIcon />
      </div>
    </div>
  );
};

export default Pagination;
