import * as t from "../actionTypes";

const initialState = [];

const fetchAllAds = (state = initialState, action) => {
  switch (action.type) {
    case t.FETCH_ALL_ADS:
      return action.payload;
    default:
      return state;
  }
};

export default fetchAllAds;
