import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import { withCookies } from "react-cookie";

import DialogComponent from "../components/DialogComponent";

import ValidateMail from "../helpers/ValidateMail";

const ContactUs = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [loading, setLoading] = useState(true);
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [senderPhone, setSenderPhone] = useState("");
  const [content, setContent] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [messageFailed, setMessageFailed] = useState(false);
  const [mailValidation, setMailValidation] = useState(false);

  var headers = {
    "x-auth": cookieId,
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  const closeAfterSent = () => {
    setSenderName("");
    setSenderEmail("");
    setSenderPhone("");
    setContent("");
    setMessageSent(false);
  };

  const closeAllModals = () => {
    setMessageSent(false);
    setMessageFailed(false);
    setMailValidation(false);
  };

  const handleSenderName = (e) => {
    setSenderName(e.target.value);
  };

  const handleSenderEmail = (e) => {
    setSenderEmail(e.target.value);
  };

  const handleSenderPhone = (e) => {
    setSenderPhone(e.target.value);
  };

  const handleContent = (e) => {
    setContent(e.target.value);
  };

  const handleRequest = (e) => {
    e.preventDefault();
    var obj = {
      senderName,
      senderEmail,
      senderPhone,
      content,
    };

    if (senderName !== "" && senderEmail !== "" && content !== "") {
      if (ValidateMail(senderEmail)) {
        try {
          axios
            .post(process.env.REACT_APP_API_DOMAIN + "/cb/kontakt", obj, {
              headers: headers,
            })
            .then((res) => {
              if (res.data === "🎉") {
                setMessageSent(true);
              }
            });
        } catch (err) {
          console.log(err.message);
        }
      } else {
        setMailValidation(true);
      }
    } else {
      setMessageFailed(true);
    }
  };

  return (
    <div className="contactus-container mainwrapper">
      <div className="contactus-wrapper">
        <div className="contactus-title-container">
          <h1 className="contactus-title">
            {loading ? (
              <Skeleton variant="text" width={50} height={50} />
            ) : (
              <span>Kontakta oss</span>
            )}
          </h1>
        </div>
        <div className="contactus-content-container">
          {loading ? (
            <Skeleton variant="text" width={400} height={30} />
          ) : (
            <div className="contactus-content-info">
              <span>Fält med blå markering är obligatoriska.</span>
            </div>
          )}
          <form>
            {loading ? (
              <Skeleton variant="text" width={500} height={30} />
            ) : (
              <input
                type="text"
                className="contactus-name contactus-required"
                autoComplete="name"
                placeholder="Namn"
                onChange={(e) => handleSenderName(e)}
                value={senderName}
              />
            )}
            {loading ? (
              <Skeleton variant="text" width={500} height={40} />
            ) : (
              <input
                type="text"
                className="contactus-email contactus-required"
                autoComplete="email"
                placeholder="Mejladress"
                onChange={(e) => handleSenderEmail(e)}
                value={senderEmail}
              />
            )}
            {loading ? (
              <Skeleton variant="text" width={500} height={40} />
            ) : (
              <input
                type="text"
                className="contactus-phone"
                placeholder="Telefon"
                autoComplete="tel"
                onChange={(e) => handleSenderPhone(e)}
                value={senderPhone}
              />
            )}
            {loading ? (
              <Skeleton variant="text" width={500} height={130} />
            ) : (
              <textarea
                className="contactus-message contactus-required"
                placeholder="Meddelande"
                onChange={(e) => handleContent(e)}
                value={content}
              />
            )}
            {loading ? (
              <Skeleton variant="text" width={100} height={50} />
            ) : (
              <button
                className="contactus-button"
                onClick={(e) => handleRequest(e)}
              >
                Skicka
              </button>
            )}
          </form>
        </div>
      </div>
      {messageFailed ? (
        <DialogComponent
          text="Meddelandet kunde inte skickas. Kontrollera så att alla fält med blå markering är ifyllda."
          title="Något gick fel!"
          onClose={closeAllModals}
        />
      ) : messageSent ? (
        <DialogComponent
          text="Tack för ditt meddelande, vi kommer att återkomma till dig så snart vi kan."
          title="Ditt meddelande har skickats."
          onClose={closeAfterSent}
        />
      ) : mailValidation ? (
        <DialogComponent
          text="Det verkar som att din mejladress inte är giltig. Försök igen."
          title="Mejladresss ogiltig"
          onClose={closeAllModals}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default withCookies(ContactUs);
