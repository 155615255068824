import React, { useState, useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const Cookies = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  return (
    <div className="cookies-container mainwrapper">
      <div className="cookies-wrapper">
        <div className="cookies-title-container">
          <h1 className="cookies-title">
            {loading ? (
              <Skeleton variant="text" width={250} height={50} />
            ) : (
              <span>Cookies</span>
            )}
          </h1>
        </div>
        <div className="cookies-content-container">
          <div className="cookies-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="cookies-block-title">
                  <span>Så använder vi cookies</span>
                </div>
              )}
            </strong>
            {loading ? (
              <Skeleton variant="text" width={700} height={20} />
            ) : (
              <span>
                Citiboard använder cookies när du besöker sajten, dessa är
                nödvändiga för att användaren ska kunna använda Citiboard och
                dess funktioner enkelt och på återkommande basis. En cookie är
                en liten informationsfil som placeras i användarens dator.
                Cookien innehåller ingen personlig information eller virus och
                den kan inte heller förstöra annan information på din dator. Du
                kan alltså vara helt trygg när du besöker Citiboard.
              </span>
            )}
          </div>
          <div className="cookies-general-container">
            <strong>
              {loading ? (
                <Skeleton variant="text" width={100} height={20} />
              ) : (
                <div className="cookies-block-title">
                  <span>Ändamål</span>
                </div>
              )}
            </strong>
            <ul>
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="cookies-link">
                  Låta systemet känna igen återkommande användare för att kunna
                  anpassa tjänsterna.
                </li>
              )}

              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="cookies-link">
                  Underlätta navigationen på webbplatsen.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="cookies-link">
                  Minska reklam som kan vara irrelevant.
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="cookies-link">
                  Utveckla och förbättra webbplatsen genom att förstå hur den
                  används
                </li>
              )}
              {loading ? (
                <Skeleton variant="text" width={700} height={20} />
              ) : (
                <li className="cookies-link">
                  Segmentera målgrupper i marknadsföringssyfte för att kunna
                  rikta annonser.
                </li>
              )}
            </ul>
            <br />
            {loading ? (
              <Skeleton variant="text" width={700} height={20} />
            ) : (
              <span>
                Om du vill kan du stänga av cookie-funktionen i din webbläsare.
                Det gör du genom att gå in i säkerhetsinställningarna i din
                webbläsare. Det kan dock innebära att du inte kan använda vissa
                tjänster på Citiboard nu eller i framtiden.
                <br />
                Om du har några frågor om denna hantering är du alltid välkommen
                att kontakta oss på: kontakt@citiboard.com
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
