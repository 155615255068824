import React, { useState } from "react";
import { Link } from "react-router-dom";

import error404 from "../images/404.jpg";

const NotFound = () => {
  const [imgURL] = useState(error404);

  const homeLocation = {
    pathname: "/",
    state: { fromNotFound: true },
  };

  return (
    <div className="notfound">
      <div className="notfound-container mainwrapper">
        <div className="notfound-title-container">
          <h3>Något gick fel!</h3>
        </div>
        <div className="notfound-content">
          <div className="notfound-wrapper">
            <div className="notfound-image-container">
              <img src={imgURL} className="notfound-image" alt="404 error" />
            </div>
            <div className="notfound-block">
              <div className="notfound-block-title">
                <span>Sidan kan inte visas</span>
              </div>
              <span>
                Det verkar som att sidan du sökte på inte hittades.{" "}
              </span>
              <br />
              <span>
                {" "}
                Sidan eller annonsen finns inte eller har blivit borttagen.
                Försök igen.{" "}
              </span>
              <br />
            </div>

            <div className="notfound-button-container">
                <Link
                  to={homeLocation}
                  className="notfound-button"
                >
                  Gå tillbaka till startsidan
                </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
