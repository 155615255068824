import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import InfoIcon from "@material-ui/icons/Info";
import RowView from "@material-ui/icons/DnsOutlined";
import GridView from "@material-ui/icons/AppsOutlined";

import axios from "axios";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";

import { fetchAllAds } from "../redux/actions/fetchAllAds";

import BannerHandler from "../helpers/BannerHandler";

import Ads from "../components/Ads";
import AdsLoading from "../components/AdsLoading";
import Pagination from "../components/Pagination";
import Sidebar from "../components/Sidebar";

const Home = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [defaultGrid, setDefaultGrid] = useState(false);
  const [loading, setLoading] = useState(true);

  const [loadingAds, setLoadingAds] = useState(true);

  const [ads, setAds] = useState([]);
  const [totalAds, setTotalAds] = useState(0);

  const [adsPerPage] = useState(30);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("");
  const [catHeading, setCatHeading] = useState("Köp och sälj begagnade prylar på Citiboard");
  const [catDescription, setCatDescription] = useState("Annonsera snabbt, enkelt och helt gratis!");

  const location = useLocation();

  useEffect(() => {
    // Ladda bara om location.pathname och window.location.pathname överensstämmer
    //console.log( location.pathname, window.location.pathname );
    if( location.pathname === window.location.pathname ) {
      let pathname = location.pathname;
      const urlParams = new URLSearchParams(location.search);
      const searchWord = urlParams.get("sok") || "";

      setLoadingAds(true);

      try {
        axios
          .get(
            process.env.REACT_APP_API_DOMAIN + "/cb/annonslista?url=" +
              pathname +
              "&sida=" +
              currentPage +
              "&sort=" +
              sortOrder +
              "&sok=" +
              searchWord
          )
          .then((res) => {
            setCurrentPage(1);
            updateSidebar(res.data.filter);
            setAds(res.data.annonser);
            setTotalAds(res.data.totalt);
            document.title = res.data.meta.title.replace("Alla kategorier","Köp och sälj begagnade prylar");
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", res.data.meta.shortdescription);
            setCatHeading( res.data.meta.title.replace("- Gratis annonsering på Citiboard","").replace("Alla kategorier","Köp och sälj begagnade prylar") );
            setCatDescription( res.data.meta.description );
            setLoadingAds(false);
            setLoading(false);
            BannerHandler();
          })
          .catch(() => {
            window.location.assign("/sidan-finns-ej");
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [cookieId, sortOrder, location]);

  useEffect(() => {
    if( !loading ) {
      let pathname = location.pathname;
      const urlParams = new URLSearchParams(location.search);
      const searchWord = urlParams.get("sok") || "";
  
      setLoadingAds(true);
  
      try {
        axios
          .get(
            process.env.REACT_APP_API_DOMAIN + "/cb/annonslista?url=" +
              pathname +
              "&sida=" +
              currentPage +
              "&sort=" +
              sortOrder +
              "&sok=" +
              searchWord
          )
          .then((res) => {
            setAds(res.data.annonser);
            setLoadingAds(false);
            BannerHandler();
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [currentPage]);

  const updateSidebar = ( filter ) => {
    let cats = document.querySelectorAll( "#sidebar-container-categories li" );
    let orts = document.querySelectorAll( "#sidebar-container-orter li" );

    cats.forEach( function( el ) {
      if( filter.kat === el.id ) {
        el.classList.add( "selected" );
      } else {
        el.classList.remove( "selected" );
      }
    } );

    orts.forEach( function( el ) {
      if( filter.ort === el.id ) {
        el.classList.add( "selected" );
      } else {
        el.classList.remove( "selected" );
      }
    } );
  }

  const showTreddyInfo = () => {
    document.querySelector( ".treddyInfo" ).style.display = "block";
    document.querySelector( ".treddyInfoLink" ).style.display = "none";

    return false;
  }

  const paginate = (pageNumber) => {
    setLoadingAds(true);
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
    BannerHandler();
  };

  const handleSort = (e) => {
    setSortOrder(e.target.value);
  };

  return (
    <div className="annons-wrapper mainwrapper">
      <div className="home-banner-container">
        <div className="home-banner-first-wrapper" adunit="101">
          <Skeleton variant="rect" width={1200} height={250} />
        </div>
        <div className="home-banner-first-wrapper" adunit="112">
          <Skeleton variant="rect" width={375} height={120} />
        </div>
      </div>
      {loading ? (
        <Skeleton
          variant="text"
          height={40}
          style={{ width: "100%", maxWidth: "1680px", margin: "1rem auto 0.3rem", transform: "scale(1,1)" }}
        />
      ) : (
        <div className="annons-middle">
          <Link to={"/annonsering"} className="ads-text-link">
            <InfoIcon /> 
            Annonsering
          </Link>
          <div className="annons-sum-container">
            <div className="annons-sum-wrapper">
              <div className="annons-sum-ads">
                <span>Hittade {totalAds} annonser</span>
              </div>
              <div className="annons-sum-published">
                <div className="annons-latest-published">
                  <select id="sort" onChange={(e) => handleSort(e)}>
                    <option value="nyast" defaultValue>
                      Senast inlagda
                    </option>
                    <option value="dyrast">Dyrast</option>
                    <option value="billigast">Billigast</option>
                    <option value="äldst">Äldst</option>
                  </select>
                  <RowView
                    className={"home-basic-grid" + ( defaultGrid ? " active" : "" )}
                    onClick={() => setDefaultGrid(true)}
                  />
                  <GridView
                    className={"home-grid" + ( !defaultGrid ? " active" : "" )}
                    onClick={() => setDefaultGrid(false)}
                  />
                </div>
              </div>
            </div>
          </div>
          <Link to={"/annonsering"} className="ads-text-link">
            <InfoIcon /> 
            Annonsering
          </Link>
        </div>
      )}
      <div className="home-banner-container">
        <div className="home-content">
          <div className="left">
            <Sidebar></Sidebar>
            <div className="home-content-container-banner-left">
              <div adunit="103">
                <Skeleton variant="rect" />
              </div>
            </div>
          </div>
          <div>
            <div className="treddyPush">
              <h4>Säker betalning och frakt i samarbete med <img src="https://media.citiboard.se/layout/treddy-logo.png" />. <a className="treddyInfoLink" href="#" onClick={ () => showTreddyInfo() }>Läs mer</a></h4>
              <div className="treddyInfo">
                Tillsammans med <a href="https://treddy.se/" target="_blank">Treddy</a> kan vi nu erbjuda alla våra annonsörer möjligheten att kostnadsfritt aktivera Treddy - en tjänst som gör det <strong>enkelt</strong> och <strong>säkert</strong> att <strong>betala och frakta dina varor</strong> över hela Sverige.<br/>
                <a href="https://treddy.se/hur-funkar-det" target="_blank">Läs mer om hur det fungerar</a>
              </div>
            </div>
            {/* <div className="home-content-container"> */}
              {ads.length > 0 && !loadingAds ? (
                <Ads ads={ads} loading={loadingAds} defaultGrid={defaultGrid} />
              ) : loadingAds ? (
                <AdsLoading defaultGrid={defaultGrid} />
              ) : (
                <h4>Inga annonser hittades enligt Dina kriterier</h4>
              )}
            {/* </div> */}
          </div>
          <div className="home-content-container-banner-right">
            <div adunit="104">
              <Skeleton variant="rect" />
            </div>
          </div>
        </div>
      </div>
      {loadingAds ? (
        <Skeleton variant="rect" />
      ) : (
        <Pagination
          adsPerPage={adsPerPage}
          totalAds={totalAds}
          paginate={paginate}
          currentPage={currentPage}
          loading={loadingAds}
        />
      )}
      <div className="home-banner-container">
        <div className="home-banner-last-wrapper">
          <div adunit="102">
            <Skeleton
              variant="rect"
              width={1200}
              height={250}
              style={{ maxWidth: "95vw" }}
            />
          </div>
          <div className="home-banner-last-wrapper" adunit="116">
            <Skeleton
              variant="rect"
              width={375}
              height={120}
              style={{ maxWidth: "95vw" }}
            />
          </div>
        </div>
      </div>
      <div className="home-category-description-wrapper">
        <h1>{catHeading}</h1>
        <div className="home-category-description">{catDescription}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allAdsArr: state.fetchAllAds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllAds: (ads) => dispatch(fetchAllAds(ads)),
  };
};

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Home));
