import React from "react";
import { Link } from "react-router-dom";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";

import noImage from "../images/noImage.png";

const mediaUrl = process.env.REACT_APP_MEDIA_DOMAIN + "/y/";

const YrkesAd = ({ ad, idx }) => {
  return (
      <div className="yrkesguide-ad">
        <div>
          <Link
            to={ad.hemsida}
            style={{ color: "inherit", textDecoration: "none" }}
            key={idx}
          >
            <img
              className="yrkesguide-img"
              id="yrkesguide-img"
              src={
                ad.bild === null
                  ? noImage
                  : mediaUrl + ad.bild
              }
              alt={ad.foretag}
            />
          </Link>
        </div>
        <div className="yrkesguide-information">
          <strong>
            {ad.foretag}
          </strong>
          { ad.telefon === "" ? "" : 
              <a href={"tel:" + ad.telefon} >
                <PhoneEnabledIcon /> <span className="phone">{ad.telefon}</span>
              </a>
          }
          { ad.hemsida === "" ? "" : 
              <Link to={ad.hemsida} className="yrkesguide-link">
                  Gå till hemsidan &raquo;
              </Link>
          }
        </div>
      </div>
  );
};

const YrkesAds = ({ ads }) => {
  return (
    <div className="yrkesguide-ads">
      {ads.map((ad, idx) => 
        <YrkesAd
          ad={ad}
          idx={idx}
          key={idx}
        />
      )}
    </div>
  );
};

export default YrkesAds;
