import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
import { withCookies } from "react-cookie";

const Yrkesguider = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));

  const [loadingAds, setLoadingAds] = useState(true);

  const [ads, setAds] = useState([]);

  const location = useLocation();

  useEffect(() => {
    //console.log( location.pathname, window.location.pathname );
    if( location.pathname === window.location.pathname ) {
      setLoadingAds(true);

      try {
        axios
          .get(
            process.env.REACT_APP_API_DOMAIN + "/cb/yrkesguider"
          )
          .then((res) => {
            setAds(res.data);

            document.title = "Yrkesguiden hjälper dig hitta företag lokalt";
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", "Yrkesguiden hjälper dig hitta etablerade företag att hjälpa dig lokalt.");
            setLoadingAds(false);
          })
          .catch((res) => {
            console.log(res);
          });
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [cookieId, location]);

  return (
    <div className="yrkesguide-wrapper">
      <div className="yrkesguide-container yrkesguide-hero">
        <h1>Yrkesguider</h1>
        <span className="ingress" style={{whiteSpace: "pre-wrap"}}>
        Våra Yrkesguider hjälper dig hitta etablerade företag att hjälpa dig lokalt.
        </span>
      </div>
      
      <div className="yrkesguide-container">
        <div className="yrkesguide-content">
          <div className="yrkesguider-lista">
              {ads.length > 0 && !loadingAds ? (
                ads.map( ( ad, adx ) =>
                  <a key={ adx } href={ ad.link }>{ad.rubrik} &raquo;</a>
                )
              ) : loadingAds ? (
                "Laddar yrkesguider..."
              ) : (
                <h4>Inga aktiva yrkesguider hittades</h4>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCookies((Yrkesguider));
