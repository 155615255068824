import React, { useState, useEffect, useRef } from "react";
//import InfoIcon from "@material-ui/icons/Info";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import CancelIcon from "@material-ui/icons/Cancel";
import'../assets/scss/pages/editad.scss'

import { fetchAllAds } from "../redux/actions/fetchAllAds";

import Base64 from "../helpers/Base64";
import BlobUrl from "../helpers/BlobUrl";

import ProfileInfo from "../components/ProfileInfo";
import DialogComponent from "../components/DialogComponent";

import categories from "../dumpdata/categories.js";

import camera from "../images/camera.png";

import PlaceIcon from "@material-ui/icons/Place";

const EditAd = (props) => {
  const [uploadButtonText, setUploadButtonText] = useState("");
  const uploadedImgRef = useRef(-1);
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [loading, setLoading] = useState(true);

  // living --> apartment
  const [livingType, setLivingType] = useState("");
  const [sqm, setSqm] = useState("");
  const [room, setRoom] = useState("");
  
  // vehicles --> car
  const [carModel, setCarModel] = useState("");
  const [gearBox, setGearBox] = useState("");
  const [carYear, setCarYear] = useState("");
  const [carMiles, setCarMiles] = useState("");
  const [carFuel, setCarFuel] = useState("");
  const [horsePower, setHorsePower] = useState("");
  const [carColor, setCarColor] = useState("");
  const [carType, setCarType] = useState("");

  // vehicles --> boat
  const [boatType, setBoatType] = useState("");

  // vehicles --> car equipment
  const [carEquipment, setCarEquipment] = useState("");

  // vehicles --> mc
  const [mc, setMc] = useState("");
  const [mcThings, setMcThings] = useState("");

  // fashion -->  clothes and shoes
  const [womenClothing, setWomenClothing] = useState("");
  const [menClothing, setMenClothing] = useState("");
  const [shoeSize, setShoeSize] = useState("");
  const [womenClothingSize, setWomenClothingSize] = useState("");
  const [menClothingSize, setMenClothingSize] = useState("");

  // home
  const [homeBuild, setHomeBuild] = useState("");
  const [houseThings, setHouseThings] = useState("");

  // hobbies and freetime
  const [horseValue, setHorseValue] = useState("");
  const [huntfishing, setHuntFishing] = useState("");
  const [musicfilmgames, setMusicFilmGames] = useState("");
  const [musicInstruments, setMusicInstruments] = useState("");
  const [sportFreeTime, setSportFreeTime] = useState("");

  // children --> clothes and shoes
  const [childrenShoeSize, setChildrenShoeSize] = useState("");
  const [childrenClothingSizes, setChildrenClotingSizes] = useState("");

  const initialState = ["1", "2", "3", "4", "5"];
  const [noFiles] = useState([initialState]);
  const [adObject, setAdObject] = useState({
    category: "",
    underCategory: "",
    title: "",
    price: "",
    filesArray: [],
    city: "Sverige",
    description: "",
    id: "",
    attributes: [],
  });

  const [filesArrayShow, setFilesArrayShow] = useState([]);

  const [publishMessage, setPublishMessage] = useState(false);
  const [wrongMessage, setWrongMessage] = useState(false);
  const [maxLimitMessage, setMaxLimitMessage] = useState(false);
  const [wrongFormat, setWrongFormat] = useState(false);
  const [infoHelp, setInfoHelp] = useState(false);
  const [disablePost, setDisablePost] = useState(false);

  const pathname = window.location.pathname;
  const adId = pathname.replace("/redigera/", "");

  const adLocation = {
    pathname: "/annons/" + adId,
    state: { fromEditAd: true },
  };

  var headers = {
    "x-auth": cookieId,
  };

  const mediaUrl = process.env.REACT_APP_MEDIA_DOMAIN + "/a/large/";

  // useEffect(() => {
  //   axios.get("https://geoip-db.com/json").then((res) => setIp(res.data.IPv4));
  // }, [ip]);

  useEffect(() => {
    if (adObject.id === "") {
      axios
        .get(process.env.REACT_APP_API_DOMAIN + "/cb/annons.get?annons_id=" + adId, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.error === "Annonsen hittades inte") {
            window.location.replace("/sidan-finns-ej");
          } else {
            setFilesArrayShow(res.data.bilder);
            var headCategory = "";
            if (
              res.data.kategori_id === "312" ||
              res.data.kategori_id === "313" ||
              res.data.kategori_id === "314"
            ) {
              headCategory = "6";
            } else if (
              res.data.kategori_id === "74" ||
              res.data.kategori_id === "80"
            ) {
              headCategory = "7";
            } else if (
              res.data.kategori_id === "42" ||
              res.data.kategori_id === "44"
            ) {
              headCategory = "8";
            } else if (
              res.data.kategori_id === "63" ||
              res.data.kategori_id === "65" ||
              res.data.kategori_id === "66" ||
              res.data.kategori_id === "67" ||
              res.data.kategori_id === "69"
            ) {
              headCategory = "9";
            } else if (
              res.data.kategori_id === "51" ||
              res.data.kategori_id === "49" ||
              res.data.kategori_id === "52" ||
              res.data.kategori_id === "54" ||
              res.data.kategori_id === "56"
            ) {
              headCategory = "12";
            } else if (res.data.kategori_id === "18") {
              headCategory = "13";
            } else {
              headCategory = res.data.kategori_id;
            }
            setAdObject((prevState) => ({
              ...prevState,
              category: headCategory,
              underCategory: res.data.kategori_id,
              title: res.data.rubrik,
              price: res.data.pris,
              filesArray: res.data.bilder,
              city: res.data.ort_id,
              description: res.data.info,
              id: res.data.annons_id,
              attributes: res.data.attribut,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
          window.location.replace("/sidan-finns-ej");
        });
    }
  }, [adId, adObject, headers]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  useEffect(() =>{
    //Fordon
    setCarFuel(adObject.attributes.carFuel)
    setCarModel(adObject.attributes.carModel)
    setGearBox(adObject.attributes.gearBox)
    setCarYear(adObject.attributes.carYear)
    setHorsePower(adObject.attributes.horsePower)
    setCarMiles(adObject.attributes.carMiles)
    setCarColor(adObject.attributes.carColor)
    setCarType(adObject.attributes.carType)
    setCarEquipment(adObject.attributes.carEquipment)

    // vehicles --> boat
    setBoatType(adObject.attributes.boatType)

    // living --> apartment
    setLivingType(adObject.attributes.livingType)
    setSqm(adObject.attributes.sqm)
    setRoom(adObject.attributes.livingForm)

    // vehicles --> mc
    setMc(adObject.attributes.mc)
    setMcThings(adObject.attributes.mcThings)

    // fashion -->  clothes and shoes
    setShoeSize(adObject.attributes.shoeSize)
    setWomenClothingSize(adObject.attributes.womenClothing)
    setMenClothingSize(adObject.attributes.menClothing)

    // home
    setHomeBuild(adObject.attributes.homeBuild)
    setHouseThings(adObject.attributes.houseThings)

    // hobbies and freetime
    setHorseValue(adObject.attributes.horseValue)
    setHuntFishing(adObject.attributes.huntfishing)
    setMusicFilmGames(adObject.attributes.musicfilmgames)
    setMusicInstruments(adObject.attributes.musicInstruments)
    setSportFreeTime(adObject.attributes.sportFreeTime)

    // children --> clothes and shoes
    setChildrenShoeSize(adObject.attributes.childrenShoeSize)
    setChildrenClotingSizes(adObject.attributes.childrenClothingSizes)

  },[adObject])

  const handleMultipleFiles = (e) => {
    e.persist();
    if (
      e.target.files.length > 5 ||
      adObject.filesArray.length + e.target.files.length > 5
    ) {
      setMaxLimitMessage(true);
      return;
    }

    for (let i = 0; i < e.target.files.length; i++) {
      var file = e.target.files[i];
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/jpg"
      ) {
        setWrongFormat(true);
        return;
      }
      Base64(file).then((data) => {
        setAdObject((prevState) => ({
          ...prevState,
          filesArray: [...prevState.filesArray, data],
        }));
      });
      setFilesArrayShow((prevState) => [
        ...prevState,
        BlobUrl(e.target.files[i]),
      ]);
    }
  };

  const changeToFirstImage = (clickedIndex) => {
    const firstImageIndex = 0;
    const updatedFilesArrayForDb = Object.assign([...adObject.filesArray], {
      [firstImageIndex]: adObject.filesArray[clickedIndex],
      [clickedIndex]: adObject.filesArray[firstImageIndex],
    });
    setAdObject((prevState) => ({
      ...prevState,
      filesArray: updatedFilesArrayForDb,
    }));

    const updatedFilesArrayForShow = Object.assign([...filesArrayShow], {
      [firstImageIndex]: filesArrayShow[clickedIndex],
      [clickedIndex]: filesArrayShow[firstImageIndex],
    });
    setFilesArrayShow(updatedFilesArrayForShow);
  };

  const removeImage = (e, clickedIndex) => {
    e.stopPropagation();
    const updatedFilesArrayForDb = adObject.filesArray.filter(
      (file, index) => file[index] !== file[clickedIndex]
    );
    setAdObject((prevState) => ({
      ...prevState,
      filesArray: updatedFilesArrayForDb,
    }));

    var updatedFilesArrayForShow = [];
    for (let i = 0; i < filesArrayShow.length; i++) {
      if (i !== clickedIndex) {
        updatedFilesArrayForShow.push(filesArrayShow[i]);
      }
    }
    setFilesArrayShow(updatedFilesArrayForShow);
  };

  const handleCategory = (e) => {
    e.persist();
    setAdObject((prevState) => ({
      ...prevState,
      category: e.target.value,
    }));
  };

  const handleTitle = (e) => {
    e.persist();
    setAdObject((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  const handlePrice = (e) => {
    e.persist();
    setAdObject((prevState) => ({
      ...prevState,
      price: e.target.value,
    }));
  };

  const handleCity = (e) => {
    e.persist();
    setAdObject((prevState) => ({
      ...prevState,
      city: e.target.value,
    }));
  };

  const handleDescription = (e) => {
    e.persist();
    setAdObject((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
  };

  const handleUnderCategory = (e) => {
    e.persist();
    setAdObject((prevState) => ({
      ...prevState,
      underCategory: e.target.value,
    }));
  };

  const handleByggCategory = (e) => {
    e.preventDefault();
    setHomeBuild(e.target.value);
  };

  const handleHouseThings = (e) => {
    e.preventDefault();
    setHouseThings(e.target.value);
  };

  const handleHorseValue = (e) => {
    e.preventDefault();
    setHorseValue(e.target.value);
  };

  const handleHuntFishing = (e) => {
    e.preventDefault();
    setHuntFishing(e.target.value);
  };

  const handleMusicFilmGames = (e) => {
    e.preventDefault();
    setMusicFilmGames(e.target.value);
  };

  const handleInstruments = (e) => {
    e.preventDefault();
    setMusicInstruments(e.target.value);
  };

  const handlSportFreeTime = (e) => {
    e.preventDefault();
    setSportFreeTime(e.target.value);
  };

  const handleCarEquipment = (e) => {
    e.preventDefault();
    setCarEquipment(e.target.value);
  };

  const handleMcThings = (e) => {
    e.preventDefault();
    setMcThings(e.target.value);
  };

  const handleMc = (e) => {
    e.preventDefault();
    setMc(e.target.value);
  };

  const handleLivingType = (e) => {
    e.preventDefault();
    setLivingType(e.target.value);
  };

  const handleSqm = (e) => {
    e.preventDefault();
    setSqm(e.target.value);
  };

  const handleRoom = (e) => {
    e.preventDefault();
    setRoom(e.target.value);
  };

  const handleCarModel = (e) => {
    e.preventDefault();
    setCarModel(e.target.value);
  };

  const handleGearBox = (e) => {
    e.preventDefault();
    setGearBox(e.target.value);
  };

  const handleCarYear = (e) => {
    e.preventDefault();
    setCarYear(e.target.value);
  };

  const handleCarMiles = (e) => {
    e.preventDefault();
    setCarMiles(e.target.value);
  };

  const handleCarFuel = (e) => {
    e.preventDefault();
    setCarFuel(e.target.value);
  };

  const handleHorsePower = (e) => {
    e.preventDefault();
    setHorsePower(e.target.value);
  };

  const handleCarType = (e) => {
    e.preventDefault();
    setCarType(e.target.value);
  };

  const handleCarColor = (e) => {
    e.preventDefault();
    setCarColor(e.target.value);
  };

  const handleBoatType = (e) => {
    e.preventDefault();
    setBoatType(e.target.value);
  };

  const handleWomenClothing = (e) => {
    e.preventDefault();
    setWomenClothing(e.target.value);
  };

  const handleShoeSize = (e) => {
    e.preventDefault();
    setShoeSize(e.target.value);
  };

  const handleWomenClothingSize = (e) => {
    e.preventDefault();
    setWomenClothingSize(e.target.value);
  };

  const handleMenClothing = (e) => {
    e.preventDefault();
    setMenClothing(e.target.value);
  };

  const handleMenClothingSize = (e) => {
    e.preventDefault();
    setMenClothingSize(e.target.value);
  };

  const handleChildrenClothingSizes = (e) => {
    e.preventDefault();
    setChildrenClotingSizes(e.target.value);
  };

  const handleChildrenShoeSize = (e) => {
    e.preventDefault();
    setChildrenShoeSize(e.target.value);
  };

  const closeModal = () => {
    setPublishMessage(false);
    setMaxLimitMessage(false);
    setInfoHelp(false);
    setWrongMessage(false);
    setWrongFormat(false);
  };

  const accept = () => {
    closeModal();
    window.location.replace(adLocation.pathname);
  };

  const requestPosition = (e) => {
    e.preventDefault();
    navigator.geolocation.getCurrentPosition(usePosition);
  }

  const usePosition = (pos) => {
    try {
      axios
        .get(process.env.REACT_APP_API_DOMAIN + "/cb/getNearestOrt?lat=" + pos.coords.latitude + "&lon=" + pos.coords.longitude + "&geturl=false")
        .then((res) => {
          // Loopa igenom listan med orter och sätt selected på rätt ort
          let orter = document.getElementById("orter");
          let position = res.data;

          orter.value = position;
        });
    } catch (err) {
      console.log(err.message);
    }
  }

  const validateForm = () => {
    const standardForm =
      adObject.category === "" ||
      adObject.title === "" ||
      adObject.filesArray.length === 0 ||
      adObject.price === "" ||
      adObject.city === "Sverige" ||
      adObject.description === "";

    const fashionForm =
      (adObject.category === "6" && adObject.underCategory === "") ||
      (adObject.category === "6" &&
        adObject.underCategory === "312" &&
        womenClothing === "") ||
      (adObject.category === "6" &&
        adObject.underCategory === "312" &&
        womenClothing !== "333" &&
        womenClothingSize === "") ||
      (adObject.category === "6" &&
        adObject.underCategory === "312" &&
        womenClothing === "333" &&
        shoeSize === "") ||
      (adObject.category === "6" &&
        adObject.underCategory === "313" &&
        menClothing === "") ||
      (adObject.category === "6" &&
        adObject.underCategory === "313" &&
        menClothing !== "314" &&
        menClothingSize === "") ||
      (adObject.category === "6" &&
        adObject.underCategory === "313" &&
        menClothing === "314" &&
        shoeSize === "");

    const homeInteriorForm =
      (adObject.category === "7" && adObject.underCategory === "") ||
      (adObject.category === "7" && adObject.underCategory === "");

    const childrenForm =
      (adObject.category === "8" && adObject.underCategory === "") ||
      (adObject.category === "8" &&
        adObject.underCategory === "42" &&
        childrenClothingSizes === "") ||
      (adObject.category === "8" &&
        adObject.underCategory === "44" &&
        childrenShoeSize === "");

    const hobbiesForm =
      adObject.category === "9" && adObject.underCategory === "";

    const electronicForm =
      adObject.category === "10" && adObject.underCategory === "";

    const petForm = adObject.category === "11" && adObject.underCategory === "";

    const vehiclesForm =
      (adObject.category === "12" && adObject.underCategory === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "49" &&
        boatType === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "51" &&
        carModel === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "51" &&
        carFuel === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "51" &&
        gearBox === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "51" &&
        carYear === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "51" &&
        horsePower === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "51" &&
        carMiles === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "51" &&
        carType === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "51" &&
        carColor === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "54" &&
        carType === "") ||
      (adObject.category === "12" &&
        adObject.underCategory === "56" &&
        carType === "");

    const livingForm =
      (adObject.category === "13" && adObject.underCategory === "") ||
      (adObject.category === "13" &&
        adObject.underCategory === "18" &&
        livingType === "") ||
      (adObject.category === "13" &&
        adObject.underCategory === "18" &&
        sqm === "") ||
      (adObject.category === "13" &&
        adObject.underCategory === "18" &&
        room === "");

    if (
      standardForm ||
      fashionForm ||
      homeInteriorForm ||
      childrenForm ||
      hobbiesForm ||
      electronicForm ||
      petForm ||
      vehiclesForm ||
      livingForm
    ) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    uploadedImgRef.current = uploadedImgRef.current + 1;
    !uploadedImgRef.current == 1
      ? setUploadButtonText("Ladda upp bild")
      : setUploadButtonText("Ladda upp fler bilder");
  }, [adObject.filesArray]);

  const submitAd = () => {
    setDisablePost(true);

    var dataObj = {
      category: adObject.category,
      title: adObject.title,
      price: adObject.price,
      filesArray: adObject.filesArray,
      city: adObject.city,
      description: adObject.description,
      id: adObject.id,
      attributes: {},
    };
    if (validateForm()) {
      // Mode
      if (adObject.category === "6") {
        if (adObject.underCategory === "312" && womenClothing === "333") {
          dataObj.category = womenClothing;
          dataObj.attributes.shoeSize = shoeSize;
        } else if (
          adObject.underCategory === "312" &&
          womenClothing !== "333" &&
          womenClothing !== ""
        ) {
          dataObj.category = womenClothing;
          dataObj.attributes.womenClothingSize = womenClothingSize;
        } else if (adObject.underCategory === "313" && menClothing === "314") {
          dataObj.category = menClothing;
          dataObj.attributes.shoeSize = shoeSize;
        } else if (
          adObject.underCategory === "313" &&
          menClothing !== "314" &&
          menClothing !== ""
        ) {
          dataObj.category = menClothing;
          dataObj.attributes.menClothingSize = menClothingSize;
        }
        // Hem & Inredning
      } else if (adObject.category === "7") {
        if (adObject.underCategory === "74") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.homeBuild = homeBuild;
        } else if (adObject.underCategory === "80") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.houseThings = houseThings;
        }
        // För barn
      } else if (adObject.category === "8") {
        if (adObject.underCategory === "42") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.childrenClothingSizes = childrenClothingSizes;
        } else if (adObject.underCategory === "44") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.childrenShoeSize = childrenShoeSize;
        }
        // Fritid & Hobby
      } else if (adObject.category === "9") {
        if (adObject.underCategory === "63") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.horseValue = horseValue;
        } else if (adObject.underCategory === "65") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.huntfishing = huntfishing;
        } else if (adObject.underCategory === "66") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.musicfilmgames = musicfilmgames;
        } else if (adObject.underCategory === "67") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.musicInstruments = musicInstruments;
        } else if (adObject.underCategory === "69") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.sportFreeTime = sportFreeTime;
        }
        // Fordon
      } else if (adObject.category === "12") {
        if (adObject.underCategory === "51") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.carModel = carModel;
          dataObj.attributes.carFuel = carFuel;
          dataObj.attributes.gearBox = gearBox;
          dataObj.attributes.carYear = carYear;
          dataObj.attributes.horsePower = horsePower;
          dataObj.attributes.carMiles = carMiles;
          dataObj.attributes.carType = carType;
          dataObj.attributes.carColor = carColor;
        } else if (adObject.underCategory === "49") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.boatType = boatType;
        } else if (adObject.underCategory === "52") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.carEquipment = carEquipment;
        } else if (adObject.underCategory === "54") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.mcThings = mcThings;
        } else if (adObject.underCategory === "56") {
          dataObj.category = adObject.underCategory;
          dataObj.attributes.mc = mc;
        }
        // Bostad
      } else if (
        (adObject.category === "13") &
        (adObject.underCategory === "18")
      ) {
        dataObj.category = adObject.underCategory;
        dataObj.attributes.livingType = livingType;
        dataObj.attributes.sqm = sqm;
        dataObj.attributes.room = room;
      }

      var headers = {
        "x-auth": cookieId,
      };

      axios
        .post(process.env.REACT_APP_API_DOMAIN + "/cb/annons.edit", dataObj, {
          headers: headers,
        })
        .then((res) => {
          // PUBLISH AD VALIDATION OK
          console.log(res.data);
          if (res.data === "🎉") {
            setPublishMessage(true);
          } else {
            if (res.status !== 200) {
              setWrongMessage(true);
            }
          }
        });
    } else {
      setWrongMessage(true);
      setDisablePost(false);
    }
  };

  const checkAllCat = ( label ) => {
    return ( label.substring(0,4) === 'Allt' || label.substring(0,4) === 'Alla' );
  }

  return (
    <div className="my-page-container">
      <div className="my-page-wrapper">
        <ProfileInfo />
        <div className="flex-mother">
          {loading ? (
            <Skeleton
              variant="rect"
              width={270}
              height={30}
              style={{ margin: "auto", marginTop: 30 }}
            />
          ) : (
            <div className="flex-mother">
                  <div className="ad-details-info">
                    Här kan du redigera Din annons, till exempel lägga till mer information eller byta bilder.<br/><br/>Vill du ändra dina uppgifter såsom e-postadress eller telefonnummer? Gå då till <a href="/installningar">Inställningar</a>. Det kan du göra även efter Din annons är publicerad.
                  </div>
                  <div className="flex-mother-child-img">
                    <div className="upload-">
                      <ul>
                        {adObject.filesArray.length === 0
                          ? noFiles[0].map((number, index) => (
                              <li key={index} className="upload-link">
                                {index === 0 ? (
                                  <div className="upload-head-container">
                                    {loading ? (
                                      <Skeleton
                                        variant="rect"
                                        width={400}
                                        height={280}
                                      />
                                    ) : (
                                      <div>
                                        <input
                                          type="file"
                                          name="uploadfile"
                                          id="head-img"
                                          onChange={handleMultipleFiles}
                                          multiple
                                          style={{ display: "none" }}
                                        />
                                        <label htmlFor="head-img">
                                          <div className="upload-head-img">
                                            <img
                                              src={camera}
                                              alt="camera"
                                              className="upload-head-img"
                                            />
                                          </div>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                ) : index === 1 ? (
                                  <div
                                    className="upload-block-left"
                                    style={
                                      loading
                                        ? { backgroundColor: "white" }
                                        : {}
                                    }
                                  >
                                    {loading ? (
                                      <Skeleton
                                        variant="rect"
                                        width={90}
                                        height={90}
                                      />
                                    ) : (
                                      <div>
                                        <input
                                          type="file"
                                          name="uploadfile"
                                          id="img-one"
                                          onChange={handleMultipleFiles}
                                          multiple
                                          style={{ display: "none" }}
                                        />
                                        <label htmlFor="img-one">
                                          <div className="upload-image-container">
                                            <img
                                              src={camera}
                                              alt="camera"
                                              className="upload-img-block"
                                            />
                                          </div>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                ) : index === 2 ? (
                                  <div
                                    className="upload-block"
                                    style={
                                      loading
                                        ? { backgroundColor: "white" }
                                        : {}
                                    }
                                  >
                                    {loading ? (
                                      <Skeleton
                                        variant="rect"
                                        width={90}
                                        height={90}
                                      />
                                    ) : (
                                      <div>
                                        <input
                                          type="file"
                                          name="uploadfile"
                                          id="img-two"
                                          onChange={handleMultipleFiles}
                                          multiple
                                          style={{ display: "none" }}
                                        />
                                        <label htmlFor="img-two">
                                          <div className="upload-image-container">
                                            <img
                                              src={camera}
                                              alt="camera"
                                              className="upload-img-block"
                                            />
                                          </div>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                ) : index === 3 ? (
                                  <div
                                    className="upload-block"
                                    style={
                                      loading
                                        ? { backgroundColor: "white" }
                                        : {}
                                    }
                                  >
                                    {loading ? (
                                      <Skeleton
                                        variant="rect"
                                        width={90}
                                        height={90}
                                      />
                                    ) : (
                                      <div>
                                        <input
                                          type="file"
                                          name="uploadfile"
                                          id="img-three"
                                          onChange={handleMultipleFiles}
                                          multiple
                                          style={{ display: "none" }}
                                        />
                                        <label htmlFor="img-three">
                                          <div className="upload-image-container">
                                            <img
                                              src={camera}
                                              alt="camera"
                                              className="upload-img-block"
                                            />
                                          </div>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                ) : index === 4 ? (
                                  <div
                                    className="upload-block-right"
                                    style={
                                      loading
                                        ? { backgroundColor: "white" }
                                        : {}
                                    }
                                  >
                                    {loading ? (
                                      <Skeleton
                                        variant="rect"
                                        width={90}
                                        height={90}
                                      />
                                    ) : (
                                      <div>
                                        <input
                                          type="file"
                                          name="uploadfile"
                                          id="img-four"
                                          onChange={handleMultipleFiles}
                                          multiple
                                          style={{ display: "none" }}
                                        />
                                        <label htmlFor="img-four">
                                          <div className="upload-image-container">
                                            <img
                                              src={camera}
                                              alt="camera"
                                              className="upload-img-block"
                                            />
                                          </div>
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </li>
                            ))
                          : filesArrayShow.map((url, index) => (
                              <li key={index} className="upload-link">
                                {index === 0 ? (
                                  <div className="upload-head-container">
                                    <img
                                      src={
                                        url.includes("blob:")
                                          ? url
                                          : mediaUrl + url
                                      }
                                      alt="..."
                                      className="upload-head-img-active"
                                    />
                                    <div
                                      className="upload-head-cancel-icon"
                                      onClick={(e) => removeImage(e, index)}
                                    >
                                      <CancelIcon />
                                    </div>
                                  </div>
                                ) : index === 1 ? (
                                  <div
                                    className="upload-block-left"
                                    onClick={() => changeToFirstImage(index)}
                                  >
                                    <div className="upload-image-container">
                                      <img
                                        src={
                                          url.includes("blob:")
                                            ? url
                                            : mediaUrl + url
                                        }
                                        alt="..."
                                        className="upload-img-left"
                                      />
                                      <div
                                        className="upload-cancel-icon"
                                        onClick={(e) => removeImage(e, index)}
                                      >
                                        <CancelIcon />
                                      </div>
                                    </div>
                                  </div>
                                ) : index === 4 ? (
                                  <div
                                    className="upload-block-right"
                                    onClick={() => changeToFirstImage(index)}
                                  >
                                    <div className="upload-image-container">
                                      <img
                                        src={
                                          url.includes("blob:")
                                            ? url
                                            : mediaUrl + url
                                        }
                                        alt="..."
                                        className="upload-img-right"
                                      />
                                      <div
                                        className="upload-cancel-icon"
                                        onClick={(e) => removeImage(e, index)}
                                      >
                                        <CancelIcon />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="upload-block"
                                    onClick={() => changeToFirstImage(index)}
                                  >
                                    <div className="upload-image-container">
                                      <img
                                        src={
                                          url.includes("blob:")
                                            ? url
                                            : mediaUrl + url
                                        }
                                        alt="camera"
                                        className="upload-img"
                                      />
                                      <div
                                        className="upload-cancel-icon"
                                        onClick={(e) => removeImage(e, index)}
                                      >
                                        <CancelIcon />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </li>
                            ))}
                      </ul>
                      <div
                        className={
                          adObject.filesArray.length === 5
                            ? "hide"
                            : "upload-button"
                        }
                      >
                        {loading ? (
                          <Skeleton variant="text" height={40} />
                        ) : (
                          <>
                            <input
                              type="file"
                              id="upload-button-id"
                              className=""
                              onChange={handleMultipleFiles}
                              multiple
                            />
                            <label
                              htmlFor="upload-button-id"
                              className="upload-button-label"
                            >
                              {uploadButtonText}
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex-mother-child-description">
                    {loading ? (
                      <Skeleton
                        variant="rect"
                        width={400}
                        height={30}
                        style={{ paddingLeft: 10, margin: 5 }}
                      />
                    ) : (
                      <div className='options-title-category'>
                        <select
                          name="options"
                          className={
                            adObject.category === ""
                              ? "options"
                              : "options validated"
                          }
                          onChange={handleCategory}
                          value={adObject.category}
                        >
                          <option value="" disabled defaultValue>
                            Välj kategori
                          </option>
                          {categories.categories.map((category, index) => (
                            checkAllCat(category.label) ? '' : 
                            <option key={index} value={category.value}>
                              {category.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {
                      // Mode
                      adObject.category === "6" ? (
                        <div className="createdad-fashion-container">
                          <select
                            className={
                              adObject.underCategory === ""
                                ? "options-undercategory"
                                : "options-undercategory validated"
                            }
                            onChange={handleUnderCategory}
                            value={adObject.underCategory}
                          >
                            <option value="" disabled defaultValue>
                              Välj underkategori
                            </option>
                            {categories.mode.map((underCategory, index) => (
                              checkAllCat(underCategory.label) ? '' : 
                              <option key={index} value={underCategory.value}>
                                {underCategory.label}
                              </option>
                            ))}
                          </select>
                          <div className="createdad-fashion-information-container">
                            {/* Damkläder & damskor */}
                            {adObject.underCategory === "312" ? (
                              <div className="createdad-fashion-information-left">
                                <select
                                  className={
                                    womenClothing === ""
                                      ? "options-undercategory"
                                      : "options-undercategory validated"
                                  }
                                  onChange={handleWomenClothing}
                                  value={womenClothing}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj klädtyp
                                  </option>
                                  {categories.womenClothing.map(
                                    (clothing, index) => (
                                      checkAllCat(clothing.label) ? '' : 
                                      <option
                                        key={index}
                                        value={clothing.value}
                                      >
                                        {clothing.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            ) : // Herrkläder & herrskor
                            adObject.underCategory === "313" ? (
                              <div className="createdad-fashion-information-left">
                                <select
                                  className={
                                    menClothing === ""
                                      ? "options-undercategory"
                                      : "options-undercategory validated"
                                  }
                                  onChange={handleMenClothing}
                                  value={menClothing}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj klädtyp
                                  </option>
                                  {categories.menClothing.map(
                                    (clothing, index) => (
                                      checkAllCat(clothing.label) ? '' : 
                                      <option
                                        key={index}
                                        value={clothing.value}
                                      >
                                        {clothing.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="createdad-fashion-information-right">
                              {
                                // 333 = Skor
                                adObject.underCategory === "312" &&
                                womenClothing === "333" ? (
                                  <div className="createdad-fashion-shoes-container">
                                    <select
                                      className={
                                        shoeSize === ""
                                          ? "options-undercategory"
                                          : "options-undercategory validated"
                                      }
                                      onChange={handleShoeSize}
                                      value={shoeSize}
                                    >
                                      <option value="" disabled defaultValue>
                                        Välj storlek
                                      </option>
                                      {categories.shoeSize.map(
                                        (shoe, index) => (
                                          <option
                                            key={index}
                                            value={shoe.value}
                                          >
                                            {shoe}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                ) : adObject.underCategory === "312" &&
                                  womenClothing !== "333" &&
                                  womenClothing !== "" ? (
                                  <select
                                    className={
                                      womenClothingSize === ""
                                        ? "options-undercategory"
                                        : "options-undercategory validated"
                                    }
                                    onChange={handleWomenClothingSize}
                                    value={womenClothingSize}
                                  >
                                    <option value="" disabled defaultValue>
                                      Välj storlek
                                    </option>
                                    {categories.womenClothingSize.map(
                                      (clothing, index) => (
                                        <option
                                          key={index}
                                          value={clothing.value}
                                        >
                                          {clothing}
                                        </option>
                                      )
                                    )}
                                  </select>
                                ) : adObject.underCategory === "313" &&
                                  menClothing === "314" ? (
                                  <div className="createdad-fashion-shoes-container">
                                    <select
                                      className={
                                        shoeSize === ""
                                          ? "options-undercategory"
                                          : "options-undercategory validated"
                                      }
                                      onChange={handleShoeSize}
                                      value={shoeSize}
                                    >
                                      <option value="" disabled defaultValue>
                                        Välj storlek
                                      </option>
                                      {categories.shoeSize.map(
                                        (shoe, index) => (
                                          <option
                                            key={index}
                                            value={shoe.value}
                                          >
                                            {shoe}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                ) : adObject.underCategory === "313" &&
                                  menClothing !== "314" &&
                                  menClothing !== "" ? (
                                  <select
                                    className={
                                      menClothingSize === ""
                                        ? "options-undercategory"
                                        : "options-undercategory validated"
                                    }
                                    onChange={handleMenClothingSize}
                                    value={menClothingSize}
                                  >
                                    <option value="" disabled defaultValue>
                                      Välj storlek
                                    </option>
                                    {categories.menClothingSize.map(
                                      (clothing, index) => (
                                        <option
                                          key={index}
                                          value={clothing.value}
                                        >
                                          {clothing}
                                        </option>
                                      )
                                    )}
                                  </select>
                                ) : (
                                  ""
                                )
                              }
                            </div>
                          </div>
                        </div>
                      ) : // Hem och inredning
                      adObject.category === "7" ? (
                        <div className="createad-undercategory-wrapper">
                          <select
                            className={
                              adObject.underCategory === ""
                                ? "options-undercategory"
                                : "options-undercategory validated"
                            }
                            onChange={handleUnderCategory}
                            value={adObject.underCategory}
                          >
                            <option value="" disabled defaultValue>
                              Välj underkategori
                            </option>
                            {categories.hem.map((underCategory, index) => (
                              checkAllCat(underCategory.label) ? '' : 
                              <option key={index} value={underCategory.value}>
                                {underCategory.label}
                              </option>
                            ))}
                          </select>
                          <div className="createdad-bygg-information-right">
                            {
                              // 74 = Bygg & Trädgård
                              adObject.underCategory === "74" ? (
                                <div className="createdad-bygg-information-left">
                                  <select
                                    className={
                                      homeBuild === ""
                                        ? "options-undercategory"
                                        : "options-undercategory validated"
                                    }
                                    onChange={handleByggCategory}
                                    value={homeBuild}
                                  >
                                    <option value="" disabled defaultValue>
                                      Välj underkategori
                                    </option>
                                    {categories.bygg.map((bygg, index) => (
                                      checkAllCat(bygg.label) ? '' : 
                                      <option key={index} value={bygg.value}>
                                        {bygg.label}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              ) : // 80 = Vitvarot & Husgeråd
                              adObject.underCategory === "80" ? (
                                <div className="createdad-bygg-information-left">
                                  <select
                                    className={
                                      houseThings === ""
                                        ? "options-undercategory"
                                        : "options-undercategory validated"
                                    }
                                    onChange={handleHouseThings}
                                    value={houseThings}
                                  >
                                    <option value="" disabled defaultValue>
                                      Välj underkategori
                                    </option>
                                    {categories.vitvaror.map(
                                      (vitvaror, index) => (
                                        checkAllCat(vitvaror.label) ? '' : 
                                        <option
                                          key={index}
                                          value={vitvaror.value}
                                        >
                                          {vitvaror.label}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              ) : (
                                ""
                              )
                            }
                          </div>
                        </div>
                      ) : // För barn
                      adObject.category === "8" ? (
                        <div className={"options-undercategory-boats-active"}>
                          <div
                            className={"options-undercategory-boats-container"}
                          >
                            <select
                              className={
                                adObject.underCategory === ""
                                  ? "options-undercategory"
                                  : "options-undercategory validated"
                              }
                              onChange={handleUnderCategory}
                              value={adObject.underCategory}
                            >
                              <option value="" disabled defaultValue>
                                Välj underkategori
                              </option>
                              {categories.barn.map((underCategory, index) => (
                                checkAllCat(underCategory.label) ? '' : 
                                <option key={index} value={underCategory.value}>
                                  {underCategory.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* 42 = Barnkläder */}
                          {adObject.underCategory === "42" ? (
                            <div className="options-undercategory-column-left">
                              <div className="options-undercategory-column-two">
                                <select
                                  name="options"
                                  className={
                                    childrenClothingSizes === ""
                                      ? "options-undercategory-children-sizes"
                                      : "options-undercategory-children-sizes validated"
                                  }
                                  onChange={handleChildrenClothingSizes}
                                  value={childrenClothingSizes}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj storlek
                                  </option>
                                  {categories.childrenClothingSizes.map(
                                    (size, index) => (
                                      <option key={index} value={size.value}>
                                        {size}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          ) : // 44 = Barnskor
                          adObject.underCategory === "44" ? (
                            <div className="options-undercategory-column-two-container-right">
                              <div className="options-undercategory-column-two">
                                <select
                                  name="options"
                                  className={
                                    childrenShoeSize === ""
                                      ? "options-undercategory-children-sizes"
                                      : "options-undercategory-children-sizes validated"
                                  }
                                  onChange={handleChildrenShoeSize}
                                  value={childrenShoeSize}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj storlek
                                  </option>
                                  {categories.childrenShoeSize.map(
                                    (size, index) => (
                                      <option key={index} value={size.value}>
                                        {size}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : // Fritid & Hobby
                      adObject.category === "9" ? (
                        <div className="createad-undercategory-wrapper">
                          <select
                            className={
                              adObject.underCategory === ""
                                ? "options-undercategory"
                                : "options-undercategory validated"
                            }
                            onChange={handleUnderCategory}
                            value={adObject.underCategory}
                          >
                            <option value="" disabled defaultValue>
                              Välj underkategori
                            </option>
                            {categories.fritidhobby.map(
                              (underCategory, index) => (
                                checkAllCat(underCategory.label) ? '' : 
                                <option key={index} value={underCategory.value}>
                                  {underCategory.label}
                                </option>
                              )
                            )}
                          </select>
                          {/* 63 = Häst & Ridsport */}
                          {adObject.underCategory === "63" ? (
                            <div className="options-undercategory-column-left">
                              <div className="options-undercategory-column">
                                <select
                                  name="options"
                                  className={
                                    horseValue === ""
                                      ? "options-undercategory-children-sizes"
                                      : "options-undercategory-children-sizes validated"
                                  }
                                  onChange={handleHorseValue}
                                  value={childrenClothingSizes}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj underkategori
                                  </option>
                                  {categories.horseSports.map(
                                    (horseSport, index) => (
                                      checkAllCat(horseSport.label) ? '' : 
                                      <option
                                        key={index}
                                        value={horseSport.value}
                                      >
                                        {horseSport.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          ) : // 65 = Jakt & Fiske
                          adObject.underCategory === "65" ? (
                            <div className="options-undercategory-column-left">
                              <div className="options-undercategory-column">
                                <select
                                  name="options"
                                  className={
                                    huntfishing === ""
                                      ? "options-undercategory-children-sizes"
                                      : "options-undercategory-children-sizes validated"
                                  }
                                  onChange={handleHuntFishing}
                                  value={huntfishing}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj underkategori
                                  </option>
                                  {categories.jaktfiske.map(
                                    (jaktfiske, index) => (
                                      checkAllCat(jaktfiske.label) ? '' : 
                                      <option
                                        key={index}
                                        value={jaktfiske.value}
                                      >
                                        {jaktfiske.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          ) : // 66 = Musik, Film & Spel
                          adObject.underCategory === "66" ? (
                            <div className="options-undercategory-column-left">
                              <div className="options-undercategory-column">
                                <select
                                  name="options"
                                  className={
                                    musicfilmgames === ""
                                      ? "options-undercategory-children-sizes"
                                      : "options-undercategory-children-sizes validated"
                                  }
                                  onChange={handleMusicFilmGames}
                                  value={musicfilmgames}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj underkategori
                                  </option>
                                  {categories.musikfilmspel.map(
                                    (musikfilmspel, index) => (
                                      checkAllCat(musikfilmspel.label) ? '' : 
                                      <option
                                        key={index}
                                        value={musikfilmspel.value}
                                      >
                                        {musikfilmspel.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          ) : // 67 = Musikinstrument
                          adObject.underCategory === "67" ? (
                            <div className="options-undercategory-column-left">
                              <div className="options-undercategory-column">
                                <select
                                  name="options"
                                  className={
                                    musicInstruments === ""
                                      ? "options-undercategory-children-sizes"
                                      : "options-undercategory-children-sizes validated"
                                  }
                                  onChange={handleInstruments}
                                  value={musicInstruments}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj underkategori
                                  </option>
                                  {categories.musik.map((musik, index) => (
                                    checkAllCat(musik.label) ? '' : 
                                    <option key={index} value={musik.value}>
                                      {musik.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : // 69 = Sport & Fritid
                          adObject.underCategory === "69" ? (
                            <div className="options-undercategory-column-left">
                              <div className="options-undercategory-column">
                                <select
                                  name="options"
                                  className={
                                    sportFreeTime === ""
                                      ? "options-undercategory-children-sizes"
                                      : "options-undercategory-children-sizes validated"
                                  }
                                  onChange={handlSportFreeTime}
                                  value={sportFreeTime}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj underkategori
                                  </option>
                                  {categories.sporter.map((sport, index) => (
                                    checkAllCat(sport.label) ? '' : 
                                    <option key={index} value={sport.value}>
                                      {sport.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : // Elektronik
                      adObject.category === "10" ? (
                        <div className="createad-undercategory-wrapper">
                          <select
                            className={
                              adObject.underCategory === ""
                                ? "options-undercategory"
                                : "options-undercategory validated"
                            }
                            onChange={handleUnderCategory}
                            value={adObject.underCategory}
                          >
                            <option value="" disabled defaultValue>
                              Välj underkategori
                            </option>
                            {categories.elektronik.map(
                              (underCategory, index) => (
                                checkAllCat(underCategory.label) ? '' : 
                                <option key={index} value={underCategory.value}>
                                  {underCategory.label}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      ) : // Djur
                      adObject.category === "11" ? (
                        <div className="createad-undercategory-wrapper">
                          <select
                            className={
                              adObject.underCategory === ""
                                ? "options-undercategory"
                                : "options-undercategory validated"
                            }
                            onChange={handleUnderCategory}
                            value={adObject.underCategory}
                          >
                            <option value="" disabled defaultValue>
                              Välj underkategori
                            </option>
                            {categories.djur.map((underCategory, index) => (
                              checkAllCat(underCategory.label) ? '' : 
                              <option key={index} value={underCategory.value}>
                                {underCategory.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : // Fordon
                      adObject.category === "12" ? (
                        <div
                          className={
                            adObject.underCategory === "Båtar"
                              ? "options-undercategory-boats-active"
                              : "options-undercategory-under"
                          }
                        >
                          <div
                            className={
                              adObject.underCategory === ""
                                ? "options-undercategory-boats-container"
                                : "options-undercategory-vehicles-container"
                            }
                          >
                            <select
                              className={
                                adObject.underCategory === ""
                                  ? "options-undercategory"
                                  : "options-undercategory validated"
                              }
                              onChange={handleUnderCategory}
                              value={adObject.underCategory}
                            >
                              <option value="" disabled defaultValue>
                                Välj underkategori
                              </option>
                              {categories.fordon.map((underCategory, index) => (
                                checkAllCat(underCategory.label) ? '' : 
                                <option key={index} value={underCategory.value}>
                                  {underCategory.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* // 52 = Bilar & Tillbehör */}
                          {/* 51 = Bilar */}
                          {adObject.underCategory === "51" ? (
                            <div className="options-undercategory-container">
                              <div className="options-undercategory-container-vehicles">
                                <div className="options-undercategory-vehicles-left">
                                  <select
                                    name="options"
                                    className={
                                      carModel === ""
                                        ? "options-undercategory-vehicles-active"
                                        : "options-undercategory-vehicles-active validated"
                                    }
                                    onChange={handleCarModel}
                                    value={carModel}
                                  >
                                    <option value="" disabled defaultValue>
                                      Bilmärke
                                    </option>
                                    {categories.carModels.map(
                                      (carModel, index) => (
                                        <option
                                          key={index}
                                          value={carModel}
                                        >
                                          {carModel}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                                <div className="options-undercategory-vehicles">
                                  <select
                                    name="options"
                                    className={
                                      carFuel === ""
                                        ? "options-undercategory-vehicles-active"
                                        : "options-undercategory-vehicles-active validated"
                                    }
                                    onChange={handleCarFuel}
                                    value={carFuel}
                                  >
                                    <option value="" disabled defaultValue>
                                      Bränsle
                                    </option>
                                    {categories.carFuel.map((fuel, index) => (
                                      <option key={index} value={fuel.value}>
                                        {fuel}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="options-undercategory-vehicles-right">
                                  <select
                                    name="options"
                                    className={
                                      gearBox === ""
                                        ? "options-undercategory-vehicles-active"
                                        : "options-undercategory-vehicles-active validated"
                                    }
                                    onChange={handleGearBox}
                                    value={gearBox}
                                  >
                                    <option value="" disabled defaultValue>
                                      Växellåda
                                    </option>
                                    {categories.gearBox.map(
                                      (gearBoxType, index) => (
                                        <option
                                          key={index}
                                          value={gearBoxType.value}
                                        >
                                          {gearBoxType}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </div>
                              </div>
                              <div className="options-undercategory-container-vehicles">
                                <div className="options-undercategory-vehicles-information">
                                  <input
                                    type="number"
                                    className={
                                      carYear === ""
                                        ? "options-undercategory-vehicles-active"
                                        : "options-undercategory-vehicles-active validated"
                                    }
                                    placeholder="Årsmodell"
                                    onChange={handleCarYear}
                                    value={carYear}
                                  />
                                </div>
                                <div className="options-undercategory-vehicles-information">
                                  <input
                                    type="number"
                                    className="options-undercategory-vehicles-active"
                                    placeholder="Hästkrafter"
                                    onChange={handleHorsePower}
                                    value={horsePower}
                                  />
                                  <span
                                    className={
                                      horsePower === ""
                                        ? "options-undercategory-information-prefix"
                                        : "options-undercategory-information-prefix validated"
                                    }
                                  >
                                    hk
                                  </span>
                                </div>
                                <div className="options-undercategory-vehicles-information">
                                  <input
                                    type="number"
                                    className={
                                      carMiles === ""
                                        ? "options-undercategory-vehicles-active"
                                        : "options-undercategory-vehicles-active validated"
                                    }
                                    placeholder="Mil"
                                    onChange={handleCarMiles}
                                    value={carMiles}
                                  />
                                  <span className="options-undercategory-information-prefix">
                                    mil
                                  </span>
                                </div>
                              </div>
                              <div className="options-undercategory-container-vehicles">
                                <div className="options-undercategory-vehicles-information">
                                  <select
                                    name="options"
                                    className={
                                      carType === ""
                                        ? "options-undercategory-vehicles-active"
                                        : "options-undercategory-vehicles-active validated"
                                    }
                                    onChange={handleCarType}
                                    value={carType}
                                  >
                                    <option value="" disabled defaultValue>
                                      Karosstyp
                                    </option>
                                    {categories.carType.map((type, index) => (
                                      <option key={index} value={type.value}>
                                        {type}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="options-undercategory-vehicles-information">
                                  <select
                                    name="options"
                                    className={
                                      carColor === ""
                                        ? "options-undercategory-vehicles-active"
                                        : "options-undercategory-vehicles-active validated"
                                    }
                                    onChange={handleCarColor}
                                    value={carColor}
                                  >
                                    <option value="" disabled defaultValue>
                                      Färg
                                    </option>
                                    {categories.carColor.map((color, index) => (
                                      <option key={index} value={color.value}>
                                        {color}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="options-undercategory-vehicles-information">
                                      &nbsp;
                                </div>
                              </div>
                            </div>
                          ) : // 52 = Bilar & Tillbehör
                          adObject.underCategory === "52" ? (
                            <div className="options-undercategory-column-left">
                              <div className="options-undercategory-column">
                                <select
                                  name="options"
                                  className={
                                    carEquipment === ""
                                      ? "options-undercategory-vehicles-boat"
                                      : "options-undercategory-vehicles-boat validated"
                                  }
                                  onChange={handleCarEquipment}
                                  value={carEquipment}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj tillbehör
                                  </option>
                                  {categories.biltillbehör.map(
                                    (carEquipment, index) => (
                                      checkAllCat(carEquipment.label) ? '' : 
                                      <option
                                        key={index}
                                        value={carEquipment.value}
                                      >
                                        {carEquipment.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                            </div>
                          ) : // 49 = Båtar
                          adObject.underCategory === "49" ? (
                            <div className="options-undercategory-column-left">
                              <div className="options-undercategory-column">
                                <select
                                  name="options"
                                  className={
                                    boatType === ""
                                      ? "options-undercategory-vehicles-boat"
                                      : "options-undercategory-vehicles-boat validated"
                                  }
                                  onChange={handleBoatType}
                                  value={boatType}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj underkategori
                                  </option>
                                  {categories.boatType.map((type, index) => (
                                    checkAllCat(type.label) ? '' : 
                                    <option key={index} value={type.value}>
                                      {type.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : // 54 = MC-Delar & Tillbehör
                          adObject.underCategory === "54" ? (
                            <div className="options-undercategory-column-left">
                              <div className="options-undercategory-column">
                                <select
                                  name="options"
                                  className={
                                    mcThings === ""
                                      ? "options-undercategory-vehicles-boat"
                                      : "options-undercategory-vehicles-boat validated"
                                  }
                                  onChange={handleMcThings}
                                  value={mcThings}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj underkategori
                                  </option>
                                  {categories.mcthings.map((mcthing, index) => (
                                    checkAllCat(mcthing.label) ? '' : 
                                    <option key={index} value={mcthing.value}>
                                      {mcthing.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : // 56 = Motorcyklar
                          adObject.underCategory === "56" ? (
                            <div className="options-undercategory-column-left">
                              <div className="options-undercategory-column">
                                <select
                                  name="options"
                                  className={
                                    mc === ""
                                      ? "options-undercategory-vehicles-boat"
                                      : "options-undercategory-vehicles-boat validated"
                                  }
                                  onChange={handleMc}
                                  value={mc}
                                >
                                  <option value="" disabled defaultValue>
                                    Välj underkategori
                                  </option>
                                  {categories.mc.map((mcthing, index) => (
                                    checkAllCat(mcthing.label) ? '' : 
                                    <option key={index} value={mcthing.value}>
                                      {mcthing.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : // Bostad
                      adObject.category === "13" ? (
                        <div className="options-undercategory-under">
                          <div className="options-undercategory-living-container">
                            <select
                              className={
                                adObject.underCategory === ""
                                  ? "options-undercategory"
                                  : "options-undercategory validated"
                              }
                              onChange={handleUnderCategory}
                              value={adObject.underCategory}
                            >
                              <option value="" disabled defaultValue>
                                Välj underkategori
                              </option>
                              {categories.boende.map((underCategory, index) => (
                                checkAllCat(underCategory.label) ? '' : 
                                <option key={index} value={underCategory.value}>
                                  {underCategory.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* 18 = Lägenheter */}
                          {adObject.underCategory === "18" ? (
                            <div className="options-undercategory-apartment-container">
                              <div className="options-undercategory-apartment-information-options">
                                <select
                                  name="options"
                                  className={
                                    livingType === ""
                                      ? "options-undercategory-living-active"
                                      : "options-undercategory-living-active validated"
                                  }
                                  onChange={handleLivingType}
                                  value={livingType}
                                >
                                  <option value="" disabled defaultValue>
                                    Bostadsform
                                  </option>
                                  {categories.livingType.map(
                                    (underCategory, index) => (
                                      checkAllCat(underCategory.label) ? '' : 
                                      <option
                                        key={index}
                                        value={underCategory.value}
                                      >
                                        {underCategory.label}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              <div className="options-undercategory-apartment-information">
                                <input
                                  type="number"
                                  className={
                                    sqm === ""
                                      ? "options-undercategory-living-active"
                                      : "options-undercategory-living-active validated"
                                  }
                                  placeholder="Kvm"
                                  onChange={handleSqm}
                                  value={sqm}
                                />
                                <span className="options-undercategory-information-prefix">
                                  kvm
                                </span>
                              </div>
                              <div className="options-undercategory-apartment-information">
                                <input
                                  type="number"
                                  className={
                                    room === ""
                                      ? "options-undercategory-living-active"
                                      : "options-undercategory-living-active validated"
                                  }
                                  placeholder="Rum"
                                  onChange={handleRoom}
                                  value={room}
                                />
                                <span className="options-undercategory-information-prefix">
                                  rum
                                </span>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )
                    }
                    {loading ? (
                      <Skeleton
                        variant="rect"
                        width={400}
                        height={30}
                        style={{ paddingLeft: 10, margin: 5 }}
                      />
                    ) : (
                      <div className='options-title'>
                      <input
                        type="text"
                        placeholder="Rubrik..."
                        className={
                          adObject.title === ""
                            ? "createad-container-title"
                            : "createad-container-title validated"
                        }
                        value={adObject.title}
                        onChange={handleTitle}
                      />
                      </div>
                    )}
                    {loading ? (
                      <Skeleton
                        variant="rect"
                        width={200}
                        height={35}
                        style={{
                          marginLeft: 5,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      />
                    ) : (
                      <div className="createad-container-price">
                        <input
                          type="number"
                          className={
                            adObject.price === ""
                              ? "createad-container-price"
                              : "createad-container-price validated"
                          }
                          placeholder="Pris..."
                          onChange={handlePrice}
                          value={adObject.price}
                        />
                        <span className="createad-container-price-value">
                          kr
                        </span>
                      </div>
                    )}
                    <a href="#" type="button" className="editad-findme" onClick={requestPosition}>
                      Hitta min position <PlaceIcon />
                    </a>
                    {loading ? (
                      <Skeleton
                        variant="rect"
                        width={400}
                        height={35}
                        style={{
                          marginLeft: 5,
                          marginTop: 20,
                          marginBottom: 10,
                        }}
                      />
                    ) : (
                      <select
                        id="orter" 
                        name="options"
                        className={
                          adObject.city === "Sverige"
                            ? "options"
                            : "options validated"
                        }
                        onChange={(e) => handleCity(e)}
                        value={adObject.city}
                      >
                        <option value="Sverige" disabled>
                          Välj stad
                        </option>
                        {categories.choiceRegion.map((region, index) => (
                          <option key={index} value={region.value}>
                            {region.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>

                  <div className="flex-mother-child-text">
                    {loading ? (
                      <Skeleton
                        variant="rect"
                        width={730}
                        height={300}
                        style={{ padding: 10 }}
                      />
                    ) : (
                      <textarea
                        rows={14}
                        className={
                          adObject.description === ""
                            ? "createad-container-textarea"
                            : "createad-container-textarea validated"
                        }
                        placeholder="Beskrivning..."
                        onChange={handleDescription}
                        value={adObject.description}
                      />
                    )}
                    <div className="save-ad-button-svante">
                      <button
                        className="createad-form-submit-button"
                        onClick={() => submitAd()}
                        disabled={disablePost}
                      >
                        Spara annons
                      </button>
                    </div>
                    {publishMessage ? (
                      <DialogComponent
                        title="Annons uppdaterad"
                        text="Din annons är nu uppdaterad. Du kommer nu skickas till annonsen."
                        onClose={accept}
                      />
                    ) : wrongMessage ? (
                      <DialogComponent
                        title="Något gick fel"
                        text="Något gick fel när annonsen skulle uppdateras. Se till så att alla fälten som visas är ifyllda."
                        onClose={closeModal}
                      />
                    ) : maxLimitMessage ? (
                      <DialogComponent
                        title="Maxgränsen har överskridits"
                        text="Bildfältet har en maxgräns på 5 bilder."
                        onClose={closeModal}
                      />
                    ) : wrongFormat ? (
                      <DialogComponent
                        title="Fel format"
                        text="Bilderna har fel filformat. De godkända filformaten är .png, .jpg, .jpeg"
                        onClose={closeModal}
                      />
                    ) : infoHelp ? (
                      <DialogComponent
                        title="Redigera en annons"
                        text="Redigera innehållet på din annons för att sedan klicka på spara så uppdateras din annons."
                        onClose={closeModal}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              
          )}
          </div>
        </div>
      <div className="mypage-banner-container">
        <div className="mypage-banner-first-wrapper">
          {loading ? <Skeleton variant="rect" width={1200} height={250} /> : ""}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    allAdsArr: state.fetchAllAds,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllAds: (ads) => dispatch(fetchAllAds(ads)),
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(EditAd)
);
