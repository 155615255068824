import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import { withCookies } from "react-cookie";
import { connect } from "react-redux";
import CancelIcon from "@material-ui/icons/Cancel";
import axios from "axios";

import { user } from "../redux/actions/user";
import { logoutUser } from "../redux/actions/user";

import ProfileInfo from "../components/ProfileInfo";
import DialogComponent from "../components/DialogComponent";

import ValidatePassword from "../helpers/ValidatePassword";
import ValidateMail from "../helpers/ValidateMail";
import BlobUrl from "../helpers/BlobUrl";
import Base64 from "../helpers/Base64";

import categories from "../dumpdata/categories.js";

import emptyAvatar from "../images/empty-avatar.jpg";

const SaveButton = withStyles({
  root: {
    backgroundColor: "var(--dorange) !important",
    borderRadius: 3,
    border: 0,
    color: "white !important",
    height: 40,
    padding: "0 30px",
    margin: 10,
    "&:hover": {
      backgroundColor: "var(--dblue) !important",
    },
  },
})(Button);

const RemoveUserButton = withStyles({
  root: {
    backgroundColor: "rgb(225,78,55)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 40,
    padding: "0 30px",
    margin: 10,
    "&:hover": {
      backgroundColor: "rgb(206, 59, 59)",
    },
  },
})(Button);

const Settings = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const isLoggedIn = props.user.user_id !== undefined;
  const user = props.user;
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [avatar, setAvatar] = useState("");
  const [avatarForShow, setAvatarForShow] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("Sverige");
  const [password, setPassword] = useState("");

  const [newsLetter, setNewsLetter] = useState(false);
  const [consent, setConsent] = useState(false);
  const [msgNotify, setMsgNotify] = useState(false);

  const [emptyFields, setEmptyFields] = useState(false);
  const [failedPassword, setFailedPassword] = useState(false);
  const [failedMail, setFailedMail] = useState(false);
  const [failedConsent, setFailedConsent] = useState(false);
  const [successChange, setSuccessChange] = useState(false);
  const [wrongFormat, setWrongFormat] = useState(false);
  const [somethingWrong, setSomethingWrong] = useState(false);
  const [userRemove, setUserRemove] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fetchUser, setFetchUser] = useState(false);

  const notLoggedInLocation = {
    pathname: "/inte-inloggad",
    state: { fromSettings: true },
  };

  const homeLocation = {
    pathname: "/",
    state: { fromSettings: true },
  };

  var headers = {
    "x-auth": cookieId,
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [loading]);

  useEffect(() => {
    const fetchData = () => {
      setName(user.name);
      setMail(user.mail);
      setPhone(user.phone);
      setAvatar(user.avatar);
      setAvatarForShow(user.avatar);
      setZipCode(user.zip_code);
      setCity(user.ort_id);
      setConsent(user.consent);
      setNewsLetter(user.newsLetter);
      setMsgNotify(user.msgnotify);
      setFetchUser(true);
    };

    if (!fetchUser && user.name !== "") {
      fetchData();
    }
  }, [name, phone, avatar, user, props, mail, fetchUser]);

  const closeAll = () => {
    setEmptyFields(false);
    setFailedPassword(false);
    setSuccessChange(false);
    setUserRemove(false);
    setWrongFormat(false);
    setSomethingWrong(false);
  };

  const saveUserInfo = (e) => {
    e.preventDefault();
    if (name === "" || mail === "") {
      setEmptyFields(true);
    } else if (password !== "" && !ValidatePassword(password)) {
      setFailedPassword(true);
    } else if (!ValidateMail(mail)) {
      setFailedMail(true);
    } else if (consent !== "1") {
      setFailedConsent(true);
    } else {
      var tempCity = city;
      var tempAvatar = "";

      if (city === "Sverige") {
        tempCity = 0;
      }

      if (avatar !== user.avatar) {
        tempAvatar = avatar;
      }

      var obj = {
        name: name,
        email: mail,
        phone,
        avatar: tempAvatar,
        postnr: zipCode,
        ort_id: tempCity,
        password,
        consent,
        nyhetsbrev: newsLetter,
        msgnotify: msgNotify,
      };

      try {
        axios
          .post(process.env.REACT_APP_API_DOMAIN + "/cb/user.update", obj, {
            headers: headers,
          })
          .then((res) => {
            if (res.data === "🎉") {
              setSuccessChange(true);
              // try {
              //   axios
              //     .get(
              //       process.env.REACT_APP_API_DOMAIN + "/cb/user?user_id=" +
              //         user.user_id,
              //       { headers: headers }
              //     )
              //     .then((res) => {
              //       props.setUser(res.data);
              //     })
              //     .catch((err) => {
              //       console.log(err);
              //     });
              // } catch (err) {
              //   console.log(err);
              // }
              window.location.reload();
            } else {
              setSomethingWrong(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleUserRemove = (e) => {
    e.preventDefault();
    setUserRemove(true);
  };

  const removeUser = () => {
    try {
      axios
        .post(process.env.REACT_APP_API_DOMAIN + "/cb/user.remove", {}, {
          headers: headers,
        })
        .then((res) => {
          if (res.data === "🎉") {
            cookies.set("id", "", { path: "/" });
            props.logoutUser();
            window.location.replace(homeLocation.pathname);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleAvatar = (e) => {
    e.persist();
    const file = e.target.files[0];
    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/png" &&
      file.type !== "image/jpg"
    ) {
      setWrongFormat(true);
      return;
    } else {
      Base64(file).then((data) => {
        setAvatar(data);
      });
      setAvatarForShow(BlobUrl(e.target.files[0]));
    }
  };

  const handleCity = (e) => {
    e.preventDefault();
    setCity(e.target.value);
  };

  const removeAvatar = () => {
    setAvatar("remove");
    setAvatarForShow("");
  };

  const handleConsent = () => {
    if (consent === "1") {
      setConsent("0");
    } else {
      setConsent("1");
    }
  };

  const handleMessageNotify = () => {
    if (msgNotify === "1") {
      setMsgNotify("0");
    } else {
      setMsgNotify("1");
    }
  };

  const handleNewsLetter = () => {
    if (newsLetter === "1") {
      setNewsLetter("0");
    } else {
      setNewsLetter("1");
    }
  };

  if ((cookieId === "" || cookieId === undefined) && !isLoggedIn) {
    return <Navigate to={notLoggedInLocation} />;
  }

  return (
    <div className="my-page-container">
      <div className="my-page-wrapper">
        <ProfileInfo />
        <div className="my-page-ads-wrapper">
          <div className="settings-content">
                <div className="settings-title-container">
                  <span className="saved-title">Inställningar</span>
                </div>
            {loading ? (
              <Skeleton
                variant="rect"
                width={270}
                height={30}
                style={{ margin: "auto", marginTop: 30 }}
              />
            ) : (
              <div className="settings-container">
                <form>
                  <h4>Namn</h4>
                  <div className="settings-name-container">
                    <div className="settings-fname-container">
                      {loading ? (
                        <Skeleton variant="rect" height={33} width={235} />
                      ) : (
                        <input
                          type="text"
                          className="settings-fname"
                          autoComplete="given-name"
                          placeholder="Namn"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                  <h4>Kontaktuppgifter</h4>
                  <div className="settings-info-container">
                    <div className="settings-mail-container">
                      {loading ? (
                        <Skeleton variant="rect" height={33} />
                      ) : (
                        <input
                          type="text"
                          className="settings-mail"
                          autoComplete="email"
                          placeholder="Mail"
                          value={mail}
                          onChange={(e) => setMail(e.target.value)}
                        />
                      )}
                    </div>
                    <div className="settings-phone-container">
                      {loading ? (
                        <Skeleton variant="rect" height={33} />
                      ) : (
                        <input
                          type="text"
                          className="settings-phone"
                          autoComplete="tel"
                          placeholder="Mobilnummer"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                  <h4>Profilbild</h4>
                  <div className="settings-avatar-container">
                    <input
                      type="file"
                      name=""
                      id="change-profile"
                      onChange={(e) => handleAvatar(e)}
                      style={{ display: "none" }}
                    />
                    {loading ? (
                      <Skeleton variant="circle" width={100} height={100} />
                    ) : (
                      <>
                        <label htmlFor="change-profile">
                          <img
                            src={
                              avatarForShow === "" ? emptyAvatar : avatarForShow
                            }
                            className="settings-avatar"
                            alt="profile-avatar"
                          />
                        </label>
                        {avatarForShow !== "" ? (
                          <div
                            className="settings-remove-avatar-button"
                            onClick={removeAvatar}
                          >
                            <CancelIcon />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                  <h4>Postnummer</h4>
                  <small>
                    Används för att visa <em>ungefär</em> vart dina annonserade
                    varor finns på en karta.
                  </small>
                  <div className="settings-zipcode-container">
                    {loading ? (
                      <Skeleton variant="rect" height={33} />
                    ) : (
                      <input
                        type="number"
                        className="settings-zipcode"
                        autoComplete="postal-code"
                        placeholder="Postnummer"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    )}
                  </div>
                  <h4>Min hemort</h4>
                  <small>
                    Visa automatiskt annonser från Din hemort på startsidan.
                  </small>
                  <div className="settings-city-container">
                    {loading ? (
                      <Skeleton variant="rect" height={33} />
                    ) : (
                      <select
                        name="options"
                        className="settings-city"
                        onChange={handleCity}
                        value={city}
                      >
                        <option value="Sverige" disabled>
                          Välj din stad
                        </option>
                        {categories.choiceRegion.map((region, index) => (
                          <option key={index} value={region.value}>
                            {region.label}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  {user.fb_id === "0" ? (
                    <>
                      <h4>Lösenord</h4>
                      <div className="settings-password-container">
                        {loading ? (
                          <Skeleton variant="rect" height={33} />
                        ) : (
                          <input
                            type="password"
                            className="settings-password"
                            autoComplete="current-password"
                            placeholder="**********"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="settings-subscription-container">
                    {loading ? (
                      <Skeleton variant="text" width={170} />
                    ) : (
                      <div className="settings-subscription">
                        <input
                          type="checkbox"
                          id="settings-newsletter"
                          className="settings-buttons"
                          name="settings-newsletter"
                          onChange={() => handleNewsLetter()}
                          checked={newsLetter === "1" ? true : false}
                        />
                        <label
                          htmlFor="settings-newsletter"
                          className="settings-newsletter"
                        >
                          Ta emot nyhetsbrev
                        </label>
                      </div>
                    )}
                    {loading ? (
                      <Skeleton variant="text" width={170} />
                    ) : (
                      <div className="settings-subscription">
                        <input
                          type="checkbox"
                          id="settings-messages"
                          className="settings-buttons"
                          name="settings-messages"
                          onChange={() => handleMessageNotify()}
                          checked={msgNotify === "1" ? true : false}
                        />
                        <label
                          htmlFor="settings-messages"
                          className="settings-messages"
                        >
                          Meddelandenotiser på mail
                        </label>
                      </div>
                    )}
                    {loading ? (
                      <Skeleton variant="text" width={170} />
                    ) : (
                      <div className="settings-subscription">
                        <input
                          type="checkbox"
                          id="settings-consent"
                          className="settings-buttons"
                          name="settings-consent"
                          onChange={() => handleConsent()}
                          checked={consent === "1" ? true : false}
                        />
                        <label
                          htmlFor="settings-consent"
                          className="settings-consent"
                        >
                          Jag godkänner{" "}
                          <Link to="/anvandarvillkor">
                            annonseringsvillkoren
                          </Link>
                          .
                        </label>
                      </div>
                    )}
                  </div>
                  <div className="settings-buttons-container">
                    <div
                      className="settings-delete-container"
                      onClick={handleUserRemove}
                    >
                      <RemoveUserButton>
                        {loading ? (
                          <Skeleton variant="text" width={100} />
                        ) : (
                          "Radera konto"
                        )}
                      </RemoveUserButton>
                    </div>
                    <div
                      className="settings-save-container"
                      onClick={saveUserInfo}
                    >
                      <SaveButton>
                        {loading ? (
                          <Skeleton variant="text" width={60} />
                        ) : (
                          "Spara"
                        )}
                      </SaveButton>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mypage-banner-container">
        <div className="mypage-banner-first-wrapper">
          {loading ? <Skeleton variant="rect" width={1200} height={250} /> : ""}
        </div>
      </div>
      {emptyFields ? (
        <DialogComponent
          title="Något gick fel"
          text="Alla fält som är markerade med blått måste vara ifyllda"
          onClose={closeAll}
        />
      ) : failedPassword ? (
        <DialogComponent
          title="Något gick fel"
          text="Lösenordet som angivits är inte giltigt. Kom ihåg att använda minst åtta tecken samt en kombination av bokstäver, siffror och symboler."
          onClose={closeAll}
        />
      ) : failedMail ? (
        <DialogComponent
          title="Något gick fel"
          text="Mejladresssen som angivits är inte giltig"
          onClose={closeAll}
        />
      ) : failedConsent ? (
        <DialogComponent
          title="Något gick fel"
          text="Du måste acceptera annonseringsvillkoren för att fortsätta"
          onClose={closeAll}
        />
      ) : successChange ? (
        <DialogComponent
          title="Ändringarna lyckades"
          text="Dina ändringar är nu uppdaterade och sparade"
          onClose={closeAll}
        />
      ) : userRemove ? (
        <DialogComponent
          title="Ta bort konto"
          text="Är du säker att du vill ta bort ditt konto? All information kommer att förloras och du kommer inte längre kunna skapa annonser."
          onClose={closeAll}
          accept={removeUser}
        />
      ) : wrongFormat ? (
        <DialogComponent
          title="Fel format"
          text="Bilderna har fel filformat. De godkända filformaten är .png, .jpg, .jpeg"
          onClose={closeAll}
        />
      ) : somethingWrong ? (
        <DialogComponent
          title="Något gick fel"
          text="Något gick fel vilket betyder att dina ändringar inte har sparats. Försök igen om en liten stund."
          onClose={closeAll}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (userData) => dispatch(user(userData)),
    logoutUser: () => dispatch(logoutUser()),
  };
};

export default withCookies(
  connect(mapStateToProps, mapDispatchToProps)(Settings)
);
