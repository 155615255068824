import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import axios from "axios";

import ValidatePassword from "../helpers/ValidatePassword";

import DialogComponent from "../components/DialogComponent";

const SendButton = withStyles({
  root: {
    background: "rgb(52,64,86)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 40,
    padding: "0 30px",
    margin: 10,
    "&:hover": {
      backgroundColor: "rgb(52, 80, 99)",
    },
  },
})(Button);

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [passwordValidationFailed, setPasswordValidationFailed] =
    useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const loginLocation = {
    pathname: "/loggain",
    state: { fromResetPassword: true },
  };

  const closeAllModals = () => {
    setPasswordValidationFailed(false);
    setPasswordChanged(false);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const accept = () => {
    closeAllModals();
    window.location.replace(loginLocation.pathname);
  };

  const sendPassword = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tmpkey = urlParams.get("tmpkey");

    var obj = {
      password: newPassword,
      tmpkey: tmpkey,
    };

    if (ValidatePassword(newPassword)) {
      try {
        axios
          .post(process.env.REACT_APP_API_DOMAIN + "/cb/passwordReset", obj)
          .then((res) => {
            if (res.data === "🎉") {
              setPasswordChanged(true);
              setNewPassword("");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        console.log(err.message);
      }
    } else {
      setPasswordValidationFailed(true);
    }
  };

  return (
    <div className="resetpassword-container" style={{textAlign: "center"}}>
      <h3>Ändra lösenord</h3>
      <form>
        <input
          type="password"
          className="resetpassword-new"
          autoComplete="email"
          onChange={(e) => handleNewPassword(e)}
          style={{fontSize: "1rem", textAlign: "center", padding: "0.2rem"}}
        />
        <SendButton onClick={sendPassword}>Spara nytt lösenord</SendButton>
      </form>
      {passwordValidationFailed ? (
        <DialogComponent
          title="Något gick fel"
          text="Lösenordet kunde inte ändras på grund lösenordet inte innehåller minst åtta tecken. Komplettera även gärna med en kombination av bokstäver, siffror och symboler."
          onClose={closeAllModals}
        />
      ) : passwordChanged ? (
        <DialogComponent
          title="Lösenord har ändrats"
          text="Ditt lösenord har nu ändrats och du kan logga in med ditt nya lösenord."
          accept={accept}
          onClose={closeAllModals}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ResetPassword;
