import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Tooltip from "@material-ui/core/Tooltip";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import { withCookies } from "react-cookie";

import emptyAvatar from "../../images/empty-avatar.jpg";
import noImage from "../../images/noImage.png";

import ActionDialogComponent from "../../components/ActionDialogComponent";
import CityHandler from "../../helpers/CityHandler";
import UnixTimeHandler from "../../helpers/UnixTimeHandler";
import CategoryHandler from "../../helpers/CategoryHandler";

const mediaUrl = process.env.REACT_APP_MEDIA_DOMAIN + "/a/medium/";

const UserProfile = (props) => {
  const { cookies } = props;
  const [cookieId] = useState(cookies.get("id"));
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [userAds, setUserAds] = useState([]);

  const pathname = window.location.pathname;
  const userId = pathname.replace("/profil/", "");
  var headers = {
    "x-auth": cookieId,
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 200);
  // }, [loading]);

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      try {
        axios
          .all([
            axios.get(process.env.REACT_APP_API_DOMAIN + "/cb/user?user_id=" + userId, {
              headers: headers,
            }),
            axios.get(
              process.env.REACT_APP_API_DOMAIN + "/cb/annonslista?user_id=" + userId,
              { headers: headers }
            ),
          ])
          .then(
            axios.spread((userInfo, userAds) => {
              setUser(userInfo.data);
              setUserAds(userAds.data.annonser);
              setLoading(false);
            })
          );
      } catch (err) {
        console.log(err.message);
      }
    }
  }, [user, headers, userId]);

  return (
    <div className="userprofile mainwrapper">
      <div className="userprofile-container">
        <div className="userprofile-wrapper">
          <div className="userprofile-user">
            <figure>
              {loading ? (
                <Skeleton variant="rect" width={100} height={100} />
              ) : (
                <img
                  src={
                    user.image === ""
                      ? emptyAvatar
                      : "data:image/png;base64, " + user.image
                  }
                  alt="avatar"
                  className="userprofile-user-image"
                />
              )}
            </figure>
            <div className="userprofile-user-name">
              {loading ? (
                <Skeleton variant="text" width={200} height={50} />
              ) : (
                <>
                  <p>{user.name}</p>
                  {user.ort_id !== undefined || user.ort_id !== 0 ? (
                    <div className="userprofile-user-city">
                      <span>{CityHandler(user.ort_id)}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="userprofile-ads-container">
          <div className="userprofile-ads">
            {userAds.length > 0 && user.name !== undefined ? (
              userAds.map((ad, index) => (
                <Link
                  to={"/annons/" + ad.annons_id + "-" + ad.permalink}
                  key={index}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {loading ? (
                    <Skeleton
                      variant="rect"
                      height={130}
                      style={{ margin: 10 }}
                    />
                  ) : (
                    <div className="userprofile-ad" key={index}>
                      <div className="userprofile-ad-image-container">
                        <img
                          src={
                            ad.thumb === null
                              ? noImage
                              : mediaUrl + ad.thumb
                          }
                          className="userprofile-ad-image"
                          alt="ad"
                        />
                      </div>
                      <div className="userprofile-ad-info">
                        <div className="userprofile-ad-title">
                          <p>{ad.rubrik}</p>
                        </div>
                        <div className="userprofile-ad-price">
                          <p>{ad.pris} kr</p>
                        </div>
                        <div className="userprofile-ad-date">
                          <p>
                            Publicerad {UnixTimeHandler(ad.skapad)} i{" "}
                            {CategoryHandler(ad.kategori_id)}
                          </p>
                        </div>
                      </div>
                      <div className="userprofile-ad-link">
                        <p>Gå till annons</p>
                        <ArrowRightIcon />
                      </div>
                    </div>
                  )}
                </Link>
              ))
            ) : loading ? <Skeleton
            variant="rect"
            height={75}
            width="95%" 
            style={{ margin: 10 }}
          /> : (
              <div className="userprofile-no-ads">
                <p>Användaren har inte några annonser</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withCookies(UserProfile);
